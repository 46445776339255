import {
    CardHeader,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Link,
    TableCell,
    Typography,
} from "@mui/material";
import styled from "styled-components";

export const CardHeaderTitleStyled = styled(CardHeader)`
    && {
        span:first-child {
            font-size: .875rem;
            color: var(--gray-600);
            font-weight: 700;
        }
        span:last-child {
            font-size: .875rem;
            color: var(--gray-600);
            font-weight: 400;
        }
    }
`;

export const DivRadio = styled.div`
    display: 'flex';
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
    && {
        .MuiTypography-root {
            font-size: .75rem!important;
        }
            
        color: var(--gray-600);
    }
`;

export const FormDescription = styled(Typography)`
    font-size: 1rem;
    color: var(--gray-600);
    line-height: 1.5rem;
`;

export const FormHelperTextStyled = styled(FormHelperText)`
    && {
        color: #D32F2F;
    }
`;

export const FormLabelStyled = styled(FormLabel)`
    && {
        font-size: .75rem;
        font-weight: 700;
        color: var(--gray-600);
    }
`;

export const FormLabelInfoStyled = styled(Typography)`
    && {
        font-size: .75rem;
        font-weight: 400;
        color: var(--gray-600);
    }
`;

export const FormTitle = styled(Typography)`
    && {
        font-size: 2rem;
        font-weight: 700;
        color: #212121;
        text-align: center;
    }
`;

export const InfoTitle = styled(Typography)`
    font-size: .875rem;
    font-weight: 700;
    color: var(--gray-600);
`;

export const InfoDescription = styled(Typography)`
    font-size: .875rem;
    font-weight: 400;
    color: var(--gray-600);
`;

export const InfoSubSubtitlePageForm = styled(Typography)`
    && {
        font-size: .875rem;
        font-weight: 400;
        color: var(--gray-600);
    }
`;

export const Span = styled.span`
    color: var(--brandColor-01);
    font-weight: 700;
`;

export const SpanStyled = styled.span`
    font-size: .875rem;
    color: var(--gray-600);
`;

export const SubSubtitlePageForm = styled(Typography)`
    && {
        font-size: 1rem;
        font-weight: 700;
        color: var(--gray-600);
    }
`;

export const SubtitlePageForm = styled(Typography)`
    && {
        font-size: 1.25rem;
        font-weight: 700;
        color: var(--gray-600);
    }
`;

export const TableCellTitleStyled = styled(TableCell)`
    && {
        font-size: .875rem;
        color: var(--gray-600);
        font-weight: 700;
    }
`;

export const TableCellBodyStyled = styled(TableCell)`
    && {
        font-size: .875rem;
        color: var(--gray-900);
        font-weight: 400;
    }
`;

export const LinkCheckbox = styled(Link) <{
    component?: React.ElementType
}>`
    && {
        .MuiTypography-root {
            font-size: .75rem;
            font-weight: 700;
        }
            
        color: var(--brandColor-01);
    }
`;