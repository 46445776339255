import { createTheme, ThemeProvider } from '@mui/material';
import React, { useEffect } from 'react';
import ModalContainer from 'react-modal-promise';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from "./contexts/auth";
import { AxiosInterceptor } from "./services/api";
import { GlobalStyle } from './styles/global';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';

const defaultTheme = createTheme({
  typography: {
    fontFamily: ['Noto Sans', 'sans-serif'].join(',')
  },
  palette: {
    primary: {
      main: "#2F7BC0",
    },
  },
});

export function App() {
  useEffect(() => {
    document.title = `FXMoneyForm`;
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <BrowserRouter>
        <AuthProvider>
          <AxiosInterceptor>
            <Routes />
          </AxiosInterceptor>
          <ModalContainer />
        </AuthProvider>
        <ToastContainer />
        <GlobalStyle />
      </BrowserRouter>
    </ThemeProvider>
  );
}
