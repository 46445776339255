import {
  AccountBalanceOutlined,
  Close,
  DescriptionOutlined,
  HistoryEduOutlined,
  HomeOutlined,
  Logout,
  Menu,
  PaidOutlined,
  PeopleOutlined,
  PersonOutlined,
  PhoneOutlined,
  StoreOutlined,
  UpdateOutlined,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Dialog,
  DialogActions,
  Divider,
  Drawer,
  IconButton,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { ReactNode, useContext, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  NavLink,
  Outlet,
  To,
  useLocation,
  useNavigate,
} from "react-router-dom";
import LogoMoneycorp from "../../../assets/image/moneycorp.svg";
import { Button } from "../../../components/Button";
import { LanguageSelect } from "../../../components/LanguageSelect";
import { AuthContext } from "../../../contexts/auth";
import {
  AvatarStyled,
  DialogContentStyled,
  DialogTitleStyled,
  ListItemIconStyled,
  StyledList,
  ToolbarStyled,
  ToolbarUserInfoStyled,
  UserInfo,
  UserNameStyled,
} from "./style";

const drawerWidth = "296px";

interface MyNavLinkProps {
  to: To;
  className: any;
  activeClassName: any;
  end: boolean | undefined;
  children: ReactNode | ((props: any) => ReactNode);

  // component: any;
  // href: string;
}

const MyNavLink = React.forwardRef<HTMLAnchorElement, MyNavLinkProps>(
  (props, ref) => (
    <NavLink
      ref={ref}
      to={props.to}
      className={({ isActive }) =>
        `${props.className} ${isActive ? props.activeClassName : ""}`
      }
      end={props.end}
    >
      {props.children}
    </NavLink>
  )
);


export function DefaultDrawer() {
  const location = useLocation();
  const currentPath = location.pathname;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { user, handleSignOut } = useContext(AuthContext);
  const {
    value: {
      name,
      type,
      graphicAccount,
      iban,
      productId,
      sa,
      canEdit,
      stateCode,
      update,
      updateStateCode
    },
  } = user;

  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerItems = (
    <>
      <div>
        <ToolbarStyled>
          <img src={LogoMoneycorp} alt="Moneycorp banco de câmbio" />
          <IconButton
            onClick={handleDrawerToggle}
            sx={{
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
                xl: "none",
              },
              color: "var(--white)",
            }}
          >
            <Close />
          </IconButton>
        </ToolbarStyled>
        <StyledList>
          <ListItemButton
            disabled={!canEdit}
            LinkComponent={MyNavLink}
            selected={currentPath === "/dashboard" ? true : false}
            onClick={() => navigate("/dashboard")}
          >
            <ListItemIconStyled>
              <HomeOutlined />
            </ListItemIconStyled>
            <ListItemText primary={t("text.home")} />
          </ListItemButton>
          {type === 1 && (
            <>
              <ListItemButton
                disabled={!canEdit}
                LinkComponent={MyNavLink}
                selected={
                  currentPath === "/dashboard/contact-reason" ? true : false
                }
                onClick={() => navigate("/dashboard/contact-reason")}
              >
                <ListItemIconStyled>
                  <PersonOutlined />
                </ListItemIconStyled>
                <ListItemText primary={t("text.profileRegistered")} />
              </ListItemButton>

              <ListItemButton
                disabled={!update || updateStateCode !== "0"}
                selected={currentPath === "/update/personal-data-update" ? true : false}
                LinkComponent={MyNavLink}
                onClick={() => navigate("/update/personal-data-update")}
              >
                <ListItemIconStyled>
                  <UpdateOutlined />
                </ListItemIconStyled>
                <ListItemText primary={t("text.updateInformation")} />
              </ListItemButton>

              <ListItemButton
                disabled={!canEdit}
                LinkComponent={MyNavLink}
                selected={currentPath === "/dashboard/contacts" ? true : false}
                onClick={() => navigate("/dashboard/contacts")}
              >
                <ListItemIconStyled>
                  <PhoneOutlined />
                </ListItemIconStyled>
                <ListItemText primary={t("text.contacts")} />
              </ListItemButton>
              <ListItemButton
                disabled={!canEdit}
                LinkComponent={MyNavLink}
                //   className={"active"}
                selected={currentPath === "/dashboard/bank-details" ? true : false}
                onClick={() => navigate("/dashboard/bank-details")}
              // component={MyNavLink}
              // activeClassName={"active"}
              // to="/dashboard/bank-details"
              // key="/dashboard/bank-details"
              >
                <ListItemIconStyled>
                  <AccountBalanceOutlined />
                </ListItemIconStyled>
                <ListItemText primary={t("text.bankInformation")} />
              </ListItemButton>
              {productId === "6c7e3226-bd70-ed11-81ac-0022481b5e08" ||
                productId === "0141d350-bd70-ed11-81ac-0022481b5e08" ? (
                <ListItemButton
                  disabled={!canEdit}
                  LinkComponent={MyNavLink}
                  selected={currentPath === "/dashboard/pre-paid-card" ? true : false}
                  onClick={() => navigate("/dashboard/pre-paid-card")}
                // component={MyNavLink}
                //   activeClassName={"active"}
                //   to="/dashboard/pre-paid-card"
                //   key="/dashboard/pre-paid-card"
                >
                  <ListItemIconStyled>
                    <PaidOutlined />
                  </ListItemIconStyled>
                  <ListItemText primary={t("text.prePaidCardInformation")} />
                </ListItemButton>
              ) : null}
              <ListItemButton
                disabled={!canEdit}
                LinkComponent={MyNavLink}
                //   className={"active"}
                selected={currentPath === "/dashboard/power-of-attorney" ? true : false}
                onClick={() => navigate("/dashboard/power-of-attorney")}

              >
                <ListItemIconStyled>
                  <HistoryEduOutlined />
                </ListItemIconStyled>
                <ListItemText primary={t("text.powerOfAttorney")} />
              </ListItemButton>
              <ListItemButton
                disabled={!canEdit}
                LinkComponent={MyNavLink}

                selected={currentPath === "/dashboard/registration-documents" ? true : false}
                onClick={() => navigate("/dashboard/registration-documents")}

              >
                <ListItemIconStyled>
                  <DescriptionOutlined />
                </ListItemIconStyled>
                <ListItemText primary={t("text.registrationDocuments")} />
              </ListItemButton>
            </>
          )}
          {type === 2 && (
            <>
              <ListItemButton
                disabled={!canEdit}
                LinkComponent={MyNavLink}
                selected={currentPath === "/dashboard/contact-reason-legal-entity" ? true : false}
                onClick={() =>
                  navigate("/dashboard/contact-reason-legal-entity")
                }

              >
                <ListItemIconStyled>
                  <StoreOutlined />
                </ListItemIconStyled>
                <ListItemText primary={t("text.profileRegistered")} />
              </ListItemButton>


              <ListItemButton
                disabled={!update || updateStateCode !== "0"}
                LinkComponent={MyNavLink}
                selected={currentPath === "/update/corporate-data-update" ? true : false}
                onClick={() => navigate("/update/corporate-data-update")}
              >
                <ListItemIconStyled>
                  <UpdateOutlined />
                </ListItemIconStyled>
                <ListItemText primary={t("text.updateInformation")} />
              </ListItemButton>


              <ListItemButton
                disabled={!canEdit}
                LinkComponent={MyNavLink}
                selected={currentPath === "/dashboard/administration" ? true : false}
                onClick={() => navigate("/dashboard/administration")}

              >
                <ListItemIconStyled>
                  <PeopleOutlined />
                </ListItemIconStyled>
                <ListItemText primary={t("text.administrator")} />
              </ListItemButton>
              {!sa && (
                <ListItemButton
                  disabled={!canEdit}
                  LinkComponent={MyNavLink}
                  selected={currentPath === "/dashboard/corporate-structure" ? true : false}
                  onClick={() => navigate("/dashboard/corporate-structure")}

                >
                  <ListItemIconStyled>
                    <PeopleOutlined />
                  </ListItemIconStyled>
                  <ListItemText primary={t("text.corporateStructure")} />
                </ListItemButton>
              )}
              {/* <ListItemButton
                                component={MyNavLink}
                                activeClassName={'active'}
                                to="/dashboard/register-subsidiary-legal-entity"
                                key="/dashboard/register-subsidiary-legal-entity"
                            >
                                <ListItemIconStyled>
                                    <AddBusinessOutlined />
                                </ListItemIconStyled>
                                <ListItemText primary="Cadastrar filial" />
                            </ListItemButton> */}
              <ListItemButton
                disabled={!canEdit}
                LinkComponent={MyNavLink}
                selected={currentPath === "/dashboard/bank-details-legal-entity" ? true : false}
                onClick={() => navigate("/dashboard/bank-details-legal-entity")}
              >
                <ListItemIconStyled>
                  <AccountBalanceOutlined />
                </ListItemIconStyled>
                <ListItemText primary={t("text.bankInformation")} />
              </ListItemButton>
              <ListItemButton
                disabled={!canEdit}
                LinkComponent={MyNavLink}
                selected={currentPath === "/dashboard/contacts-legal-entity" ? true : false}
                onClick={() => navigate("/dashboard/contacts-legal-entity")}
              >
                <ListItemIconStyled>
                  <PhoneOutlined />
                </ListItemIconStyled>
                <ListItemText primary={t("text.contacts")} />
              </ListItemButton>
              <ListItemButton
                disabled={!canEdit}
                LinkComponent={MyNavLink}
                selected={currentPath === "/dashboard/power-of-attorney-legal-entity" ? true : false}
                onClick={() =>
                  navigate("/dashboard/power-of-attorney-legal-entity")
                }

              >
                <ListItemIconStyled>
                  <HistoryEduOutlined />
                </ListItemIconStyled>
                <ListItemText primary={t("text.powerOfAttorney")} />
              </ListItemButton>
              <ListItemButton
                disabled={!canEdit}
                LinkComponent={MyNavLink}
                selected={currentPath === "/dashboard/registration-documents" ? true : false}
                onClick={() => navigate("/dashboard/registration-documents")}
              // disabled={!canEdit}
              // component={MyNavLink}
              // activeClassName={"active"}
              // to="/dashboard/registration-documents"
              // key="/dashboard/registration-documents"
              >
                <ListItemIconStyled>
                  <DescriptionOutlined />
                </ListItemIconStyled>
                <ListItemText primary={t("text.registrationDocuments")} />
              </ListItemButton>
            </>
          )}
        </StyledList>
      </div>
      <div>
        <div>
          <Divider
            sx={{
              borderColor: "var(--gray-300-withOpacity)",
            }}
          />
          <StyledList>
            <ListItemButton onClick={handleDialog}>
              <ListItemIconStyled>
                <Logout />
              </ListItemIconStyled>
              <ListItemText primary={t("text.logout")} />
            </ListItemButton>
          </StyledList>
        </div>
        <ToolbarUserInfoStyled>
          <UserInfo>
            <AvatarStyled>{name.substr(0, 1)}</AvatarStyled>
            <Box
              sx={{
                wordBreak: "break-all",
              }}
            >
              <UserNameStyled>{name}</UserNameStyled>
              {stateCode === "1" ? (
                <UserData graphicAccount={graphicAccount} iban={iban} />
              ) : null}
            </Box>
          </UserInfo>
          <LanguageSelect />
        </ToolbarUserInfoStyled>
      </div>
    </>
  );

  const drawerDesktop = (
    <Drawer
      variant="permanent"
      anchor="left"
      open={true}
      sx={{
        display: {
          xs: "none",
          sm: "none",
          md: "block",
          lg: "block",
          xl: "block",
        },
        width: drawerWidth,
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
          background: "var(--brandColor-dark-blue)",
        },
        "& .MuiPaper-root": {
          borderRight: "1px solid var(--gray-300-withOpacity)",
          display: "flex",
          justifyContent: "space-between",
        },
      }}
    >
      {drawerItems}
    </Drawer>
  );

  const drawerMobile = (
    <Drawer
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        boxSizing: "border-box",
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          display: "flex",
          justifyContent: "space-between",
          background: "var(--brandColor-dark-blue)",
        },
      }}
    >
      {drawerItems}
    </Drawer>
  );

  useLayoutEffect(() => {
    document.body.style.background = "var(--background)";
  });

  return (
    <>
      {/* Dialog sair */}
      <Dialog open={openDialog} onClose={handleDialog} fullWidth>
        <DialogTitleStyled>{t("text.logout")}</DialogTitleStyled>
        <DialogContentStyled>
          {t("text.areYourSureYouWantToLogout")}
        </DialogContentStyled>
        <DialogActions>
          <Button label={t("text.no")} onClick={handleDialog} />
          <Button label={t("text.yes")} onClick={handleSignOut} />
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          "@media(min-width:900px)": {
            display: "flex",
          },
        }}
      >
        <AppBar
          position="sticky"
          sx={{
            "@media(min-width:900px)": {
              display: "none",
            },
            background: "var(--brandColor-dark-blue)",
            color: "var(--white)",
          }}
        >
          <Toolbar>
            <IconButton
              size="large"
              color="inherit"
              aria-label="Menu"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                svg: {
                  fontSize: "2rem",
                },
              }}
            >
              <Menu />
            </IconButton>
          </Toolbar>
        </AppBar>
        {drawerDesktop}
        {drawerMobile}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            margin: "2rem",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

const UserData = ({
  graphicAccount,
  iban,
}: {
  graphicAccount: string;
  iban: string;
}) => {
  if (!graphicAccount) return null;

  return (
    <>
      <Typography
        sx={{
          fontSize: ".75rem",
          fontWeight: "400",
          color: "rgba(253, 253, 253, 0.7)",
        }}
      >
        Conta gráfica: {graphicAccount}
      </Typography>
      <Typography
        sx={{
          fontSize: ".75rem",
          fontWeight: "400",
          color: "rgba(253, 253, 253, 0.7)",
        }}
      >
        IBAN: {iban}
      </Typography>
    </>
  );
};
