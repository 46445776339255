import { isValidCEP } from '@brazilian-utils/brazilian-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { Search } from '@mui/icons-material';
import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { Button } from '../../../components/Button';
import { CRMAddressStateOptions } from '../../../components/CRMAddressStateOptions';
import { LoadingBox } from '../../../components/LoadingBox';
import { LoadingButton } from '../../../components/LoadingButton';
import { PageName } from '../../../components/PageName';
import { TextField } from '../../../components/TextField';
import { Label } from "../../../components/TextField/styles";
import { AuthContext } from '../../../contexts/auth';
import { findAddressByCep } from "../../../services/cepService";
import { getAddressStatePromise } from '../../../services/crmAddressState';
import { getAddressPromise, saveAddress } from '../../../services/onboardingService';
import { FormHelperTextStyled } from '../../../styles/styles';
import { displayErrorMessage } from '../../../utils/errorMessage';
import * as masks from "../../../utils/masks";
import { LegalEntityStepper } from '../../../components/LegalEntityStepper';
import { removeSpecialCharactersAndAccents } from '../../../utils/stringUtils';
import { getCRMCountriesPromise } from "../../../services/crmCountryService";
import { CRMCountryOptions } from "../../../components/CRMCountryOptions";
import { AddressState } from '../../../components/EditAddressModal/AddressState';

const validationSchema = yup.object({
    country: yup.string().required(),
    addressZipCode: yup.string()
        .when("country", {
            is: "BR",
            then: yup.string().required()
                .transform(masks.cepMask.transform)
                .test("validateCep", (value) => {
                    return isValidCEP(value!)
                }),
            otherwise: yup.string().required()
        }),
    addressStreet: yup.string().required(),
    addressNumber: yup.string().required(),
    addressNeighborhood: yup.string()
        .when("country", {
            is: "BR",
            then: yup.string().required()
        }),
    addressCity: yup.string().required(),
    addressState: yup.string()
        .when("country", {
            is: "BR",
            then: yup.string().required()
        }),
    addressComplement: yup.string(),
    legalNature: yup.string(),
});

export function AddressLegalEntityRegister() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { handleSignOut } = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [addressStateOptions, setAddressStateOptions] = useState<AddressState[]>([]);
    const [countryOptions, setCountryOptions] = useState([]);

    const { control, handleSubmit, formState: { errors }, getValues, reset, setFocus } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            country: "BR",
            addressZipCode: "",
            addressStreet: "",
            addressNumber: "",
            addressNeighborhood: "",
            addressCity: "",
            addressState: "",
            addressComplement: "",
            legalNature: "",
        }
    });

    const legalNature = useWatch({
        control,
        name: "legalNature",
    });

    const country = useWatch({
        control,
        name: "country"
    });

    const countryChanged = () => {

        const values = {
            ...getValues(),
            addressZipCode: "",
            addressStreet: "",
            addressNeighborhood: "",
            addressCity: "",
            addressState: "",
            addressComplement: "",
            addressNumber: "",
        }

        setFocus("addressZipCode", { shouldSelect: true });
        reset(values);
    }

    useEffect(() => {
        const fetchAddress = async () => {
            try {
                const [addressStateOptions, countryOptions, addressRegister] = await Promise.all([
                    getAddressStatePromise(),
                    getCRMCountriesPromise(),
                    getAddressPromise()
                ]);


                setAddressStateOptions(addressStateOptions.data);
                setCountryOptions(countryOptions.data);

                const responseData = addressRegister.data;

                const values = {
                    ...responseData,
                    country: responseData.country === "" ? "BR" : responseData.country,
                    addressZipCode: responseData.country === "BR" ? masks.cepMask.maskDefault(responseData.addressZipCode) : responseData.addressZipCode,
                }

                reset(values);

                setLoading(false);
            } catch (error: any) {
                const errorCode = error.response?.data?.errorCode;
                if (errorCode === "12000") {
                    navigate('/dashboard');
                }
            }
        }
        fetchAddress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = async (data: any) => {
        setSubmitting(true);

        try {

            let newData = {
                ...data,
                state: data.country === "BR" ? data.state : ""
            }

            await saveAddress(newData);
            navigate('/onboarding/banking-references-legal-entity-register');
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    };

    const findByCep = async () => {
        const zipCode = masks.cepMask.unmask(getValues("addressZipCode"));
        if (zipCode === "" || zipCode.length !== 8) return;
        try {
            const response = await findAddressByCep(zipCode);
            if (response.data.erro) {
                toast.error(`${t("validations.findAddress")}`);
                setFocus("addressZipCode");
                return;
            }

            const state = addressStateOptions.find(s => s.state === response.data.uf);
            const values = {
                ...getValues(),
                addressStreet: removeSpecialCharactersAndAccents(response.data.logradouro),
                addressNeighborhood: removeSpecialCharactersAndAccents(response.data.bairro),
                addressCity: removeSpecialCharactersAndAccents(response.data.localidade),
                addressState: state?.id,
                addressComplement: removeSpecialCharactersAndAccents(response.data.complemento),
            }

            setFocus("addressNumber", { shouldSelect: true });
            reset(values);
        } catch (error) {
            console.log('error', error);
            toast.error(t("text.toast.zipCodeServiceUnavailable"));
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={submitting} style={{ border: 0 }}>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '2rem',
                    }}
                >
                    {loading
                        ? <LoadingBox />
                        : <React.Fragment>
                            <LegalEntityStepper
                                activeStep={2}
                                legalNature={legalNature}
                            />
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={10}
                                lg={10}
                                xl={10}
                                sx={{
                                    background: 'var(--white)',
                                    borderRadius: '4px',
                                    padding: '2rem',
                                    // '@media(max-width: 899px)': {
                                    //     padding: '0rem',
                                    // }
                                }}
                            >
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <PageName
                                            title={t("text.address")}
                                            info={t("text.foreignAddressesCantBeAdded")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <CRMCountryOptions
                                                    name="country"
                                                    control={control}
                                                    countries={countryOptions}
                                                    error={!!errors.country}
                                                    countryChanged={countryChanged}
                                                />
                                                <FormHelperTextStyled>
                                                    {displayErrorMessage(errors.country?.message) as string}
                                                </FormHelperTextStyled>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={9}
                                                lg={2}
                                                xl={2}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="addressZipCode"
                                                    render={({ field }) => (
                                                        <TextField
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                e.persist();
                                                                if (country === "BR") masks.cepMask.onChange(e);
                                                                field.onChange(e);
                                                            }}
                                                            inputRef={field.ref}
                                                            label={t("text.zipCode")}
                                                            placeholder={t("text.zipCode") as string}
                                                            inputProps={{ maxLength: 20 }}
                                                            fullWidth
                                                            error={!!errors.addressZipCode}
                                                            helperText={displayErrorMessage(errors?.addressZipCode?.message) as string}
                                                            variant='outlined'
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            {country === "BR" ?
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={3}
                                                    lg={2}
                                                    xl={2}
                                                >
                                                    <Label>&nbsp;</Label>
                                                    <Button
                                                        type="button"
                                                        label={t("text.search")}
                                                        variant="outlined"
                                                        startIcon={<Search />}
                                                        onClick={findByCep}
                                                        disabled={submitting}
                                                        fullWidth
                                                        style={{
                                                            marginTop: '.25rem',
                                                            marginBottom: '0!important',
                                                        }}
                                                    />
                                                </Grid> : null}
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={9}
                                                lg={country === "BR" ? 5 : 7}
                                                xl={country === "BR" ? 5 : 7}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="addressStreet"
                                                    render={({ field }) => (
                                                        <TextField
                                                            value={field.value}
                                                            onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                            inputRef={field.ref}
                                                            label={t("text.streetName")}
                                                            placeholder={t("text.streetName") as string}
                                                            inputProps={{ maxLength: 250 }}
                                                            fullWidth
                                                            error={!!errors.addressStreet}
                                                            helperText={displayErrorMessage(errors?.addressStreet?.message) as string}
                                                            variant='outlined'
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={3}
                                                lg={3}
                                                xl={3}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="addressNumber"
                                                    render={({ field }) => (
                                                        <TextField
                                                            value={field.value}
                                                            onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                            inputRef={field.ref}
                                                            label={t("text.number")}
                                                            placeholder={t("text.number") as string}
                                                            inputProps={{ maxLength: 10 }}
                                                            fullWidth
                                                            error={!!errors.addressNumber}
                                                            helperText={displayErrorMessage(errors?.addressNumber?.message) as string}
                                                            variant='outlined'
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={country === "BR" ? 6 : 12}
                                                lg={country === "BR" ? 6 : 12}
                                                xl={country === "BR" ? 6 : 12}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="addressComplement"
                                                    render={({ field }) => (
                                                        <TextField
                                                            value={field.value}
                                                            onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                            inputRef={field.ref}
                                                            label={t("text.complement")}
                                                            placeholder={t("text.complement") as string}
                                                            inputProps={{ maxLength: 100 }}
                                                            fullWidth
                                                            variant='outlined'
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            {country === "BR" ?
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="addressNeighborhood"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                                inputRef={field.ref}
                                                                label={t("text.neighborhood")}
                                                                placeholder={t("text.neighborhood") as string}
                                                                inputProps={{ maxLength: 100 }}
                                                                fullWidth
                                                                error={!!errors.addressNeighborhood}
                                                                helperText={displayErrorMessage(errors?.addressNeighborhood?.message) as string}
                                                                variant='outlined'
                                                            />
                                                        )}
                                                    />
                                                </Grid> : null}
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={country === "BR" ? 9 : 12}
                                                lg={country === "BR" ? 9 : 12}
                                                xl={country === "BR" ? 9 : 12}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="addressCity"
                                                    render={({ field }) => (
                                                        <TextField
                                                            value={field.value}
                                                            onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                            inputRef={field.ref}
                                                            label={t("text.city")}
                                                            placeholder={t("text.city") as string}
                                                            inputProps={{ maxLength: 80 }}
                                                            fullWidth
                                                            error={!!errors.addressCity}
                                                            helperText={displayErrorMessage(errors?.addressCity?.message) as string}
                                                            variant='outlined'
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            {country === "BR" ?
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                    lg={3}
                                                    xl={3}
                                                >
                                                    <CRMAddressStateOptions
                                                        name="addressState"
                                                        label={t("text.uf")}
                                                        control={control}
                                                        states={addressStateOptions}
                                                        error={!!errors.addressState}
                                                    />
                                                    <FormHelperTextStyled>
                                                        {displayErrorMessage(errors.addressState?.message) as string}
                                                    </FormHelperTextStyled>
                                                </Grid> : null}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    label={t("text.previousStep")}
                                                    component={Link}
                                                    to="/onboarding/overall-data-legal-entity-register"
                                                    fullWidth
                                                    disabled={submitting}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                            >
                                                <LoadingButton
                                                    type="submit"
                                                    variant="contained"
                                                    label={t("text.nextStep")}
                                                    loading={submitting}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <Button
                                                    variant="text"
                                                    label={t("text.continueLater")}
                                                    onClick={handleSignOut}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </fieldset>
        </form >
    );
}