import { useTranslation } from "react-i18next";
import { SubSubtitlePageForm } from "../../styles/styles";
import { documentInfo } from "../../utils/documentInfo";
import React from "react";

interface DocumentSubtitleProps {
    hasDriverLicense:string, 
    typeOfDocument :string
}

export const DocumentSubtitle : React.FC<DocumentSubtitleProps> = ({ hasDriverLicense, typeOfDocument }) => {
    const selectedDocumentInfo = documentInfo.find(d => d.id === typeOfDocument);
    const { t } = useTranslation();

    return (
        <SubSubtitlePageForm>
            {
                hasDriverLicense === "1"
                    ? t("text.attachYourCNH")
                    : t(selectedDocumentInfo?.subtitle!)
            }
        </SubSubtitlePageForm>
    )
}
