/* eslint-disable @typescript-eslint/no-unused-vars */
import { formatCPF, isValidCPF, isValidEmail } from '@brazilian-utils/brazilian-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    FormControl,
    Radio,
    RadioGroup,
    Divider,
    Card,
    CardActions,
    Typography,
} from '@mui/material';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useState, useRef } from "react";
import { Controller, useForm, useFieldArray, useWatch, FieldValues } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { create } from 'react-modal-promise';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { sub } from 'date-fns';
import { Button } from '../Button';
import { LoadingBox } from '../LoadingBox';
import { LoadingButton } from '../LoadingButton';
import { TextField } from '../TextField';
import {
    FormControlLabelStyled,
    FormHelperTextStyled,
    FormLabelInfoStyled,
    FormLabelStyled,
    SubtitlePageForm
} from '../../styles/styles';
import * as masks from "../../utils/masks";
import { languages } from '../../utils/adapterLocaleUtils';
import { displayErrorMessage } from '../../utils/errorMessage';
import { DialogTitleStyled } from '../DeleteModal/styles';
import { getPersonalDataUpdatePromise, updatePersonalData } from '../../services/updateService';
import { stripToEmpty, removeSpecialCharactersAndAccents } from "../../utils/stringUtils";
import { getAddressStatePromise } from '../../services/crmAddressState';
import { getCRMDocumentTypePromise } from '../../services/crmDocumentTypeService';
import { getCRMDdiPromise } from '../../services/crmDdi';
import { CRMAddressStateOptions } from '../CRMAddressStateOptions';
import { CRMDdiOptions } from '../CRMDdiOptions';
import { hasCellPhone, hasNumber, hasPhone } from '../../utils/phoneUtils';
import { DocumentAlert } from '../DocumentAlert';
import { DocumentSubtitle } from '../DocumentSubtitle';
import { DocumentHead } from '../DocumentHead';
import Document from '../Document';
import { CRMDocumentTypeOptions } from '../CRMDocumentTypeOptions';
import DocumentAdditional from '../DocumentAdditional';
import { Add } from '@mui/icons-material';
import { toBase64 } from '../../utils/fileUtils';

const validationSchema = yup.object({
    productId: yup.string(),
    estimatedValueId: yup.string(),
    identificationRequested: yup.string(),
    taxId: yup.string()
        .required()
        .transform(masks.cpfMask.transform)
        .test("validateCPF", (value) => {
            return isValidCPF(value!)
        }),
    name: yup.string()
        .min(2)
        .required(),
    dateOfBirth: yup.date()
        .when("productId", (productId) => {
            if (productId === "6c7e3226-bd70-ed11-81ac-0022481b5e08")
                return yup.date().typeError('Erro').required().max(sub(new Date(), { years: 16 }), "validations.under16")
            else
                return yup.date().typeError('Erro').required().max(sub(new Date(), { years: 18 }), "validations.under18")
        }),
    birthCity: yup.string().required(),
    birthState: yup.string().required(),
    motherName: yup.string()
        .min(2)
        .required(),
    cellPhoneDdi: yup.string().nullable()
        .test('hasNumber', 'Campo obrigatório', (value, context) => {
            return hasNumber(context);
        })
        .test('hasCellPhone', 'Campo obrigatório', (value, context) => {
            return hasCellPhone(context);
        }),
    cellPhoneDdd: yup.string().nullable()
        .test('hasNumber', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasCellPhone', 'validations.required', (value, context) => {
            return hasCellPhone(context);
        })
        .matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    cellPhone: yup.string().nullable()
        .test('hasCellPhone', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasPhone', 'validations.required', (value, context) => {
            return hasCellPhone(context);
        })
        .matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    phoneDdi: yup.string().nullable()
        .test('hasNumber', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasPhone', 'validations.required', (value, context) => {
            return hasPhone(context);
        }),
    phoneDdd: yup.string().nullable()
        .test('hasNumber', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasPhone', 'validations.required', (value, context) => {
            return hasPhone(context);
        })
        .matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    phone: yup.string().nullable()
        .test('custom', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasPhone', 'validations.required', (value, context) => {
            return hasPhone(context);
        })
        .matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    email: yup.string()
        .required()
        .min(10)
        .transform((curr, orig) => stripToEmpty(curr))
        .test("validateEmail", (value) => isValidEmail(stripToEmpty(value))),
    document: yup.object({
        hasDriverLicense: yup.string()
            .required(),
        documentTypeId: yup.string()
            .when("hasDriverLicense", {
                is: "2",
                then: yup.string().required()
            }),
        documentNumber: yup.string()
            .required()
            .matches(/^[a-zA-Z0-9]+$/, "validations.notSpecialCharacter"),
        documentIssuer: yup.string().required(),
        documentStateIssuer: yup.string().required(),
        documentDateOfIssue: yup.date()
            .nullable()
            .transform((curr, orig) => orig === '' ? null : curr)
            .typeError("Erro")
            .required()
            .min(new Date(1500, 0, 1))
            .max(new Date()),
    }),
    usPerson: yup.string().required(),
    nif: yup.string()
        .when("usPerson", {
            is: "1",
            then: yup.string().required(),
        }),
    isPep: yup.string().required(),
    isPepExplain: yup.string()
        .when("isPep", {
            is: "120240000",
            then: yup.string().required()
        }),
    proof: yup.mixed()
        .when("identificationRequested", {
            is: "1",
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                }),
        }),
    documents: yup.array()
        .of(
            yup.object().shape({
                id: yup.string(),
                documentProof: yup.mixed()
                    .test("required", "validations.fileRequired", (value) => value.length > 0)
                    .test("fileSize", "validations.fileSize", (value) => {
                        return value.length && value[0].size <= 20971520;
                    })
                    .test("fileType", "validations.fileType", (value) => {
                        return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                    }),
            })
        ),
});

interface EditPersonalDataModalProps {
    open: boolean;
    onResolve: () => void;
    onReject: () => void;
}

const EditPersonalDataModal = ({ open, onResolve, onReject }: EditPersonalDataModalProps) => {
    const { i18n, t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [addressStateOptions, setAddressStateOptions] = useState([]);
    const [documentTypeOptions, setDocumentTypeOptions] = useState([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const [ddis, setDdis] = useState([]);
    const proofRef = useRef<any>(null);

    const { control, handleSubmit, formState: { errors }, reset, resetField, setValue, register, getValues } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            stepIndex: "",
            productId: "",
            identificationRequested: "",
            estimatedValueId: "",
            taxId: "",
            name: "",
            dateOfBirth: "",
            birthCity: "",
            birthState: "",
            motherName: "",
            phoneDdi: "",
            phoneDdd: "",
            phone: "",
            cellPhoneDdi: "",
            cellPhoneDdd: "",
            cellPhone: "",
            email: "",
            document: {
                documentId: "",
                hasDriverLicense: "",
                documentTypeId: "",
                documentNumber: "",
                documentIssuer: "",
                documentStateIssuer: "",
                documentDateOfIssue: "",
            },
            usPerson: "",
            nif: "",
            isPep: "",
            isPepExplain: "",
            proof: "",
        }
    });

    const { fields, append, remove } = useFieldArray<any, string, "keyName">({
        control,
        name: "documents",
        keyName: "keyName",
    });

    const estimatedValueId = useWatch({
        control,
        name: "estimatedValueId"
    });

    const usPerson = useWatch({
        control,
        name: "usPerson",
    });

    const isPep = useWatch({
        control,
        name: "isPep",
    });

    const identificationRequested = useWatch({
        control,
        name: "identificationRequested",
    });

    const hasDriverLicense = useWatch({
        control,
        name: "document.hasDriverLicense",
    });

    const documentTypeId = useWatch({
        control,
        name: "document.documentTypeId",
    });

    useEffect(() => {
        async function fetchPersonalData() {
            try {

                const [addressStateOptions, documentTypeOptions, ddisOptions, customer] = await Promise.all([
                    getAddressStatePromise(),
                    getCRMDocumentTypePromise(),
                    getCRMDdiPromise(),
                    getPersonalDataUpdatePromise()
                ]);

                setAddressStateOptions(addressStateOptions.data);
                setDocumentTypeOptions(documentTypeOptions.data);
                setDdis(ddisOptions.data);

                const _responseData = customer.data;

                const document = _responseData.document;
                var _proof: any;
                if (document?.id) {
                    _proof = new File(["proof"], document.fileOriginalName, {
                        type: document.fileType,
                    });
                    _proof.id = document.id;
                }

                let responseData = {
                    ..._responseData,
                    taxId: formatCPF(_responseData.taxId),
                    proof: _proof ? [].concat(_proof) : "",
                };

                if (responseData?.documents) {
                    let documents = [];

                    for (let i = 0; i < responseData.documents.length; i = i + 1) {

                        var _documentProof: any = new File(["documentProof"], responseData.documents[i].fileOriginalName, {
                            type: responseData.documents[i].fileType,
                        });
                        _documentProof.id = responseData.documents[i].id;


                        documents.push({
                            id: responseData.documents[i].id,
                            documentTypeId: responseData.documents[i].id,
                            documentProof: _documentProof ? [].concat(_documentProof) : "",
                        });
                    }

                    responseData = {
                        ...responseData,
                        documents: documents
                    };
                }

                reset(responseData);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }

        fetchPersonalData();
    }, [])

    const ddiPhoneChanged = () => {
        setValue('phoneDdd', '');
        setValue('phone', '');
    }

    const ddiCellPhoneChanged = () => {
        setValue('cellPhoneDdd', '');
        setValue('cellPhone', '');
    }


    const onSubmit = async (data: any) => {

        setSubmitting(true);
        try {

            let newData = data;

            const _proof: any = getValues("proof")[0];
            if (_proof && !_proof.id) {
                const result = await toBase64(_proof);
                newData = {
                    ...data,
                    document: {
                        ...data.document,
                        fileOriginalName: _proof.name,
                        fileInBase64: result,
                        fileSize: _proof.size,
                        fileType: _proof.type,
                    }
                }

                delete newData.proof;
            }

            await updatePersonalData(newData);
            toast.success(`${t("text.toast.successEdit")}`);
            onResolve();

        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    const normalizeName = (value: any) => {
        var names = value.split(" ") || "";
        var namesFormatted = names.map((name: string) => {
            if (/\b(da|de)\b/gi.test(name)) return name.toLowerCase();
            return name.charAt(0).toUpperCase() + name.substring(1).toLowerCase();
        });
        return namesFormatted.join(" ");
    }

    const cannotAddDocument = () => {
        return fields.length !== 0 || uploading || (hasDriverLicense === "" || (hasDriverLicense === "2" && (documentTypeId === "")));
    }

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            scroll="paper"
            fullWidth
        >
            {loading
                ? <LoadingBox />
                : <React.Fragment>
                    <DialogTitleStyled>
                        {t("text.personalData")}
                    </DialogTitleStyled>
                    <DialogContent dividers>
                        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    sx={{
                                        background: 'var(--white)',
                                        borderRadius: '4px',
                                        padding: '2rem',
                                        '@media(max-width: 899px)': {
                                            padding: '0rem',
                                        }
                                    }}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={3}
                                            lg={3}
                                            xl={3}
                                        >
                                            <Controller
                                                control={control}
                                                name="taxId"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            e.persist();
                                                            masks.cpfMask.onChange(e);
                                                            field.onChange(e);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.cpf")}
                                                        placeholder={t("text.cpf") as string}
                                                        fullWidth
                                                        error={!!errors.taxId}
                                                        helperText={displayErrorMessage(errors.taxId?.message) as string}
                                                        disabled
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <Controller
                                                control={control}
                                                name="name"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const { value } = e.target;
                                                            e.target.value = normalizeName(value);
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.fullName")}
                                                        placeholder={t("text.fullName") as string}
                                                        inputProps={{ maxLength: 100 }}
                                                        fullWidth
                                                        error={!!errors.name}
                                                        helperText={displayErrorMessage(errors.name?.message) as string}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={3}
                                            lg={3}
                                            xl={3}
                                        >
                                            <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                                adapterLocale={languages[i18n.language as keyof typeof languages]}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="dateOfBirth"
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            disabled
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                            inputRef={field.ref}
                                                            inputFormat="dd/MM/yyyy"
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        placeholder: t("text.dob")
                                                                    }}
                                                                    label={t("text.dob")}
                                                                    error={!!errors.dateOfBirth}
                                                                    helperText={displayErrorMessage(errors.dateOfBirth?.message) as string}
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={9}
                                            lg={9}
                                            xl={9}
                                        >
                                            <Controller
                                                control={control}
                                                name="birthCity"
                                                render={({ field }) => (
                                                    <TextField
                                                        disabled
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.placeOfBirth")}
                                                        placeholder={t("text.placeOfBirth") as string}
                                                        inputProps={{ maxLength: 100 }}
                                                        fullWidth
                                                        error={!!errors.birthCity}
                                                        helperText={displayErrorMessage(errors.birthCity?.message) as string}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={3}
                                            lg={3}
                                            xl={3}
                                        >
                                            <CRMAddressStateOptions
                                                disabled
                                                name="birthState"
                                                label={t("text.stateOfBirth")}
                                                control={control}
                                                states={addressStateOptions}
                                                error={!!errors.birthState}
                                            />
                                            <FormHelperTextStyled>
                                                {displayErrorMessage(errors.birthState?.message) as string}
                                            </FormHelperTextStyled>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Controller
                                                control={control}
                                                name="motherName"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.fullMotherName")}
                                                        placeholder={t("text.fullName") as string}
                                                        inputProps={{ maxLength: 100 }}
                                                        fullWidth
                                                        error={!!errors.motherName}
                                                        helperText={displayErrorMessage(errors.motherName?.message) as string}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                        >
                                            <CRMDdiOptions
                                                control={control}
                                                label={t("text.landlineDDI")}
                                                name="phoneDdi"
                                                ddis={ddis}
                                                error={!!errors.phoneDdi}
                                                ddiChanged={ddiPhoneChanged}
                                            />
                                            <FormHelperTextStyled>
                                                {displayErrorMessage(errors.phoneDdi?.message) as string}
                                            </FormHelperTextStyled>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                        >
                                            <Controller
                                                control={control}
                                                name="phoneDdd"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.landlineDDD")}
                                                        inputProps={{ maxLength: 3 }}
                                                        fullWidth
                                                        error={!!errors.phoneDdd}
                                                        helperText={displayErrorMessage(errors.phoneDdd?.message) as string}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                        >
                                            <Controller
                                                control={control}
                                                name="phone"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.landline")}
                                                        inputProps={{ maxLength: 20 }}
                                                        fullWidth
                                                        error={!!errors.phone}
                                                        helperText={displayErrorMessage(errors.phone?.message) as string}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                        >
                                            <CRMDdiOptions
                                                control={control}
                                                label={t("text.ddi")}
                                                name="cellPhoneDdi"
                                                ddis={ddis}
                                                error={!!errors.cellPhoneDdi}
                                                ddiChanged={ddiCellPhoneChanged}
                                            />
                                            <FormHelperTextStyled>
                                                {displayErrorMessage(errors.cellPhoneDdi?.message) as string}
                                            </FormHelperTextStyled>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                        >
                                            <Controller
                                                control={control}
                                                name="cellPhoneDdd"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.ddd")}
                                                        inputProps={{ maxLength: 3 }}
                                                        fullWidth
                                                        error={!!errors.cellPhoneDdd}
                                                        helperText={displayErrorMessage(errors.cellPhoneDdd?.message) as string}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                        >
                                            <Controller
                                                control={control}
                                                name="cellPhone"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.cellphone")}
                                                        inputProps={{ maxLength: 20 }}
                                                        fullWidth
                                                        error={!!errors.cellPhone}
                                                        helperText={displayErrorMessage(errors.cellPhone?.message) as string}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Controller
                                                control={control}
                                                name="email"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                        inputRef={field.ref}
                                                        label={t("text.email")}
                                                        placeholder={t("text.email") as string}
                                                        inputProps={{ maxLength: 100 }}
                                                        fullWidth
                                                        type="email"
                                                        error={!!errors.email}
                                                        helperText={displayErrorMessage(errors.email?.message) as string}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        {identificationRequested === "1" ? (
                                            <React.Fragment>

                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <DocumentAlert
                                                        hasDriverLicense={hasDriverLicense}
                                                        typeOfDocument={documentTypeId}
                                                        update={true}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >

                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <FormControl>
                                                            <FormLabelStyled>
                                                                {t("text.hasCNH")}
                                                            </FormLabelStyled>
                                                            <Controller
                                                                control={control}
                                                                name="document.hasDriverLicense"
                                                                render={({ field }) => {
                                                                    return (
                                                                        <RadioGroup
                                                                            row
                                                                            onChange={(e) => {
                                                                                field.onChange(e);
                                                                                resetField("document.documentTypeId");
                                                                                if (proofRef.current !== null)
                                                                                    proofRef.current.removeFile();
                                                                                remove(0);
                                                                            }}
                                                                            value={field.value}
                                                                        >
                                                                            <FormControlLabelStyled
                                                                                value="1"
                                                                                control={<Radio />}
                                                                                label={t("text.yes")}
                                                                            />
                                                                            <FormControlLabelStyled
                                                                                value="2"
                                                                                control={<Radio />}
                                                                                label={t("text.no")}
                                                                            />
                                                                        </RadioGroup>
                                                                    );
                                                                }}
                                                            />
                                                        </FormControl>
                                                        {errors.document?.hasDriverLicense
                                                            ? <FormHelperTextStyled>{displayErrorMessage(errors.document?.hasDriverLicense?.message) as string}</FormHelperTextStyled>
                                                            : null
                                                        }
                                                    </Grid>

                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >


                                                </Grid>

                                                {hasDriverLicense === "2"
                                                    ? <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={2}
                                                        lg={2}
                                                        xl={2}
                                                    >
                                                        <CRMDocumentTypeOptions
                                                            control={control}
                                                            name="document.documentTypeId"
                                                            documentTypes={documentTypeOptions}
                                                            error={!!errors.document && !!errors.document.documentTypeId}
                                                        />
                                                        <FormHelperTextStyled>
                                                            {displayErrorMessage(errors.document?.documentTypeId?.message) as string}
                                                        </FormHelperTextStyled>
                                                    </Grid>
                                                    : null
                                                }
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                    lg={3}
                                                    xl={3}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="document.documentNumber"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => {
                                                                    const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                                    field.onChange(cleanedValue);
                                                                }}
                                                                inputRef={field.ref}
                                                                label={(hasDriverLicense === "2") ? t("text.documentNumber") : t("text.cnhNumber")}
                                                                placeholder={(hasDriverLicense === "2") ? t("text.documentNumber") as string : t("text.cnhNumber") as string}
                                                                inputProps={{ maxLength: 100 }}
                                                                fullWidth
                                                                error={!!errors.document && !!errors.document.documentNumber}
                                                                helperText={displayErrorMessage(errors.document?.documentNumber?.message) as string}
                                                                variant='outlined'
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={hasDriverLicense === "2" ? 2 : 3}
                                                    lg={hasDriverLicense === "2" ? 2 : 3}
                                                    xl={hasDriverLicense === "2" ? 2 : 3}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="document.documentIssuer"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => {
                                                                    const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                                    field.onChange(cleanedValue);
                                                                }}
                                                                inputRef={field.ref}
                                                                label={t("text.issuingBody")}
                                                                placeholder={t("text.issuingBody") as string}
                                                                inputProps={{ maxLength: 100 }}
                                                                fullWidth
                                                                error={!!errors.document && !!errors.document.documentIssuer}
                                                                helperText={displayErrorMessage(errors.document?.documentIssuer?.message) as string}
                                                                variant='outlined'
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={hasDriverLicense === "2" ? 2 : 3}
                                                    lg={hasDriverLicense === "2" ? 2 : 3}
                                                    xl={hasDriverLicense === "2" ? 2 : 3}
                                                >

                                                    <CRMAddressStateOptions
                                                        name="document.documentStateIssuer"
                                                        label={t("text.issuingState")}
                                                        control={control}
                                                        states={addressStateOptions}
                                                        error={!!errors.document && !!errors.document.documentStateIssuer}
                                                    />
                                                    <FormHelperTextStyled>
                                                        {displayErrorMessage(errors.document?.documentStateIssuer?.message) as string}
                                                    </FormHelperTextStyled>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                    lg={3}
                                                    xl={3}
                                                >
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                        adapterLocale={languages[i18n.language as keyof typeof languages]}
                                                    >
                                                        <Controller
                                                            control={control}
                                                            name="document.documentDateOfIssue"
                                                            render={({ field }) => (
                                                                <DatePicker
                                                                    value={field.value}
                                                                    onChange={field.onChange}
                                                                    inputRef={field.ref}
                                                                    inputFormat="dd/MM/yyyy"
                                                                    renderInput={(params) =>
                                                                        <TextField
                                                                            {...params}
                                                                            inputProps={{
                                                                                ...params.inputProps,
                                                                                placeholder: t("text.issueDate")
                                                                            }}
                                                                            label={t("text.issueDate")}
                                                                            error={!!errors.document && !!errors.document.documentDateOfIssue}
                                                                            helperText={displayErrorMessage(errors.document?.documentDateOfIssue?.message) as string}
                                                                            fullWidth
                                                                            variant='outlined'
                                                                        />
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                {
                                                    (hasDriverLicense === "1" || documentTypeId !== "")
                                                        ? <>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                            >
                                                                <DocumentSubtitle
                                                                    hasDriverLicense={hasDriverLicense}
                                                                    typeOfDocument={documentTypeId}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                            >
                                                                <Card>
                                                                    <DocumentHead
                                                                        hasDriverLicense={hasDriverLicense}
                                                                        typeOfDocument={documentTypeId}
                                                                    />
                                                                    <Document
                                                                        ref={proofRef}
                                                                        title={t("text.add")}
                                                                        register={register}
                                                                        getValues={getValues}
                                                                        name="proof"
                                                                        uploading={uploading}
                                                                        setUploading={setUploading}
                                                                    />
                                                                    <CardActions>
                                                                        <Typography variant="caption" display="block">
                                                                            {t("text.allowedFileTypesAndSize")}
                                                                        </Typography>
                                                                    </CardActions>
                                                                </Card>
                                                                {errors.proof && <FormHelperTextStyled>{displayErrorMessage(errors.proof?.message) as string}</FormHelperTextStyled>}
                                                            </Grid>
                                                        </>
                                                        : null
                                                }

                                            </React.Fragment>
                                        ) : null
                                        }

                                        {/* <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >

                                            {fields.map((item, index) => {
                                                return (
                                                    <>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                            key={index}
                                                            paddingTop={"1rem"}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                            >
                                                                <DocumentSubtitle
                                                                    hasDriverLicense={hasDriverLicense}
                                                                    typeOfDocument={documentTypeId}
                                                                />
                                                            </Grid>
                                                            <DocumentAdditional
                                                                index={index}
                                                                title={t("text.add")}
                                                                name={`documents.${index}.documentProof`}
                                                                register={register}
                                                                errors={errors}
                                                                remove={remove}
                                                                getValues={getValues}
                                                                hasDriverLicense={hasDriverLicense}
                                                                typeOfDocument={documentTypeId}
                                                                uploading={uploading}
                                                                setUploading={setUploading}
                                                            />
                                                        </Grid>
                                                    </>
                                                );
                                            })}

                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                paddingTop={"1rem"}

                                            >
                                                <Button
                                                    variant="outlined"
                                                    label={t("text.addDocument")}
                                                    startIcon={<Add />}
                                                    onClick={() => append({
                                                        id: "",
                                                        fileOriginalName: "",
                                                        fileSize: "",
                                                        fileType: "",
                                                        documentProof: "",
                                                    })}
                                                    disabled={cannotAddDocument()}
                                                />
                                            </Grid>
                                        </Grid> */}
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Grid
                                                container
                                                spacing={1}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <SubtitlePageForm>
                                                        {t("text.moreInformation")}
                                                    </SubtitlePageForm>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Divider />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <React.Fragment>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <Grid
                                                    container
                                                    spacing={1}
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <FormLabelStyled>
                                                            {t("text.areYouUsPerson")}
                                                        </FormLabelStyled>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <FormLabelInfoStyled>
                                                            {t("text.usPersonExplanation")}
                                                        </FormLabelInfoStyled>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <FormControl>
                                                    <Controller
                                                        control={control}
                                                        name="usPerson"
                                                        render={({ field }) => {
                                                            return (
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabelStyled
                                                                        value="1"
                                                                        control={<Radio />}
                                                                        label={t("text.yes")}
                                                                    />
                                                                    <FormControlLabelStyled
                                                                        value="2"
                                                                        control={<Radio />}
                                                                        label={t("text.no")}
                                                                    />
                                                                </RadioGroup>
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            {usPerson === "1"
                                                ? <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="nif"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => {
                                                                    const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                                    field.onChange(cleanedValue);
                                                                }}
                                                                inputRef={field.ref}
                                                                label={t("text.nif")}
                                                                placeholder={t("text.nif") as string}
                                                                inputProps={{ maxLength: 100 }}
                                                                fullWidth
                                                                error={!!errors.nif}
                                                                helperText={displayErrorMessage(errors.nif?.message) as string}
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                : null
                                            }
                                        </React.Fragment>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <FormLabelStyled>
                                                                {t("text.isPepOrHasRelationshipWithPep")}
                                                            </FormLabelStyled>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <FormLabelInfoStyled>
                                                                {t("text.pepExplanation")}
                                                            </FormLabelInfoStyled>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <FormControl>
                                                        <Controller
                                                            control={control}
                                                            name="isPep"
                                                            render={({ field }) => {
                                                                return (
                                                                    <RadioGroup row {...field}>
                                                                        <FormControlLabelStyled
                                                                            value="120240000"
                                                                            control={<Radio />}
                                                                            label={t("text.pepYes")}
                                                                        />
                                                                        <FormControlLabelStyled
                                                                            value="120240001"
                                                                            control={<Radio />}
                                                                            label={t("text.pepNo")}
                                                                        />
                                                                    </RadioGroup>
                                                                );
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {errors.isPep
                                                        ? <FormHelperTextStyled>{displayErrorMessage(errors.isPep?.message) as string}</FormHelperTextStyled>
                                                        : null
                                                    }
                                                </Grid>
                                                {isPep === "120240000"
                                                    ? <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <Controller
                                                            control={control}
                                                            name="isPepExplain"
                                                            render={({ field }) => (
                                                                <TextField
                                                                    value={field.value}
                                                                    onChange={(e) => {
                                                                        const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                                        field.onChange(cleanedValue);
                                                                    }}
                                                                    inputRef={field.ref}
                                                                    label={t("text.explain")}
                                                                    placeholder={t("text.explain") as string}
                                                                    fullWidth
                                                                    error={!!errors.isPepExplain}
                                                                    helperText={displayErrorMessage(errors.isPepExplain?.message) as string}
                                                                    variant="outlined"
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    : null
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form >
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={submitting}
                            onClick={onReject}
                            label={t("text.cancel")}
                        />
                        <LoadingButton
                            type="submit"
                            label={t("text.saveChanges")}
                            form="submit-form"
                            loading={submitting}
                        />
                    </DialogActions>
                </React.Fragment>
            }
        </Dialog>
    );
}

export const editPersonalDataModal = create(EditPersonalDataModal);
