import { stripToNull } from "./stringUtils";

export function hasNumber(context:any) {
    const phoneDdi = stripToNull(context.parent.phoneDdi);
    const phoneDdd = context.parent.phoneDdd;
    const phone = context.parent.phone;
    const cellPhoneDdi = stripToNull(context.parent.cellPhoneDdi);
    const cellPhoneDdd = context.parent.cellPhoneDdd;
    const cellPhone = context.parent.cellPhone;

    if (phoneDdi === null && phoneDdd === "" && phone === "" && cellPhoneDdi === null && cellPhoneDdd === "" && cellPhone === "") {
        return false;
    }

    return true;
}

export function hasPhone(context:any) {
    const phoneDdi = stripToNull(context.parent.phoneDdi);
    const phoneDdd = context.parent.phoneDdd;
    const phone = context.parent.phone;

    const hasField = phoneDdi !== null || phoneDdd !== "" || phone !== "";
    const hasEmpty = phoneDdi === null || phoneDdd === "" || phone === ""

    if (hasField && hasEmpty) return false;

    return true;
}

export function hasCellPhone(context:any) {
    const cellPhoneDdi = stripToNull(context.parent.cellPhoneDdi);
    const cellPhoneDdd = context.parent.cellPhoneDdd;
    const cellPhone = context.parent.cellPhone;

    const hasField = cellPhoneDdi !== null || cellPhoneDdd !== "" || cellPhone !== "";
    const hasEmpty = cellPhoneDdi === null || cellPhoneDdd === "" || cellPhone === ""

    if (hasField && hasEmpty) return false;

    return true;
}
