import { isValidCPF } from "@brazilian-utils/brazilian-utils";
import { yupResolver } from '@hookform/resolvers/yup';
import {
    ArrowBack,
    Visibility,
    VisibilityOff
} from '@mui/icons-material';
import {
    Grid,
    IconButton,
    InputAdornment,
    Typography
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useLocation } from 'react-router-dom';
import * as yup from "yup";
import { LoadingButton } from '../../components/LoadingButton';
import TextButton from '../../components/TextButton';
import { TextField } from "../../components/TextField";
import { AuthContext } from '../../contexts/auth';
import { FormDescription, FormTitle } from "../../styles/styles";
import { displayErrorMessage } from "../../utils/errorMessage";
import * as masks from "../../utils/masks";
import { User } from "../../types/user";

const validationSchema = yup.object({
    username: yup.string()
        .required()
        .transform(masks.cpfCnpjMask.transform)
        .test({
            name: "validateCPF",
            test: function (value) {
                if (isValidCPF(value!)) {
                    return true;
                }
                return this.createError({
                    path: 'username',
                })
            }
        }),
    password: yup.string().required(),
});

export function LoginCnpj() {
    const { t } = useTranslation();
    let location = useLocation();

    const { handleSignIn, user } = useContext(AuthContext)

    const { company } = location.state || {};

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            username: "",
            password: "",
        }
    })

    const onSubmit = async (data: User) => {
        const { username, password } = data;
        handleSignIn(username, password, true, company.taxId);
    };

    if (!company) {
        return <Navigate to={"/"} replace />
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={user.authenticating} style={{ border: 0 }}>
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={4}
                    lg={4}
                    xl={4}
                    sx={{ p: 4 }}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            sx={{
                                textTransform: 'uppercase'
                            }}
                        >
                            <TextButton
                                label={t("text.back")}
                                startIcon={<ArrowBack />}
                                component={Link}
                                to="/"
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{
                                textAlign: "left",
                                pb: 1,
                            }}
                        >
                            <FormTitle>
                                {t("text.enter")}
                            </FormTitle>
                            <FormDescription>
                                {t("text.fillInYourInformation")}
                            </FormDescription>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{
                                padding: '1rem',
                                margin: '1rem 0rem 0rem 1rem',
                                background: 'rgba(142, 142, 142, 0.1)',
                                borderRadius: '4px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '.875rem',
                                    fontWeight: '700',
                                    color: 'var(--gray-600)',
                                    marginBottom: '.25rem'
                                }}
                            >
                                {company.name}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '.875rem',
                                    fontWeight: '400',
                                    color: 'var(--gray-300)',
                                }}
                            >
                                {masks.cnpjMask.maskDefault(company.taxId)}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <Controller
                                control={control}
                                name="username"
                                render={({ field }) => (
                                    <TextField
                                        autoFocus
                                        id="username"
                                        fullWidth
                                        value={field.value}
                                        onChange={(e) => {
                                            e.persist();
                                            masks.cpfMask.onChange(e);
                                            field.onChange(e);
                                        }}
                                        inputRef={field.ref}
                                        label={t("text.cpf")}
                                        placeholder={t("text.cpf") as string}
                                        error={!!errors.username}
                                        helperText={displayErrorMessage(errors.username?.message) as string}
                                        variant='outlined'
                                    />
                                )}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <Controller
                                control={control}
                                name="password"
                                render={({ field }) => (
                                    <TextField
                                        type={showPassword ? "text" : "password"}
                                        value={field.value}
                                        onChange={field.onChange}
                                        inputRef={field.ref}
                                        label={t("text.password")}
                                        placeholder={t("text.password") as string}
                                        fullWidth
                                        error={!!errors.password}
                                        helperText={displayErrorMessage(errors.password?.message) as string}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment
                                                    position="end"
                                                >
                                                    <IconButton
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        variant='outlined'
                                    />
                                )}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start'
                            }}
                        >
                            <TextButton
                                label={t("text.forgotPassword")}
                                component={Link}
                                to={`/forgot-password?companyTaxId=${company.taxId}`}
                                disabled={user.authenticating}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <LoadingButton
                                type="submit"
                                label={t("text.enter")}
                                variant="contained"
                                fullWidth
                                loading={user.authenticating}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </fieldset>
        </form>
    );
}