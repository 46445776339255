import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    :root {
        --white: #FFFFFF;
        --background: #F7F7F7;
        --brandColor-red: #CD001C;
        --brandColor-dark-gray: #587690;
        --brandColor-light-gray: #D9D9D9;
        --brandColor-mid-gray: #8C9CAE;
        --brandColor-dark-blue: #202930;
        --gray-300: #999999;
        --gray-300-withOpacity: rgba(153, 153, 153, 0.15);
        --gray-600: #555555;
        --gray-900: #202930;
        --red: #C62F2F;
        --yellow: #F2A516;
        --green: #53A82B;
        --blue: #2F7BC0;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html, body, #root {
        height: 100%;
    }
    
    body {
        background: var(--background);
        color: var(--gray-900);
    }

    body, input, textarea, button {
        font-family: 'Noto Sans', sans-serif;
        font-weight: 400;
        font-size: 1rem;
    }

    p {
        word-wrap: break-word;
    }

    .scrollBody {
        height: 100%;
        outline: 0px;
        overflow: hidden auto;
        text-align: center;
    }

    .scrollBody::after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        width: 0px;
    }
`;
