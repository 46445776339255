import { isValidCNPJ, isValidCPF } from "@brazilian-utils/brazilian-utils";
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Card,
    CardActions,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, FieldValues, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InstanceProps, create } from 'react-modal-promise';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { Button } from '../Button';
import { CRMCountryOptions } from "../CRMCountryOptions";
import { CurrencyTextField } from '../CurrencyTextField';
import Document from '../Document';
import { LoadingButton } from '../LoadingButton';
import { Select } from '../Select';
import { TextField } from '../TextField';

import { getRegistrationUpdateCompanyAssociateByIdPromise, registrationUpdateCompanyAssociateUpdate } from "../../services/updateService";

import { getCRMCountriesPromise } from "../../services/crmCountryService";
import {
    CardHeaderTitleStyled,
    FormControlLabelStyled,
    FormHelperTextStyled,
    FormLabelInfoStyled,
    FormLabelStyled,
} from '../../styles/styles';
import { displayErrorMessage } from '../../utils/errorMessage';
import { toBase64 } from "../../utils/fileUtils";
import * as masks from "../../utils/masks";
import { DialogTitleStyled } from '../DeleteModal/styles';
import { LoadingBox } from '../LoadingBox';
import { removeSpecialCharactersAndAccents } from "../../utils/stringUtils";

const validationSchema = yup.object().shape({
    id: yup.string().nullable(),
    typeId: yup.string().required(),
    name: yup.string()
        .min(2)
        .required(),
    taxId: yup.string()
        .transform(masks.cpfCnpjMask.transform)
        .when('typeId', {
            is: "1",
            then: yup.string()
                .required()
                .test({
                    name: "validateCPF",
                    test: function (value) {
                        if (isValidCPF(value!)) {
                            return true;
                        }
                        return this.createError({
                            path: this.path,
                        })
                    }
                }),
            otherwise: yup.string()
                .required()
                .test({
                    name: "validateCNPJ",
                    test: function (value) {
                        if (isValidCNPJ(value!)) {
                            return true;
                        }
                        return this.createError({
                            path: this.path,
                        })
                    }
                })
        }),
    shareCapital: yup.string()
        .nullable()
        .test({
            name: "shareCapital",
            message: "validations.percentageBetween0And100",
            test: function (value) {
                const percentage = parseFloat(value!);
                return percentage > 0 && percentage <= 100;
            }
        }),
    legalRepresentative: yup.string()
        .when("typeId", {
            is: "1",
            then: yup.string().required()
        }),
    usPerson: yup.string()
        .when("typeId", {
            is: "1",
            then: yup.string().required()
        }),
    nif: yup.string()
        .when(["usPerson", "typeId"], {
            is: (usPerson: string, typeId: string) => usPerson === "1" && typeId === "1",
            then: yup.string().required()
        }),
    isPep: yup.string()
        .when("typeId", {
            is: "1",
            then: yup.string().required()
        }),
    isPepExplain: yup.string()
        .when(["isPep", "typeId"], {
            is: (isPep: string, typeId: string) => isPep === "1" && typeId === "1",
            then: yup.string().required()
        }),
    identificationRequested: yup.string(),
    identificationProof: yup.mixed()
        .when(["identificationRequested", "typeId", "shareCapital", "partnerTypeId"], {
            is: (identificationRequested: string, typeId: string, shareCapital: string, partnerTypeId: string) => identificationRequested === "1" && (typeId === "1" && (parseFloat(shareCapital) >= 25 || partnerTypeId === "2")),
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                })
        }),
    isNational: yup.string()
        .when("typeId", {
            is: "2",
            then: yup.string().required()
        }),
    country: yup.string()
        .when("isNational", {
            is: "2",
            then: yup.string().required()
        }),
    contractRequested: yup.string(),
    contractProof: yup.mixed()
        .when(["contractRequested", "typeId", "shareCapital", "partnerTypeId"], {
            is: (contractRequested: string, typeId: string, shareCapital: string, partnerTypeId: string) => contractRequested === "1" && (typeId === "2" && (parseFloat(shareCapital) >= 10)),
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                })
        }),
});

interface RegistrationUpdatePartnerEditModalProps extends InstanceProps<any, any> {
    open: boolean;
    onResolve: () => void;
    onReject: () => void;
    id?: string;
}

const RegistrationUpdatePartnerEditModal: React.FC<RegistrationUpdatePartnerEditModalProps> = ({ open, onResolve, onReject, id }) => {
    const { t } = useTranslation();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [countriesOptions, setCountriesOptions] = useState([]);
    const [uploading, setUploading] = useState<boolean>(false);

    const { control, handleSubmit, formState: { errors }, reset, register, getValues, setValue } = useForm<FieldValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            id: "",
            typeId: "",
            name: "",
            taxId: "",
            shareCapital: "",
            legalRepresentative: "",
            usPerson: "",
            nif: "",
            isPep: "",
            isPepExplain: "",
            identificationRequested: "",
            identificationProof: "",
            isNational: "",
            country: "",
            contractRequested: "",
            contractProof: "",
            partnerTypeId: "",
        }
    });

    const typeId = useWatch({
        control,
        name: "typeId",
    });

    const usPerson = useWatch({
        control,
        name: "usPerson",
    });

    const isPep = useWatch({
        control,
        name: "isPep",
    });

    const contractRequested = useWatch({
        control,
        name: "contractRequested",
    });

    const identificationRequested = useWatch({
        control,
        name: "identificationRequested",
    });

    const shareCapital = useWatch({
        control,
        name: "shareCapital",
    });

    const isNational = useWatch({
        control,
        name: "isNational",
    });

    const partnerTypeId = useWatch({
        control,
        name: "partnerTypeId",
    });

    useEffect(() => {
        async function fetchPartner() {
            try {
                const [countriesOptions, corporateStructure] = await Promise.all([
                    getCRMCountriesPromise(),
                    getRegistrationUpdateCompanyAssociateByIdPromise(id!)
                ]);

                setCountriesOptions(countriesOptions.data);

                const data = corporateStructure.data;

                var identificationProof = data.identificationProof;
                const identificationProofId = identificationProof?.id;
                if (identificationProofId) {
                    identificationProof = new File(["identificationProof"], identificationProof.fileOriginalName, {
                        type: identificationProof.fileType,
                    });
                    identificationProof.id = identificationProofId;
                }

                var contractProof = data.contractProof;
                const contractProofId = contractProof?.id;
                if (contractProofId) {
                    contractProof = new File(["contractProof"], contractProof.fileOriginalName, {
                        type: contractProof.fileType,
                    });
                    contractProof.id = contractProofId;
                }

                const values = {
                    ...data,
                    taxId: masks.cpfCnpjMask.maskDefault(data.taxId),
                    identificationProof: identificationProof ? [].concat(identificationProof) : "",
                    contractProof: contractProof ? [].concat(contractProof) : "",
                }

                reset(values);
                setLoading(false);
            } catch (error: any) {
                const errorMessage = error.response?.data?.message || error.message;
                toast.error(errorMessage);
                onReject();
            }
        }

        fetchPartner();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (data: any) => {
        setSubmitting(true);

        console.log('data');
        console.log(data);

        try {
            let identificationProof = data.identificationProof[0];
            if (identificationProof && !identificationProof.id) {
                const result = await toBase64(identificationProof);
                identificationProof = {
                    fileOriginalName: identificationProof.name,
                    fileInBase64: result,
                    fileSize: identificationProof.size,
                    fileType: identificationProof.type,
                }
            } else {
                identificationProof = null;
            }

            let contractProof = data.contractProof[0];
            if (contractProof && !contractProof.id) {
                const result = await toBase64(contractProof);
                contractProof = {
                    fileOriginalName: contractProof.name,
                    fileInBase64: result,
                    fileSize: contractProof.size,
                    fileType: contractProof.type,
                }
            } else {
                contractProof = null;
            }

            console.log('data 2 ');
            console.log(data);

            data = {
                ...data,
                identificationProof,
                contractProof,
            }

            await registrationUpdateCompanyAssociateUpdate(id!, data);
            toast.success('Sócio atualizado com sucesso.');
            onResolve();
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            scroll="paper"
            fullWidth
        >
            {loading
                ? <LoadingBox />
                : <React.Fragment>
                    <DialogTitleStyled>
                        {t("text.editBusinessPartner")}
                    </DialogTitleStyled>
                    <DialogContent dividers>
                        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
                            <fieldset disabled={submitting} style={{ border: 0 }}>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                    >
                                        <Controller
                                            control={control}
                                            name="taxId"
                                            render={({ field }) => (
                                                <TextField
                                                    disabled
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        e.persist();
                                                        if (typeId === "1") {
                                                            masks.cpfMask.onChange(e);
                                                        } else {
                                                            masks.cnpjMask.onChange(e);
                                                        }
                                                        field.onChange(e);
                                                    }}
                                                    inputRef={field.ref}
                                                    label={typeId === "1" ? t("text.cpf") : t("text.cnpj")}
                                                    placeholder={typeId === "1" ? t("text.cpf") as string : t("text.cnpj") as string}
                                                    fullWidth
                                                    error={!!errors.taxId}
                                                    helperText={displayErrorMessage(errors.taxId?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={7}
                                        lg={7}
                                        xl={7}
                                    >
                                        <Controller
                                            control={control}
                                            name="name"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={typeId === "1" ? t("text.fullName") : t("text.corporateName")}
                                                    placeholder={typeId === "1" ? t("text.fullName") as string : t("text.corporateName") as string}
                                                    inputProps={{ maxLength: 100 }}
                                                    fullWidth
                                                    error={!!errors.name}
                                                    helperText={displayErrorMessage(errors.name?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={2}
                                        lg={2}
                                        xl={2}
                                    >
                                        <Controller
                                            control={control}
                                            name="shareCapital"
                                            render={({ field }) => (
                                                <CurrencyTextField
                                                    label={t("text.percentageOnEquity")}
                                                    currencySymbol="%"
                                                    decimalCharacter=","
                                                    digitGroupSeparator="."
                                                    variant="outlined"
                                                    decimalPlaces={3}
                                                    value={field.value}
                                                    onChange={(e: any, v: any) => {
                                                        field.onChange(v);
                                                    }}
                                                    inputRef={(ref: any) => {
                                                        field.ref({
                                                            focus: () => {
                                                                ref.domElement.focus();
                                                            }
                                                        });
                                                    }}
                                                    placeholder="0,00"
                                                    fullWidth
                                                    error={!!errors.shareCapital}
                                                    helperText={displayErrorMessage(errors.shareCapital?.message) as string}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {typeId === "1"
                                        ? <React.Fragment>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <FormControl>
                                                    <FormLabelStyled>
                                                        {t("text.isLegalRepresentative")}
                                                    </FormLabelStyled>
                                                    <Controller
                                                        control={control}
                                                        name="legalRepresentative"
                                                        render={({ field }) => {
                                                            return (
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabelStyled
                                                                        value="1"
                                                                        control={<Radio />}
                                                                        label={t("text.yes")}
                                                                    />
                                                                    <FormControlLabelStyled
                                                                        value="2"
                                                                        control={<Radio />}
                                                                        label={t("text.no")}
                                                                    />
                                                                </RadioGroup>
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                                {errors.legalRepresentative
                                                    ? <FormHelperTextStyled>{displayErrorMessage(errors.legalRepresentative?.message) as string}</FormHelperTextStyled>
                                                    : null
                                                }
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <Grid
                                                    container
                                                    spacing={1}
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <FormLabelStyled>
                                                            {t("text.areYouUsPerson")}
                                                        </FormLabelStyled>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <FormLabelInfoStyled>
                                                            {t("text.usPersonExplanation")}
                                                        </FormLabelInfoStyled>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <FormControl>
                                                    <Controller
                                                        control={control}
                                                        name="usPerson"
                                                        render={({ field }) => {
                                                            return (
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabelStyled
                                                                        value="1"
                                                                        control={<Radio />}
                                                                        label={t("text.yes")}
                                                                    />
                                                                    <FormControlLabelStyled
                                                                        value="2"
                                                                        control={<Radio />}
                                                                        label={t("text.no")}
                                                                    />
                                                                </RadioGroup>
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                                {errors.usPerson
                                                    ? <FormHelperTextStyled>{displayErrorMessage(errors.usPerson?.message) as string}</FormHelperTextStyled>
                                                    : null
                                                }
                                            </Grid>
                                            {usPerson === "1"
                                                ? <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="nif"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                                inputRef={field.ref}
                                                                label={t("text.nif")}
                                                                placeholder={t("text.nif") as string}
                                                                inputProps={{ maxLength: 100 }}
                                                                fullWidth
                                                                error={!!errors.nif}
                                                                helperText={displayErrorMessage(errors.nif?.message) as string}
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                : null
                                            }
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <Grid
                                                    container
                                                    spacing={1}
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <FormLabelStyled>
                                                            {t("text.isPepOrHasRelationshipWithPep")}
                                                        </FormLabelStyled>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <FormLabelInfoStyled>
                                                            {t("text.pepExplanation")}
                                                        </FormLabelInfoStyled>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <FormControl>
                                                    <Controller
                                                        control={control}
                                                        name="isPep"
                                                        render={({ field }) => {
                                                            return (
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabelStyled
                                                                        value="1"
                                                                        control={<Radio />}
                                                                        label={t("text.pepYes")}
                                                                    />
                                                                    <FormControlLabelStyled
                                                                        value="2"
                                                                        control={<Radio />}
                                                                        label={t("text.pepNo")}
                                                                    />
                                                                </RadioGroup>
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                                {errors.isPep
                                                    ? <FormHelperTextStyled>{displayErrorMessage(errors.isPep?.message) as string}</FormHelperTextStyled>
                                                    : null
                                                }
                                            </Grid>
                                            {isPep === "1"
                                                ? <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="isPepExplain"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                                inputRef={field.ref}
                                                                label={t("text.explain")}
                                                                placeholder={t("text.explain") as string}
                                                                fullWidth
                                                                error={!!errors.isPepExplain}
                                                                helperText={displayErrorMessage(errors.isPepExplain?.message) as string}
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                : null
                                            }
                                            {identificationRequested === "1" && parseFloat(shareCapital) >= 25 || partnerTypeId === "2"
                                                ? <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Card>
                                                        <CardHeaderTitleStyled
                                                            title={t("text.identificationDocumentWithPicture")}
                                                            subheader={t("text.identificationDocumentValidity")}
                                                        />
                                                        <Document
                                                            title={t("text.addDocument")}
                                                            register={register}
                                                            getValues={getValues}
                                                            name="identificationProof"
                                                            uploading={uploading}
                                                            setUploading={setUploading}
                                                        />
                                                        <CardActions>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                            >
                                                                {t("text.allowedFileTypesAndSize")}
                                                            </Typography>
                                                        </CardActions>
                                                    </Card>
                                                    {errors.identificationProof && <FormHelperTextStyled>{displayErrorMessage(errors.identificationProof.message) as string}</FormHelperTextStyled>}
                                                </Grid>
                                                : null
                                            }
                                        </React.Fragment>
                                        : null}
                                    {typeId === "2" && (
                                        <>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={2}
                                                lg={2}
                                                xl={2}
                                            >
                                                <FormControl>
                                                    <FormLabelStyled>
                                                        {t("text.isANationalCompany")}
                                                    </FormLabelStyled>
                                                    <Controller
                                                        control={control}
                                                        name="isNational"
                                                        render={({ field }) => {
                                                            return (
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabelStyled
                                                                        value="1"
                                                                        control={<Radio />}
                                                                        label={t("text.yes")}
                                                                    />
                                                                    <FormControlLabelStyled
                                                                        value="2"
                                                                        control={<Radio />}
                                                                        label={t("text.no")}
                                                                    />
                                                                </RadioGroup>
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                                {errors.isNational
                                                    ? <FormHelperTextStyled>{displayErrorMessage(errors.isNational?.message) as string}</FormHelperTextStyled>
                                                    : null
                                                }
                                            </Grid>
                                            {isNational === "2"
                                                ? <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <CRMCountryOptions
                                                        name="country"
                                                        control={control}
                                                        countries={countriesOptions}
                                                        error={!!errors.country}
                                                    />
                                                    <FormHelperTextStyled>
                                                        {displayErrorMessage(errors.country?.message) as string}
                                                    </FormHelperTextStyled>
                                                </Grid>
                                                : null
                                            }
                                            {contractRequested === "1" && parseFloat(shareCapital) >= 10
                                                ? <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Card>
                                                        <CardHeaderTitleStyled
                                                            title={t("text.socialContractOrOrganizationChart")}
                                                        />
                                                        <Document
                                                            title={t("text.addDocument")}
                                                            register={register}
                                                            getValues={getValues}
                                                            name="contractProof"
                                                            uploading={uploading}
                                                            setUploading={setUploading}
                                                        />
                                                        <CardActions>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                            >
                                                                {t("text.allowedFileTypesAndSize")}
                                                            </Typography>
                                                        </CardActions>
                                                    </Card>
                                                    {errors.contractProof && <FormHelperTextStyled>{displayErrorMessage(errors.contractProof.message) as string}</FormHelperTextStyled>}
                                                </Grid>
                                                : null
                                            }
                                        </>
                                    )}
                                </Grid>
                            </fieldset>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={submitting} onClick={onReject} label={t("text.cancel")} />
                        <LoadingButton
                            type="submit"
                            label={t("text.saveChanges")}
                            form="submit-form"
                            loading={submitting}
                        />
                    </DialogActions>
                </React.Fragment>
            }
        </Dialog>
    );
}

export const registrationUpdatePartnerEditModal = create(RegistrationUpdatePartnerEditModal);
