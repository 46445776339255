import unidecode from 'unidecode';

export function decapitalizeFirstLetter(value: string) {
    return value.charAt(0).toLowerCase() + value.slice(1);
}

export function stripToEmpty(str: string | null | undefined) {
    return str === null ? '' : strip(str!, null);
}

export function stripToNull(str: string | null) {
    if (str === null) {
        return null;
    }
    str = strip(str, null);
    return str === '' ? null : str;
}

function strip(str: string, stripChars: null) {
    str = stripStart(str, stripChars);
    return stripEnd(str, stripChars);
}

function stripStart(str: string, stripChars: string | any[] | null) {
    const strLen = str.length;
    if (strLen === 0) {
        return str;
    }
    let start = 0;

    if (stripChars === null) {
        while (start !== strLen && /\s/.test(str.charAt(start))) {
            start++;
        }
    } else if (stripChars === '') {
        return str;
    } else {
        while (start !== strLen && stripChars.indexOf(str.charAt(start)) !== -1) {
            start++;
        }
    }

    return str.substring(start);
}

function stripEnd(str: string, stripChars: string | any[] | null) {
    let end = str.length;
    if (end === 0) {
        return str;
    }

    if (stripChars === null) {
        while (end !== 0 && /\s/.test(str.charAt(end - 1))) {
            end--;
        }
    } else if (stripChars === '') {
        return str;
    } else {
        while (end !== 0 && stripChars.indexOf(str.charAt(end - 1)) !== -1) {
            end--;
        }
    }

    return str.substring(0, end);
}

export function removeSpecialCharactersAndAccents(text: string | null) {
    if (text == null) return '';

    const withoutAccents = unidecode(text);

    const withoutSpecialChars = withoutAccents.replace(/[^\w\s]/gi, '');

    return withoutSpecialChars;
}
