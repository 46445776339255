import axios from "axios";

const baseURL = process.env.REACT_APP_CEP_API_URL;

const apiCep = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json'
    },
});

export default apiCep;
