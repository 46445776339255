import api from "./api"
import querystring from 'query-string'
import { Buffer } from 'buffer'

const authorization = Buffer.from(`moneycorp_onboarding_client:moneycorponboardingclientrules`, 'utf8').toString('base64');

export const signUp = async (data:string) => {
    return await api.post(`/api/auth/signup`, data);
}

export const signIn = async (username:string, password:string) => {
    const data = {
        grant_type: "password",
        username: username,
        password: password,
    }

    return await api.post('/oauth/token', querystring.stringify(data), {
        headers: {
            "Authorization": `Basic ${authorization}`,
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })
}

export const loadCurrentUser = async (accessToken:string) => {
    return await api.get('/api/user/me', {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
}
