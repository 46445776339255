import {
    Dialog,
    DialogActions
} from '@mui/material';
import React from 'react';
import { InstanceProps, create } from 'react-modal-promise';
import { Button } from '../Button';
import { DialogContentStyled, DialogTitleStyled } from './styles';

interface ErrorModalProps extends InstanceProps<any, any> {
    title: string ,
    message:string ,
   open: boolean ,
   onResolve: ()=> void ,
   onReject:()=> void

}

const ErrorModal: React.FC<ErrorModalProps> = ({ open, onResolve, onReject, title, message }) => {
    return (
        <Dialog open={open} scroll="paper">
            <DialogTitleStyled>{title}</DialogTitleStyled>
            <DialogContentStyled>{message}</DialogContentStyled>
            <DialogActions>
                <Button onClick={onReject} label="Cancelar" />
                <Button variant="contained" onClick={onResolve} label="Tentar novamente" />
            </DialogActions>
        </Dialog>
    );
}

export const errorModal = create(ErrorModal);
