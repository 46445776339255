import {
    Grid,
} from '@mui/material';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import TextButton from '../../components/TextButton';
import {
    FormDescription,
    FormTitle,
} from "../../styles/styles";
import React from 'react';
const appVersion = require('../../../package.json').version;

export function Version() {
    const { t } = useTranslation();

    return (
        <form>
            <fieldset style={{ border: 0 }}>
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={4}
                    lg={4}
                    xl={4}
                    sx={{ p: 4 }}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{
                                textAlign: "center",
                                pb: 1,
                            }}
                        >
                            <FormTitle>
                                {t("text.version")}
                            </FormTitle>
                            <FormDescription>
                                {`FXMoneyForm - ${appVersion}`}
                            </FormDescription>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '.25rem',
                                justifyContent: 'center',
                            }}
                        >
                            <TextButton
                                label={t("text.back")}
                                component={Link}
                                to="/"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </fieldset>
        </form>
    );
}
