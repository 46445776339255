import {
    Checkbox,
    FormControlLabel
} from '@mui/material';
import React from "react";
import { Controller } from 'react-hook-form';
import { ICoin } from "../../interfaces/coin";

interface CRMCurrencyCheckBoxOptionsProps {
    control: any;
    name: string;
    currencies: Array<ICoin>;
    error: any;
}

export function CurrencyCheckBoxOptions({ name, currencies, control }: CRMCurrencyCheckBoxOptionsProps) {
    return (

        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <React.Fragment>
                    {currencies.map((currency) => (
                        <FormControlLabel
                            key={currency.id}
                            label={`${currency.code} - ${currency.name}`}
                            control={
                                <Checkbox
                                    value={currency.id}
                                    checked={field.value.some(
                                        (existingValue: string) => existingValue === currency.id
                                    )}
                                    onChange={(event, checked) => {
                                        if (checked) {
                                            field.onChange([
                                                ...field.value,
                                                event.target.value
                                            ]);
                                        } else {
                                            field.onChange(
                                                field.value.filter(
                                                    (value: string) => value != event.target.value
                                                )
                                            );
                                        }
                                    }}
                                />
                            }
                        />
                    ))}
                </React.Fragment>
            )}
        />
    );
}