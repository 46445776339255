import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ButtonStyled = styled(Button)`
    && {
        padding: 0;
        text-transform: unset;
        font-weight: 700;
        font-size: .875rem;
        text-decoration: underline;

        :hover {
            filter: brightness(85%);
            background: none;
        }
    }
`;
