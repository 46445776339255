import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { loadCurrentUser, signIn, signUp } from '../../services/authService';
import { useNavigate } from 'react-router-dom';


export interface UserState {
    loading: boolean;
    authenticated: boolean;
    authenticating: boolean;
    value: {
        accessToken?: any;
        refreshToken?: any;
        userId?: any;
        name?: any;
        // Adicione outras propriedades conforme necessário
    } | undefined;
}
export default function useAuth() {
    const [user, setUser] = useState<UserState>({ loading: true, authenticated: false, authenticating: false, value: undefined });
    const navigate = useNavigate()

    useEffect(() => {
        fetchUser();
    }, [])

    async function fetchUser() {
        const userData = localStorage.getItem('onboarding:userData');

        if (userData) {
            const parsedUserData = JSON.parse(userData);
            const { accessToken } = parsedUserData;
            try {
                const currentUserResponse = await loadCurrentUser(accessToken);

                const { name, graphicAccount, iban, productId, productName, estimatedValueName, sa, canEdit, stateCode, partnerOrClient, warning, update, updateStateCode } = currentUserResponse.data;
                setUser((prevState) => ({
                    ...prevState,
                    authenticated: true,
                    value: {
                        ...parsedUserData,
                        name: name,
                        graphicAccount: graphicAccount,
                        iban: iban,
                        productId: productId,
                        productName: productName,
                        estimatedValueName: estimatedValueName,
                        sa: sa,
                        canEdit: canEdit,
                        stateCode: stateCode,
                        partnerOrClient,
                        warning: warning,
                        update: update,
                        updateStateCode: updateStateCode,
                    },
                    loading: false,
                }))
            } catch (error: any) {
                toast.error(error.response?.data?.message || error.message)
                setUser((prevState) => ({
                    ...prevState,
                    loading: false,
                }))
            }
        } else {
            setUser((prevState) => ({
                ...prevState,
                authenticated: false,
                value: undefined,
                loading: false,
            }))
        }
    }

    async function handleSignUp(data: any) {
        setUser((prevState) => ({
            ...prevState,
            authenticating: true,
        }))

        try {

            const response = await signUp(data);
            const type = parseInt(data.type);
            const responseData = response.data;
            const { access_token, refresh_token, userId, name, graphicAccount, iban, productId, productName, estimatedValueName, sa, canEdit, stateCode, partnerOrClient, warning } = responseData;

            const userData = {
                accessToken: access_token,
                refreshToken: refresh_token,
                userId,
                name,
                type,
                graphicAccount: graphicAccount,
                iban: iban,
                productId: productId,
                productName: productName,
                estimatedValueName: estimatedValueName,
                sa: sa,
                canEdit: canEdit,
                stateCode: stateCode,
                partnerOrClient: partnerOrClient,
                warning: warning
            };
            localStorage.setItem('onboarding:userData', JSON.stringify(userData));

            setUser((prevState) => ({
                ...prevState,
                authenticated: true,
                value: userData,
            }))

            if (type === 1) {
                navigate("/onboarding/contact-reason-register")
            } else {
                navigate("/onboarding/contact-reason-legal-entity-register")
            }
        } catch (error: any) {
            toast.error(error.response?.data?.message || error.message)
        } finally {
            setUser((prevState) => ({
                ...prevState,
                authenticating: false,
            }))
        }
    }

    async function handleSignIn(username: string, password: string, corporate: boolean = false, parent: any = null) {
        setUser((prevState) => ({
            ...prevState,
            authenticating: true,
        }))

        try {
            const signInResponse = await signIn(username, password,);
            const { access_token, refresh_token } = signInResponse.data;

            const loadCurrentUserResponse = await loadCurrentUser(access_token);
            const { concluded, id, name, stepIndex, type, graphicAccount, iban, productId, productName, estimatedValueName, sa, canEdit, stateCode, partnerOrClient, warning } = loadCurrentUserResponse.data;

            const userData = {
                accessToken: access_token,
                refreshToken: refresh_token,
                userId: id,
                name,
                type,
                graphicAccount: graphicAccount,
                iban: iban,
                productId: productId,
                productName: productName,
                estimatedValueName: estimatedValueName,
                sa: sa,
                canEdit: canEdit,
                stateCode: stateCode,
                partnerOrClient: partnerOrClient,
                warning: warning
            };
            localStorage.setItem('onboarding:userData', JSON.stringify(userData));

            setUser((prevState) => ({
                ...prevState,
                authenticated: true,
                value: userData,
            }))

            if (stepIndex === 'dashboard' || concluded) {
                navigate('/dashboard')
            } else {
                navigate(`/onboarding/${stepIndex}`);
            }
        } catch (error: any) {
            const { error_description, message } = error?.response?.data || {};
            const errorMessage = error_description || message || error?.message;
            toast.error(errorMessage);
        } finally {
            setUser((prevState) => ({
                ...prevState,
                authenticating: false,
            }))
        }
    }

    function handleSignOut() {
        localStorage.removeItem('onboarding:userData');
        setUser((prevState) => ({
            ...prevState,
            authenticated: false,
            value: undefined,
            loading: false,
        }))
    }

    return { user, handleSignUp, handleSignIn, handleSignOut, fetchUser }
}
