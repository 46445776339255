import { CardHeaderTitleStyled } from "../../styles/styles";
import React from "react";

interface DocumentHeadAdditionalProps {
    title: string, 
    subheader: string | undefined | null
}

export const DocumentHeadAdditional : React.FC<DocumentHeadAdditionalProps> = ({ title, subheader }) => {
    return (
        <CardHeaderTitleStyled
            title={title}
            subheader={subheader}
        />
    )
}
