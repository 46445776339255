import React, { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { InstanceProps, create } from "react-modal-promise";
import {
  Card,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Checkbox,
} from "@mui/material";
import * as yup from "yup";
import { AuthContext } from "../../contexts/auth";
import {
  CardHeaderTitleStyled,
  FormControlLabelStyled,
  FormHelperTextStyled,
} from "../../styles/styles";
import * as masks from "../../utils/masks";
import { Controller, FieldValues, useForm, useWatch } from "react-hook-form";
import { formatCPF, isValidCPF } from "@brazilian-utils/brazilian-utils";
import { DialogTitleStyled } from "../DeleteModal/styles";
import { TextField } from "../TextField";
import { toBase64 } from "../../utils/fileUtils";
import { toast } from "react-toastify";
import Document from "../Document";
import { Button } from "../Button";
import { LoadingButton } from "../LoadingButton";
import { LoadingBox } from "../LoadingBox";
import { useTranslation } from "react-i18next";
import { displayErrorMessage } from "../../utils/errorMessage";
import { CRMBankOptions } from "../CRMBankOptions";
import { CRMBankAccountTypeOptions } from "../CRMBankAccountTypeOptions";
import { getBankAccountTypesPromise } from "../../services/bankAccountTypeService";
import { getBanksPromise } from "../../services/bankService";
import { removeSpecialCharactersAndAccents } from "../../utils/stringUtils";
import {
  getRegistrationUpdateBankDataByIdPromise,
  registrationUpdateBankDataUpdate,
} from "../../services/updateService";

const validationSchema = yup.object().shape({
  id: yup.string().nullable(),
  accountTypeId: yup.string().required(),
  bankId: yup.string().required(),
  agency: yup
    .string()
    .required()
    .matches(/^[0-9]+$/gm, "validations.numbersOnly"),
  accountNumber: yup
    .string()
    .required()
    .matches(/^[0-9]+$/gm, "validations.numbersOnly"),
  jointAccount: yup.string(),
  accountHolderName: yup.string().when("jointAccount", {
    is: "true",
    then: yup.string().required(),
  }),
  accountHolderDocument: yup.string().when("jointAccount", {
    is: "true",
    then: yup
      .string()
      .required()
      .transform(masks.cpfMask.transform)
      .test("validateCPF", (value) => {
        return isValidCPF(value!);
      }),
  }),
  jointAccountProof: yup.mixed().when("jointAccount", {
    is: "true",
    then: yup
      .mixed()
      .test("required", "validations.fileRequired", (value) => value.length > 0)
      .test("fileSize", "validations.fileSize", (value) => {
        return value.length && value[0].size <= 20971520;
      })
      .test("fileType", "validations.fileType", (value) => {
        return (
          value.length &&
          [
            "image/jpeg",
            "image/png",
            "image/jpg",
            "application/pdf",
            "image/bmp",
          ].includes(value[0].type)
        );
      }),
  }),
});

interface RegistrationUpdateBankDetailUpdateModalProps extends InstanceProps<any, any> {
  open: boolean;
  onResolve: () => void;
  onReject : () => void;
  id?: string;
}

const RegistrationUpdateBankDetailUpdateModal : React.FC<RegistrationUpdateBankDetailUpdateModalProps> = ({
  open,
  onResolve,
  onReject,
  id,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const type = user.value.type;
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [bankOptions, setBankOptions] = useState([]);
  const [bankAccountTypeOptions, setBankAccountTypeOptions] = useState([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    register,
    getValues,
  
  } = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: "",
      accountTypeId: "",
      bankId: "",
      agency: "",
      accountNumber: "",
      jointAccount: false,
      accountHolderName: "",
      accountHolderDocument: "",
      jointAccountProof: "",
    },
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const [bankAccountTypeOptions, bankOptions, bankDetail] =
          await Promise.all([
            getBankAccountTypesPromise(),
            getBanksPromise(),
            getRegistrationUpdateBankDataByIdPromise(id!),
          ]);

        setBankAccountTypeOptions(bankAccountTypeOptions.data);
        setBankOptions(bankOptions.data);

        const _responseData = bankDetail.data;

        var _jointAccountProof : any;
        if (_responseData.fileId && _responseData.jointAccount) {
          _jointAccountProof = new File(
            ["jointAccountProof"],
            _responseData.fileOriginalName,
            {
              type: _responseData.fileType,
            }
          );
          _jointAccountProof.id = _responseData.fileId;
        }

        const responseData = {
          ..._responseData,
          jointAccountProof: _jointAccountProof
            ? [].concat(_jointAccountProof)
            : "",
          accountHolderDocument: formatCPF(_responseData.accountHolderDocument),
        };

        reset(responseData);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data: any) => {
    setSubmitting(true);

    const jointAccountProof = data.jointAccountProof![0];
    const jointAccount = data.jointAccount === "true";
    const hasJointAccountProofId = !!jointAccountProof?.id;
    const shouldSendFile = jointAccount && !hasJointAccountProofId;
    if (shouldSendFile) {
      const result = await toBase64(jointAccountProof);
      data.fileOriginalName = jointAccountProof.name;
      data.fileInBase64 = result;
      data.fileSize = jointAccountProof.size;
      data.fileType = jointAccountProof.type;
    }
    delete data.jointAccountProof;

    try {
      await registrationUpdateBankDataUpdate(id!, data);
      toast.success(t("text.toast.successEdit"));
      onResolve();
    } catch (error:any) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    } finally {
      setSubmitting(false);
    }
  };

  const jointAccount = useWatch({
    control,
    name: "jointAccount",
  });

  return (
    <Dialog open={open} maxWidth="lg" scroll="paper" fullWidth>
      {loading ? (
        <LoadingBox />
      ) : (
        <React.Fragment>
          <DialogTitleStyled>{t("text.editBankDetail")}</DialogTitleStyled>
          <DialogContent dividers>
            <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
              <fieldset disabled={submitting} style={{ border: 0 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {loading ? (
                          <LoadingBox />
                        ) : (
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              display: "flex",
                            }}
                          >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={type === 1 ? 3 : 4}
                                  xl={type === 1 ? 3 : 4}
                                >
                                  <CRMBankAccountTypeOptions
                                    name="accountTypeId"
                                    control={control}
                                    bankAccountTypes={bankAccountTypeOptions}
                                    error={!!errors.accountTypeId}
                                  />
                                  <FormHelperTextStyled>
                                    {displayErrorMessage(
                                      errors.accountTypeId?.message
                                    ) as string}
                                  </FormHelperTextStyled>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={type === 1 ? 3 : 4}
                                  xl={type === 1 ? 3 : 4}
                                >
                                  <CRMBankOptions
                                    name="bankId"
                                    control={control}
                                    banks={bankOptions}
                                    error={!!errors.bankId}
                                  />
                                  <FormHelperTextStyled>
                                    {displayErrorMessage(
                                      errors.bankId?.message
                                    ) as string}
                                  </FormHelperTextStyled>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={2}
                                  xl={2}
                                >
                                  <Controller
                                    control={control}
                                    name="agency"
                                    render={({ field }) => (
                                      <TextField
                                        value={field.value}
                                        onChange={(e) =>
                                          field.onChange(
                                            removeSpecialCharactersAndAccents(
                                              e.target.value
                                            )
                                          )
                                        }
                                        inputRef={field.ref}
                                        label={t("text.branch")}
                                        placeholder={t("text.branch") as string}
                                        fullWidth
                                        error={!!errors.agency}
                                        helperText={displayErrorMessage(
                                          errors?.agency?.message
                                        ) as string }
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={2}
                                  xl={2}
                                >
                                  <Controller
                                    control={control}
                                    name="accountNumber"
                                    render={({ field }) => (
                                      <TextField
                                        value={field.value}
                                        onChange={(e) =>
                                          field.onChange(
                                            removeSpecialCharactersAndAccents(
                                              e.target.value
                                            )
                                          )
                                        }
                                        inputRef={field.ref}
                                        label={t("text.account")}
                                        placeholder={t("text.account") as string}
                                        fullWidth
                                        error={!!errors.accountNumber}
                                        helperText={displayErrorMessage(
                                          errors?.accountNumber?.message
                                        )as string }
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </Grid>
                                {type === 1 ? (
                                  <React.Fragment>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={2}
                                      xl={2}
                                      sx={{
                                        marginTop: "1.70rem",
                                        "@media(max-width: 1200px)": {
                                          marginTop: "0",
                                        },
                                      }}
                                    >
                                      <Controller
                                        control={control}
                                        name="jointAccount"
                                        render={({
                                          field: { value, onChange, ref },
                                        }) => (
                                          <FormControlLabelStyled
                                            control={
                                              <Checkbox
                                                checked={value}
                                                onChange={onChange}
                                                inputRef={ref}
                                                disabled={uploading}
                                              />
                                            }
                                            label={t("text.isJointAccount")}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    {jointAccount && (
                                      <>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          lg={9}
                                          xl={9}
                                        >
                                          <Controller
                                            control={control}
                                            name={"accountHolderName"}
                                            render={({ field }) => (
                                              <TextField
                                                value={field.value}
                                                onChange={(e) =>
                                                  field.onChange(
                                                    removeSpecialCharactersAndAccents(
                                                      e.target.value
                                                    )
                                                  )
                                                }
                                                inputRef={field.ref}
                                                label={t(
                                                  "text.fullAccountHolderName"
                                                )}
                                                placeholder={t(
                                                  "text.fullAccountHolderName"
                                                ) as string}
                                                fullWidth
                                                error={
                                                  !!errors.accountHolderName
                                                }
                                                helperText={displayErrorMessage(
                                                  errors?.accountHolderName
                                                    ?.message
                                                ) as string}
                                                variant="outlined"
                                              />
                                            )}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          lg={3}
                                          xl={3}
                                        >
                                          <Controller
                                            control={control}
                                            name="accountHolderDocument"
                                            render={({ field }) => (
                                              <TextField
                                                value={field.value}
                                                onChange={(e) => {
                                                  e.persist();
                                                  masks.cpfMask.onChange(e);
                                                  field.onChange(e);
                                                }}
                                                inputRef={field.ref}
                                                label={t(
                                                  "text.accountHolderCPF"
                                                )}
                                                placeholder={t(
                                                  "text.accountHolderCPF"
                                                ) as string }
                                                fullWidth
                                                error={
                                                  !!errors.accountHolderDocument
                                                }
                                                helperText={displayErrorMessage(
                                                  errors?.accountHolderDocument
                                                    ?.message
                                                ) as string}
                                                variant="outlined"
                                              />
                                            )}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          lg={12}
                                          xl={12}
                                        >
                                          <Card>
                                            <CardHeaderTitleStyled
                                              title={t(
                                                "text.jointAccountConfirmation"
                                              )}
                                              subheader={t(
                                                "text.copyOfCheckStatementCardEtc"
                                              )}
                                            />
                                            <Document
                                              title={t("text.add")}
                                              register={register}
                                              getValues={getValues}
                                              name="jointAccountProof"
                                              uploading={uploading}
                                              setUploading={setUploading}
                                            />
                                            <CardActions>
                                              <Typography
                                                variant="caption"
                                                display="block"
                                              >
                                                {t(
                                                  "text.allowedFileTypesAndSize"
                                                )}
                                              </Typography>
                                            </CardActions>
                                          </Card>
                                          <FormHelperTextStyled>
                                            {displayErrorMessage(
                                              errors?.jointAccountProof?.message
                                            ) as string }
                                          </FormHelperTextStyled>
                                        </Grid>
                                      </>
                                    )}
                                  </React.Fragment>
                                ) : null}
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </fieldset>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={submitting || uploading}
              onClick={onReject}
              label={t("text.cancel")}
            />
            <LoadingButton
              type="submit"
              label={t("text.saveChanges")}
              form="submit-form"
              loading={submitting || uploading}
            />
          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  );
};

export const registrationUpdateBankDetailUpdateModal = create(
  RegistrationUpdateBankDetailUpdateModal
);
