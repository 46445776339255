import { Add, DeleteOutlined, Image } from "@mui/icons-material";
import {
  Avatar,
  Box,
  CardActionArea,
  CardActions,
  Card,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import TextButton from "../TextButton";
import { FormHelperTextStyled } from "../../styles/styles";
import { deleteModal } from "../DeleteModal";
import { DocumentHeadAdditional } from "../DocumentHeadAdditional";
import { displayErrorMessage } from "../../utils/errorMessage";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import api from "../../services/api";
import { documentInfo } from "../../utils/documentInfo";
import { Variant } from "../Document";





interface DocumentAdditionalProps {
  index: any;
  title: string;
  onDownload?: (file: any) => void;
  name: string;
  register: any;
  errors: any;
  remove: (index: any) => void;
  getValues: (name: string) => void;
  hasDriverLicense?: string;
  typeOfDocument?: string;
  canRemove?: boolean;
  uploading: boolean;
  setUploading: (e: boolean) => void;
  step?: string;
  headertitle?:string ;
  subheader?:string
}

const DocumentAdditional: React.FC<DocumentAdditionalProps> = ({
  index,
  title,
  onDownload,
  name,
  register,
  errors,
  remove,
  getValues,
  hasDriverLicense,
  typeOfDocument,
  canRemove = true,
  uploading,
  setUploading,
  step,
}) => {
  const selectedDocumentInfo = documentInfo.find(
    (d) => d.id === typeOfDocument
  );
  const { t } = useTranslation();
  const { onChange, ...params } = register(name);
  const values = getValues(name);
  const firstValue = Array.isArray(values) ? values[0] : null;
  const [file, setFile] = useState(firstValue);
  // const [file, setFile] = useState(getValues(name)[0] || null);
  const [progress, setProgress] = useState(0);
  const [variant, setVariant] = useState<Variant>(Variant.determinate);

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files![0];
    if (!selectedFile) return;

    if (selectedFile && selectedFile.size > 20971520) {
      //20mb
      removeUnwantedFile(event);
      toast.error(t("O arquivo deve ter no máximo 20MB."));
      return;
    }

    setUploading(true);
    try {
      var formData = new FormData();
      formData.append("file", selectedFile);
      const response = await api.post("/api/file/scan", formData, {
        onUploadProgress: (progressEvent) => {
          setProgress(
            parseInt(
              ((progressEvent.loaded / progressEvent.total) * 100).toString(),
              10
            )
          );
          if (progressEvent.loaded === progressEvent.total) {
            setVariant(Variant.indeterminate);
          }
        },
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const malwareScanResult = response.data.result;
      if (malwareScanResult === "No threats found") {
        setVariant(Variant.determinate);
        setProgress(0);
        setFile(selectedFile);
        onChange(event);
      } else if (malwareScanResult === "Malicious") {
        removeUnwantedFile(event);
        toast.error(t("text.maliciousFile"));
      } else {
        removeUnwantedFile(event);
        toast.error(t("text.maliciousFileVerificationFailure"));
      }
    } catch (error) {
      removeUnwantedFile(event);
      toast.error(t("text.toast.errorResetPassword"));
    } finally {
      setUploading(false);
    }
  };

  const removeUnwantedFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = "";
    setFile(null);
    onChange({
      target: { name: name, value: "" },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  };

  if (file) {
    return (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Card>
          {step === "income-powerOfAttorney-register" ? (
            <DocumentHeadAdditional
              title={t("text.sendCompleteDocumentAdd")}
              subheader={undefined}
            />
          ) : (
            <DocumentHeadAdditional
              title={
                hasDriverLicense === "1"
                  ? t("text.nationalDriversLicense")
                  : t(selectedDocumentInfo?.cardTitle!)
              }
              subheader={
                hasDriverLicense === "1"
                  ? t("text.CNHMustBeWithin")
                  : t(selectedDocumentInfo?.cardSubtitle!)
              }
            />
          )}
          <React.Fragment>
            <Box
              sx={{
                height: 150,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: (theme) => theme.spacing(2),
                paddingRight: (theme) => theme.spacing(2),
              }}
            >
              <div style={{ display: "flex" }}>
                <Avatar sx={{ marginRight: (theme) => theme.spacing(2) }}>
                  <Image />
                </Avatar>
                <TextButton
                  disabled={!file.id}
                  label={file.name}
                  onClick={() => onDownload!(file)}
                />
              </div>
            </Box>

            <CardActions>
              <Button
                variant="text"
                startIcon={<DeleteOutlined />}
                label={t("text.remove")}
                size="small"
                disabled={!canRemove}
                onClick={async () => {
                  try {
                    const title = t("text.remove");
                    const message = t("text.areYouSureRemoveDocument");
                    await deleteModal({
                      title,
                      message,
                      onResolve: () => {},
                      onReject: () => {},
                      onBeforeResolve: () => {},
                      closeOnConflict: false,
                    });

                    setFile(null);
                    remove(index);
                    onChange({
                      target: { name: name, value: [] },
                    } as unknown as React.ChangeEvent<HTMLInputElement>);
                  } catch (ignored) {}
                }}
              />
            </CardActions>
            <CardActions>
              <Typography variant="caption" display="block">
                {t("text.allowedFileTypesAndSize")}
              </Typography>
            </CardActions>
          </React.Fragment>
        </Card>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Card>
        {step === "income-powerOfAttorney-register" ? (
          <DocumentHeadAdditional
            title={t("text.sendCompleteDocumentAdd")}
            subheader={undefined}
          />
        ) : (
          <DocumentHeadAdditional
            title={
              hasDriverLicense === "1"
                ? t("text.nationalDriversLicense")
                : t(selectedDocumentInfo?.cardTitle!)
            }
            subheader={
              hasDriverLicense === "1"
                ? t("text.CNHMustBeWithin")
                : t(selectedDocumentInfo?.cardSubtitle!)
            }
          />
        )}
        <CardActionArea component="label" disabled={uploading}>
          <Box
            sx={{
              height: 150,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ width: 0, overflow: "hidden" }}>
              <input
                id="single"
                style={{ opacity: 0, filter: "alpha(opacity=0)" }}
                accept=".jpeg, .jpg, image/bmp, image/png, application/pdf"
                type="file"
                onChange={(event) => {
                  uploadFile(event);
                }}
                {...params}
              />
            </div>
            {uploading ? (
              <Box sx={{ width: "100%" }} paddingLeft={3} paddingRight={3}>
                <LinearProgress variant={variant} value={progress} />
              </Box>
            ) : (
              <>
                <Add fontSize="large" />
                <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  sx={{ marginTop: (theme) => theme.spacing(2) }}
                >
                  {title}
                </Typography>
              </>
            )}
          </Box>
        </CardActionArea>
        <CardActions>
          <Button
            variant="text"
            startIcon={<DeleteOutlined />}
            label={t("text.remove")}
            size="small"
            disabled={!canRemove || uploading}
            onClick={async () => {
              try {
                const title = t("text.remove");
                const message = t("text.areYouSureRemoveDocument");
                await deleteModal({
                  title,
                  message,
                  onResolve: () => {},
                  onReject: () => {},
                  onBeforeResolve: () => {},
                  closeOnConflict: false,
                });

                setFile(null);
                remove(index);
                onChange({
                  target: { name: name, value: [] },
                } as unknown as React.ChangeEvent<HTMLInputElement>);
              } catch (ignored) {}
            }}
          />
        </CardActions>
        <CardActions>
          <Typography variant="caption" display="block">
            {t("text.allowedFileTypesAndSize")}
          </Typography>
        </CardActions>
      </Card>
      {errors.documents?.[index]?.documentProof && (
        <FormHelperTextStyled>
          {displayErrorMessage(
            errors.documents?.[index]?.documentProof?.message
          )?.toString()}
        </FormHelperTextStyled>
      )}
    </Grid>
  );
};

DocumentAdditional.propTypes = {
  uploading: PropTypes.bool.isRequired,
  setUploading: PropTypes.func.isRequired,
};

export default DocumentAdditional;
