import {
    Grid,
    Step,
    StepButton,
    Stepper,
} from '@mui/material';
import i18n from 'i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const getSteps = (estimatedValueId: string) => {
    let steps = [
        {
            label: i18n.t("text.contactReason"),
            link: "/onboarding/contact-reason-register"
        },
        {
            label: i18n.t("text.personalData"),
            link: "/onboarding/personal-data-register"
        },
        {
            label: i18n.t("text.address"),
            link: "/onboarding/address-register"
        },
        {
            label: i18n.t("text.bankInformation"),
            link: "/onboarding/banking-references-register"
        },
        {
            label: i18n.t("text.summary"),
            link: "/onboarding/resume-register"
        },
    ];
    if (estimatedValueId !== "1") {
        steps.splice(4, 0,
            {
                label: i18n.t("text.income"),
                link: "onboarding/income-powerOfAttorney-register"
            }
        );
    }
    return steps;
}

interface OnboardingStepperRegisterProps {
    estimatedValueId:string
    activeStep:number
}

export const OnboardingStepperRegister = ({ estimatedValueId, activeStep } : OnboardingStepperRegisterProps) => {
    const steps = getSteps(estimatedValueId);
    const navigate = useNavigate()
    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
                padding: '2rem 0rem 1.5rem 0rem',
                position: 'sticky',
                top: '0',
                backgroundColor: '#f7f7f7',
                zIndex: '999',
            }}
        >
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step) => (
                    <Step key={step.label}>
                        <StepButton color="inherit" onClick={() => navigate(step.link)}>
                            {step.label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        </Grid>
    )
}
