import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from "../../contexts/auth";
import {
    FormDescription,
    FormTitle
} from '../../styles/styles';
import reactStringReplace from 'react-string-replace';
import { LoadingButton } from '../../components/LoadingButton';
import { getDetail } from '../../services/detailService';
import { LoadingBox } from '../../components/LoadingBox';
import { getLegalEntityResume } from '../../services/onboardingService';

export function Order() {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    let location = useLocation();
    const { user, fetchUser } = useContext(AuthContext);
    const type = user.value.type;
    const [submitting, setSubmitting] = useState<boolean>();
    const [client, setClient] = useState({
        estimatedValueId: '',
        productName: '',
    });
    const { canVisit } = location.state || {};
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        window.history.replaceState({}, document.title);

        async function fetchDetail() {
            try {
                const response = type === 1
                    ? await getDetail(i18n.language)
                    : await getLegalEntityResume();
                setClient(response.data);
                setLoading(false);
            } catch (error:any) {
                const errorCode = error.response?.data?.errorCode;
                if (errorCode === "12000") {
                    navigate('/dashboard');
                }
            }
        }

        fetchDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!(!!canVisit)) {
        return <Navigate to="/dashboard" replace />
    }

    const refreshUser = async () => {
        setSubmitting(true);
        try {
            await fetchUser();
            navigate('/dashboard');
        } catch (error) {
            return;
        } finally {
            setSubmitting(false);
        }
    };

    const getMessage = () => {
        let message;
        if (type === 1) {

            if (client.estimatedValueId === "1") {
                message = t("text.privateOrderInfoUpTo");
                message = t("text.privateOrderInfoUpTo", {
                    product_name: t(client.productName)
                });
                message = reactStringReplace(message, "{{br}}", (_, index) => (
                    <br key={index} />
                ));
                message = reactStringReplace(message, "{{privateOrderInfoUpToComplement}}", (_, index) => (
                    <strong>{t("text.privateOrderInfoUpToComplement")}</strong>
                ));
            } else {
                message = t("text.privateOrderInfoAbove");
                message = t("text.privateOrderInfoAbove", {
                    product_name: t(client.productName)
                });
                message = reactStringReplace(message, "{{br}}", (_, index) => (
                    <br key={index} />
                ));
                message = reactStringReplace(message, "{{privateOrderInfoAboveComplement}}", (_, index) => (
                    <strong>{t("text.privateOrderInfoAboveComplement")}</strong>
                ));
            }
        } else {
            message = reactStringReplace(t("text.corporateOrderInfo") as string, "{{br}}", (_, index) => (
                <br key={index} />
            ));
        }
        return message;
    }

    return (
        <>
            {loading
                ? <LoadingBox />
                : <Grid
                    container
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '2rem',
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={5}
                        lg={5}
                        xl={5}
                        sx={{
                            background: 'var(--white)',
                            borderRadius: '4px'
                        }}
                    >
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                padding: '2rem'
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              //  align="center"
                            >
                                <FormTitle>{t("text.weAreAlmostDone")}</FormTitle>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                           //     align="center"
                            >

                                <FormDescription>
                                    {getMessage()}
                                </FormDescription>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <LoadingButton
                                    onClick={() => {
                                        refreshUser();
                                    }}
                                    variant="contained"
                                    label={t("text.continue")}
                                    loading={submitting}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    );
}
