import { isValidCPF } from "@brazilian-utils/brazilian-utils";
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Card,
    CardActions,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Typography
} from '@mui/material';
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { create } from 'react-modal-promise';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { AuthContext } from '../../contexts/auth';
import { getBankAccountTypesPromise } from "../../services/bankAccountTypeService";
import { saveBankDetail } from "../../services/bankDetailService";
import { getBanksPromise } from "../../services/bankService";
import {
    CardHeaderTitleStyled,
    FormControlLabelStyled,
    FormHelperTextStyled
} from '../../styles/styles';
import { displayErrorMessage } from '../../utils/errorMessage';
import { toBase64 } from "../../utils/fileUtils";
import * as masks from "../../utils/masks";
import { CRMBankAccountTypeOptions } from "../CRMBankAccountTypeOptions";
import { CRMBankOptions } from "../CRMBankOptions";
import { DialogTitleStyled } from '../DeleteModal/styles';
import Document from '../Document';
import { LoadingBox } from '../LoadingBox';
import { LoadingButton } from '../LoadingButton';
import { TextField } from '../TextField';
import { removeSpecialCharactersAndAccents } from '../../utils/stringUtils';
import { IBankDetails } from '../../interfaces/bankDetails'

const validationSchema = yup.object().shape({
    id: yup.string().nullable(),
    accountTypeId: yup.string().required(),
    bankId: yup.string().required(),
    agency: yup.string()
        .required()
        .matches(/^[0-9]+$/gm, "validations.numbersOnly"),
    accountNumber: yup.string()
        .required()
        .matches(/^[0-9]+$/gm, "validations.numbersOnly"),
    jointAccount: yup.bool(),
    accountHolderName: yup.string()
        .when("jointAccount", {
            is: true,
            then: yup.string().required(),
        }),
    accountHolderDocument: yup.string()
        .when("jointAccount", {
            is: true,
            then: yup.string()
                .required()
                .transform(masks.cpfMask.transform)
                .test("validateCPF", (value) => {
                    return isValidCPF(value!)
                }),
        }),
    jointAccountProof: yup.mixed()
        .when("jointAccount", {
            is: true,
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                })
        }),
});

interface AddBankDetailModalProps {
    open: boolean;
    onResolve: () => void;
    onReject: () => void;
}

const AddBankDetailModal: React.FC<AddBankDetailModalProps> = ({ open, onResolve, onReject }) => {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);
    const type = user.value.type;
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [bankOptions, setBankOptions] = useState([]);
    const [bankAccountTypeOptions, setBankAccountTypeOptions] = useState([]);
    const [uploading, setUploading] = useState<boolean>(false);

    const { control, handleSubmit, formState: { errors }, register, getValues, } = useForm<IBankDetails>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            id: "",
            accountTypeId: "",
            bankId: "",
            agency: "",
            accountNumber: "",
            jointAccount: false,
            accountHolderName: "",
            accountHolderDocument: "",
            jointAccountProof: "",
        }
    });

    useEffect(() => {
        async function fetchData() {
            try {

                const [bankAccountTypeOptions, bankOptions] = await Promise.all([
                    getBankAccountTypesPromise(),
                    getBanksPromise(),
                ]);

                setBankAccountTypeOptions(bankAccountTypeOptions.data);
                setBankOptions(bankOptions.data)
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }

        fetchData();
    }, []);

    const onSubmit = async (data: IBankDetails) => {
        setSubmitting(true);

        const jointAccountProof: any = data.jointAccountProof![0];
        const jointAccount = data.jointAccount;
        const hasJointAccountProofId = !!jointAccountProof?.id;
        const shouldSendFile = jointAccount && !hasJointAccountProofId;
        if (shouldSendFile) {
            const result = await toBase64(jointAccountProof);
            data.fileOriginalName = jointAccountProof.name;
            data.fileInBase64 = result;
            data.fileSize = jointAccountProof.size;
            data.fileType = jointAccountProof.type;
        }
        delete data.jointAccountProof;
        try {
            await saveBankDetail(data, false);
            toast.success(t("text.toast.create"));
            onResolve();
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    const jointAccount = useWatch({
        control,
        name: "jointAccount"
    });

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            scroll="paper"
            fullWidth
        >
            {loading
                ? <LoadingBox />
                : <React.Fragment>
                    <DialogTitleStyled>
                        {t("text.addBankDetail")}
                    </DialogTitleStyled>
                    <DialogContent dividers>
                        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
                            <fieldset disabled={submitting} style={{ border: 0 }}>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Grid
                                            container
                                            spacing={3}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                {loading
                                                    ? <LoadingBox />
                                                    :
                                                    (<Grid
                                                        container
                                                        spacing={2}
                                                        sx={{
                                                            display: 'flex'
                                                        }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={12}
                                                                    lg={type === 1 ? 3 : 4}
                                                                    xl={type === 1 ? 3 : 4}
                                                                >

                                                                    <CRMBankAccountTypeOptions
                                                                        name="accountTypeId"
                                                                        control={control}
                                                                        bankAccountTypes={bankAccountTypeOptions}
                                                                        error={!!errors.accountTypeId}
                                                                    />
                                                                    <FormHelperTextStyled>
                                                                        {displayErrorMessage(errors.accountTypeId?.message) as string}
                                                                    </FormHelperTextStyled>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={12}
                                                                    lg={type === 1 ? 3 : 4}
                                                                    xl={type === 1 ? 3 : 4}
                                                                >
                                                                    <CRMBankOptions
                                                                        name="bankId"
                                                                        control={control}
                                                                        banks={bankOptions}
                                                                        error={!!errors.bankId}
                                                                    />
                                                                    <FormHelperTextStyled>
                                                                        {displayErrorMessage(errors.bankId?.message) as string}
                                                                    </FormHelperTextStyled>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={12}
                                                                    lg={2}
                                                                    xl={2}
                                                                >
                                                                    <Controller
                                                                        control={control}
                                                                        name="agency"
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                value={field.value}
                                                                                onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                                                inputRef={field.ref}
                                                                                label={t("text.branch")}
                                                                                placeholder={t("text.branch") as string}
                                                                                fullWidth
                                                                                error={!!errors.agency}
                                                                                helperText={displayErrorMessage(errors?.agency?.message) as string}
                                                                                variant="outlined"
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={12}
                                                                    lg={2}
                                                                    xl={2}
                                                                >
                                                                    <Controller
                                                                        control={control}
                                                                        name="accountNumber"
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                value={field.value}
                                                                                onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                                                inputRef={field.ref}
                                                                                label={t("text.account")}
                                                                                placeholder={t("text.account") as string}
                                                                                fullWidth
                                                                                error={!!errors.accountNumber}
                                                                                helperText={displayErrorMessage(errors?.accountNumber?.message) as string}
                                                                                variant="outlined"
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                {type === 1
                                                                    ? <React.Fragment>
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={12}
                                                                            md={12}
                                                                            lg={2}
                                                                            xl={2}
                                                                            sx={{
                                                                                marginTop: '1.70rem',
                                                                                '@media(max-width: 1200px)': {
                                                                                    marginTop: '0',
                                                                                }
                                                                            }}
                                                                        >
                                                                            <Controller
                                                                                control={control}
                                                                                name="jointAccount"
                                                                                render={({ field: { value, onChange, ref } }) => (
                                                                                    <FormControlLabelStyled
                                                                                        control={
                                                                                            <Checkbox
                                                                                                checked={value}
                                                                                                onChange={onChange}
                                                                                                inputRef={ref}
                                                                                                disabled={uploading}
                                                                                            />
                                                                                        }
                                                                                        label={t("text.isJointAccount")}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </Grid>
                                                                        {jointAccount && (
                                                                            <>
                                                                                <Grid
                                                                                    item
                                                                                    xs={12}
                                                                                    sm={12}
                                                                                    md={12}
                                                                                    lg={9}
                                                                                    xl={9}
                                                                                >
                                                                                    <Controller
                                                                                        control={control}
                                                                                        name={"accountHolderName"}
                                                                                        render={({ field }) => (
                                                                                            <TextField
                                                                                                value={field.value}
                                                                                                onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                                                                inputRef={field.ref}
                                                                                                label={t("text.fullAccountHolderName")}
                                                                                                placeholder={t("text.fullAccountHolderName") as string}
                                                                                                fullWidth
                                                                                                error={!!errors.accountHolderName}
                                                                                                helperText={displayErrorMessage(errors?.accountHolderName?.message) as string}
                                                                                                variant="outlined"
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={12}
                                                                                    sm={12}
                                                                                    md={12}
                                                                                    lg={3}
                                                                                    xl={3}
                                                                                >
                                                                                    <Controller
                                                                                        control={control}
                                                                                        name="accountHolderDocument"
                                                                                        render={({ field }) => (
                                                                                            <TextField
                                                                                                value={field.value}
                                                                                                onChange={(e) => {
                                                                                                    e.persist();
                                                                                                    masks.cpfMask.onChange(e);
                                                                                                    field.onChange(e);
                                                                                                }}
                                                                                                inputRef={field.ref}
                                                                                                label={t("text.accountHolderCPF")}
                                                                                                placeholder={t("text.accountHolderCPF") as string}
                                                                                                fullWidth
                                                                                                error={!!errors.accountHolderDocument}
                                                                                                helperText={displayErrorMessage(errors?.accountHolderDocument?.message) as string}
                                                                                                variant="outlined"
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={12}
                                                                                    sm={12}
                                                                                    md={12}
                                                                                    lg={12}
                                                                                    xl={12}
                                                                                >
                                                                                    <Card>
                                                                                        <CardHeaderTitleStyled
                                                                                            title={t("text.jointAccountConfirmation")}
                                                                                            subheader={t("text.copyOfCheckStatementCardEtc")}
                                                                                        />
                                                                                        <Document
                                                                                            title={t("text.add")}
                                                                                            register={register}
                                                                                            getValues={getValues}
                                                                                            name="jointAccountProof"
                                                                                            uploading={uploading}
                                                                                            setUploading={setUploading}
                                                                                        />
                                                                                        <CardActions>
                                                                                            <Typography variant="caption" display="block">{t("text.allowedFileTypesAndSize")}</Typography>
                                                                                        </CardActions>
                                                                                    </Card>
                                                                                    <FormHelperTextStyled>
                                                                                        {displayErrorMessage(errors?.jointAccountProof?.message) as string}
                                                                                    </FormHelperTextStyled>
                                                                                </Grid>
                                                                            </>
                                                                        )}
                                                                    </React.Fragment>
                                                                    : null
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>)
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </fieldset>
                        </form >
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            disabled={submitting || uploading}
                            onClick={onReject}
                            label={t("text.cancel")}
                        />
                        <LoadingButton
                            type="submit"
                            label={t("text.add")}
                            form="submit-form"
                            loading={submitting || uploading}
                        />
                    </DialogActions>
                </React.Fragment>
            }
        </Dialog>
    );
}

export const addBankDetailModal = create(AddBankDetailModal);
