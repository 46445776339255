import { format } from "date-fns";

export const yupLocale = {
    mixed: {
        default: {
            key: 'validations.invalid',
        },
        required: {
            key: 'validations.required',
        },
        notType: ({ type } : {type:string}) => ({
            key: 'validations.invalidType',
            values: { type },
        }),
    },
    string: {
        email: {
            key: 'validations.email',
        },
        min: ({ min } : {min:number}) => ({
            key: 'validations.stringMin',
            values: { min },
        }),
        max: ({ max } : {max:number}) => ({
            key: 'validations.stringMax',
            values: { max },
        }),
    },
    number: {},
    boolean: {},
    date: {
        notType: ({ type } : {type:string}) => ({
            key: 'validations.invalidType',
            values: { type },
        }),
        min: ({ min } : {min:string | number | Date}) => ({
            key: 'validations.dateMin',
            values: { min: typeof min === 'string' ? min : (min instanceof Date ? format(min, "dd-MM-yyyy") : min) },
        }),
        max: ({ max } : {max:string | number | Date}) => ({
            key: 'validations.dateMax',
            values: { max: typeof max === 'string' ? max : (max instanceof Date ? format(max, "dd-MM-yyyy") : max) },
        }),
    }
};
