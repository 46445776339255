import i18n from "i18next";

export const displayErrorMessage = (error: any) => {
    const { t } = i18n;

    if (error === undefined) {
        return null;
    } else if (typeof error === 'string') {
        return t(error);
    } else {
        const { key, values } = error;
        return t(key, values);
    }
}
