import {
    Autocomplete,
} from '@mui/material';
import { Controller } from "react-hook-form";
import { TextField } from "../TextField";
import React from 'react';

type Ddi = {
    id: string ; 
    ddi:string
}

interface CRMDdiOptionsProps {
    control : any ;
    label: string  ;
    name: string ;
    ddis: Array<Ddi> ; 
    error:any;
    ddiChanged: ()=> void 
}

export function CRMDdiOptions({ control, label, name, ddis, error, ddiChanged } : CRMDdiOptionsProps ) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => {
                const { value, onChange } = field;
                return (
                    <Autocomplete
                        value={
                            value
                                ? ddis.find((option: { id: string ; }) => {
                                    return value === option.id;
                                }) ?? null
                                : null
                        }
                        onChange={(event, newValue) => {
                            onChange(newValue ? newValue.id : '');
                            if (newValue === null) ddiChanged();
                        }}
                        getOptionLabel={(ddis) => ddis.ddi}
                        options={ddis}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputRef={field.ref}
                                error={error}
                                label={label}
                                variant="outlined"
                            />
                        )}
                    />
                )
            }}
        />
    );
}