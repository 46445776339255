import { yupResolver } from '@hookform/resolvers/yup';
import {
    ArrowBack,
    Visibility,
    VisibilityOff
} from '@mui/icons-material';
import {
    Grid,
    IconButton,
    InputAdornment
} from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { LoadingButton } from '../../components/LoadingButton';
import TextButton from '../../components/TextButton';
import { TextField } from "../../components/TextField";
import api from '../../services/api';
import { displayErrorMessage } from "../../utils/errorMessage";

const validationSchema = yup.object({
    password: yup.string()
        .min(10)
        .max(20)
        .matches(/[a-z]/, "validations.lowercase")
        .matches(/[A-Z]/, "validations.uppercase")
        .matches(/[0-9]/, "validations.number")
        .matches(/[^\w]/, "validations.specialCharacter")
        .required(),
    passwordConfirmation: yup.string()
        .min(10)
        .max(20)
        .matches(/[a-z]/, "validations.password.lowercase")
        .matches(/[A-Z]/, "validations.password.uppercase")
        .matches(/[0-9]/, "validations.password.number")
        .matches(/[^\w]/, "validations.password.specialCharacter")
        .required()
        .oneOf([yup.ref("password")], "validations.passwordsDoNotMatch"),
});

export function ResetPassword() {
    const navigate = useNavigate() ;
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token')
    const [loading, setLoading] = useState<boolean>(false);

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            password: "",
            passwordConfirmation: ""
        }
    })

    const onSubmit = async (data:any) => {

        const { password, passwordConfirmation } = data;
        setLoading(true);
        try {
            await api.post(`/api/password/reset`, {
                password: password,
                passwordConfirmation: passwordConfirmation,
                token: token
            });
            window.setTimeout(() => {
                navigate("/")
            }, 5000);
            toast.success(
                t("text.toast.successResetPassword"),
                { autoClose: 5000 }
            );
        } catch (error:any) {
            setLoading(false)
            toast.error(`${t("text.toast.errorResetPassword")} ${error.response?.error ?? error.message}`)
        }

    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={loading} style={{ border: 0 }}>
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={4}
                    lg={4}
                    xl={4}
                    sx={{ p: 4 }}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            sx={{
                                textTransform: 'uppercase'
                            }}
                        >
                            <TextButton
                                label={t("text.back")}
                                startIcon={<ArrowBack />}
                                component={Link}
                                to="/"
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <Controller
                                control={control}
                                name="password"
                                render={({ field }) => (
                                    <TextField
                                        type={showPassword ? "text" : "password"}
                                        value={field.value}
                                        onChange={field.onChange}
                                        inputRef={field.ref}
                                        label={t("text.password")}
                                        placeholder={t("text.password") as string}
                                        fullWidth
                                        error={!!errors.password}
                                        helperText={displayErrorMessage(errors.password?.message) as string}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment
                                                    position="end"
                                                >
                                                    <IconButton
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        variant='outlined'
                                    />
                                )}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <Controller
                                control={control}
                                name="passwordConfirmation"
                                render={({ field }) => (
                                    <TextField
                                        type="password"
                                        value={field.value}
                                        onChange={field.onChange}
                                        inputRef={field.ref}
                                        label={t("text.passwordConfirmation")}
                                        placeholder={t("text.passwordConfirmation") as string}
                                        error={!!errors.passwordConfirmation}
                                        helperText={displayErrorMessage(errors.passwordConfirmation?.message) as string}
                                        fullWidth
                                        variant='outlined'
                                    />
                                )}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '.25rem',
                                justifyContent: 'center',
                            }}
                        >
                            <LoadingButton
                                type="submit"
                                label={t("text.passwordConfirmation")}
                                variant="contained"
                                fullWidth
                                loading={loading}
                                disabled={loading}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </fieldset>
        </form>
    );
}