import { yupResolver } from '@hookform/resolvers/yup';
import {
    Grid,
} from '@mui/material';
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import * as yup from "yup";
import { Button } from '../../../components/Button';
import { CRMBankAccountTypeOptions } from '../../../components/CRMBankAccountTypeOptions';
import { CRMBankOptions } from '../../../components/CRMBankOptions';
import { LoadingBox } from '../../../components/LoadingBox';
import { LoadingButton } from '../../../components/LoadingButton';
import { PageName } from '../../../components/PageName';
import { TextField } from '../../../components/TextField';
import { AuthContext } from "../../../contexts/auth";
import { getBankAccountTypesPromise } from '../../../services/bankAccountTypeService';
import { getFirstBankDetailPromise, saveBankDetail } from '../../../services/bankDetailService';
import { getBanksPromise } from '../../../services/bankService';
import {
    FormHelperTextStyled,
} from '../../../styles/styles';
import { displayErrorMessage } from "../../../utils/errorMessage";
import { LegalEntityStepper } from '../../../components/LegalEntityStepper';
import { removeSpecialCharactersAndAccents } from '../../../utils/stringUtils';

const validationSchema = yup.object({
    id: yup.string(),
    accountTypeId: yup.string().required(),
    bankId: yup.string().required(),
    agency: yup.string()
        .required()
        .matches(/^[0-9-]*$/, "validations.matchesNumbersAndDashes"),
    accountNumber: yup.string()
        .required()
        .matches(/^[0-9-]*$/, "validations.matchesNumbersAndDashes"),
        legalNature: yup.string(),
});

export function BankingReferencesLegalEntity({ onboarding = true }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { handleSignOut } = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>();
    const [bankOptions, setBankOptions] = useState([]);
    const [bankAccountTypeOptions, setBankAccountTypeOptions] = useState([]);
    const [uploading] = useState<boolean>(false);

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            id: "",
            accountTypeId: "",
            code: "",
            agency: "",
            name: "",
            accountNumber: "",
            legalNature: "",
            bankId:""
        }
    });

    const legalNature = useWatch({
        control,
        name: "legalNature",
    });

    useEffect(() => {
        async function fetchBankReferences() {
            try {
                const [bankAccountTypeOptions, bankOptions, firstBankDetail] = await Promise.all([
                    getBankAccountTypesPromise(),
                    getBanksPromise(),
                    getFirstBankDetailPromise()
                ]);

                setBankAccountTypeOptions(bankAccountTypeOptions.data);
                setBankOptions(bankOptions.data)

                const values = {
                    ...firstBankDetail.data,
                }

                reset(values);
                setLoading(false);
            } catch (error:any) {
                const errorCode = error.response?.data?.errorCode;
                if (errorCode === "12000") {
                    navigate('/dashboard');
                }
            }
        }

        fetchBankReferences();
    }, [navigate, reset])

    const onSubmit = async (data:any) => {
        setSubmitting(true);
        try {
            await saveBankDetail(data, onboarding);
            const to = onboarding
                ? `/onboarding/administration-legal-entity-register`
                : `/dashboard/administration-legal-entity`;
            navigate(to);
        } catch (error:any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                sx={onboarding
                    ? {
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '2rem',
                    }
                    : null}
            >
                {loading
                    ? <LoadingBox />
                    : <React.Fragment>
                        <LegalEntityStepper
                            activeStep={3}
                            register={onboarding}
                            legalNature={legalNature}
                        />
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={onboarding ? 10 : 12}
                            lg={onboarding ? 10 : 12}
                            xl={onboarding ? 10 : 12}
                            sx={{
                                background: 'var(--white)',
                                borderRadius: '4px',
                                padding: '2rem',
                            }}
                        >
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <PageName
                                        title={t("text.bankInformation")}
                                        info={t("text.toProceedWithTheRegistrationPleaseFillInTheInformationBelow")}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '1rem'
                                                    }}
                                                >
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={3}
                                                    xl={3}
                                                >
                                                    <CRMBankAccountTypeOptions
                                                        name="accountTypeId"
                                                        control={control}
                                                        bankAccountTypes={bankAccountTypeOptions}
                                                        error={!!errors.accountTypeId}
                                                    />
                                                    <FormHelperTextStyled>
                                                        {displayErrorMessage(errors.accountTypeId?.message) as string}
                                                    </FormHelperTextStyled>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={3}
                                                    xl={3}
                                                >
                                                    <CRMBankOptions
                                                        name="bankId"
                                                        control={control}
                                                        banks={bankOptions}
                                                        error={!!errors.bankId}
                                                    />
                                                    <FormHelperTextStyled>
                                                        {displayErrorMessage(errors.bankId?.message) as string}
                                                    </FormHelperTextStyled>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={2}
                                                    xl={2}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="agency"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                                inputRef={field.ref}
                                                                label={t("text.branch")}
                                                                placeholder={t("text.branch") as string}
                                                                fullWidth
                                                                error={!!errors?.agency}
                                                                helperText={displayErrorMessage(errors.agency?.message) as string}
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={4}
                                                    xl={4}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="accountNumber"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                                inputRef={field.ref}
                                                                label={t("text.account")}
                                                                placeholder={t("text.account") as string}
                                                                fullWidth
                                                                error={!!errors?.accountNumber}
                                                                helperText={displayErrorMessage(errors.accountNumber?.message) as string}
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <Button
                                                variant="outlined"
                                                label={t("text.previousStep")}
                                                component={Link}
                                                to={onboarding ? "/onboarding/address-legal-entity-register" : "/dashboard/address-legal-entity"}
                                                disabled={submitting || uploading}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                label={t("text.nextStep")}
                                                fullWidth
                                                loading={submitting || uploading}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Button
                                                variant="text"
                                                label={t("text.continueLater")}
                                                onClick={handleSignOut}
                                                disabled={submitting || uploading}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        </form>
    );
}
