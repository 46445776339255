import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import {
    FormDescription,
    FormTitle
} from '../../styles/styles';

export function ChangeProfile() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
      
        <Grid
            container
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '2rem',
            }}
        >
            <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                sx={{
                    background: 'var(--white)',
                    borderRadius: '4px'
                }}
            >
                <Grid
                    container
                    spacing={2}
                    sx={{
                        padding: '2rem'
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                   //    align="center"
                    >
                        <FormTitle>{t("text.dataHasBeenUpdatedSuccessfully")}</FormTitle>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                  //     justify="center" 
                        
                    >
                        <FormDescription>
                            {t("text.yourDataHasBeenUpdatedSuccessfully")}
                        </FormDescription>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        <Button
                            variant="contained"
                            label={t("text.goToDashboard")}
                            onClick={() => {
                                navigate('/dashboard');
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}