
import { IDocument } from "../interfaces/document";
import api from "./api";

export const getDocumentsByProspect = async () => {
    return await api.get(`/api/client-document/prospect`, { withCredentials: true });
}

export const getDocumentByProspectAndType = async (code:string) => {
    return await api.get(`/api/client-document/${code}/prospect`, { withCredentials: true });
}

export const saveDocument = async (data:IDocument) => {
    return await api.post(`/api/client-document`, data, { withCredentials: true });
}