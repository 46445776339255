import {
    DialogContent,
    DialogTitle
} from '@mui/material';
import styled from "styled-components";

export const DialogTitleStyled = styled(DialogTitle)`
    && {
        font-weight: 700;
        color: var(--gray-900);
    }
`;

export const DialogContentStyled = styled(DialogContent)`
    && {
        font-weight: 400;
        color: var(--gray-600);
    }
`; 
