import { formatCNPJ, isValidEmail } from '@brazilian-utils/brazilian-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Card,
    CardActions,
    Chip,
    CircularProgress,
    FormControl,
    Grid,
    InputAdornment,
    Radio,
    RadioGroup,
    Typography,
    Link as LinkMUI
} from '@mui/material';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { Button } from '../../../components/Button';
import { CRMLegalNatureOptions } from '../../../components/CRMLegalNatureOptions';
import Document from '../../../components/Document';
import DocumentAdditional from '../../../components/DocumentAdditional';
import { LoadingBox } from "../../../components/LoadingBox";
import { LoadingButton } from '../../../components/LoadingButton';
import { PageName } from '../../../components/PageName';
import { TextField } from '../../../components/TextField';
import { AuthContext } from '../../../contexts/auth';
import { getCRMLegalNaturePromise } from '../../../services/crmLegalNatureService';
import { getOverallDataPromise, saveOverallData } from "../../../services/onboardingService";
import {
    CardHeaderTitleStyled, FormControlLabelStyled, FormHelperTextStyled, FormLabelStyled,
} from '../../../styles/styles';
import { languages } from '../../../utils/adapterLocaleUtils';
import { displayErrorMessage } from "../../../utils/errorMessage";
import { toBase64 } from "../../../utils/fileUtils";
import * as masks from "../../../utils/masks";
import { DocumentTypeUpload } from '../../../components/DocumentTypeUpload';
import { LegalEntityStepper } from '../../../components/LegalEntityStepper';
import { stripToEmpty } from "../../../utils/stringUtils";
import { getCRMDdiPromise } from '../../../services/crmDdi';
import { CRMDdiOptions } from '../../../components/CRMDdiOptions';
import { removeSpecialCharactersAndAccents } from '../../../utils/stringUtils';
import { hasCellPhone, hasNumber, hasPhone } from '../../../utils/phoneUtils';
import { IOverallData } from '../../../interfaces/overallData';

const validationSchema = yup.object({
    cnpj: yup.string().required(),
    corporateName: yup.string().required(),
    constitutionDate: yup.date()
        .nullable()
        .transform((curr, orig) => orig === '' ? null : curr)
        .typeError("Erro")
        .required()
        .min(new Date(1500, 0, 1))
        .max(new Date()),
    fantasyName: yup.string(),
    fieldOfActivity: yup.string().required(),
    cellPhoneDdi: yup.string().nullable()
        .test('hasNumber', 'Campo obrigatório', (value, context) => {
            return hasNumber(context);
        })
        .test('hasCellPhone', 'Campo obrigatório', (value, context) => {
            return hasCellPhone(context);
        }),
    cellPhoneDdd: yup.string().nullable()
        .test('hasNumber', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasCellPhone', 'validations.required', (value, context) => {
            return hasCellPhone(context);
        })
        .matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    cellPhone: yup.string().nullable()
        .test('hasCellPhone', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasPhone', 'validations.required', (value, context) => {
            return hasCellPhone(context);
        })
        .matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    phoneDdi: yup.string().nullable()
        .test('hasNumber', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasPhone', 'validations.required', (value, context) => {
            return hasPhone(context);
        }),
    phoneDdd: yup.string().nullable()
        .test('hasNumber', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasPhone', 'validations.required', (value, context) => {
            return hasPhone(context);
        })
        .matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    phone: yup.string().nullable()
        .test('custom', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasPhone', 'validations.required', (value, context) => {
            return hasPhone(context);
        })
        .matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    email: yup.string()
        .required()
        .min(10)
        .transform((curr, orig) => stripToEmpty(curr))
        .test("validateEmail", (value) => isValidEmail(stripToEmpty(value))),
    hasSite: yup.string().required(),
    site: yup.string()
        .when("hasSite", {
            is: "1",
            then: yup.string().required()
                .test("url", "validations.site", (value) => {
                    const urlRegex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
                    return urlRegex.test(value!);
                }),
        }),
    legalNature: yup.string().required(),
    openedCapital: yup.string()
        .when("legalNature", {
            is: "120240004", // SA
            then: yup.string().required()
        }),
    socialContractProof: yup.mixed()
        .when(["legalNature", "documentTypeUpload"], {
            is: (legalNature: string, documentTypeUpload: string) => legalNature === "120240003" || (legalNature === "120240005" && documentTypeUpload === "1"), // LTDA
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                }),
        }),
    lastContractualAmendmentProof: yup.mixed()
        .when(["hasContractualAlteration", "legalNature", "documentTypeUpload"], {
            is: (hasContractualAlteration: string, legalNature: string, documentTypeUpload: string) => hasContractualAlteration === "1" && (legalNature === "120240003" || (legalNature === "120240005" && documentTypeUpload === "1")),
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                }),
        }),
    bylawsProof: yup.mixed()
        .when(["legalNature", "documentTypeUpload"], {
            is: (legalNature: string, documentTypeUpload: string) => legalNature === "120240004" || (legalNature === "120240005" && documentTypeUpload === "2"), // SA OU DEMAIS COM TIPO DE DOCUMENTO ESTATUTO SOCIAL
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                }),
        }),
    lastElectionMinuteProof: yup.mixed()
        .when(["legalNature", "documentTypeUpload"], {
            is: (legalNature: string, documentTypeUpload: string) => legalNature === "120240004" || (legalNature === "120240005" && documentTypeUpload === "2"), // SA OU DEMAIS COM TIPO DE DOCUMENTO ESTATUTO SOCIAL
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                }),
        }),
    individualMicroentrepreneurCertificateProof: yup.mixed()
        .when("legalNature", {
            is: "120240000", // MEI
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                }),
        }),
    entrepreneurApplicationProof: yup.mixed()
        .when("legalNature", {
            is: (val: string) => val === "120240001" || val === "120240002", // ME ou EPP
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                }),
        }),
    documents: yup.array()
        .of(
            yup.object().shape({
                id: yup.string(),
                documentProof: yup.mixed()
                    .test("required", "validations.fileRequired", (value) => value.length > 0)
                    .test("fileSize", "validations.fileSize", (value) => {
                        return value.length && value[0].size <= 20971520;
                    })
                    .test("fileType", "validations.fileType", (value) => {
                        return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                    }),
            })
        ),
    hasContractualAlteration: yup.mixed()
        .when(["legalNature", "documentTypeUpload"], {
            is: (legalNature: string, documentTypeUpload: string) => legalNature === "120240003" || (legalNature === "120240005" && documentTypeUpload === "1"), // LTDA
            then: yup.string().required(),
        }),
    documentTypeUpload: yup.mixed()
        .when("legalNature", {
            is: "120240005", // DEMAIS
            then: yup.string().required(),
        }),
});

const documentTypeUploadOptions = [
    {
        "id": "1",
        "name": "Contrato Social",
    },
    {
        "id": "2",
        "name": "Estatuto Social",
    },
];

export function OverallDataRegister() {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const { handleSignOut } = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [legalNaturesOptions, setLegalNaturesOptions] = useState([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const [ddis, setDdis] = useState([]);

    const { control, handleSubmit, formState: { errors }, register, getValues, setValue, reset, resetField } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            cnpj: "",
            corporateName: "",
            constitutionDate: "",
            fantasyName: "",
            fieldOfActivity: "",
            phoneDdi: "",
            phoneDdd: "",
            phone: "",
            cellPhoneDdi: "",
            cellPhoneDdd: "",
            cellPhone: "",
            email: "",
            hasSite: "",
            site: "",
            legalNature: "",
            documentTypeUpload: "",
            openedCapital: "",
            socialContractProof: "",
            lastContractualAmendmentProof: "",
            individualMicroentrepreneurCertificateProof: "",
            entrepreneurApplicationProof: "",
            bylawsProof: "",
            lastElectionMinuteProof: "",
            hasContractualAlteration: "1",
        }
    });

    const { fields, append, remove } = useFieldArray<any, string, string>({
        control,
        name: "documents",
        keyName: "keyName",
    });

    const legalNature = useWatch({
        control,
        name: "legalNature",
    });

    const hasContractualAlteration = useWatch({
        control,
        name: "hasContractualAlteration",
    });

    const documentTypeUpload = useWatch({
        control,
        name: "documentTypeUpload",
    });

    const hasSite = useWatch({
        control,
        name: "hasSite",
    });

    useEffect(() => {
        const fetchOverallData = async () => {
            try {
                const [legalNaturesOptions, ddisOptions, overallData] = await Promise.all([
                    getCRMLegalNaturePromise(),
                    getCRMDdiPromise(),
                    getOverallDataPromise()
                ]);

                setDdis(ddisOptions.data);
                setLegalNaturesOptions(legalNaturesOptions.data);

                let responseData = overallData.data;

                var socialContractProof = responseData.socialContractProof;
                const socialContractProofId = socialContractProof?.id;
                if (socialContractProofId) {
                    socialContractProof = new File(["socialContractProof"], socialContractProof.fileOriginalName, {
                        type: socialContractProof.fileType,
                    });
                    socialContractProof.id = socialContractProofId;
                }

                var lastContractualAmendmentProof = responseData.lastContractualAmendmentProof;
                const lastContractualAmendmentProofId = lastContractualAmendmentProof?.id;
                if (lastContractualAmendmentProofId) {
                    lastContractualAmendmentProof = new File(["lastContractualAmendmentProof"], lastContractualAmendmentProof.fileOriginalName, {
                        type: lastContractualAmendmentProof.fileType,
                    });
                    lastContractualAmendmentProof.id = lastContractualAmendmentProofId;
                }

                var bylawsProof = responseData.bylawsProof;
                const bylawsProofId = bylawsProof?.id;
                if (bylawsProofId) {
                    bylawsProof = new File(["bylawsProof"], bylawsProof.fileOriginalName, {
                        type: bylawsProof.fileType,
                    });
                    bylawsProof.id = bylawsProofId;
                }

                var lastElectionMinuteProof = responseData.lastElectionMinuteProof;
                const lastElectionMinuteProofId = lastElectionMinuteProof?.id;
                if (lastElectionMinuteProofId) {
                    lastElectionMinuteProof = new File(["lastElectionMinuteProof"], lastElectionMinuteProof.fileOriginalName, {
                        type: lastElectionMinuteProof.fileType,
                    });
                    lastElectionMinuteProof.id = lastElectionMinuteProofId;
                }

                var individualMicroentrepreneurCertificateProof = responseData.individualMicroentrepreneurCertificateProof;
                const individualMicroentrepreneurCertificateProofId = individualMicroentrepreneurCertificateProof?.id;
                if (individualMicroentrepreneurCertificateProofId) {
                    individualMicroentrepreneurCertificateProof = new File(["individualMicroentrepreneurCertificateProof"], individualMicroentrepreneurCertificateProof.fileOriginalName, {
                        type: individualMicroentrepreneurCertificateProof.fileType,
                    });
                    individualMicroentrepreneurCertificateProof.id = individualMicroentrepreneurCertificateProofId;
                }

                var entrepreneurApplicationProof = responseData.entrepreneurApplicationProof;
                const entrepreneurApplicationProofId = entrepreneurApplicationProof?.id;
                if (entrepreneurApplicationProofId) {
                    entrepreneurApplicationProof = new File(["entrepreneurApplicationProof"], entrepreneurApplicationProof.fileOriginalName, {
                        type: entrepreneurApplicationProof.fileType,
                    });
                    entrepreneurApplicationProof.id = entrepreneurApplicationProofId;
                }

                let documents: any[] = [];
                for (let i in responseData.documents) {
                    let document = responseData.documents[i];
                    var _documentProof: any = new File(["documentProof"], document.fileOriginalName, {
                        type: document.fileType,
                    });
                    _documentProof.id = document.id;

                    documents.push({
                        id: document.id,
                        documentTypeId: document.id,
                        documentProof: _documentProof ? [].concat(_documentProof) : "",
                        tipoDocumento: document.tipoDocumento,
                    });
                }

                responseData = {
                    ...responseData,
                    documents: documents
                };

                const values = {
                    ...responseData,
                    socialContractProof: socialContractProof ? [].concat(socialContractProof) : "",
                    lastContractualAmendmentProof: lastContractualAmendmentProof ? [].concat(lastContractualAmendmentProof) : "",
                    bylawsProof: bylawsProof ? [].concat(bylawsProof) : "",
                    lastElectionMinuteProof: lastElectionMinuteProof ? [].concat(lastElectionMinuteProof) : "",
                    individualMicroentrepreneurCertificateProof: individualMicroentrepreneurCertificateProof ? [].concat(individualMicroentrepreneurCertificateProof) : "",
                    entrepreneurApplicationProof: entrepreneurApplicationProof ? [].concat(entrepreneurApplicationProof) : "",
                }

                reset(values);
                setLoading(false);
            } catch (error: any) {
                console.log('error', error);
                const errorCode = error.response?.data?.errorCode;
                if (errorCode === "12000") {
                    navigate('/dashboard');
                }
            }
        }
        fetchOverallData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const ddiPhoneChanged = () => {
        setValue('phoneDdd', '');
        setValue('phone', '');
    }

    const ddiCellPhoneChanged = () => {
        setValue('cellPhoneDdd', '');
        setValue('cellPhone', '');
    }

    const onSubmit = async (data: IOverallData) => {
        setSubmitting(true);

        try {
            let socialContractProof: any = data.socialContractProof![0];
            if (socialContractProof && !socialContractProof.id) {
                const result = await toBase64(socialContractProof);
                socialContractProof = {
                    fileOriginalName: socialContractProof.name,
                    fileInBase64: result,
                    fileSize: socialContractProof.size,
                    fileType: socialContractProof.type,
                }
            }

            let lastContractualAmendmentProof: any = data.lastContractualAmendmentProof![0];
            if (lastContractualAmendmentProof && !lastContractualAmendmentProof.id) {
                const result = await toBase64(lastContractualAmendmentProof);
                lastContractualAmendmentProof = {
                    fileOriginalName: lastContractualAmendmentProof.name,
                    fileInBase64: result,
                    fileSize: lastContractualAmendmentProof.size,
                    fileType: lastContractualAmendmentProof.type,
                }
            }

            let bylawsProof: any = data.bylawsProof![0];
            if (bylawsProof && !bylawsProof.id) {
                const result = await toBase64(bylawsProof);
                bylawsProof = {
                    fileOriginalName: bylawsProof.name,
                    fileInBase64: result,
                    fileSize: bylawsProof.size,
                    fileType: bylawsProof.type,
                }
            }

            let lastElectionMinuteProof: any = data.lastElectionMinuteProof![0];
            if (lastElectionMinuteProof && !lastElectionMinuteProof.id) {
                const result = await toBase64(lastElectionMinuteProof);
                lastElectionMinuteProof = {
                    fileOriginalName: lastElectionMinuteProof.name,
                    fileInBase64: result,
                    fileSize: lastElectionMinuteProof.size,
                    fileType: lastElectionMinuteProof.type,
                }
            }

            let individualMicroentrepreneurCertificateProof: any = data.individualMicroentrepreneurCertificateProof![0];
            if (individualMicroentrepreneurCertificateProof && !individualMicroentrepreneurCertificateProof.id) {
                const result = await toBase64(individualMicroentrepreneurCertificateProof);
                individualMicroentrepreneurCertificateProof = {
                    fileOriginalName: individualMicroentrepreneurCertificateProof.name,
                    fileInBase64: result,
                    fileSize: individualMicroentrepreneurCertificateProof.size,
                    fileType: individualMicroentrepreneurCertificateProof.type,
                }
            }

            let entrepreneurApplicationProof: any = data.entrepreneurApplicationProof![0];
            if (entrepreneurApplicationProof && !entrepreneurApplicationProof.id) {
                const result = await toBase64(entrepreneurApplicationProof);
                entrepreneurApplicationProof = {
                    fileOriginalName: entrepreneurApplicationProof.name,
                    fileInBase64: result,
                    fileSize: entrepreneurApplicationProof.size,
                    fileType: entrepreneurApplicationProof.type,
                }
            }

            let documentProofs: any[] = [];

            const additionalDocuments = data.documents;
            if (additionalDocuments!.length > 0) {
                const { legalNature } = data;
                if (legalNature === "120240003") { // LTDA
                    const constitutionDocument: any = additionalDocuments![0];
                    if (constitutionDocument) {
                        const constitutionDocumentProof = constitutionDocument.documentProof[0];
                        const result = !constitutionDocument.id
                            ? await toBase64(constitutionDocumentProof)
                            : null;
                        documentProofs.push({
                            fileOriginalName: constitutionDocumentProof.name,
                            fileInBase64: result,
                            fileSize: constitutionDocumentProof.size,
                            fileType: constitutionDocumentProof.type,
                            tipoDocumento: constitutionDocument.tipoDocumento,
                        });
                    }
                } else if (legalNature === "120240004") { // SA
                    const constitutionDocument: any = additionalDocuments![0];
                    if (constitutionDocument) {
                        const constitutionDocumentProof: any = constitutionDocument.documentProof[0];
                        const result = !constitutionDocument.id
                            ? await toBase64(constitutionDocumentProof)
                            : null;
                        documentProofs.push({
                            fileOriginalName: constitutionDocumentProof.name,
                            fileInBase64: result,
                            fileSize: constitutionDocumentProof.size,
                            fileType: constitutionDocumentProof.type,
                            tipoDocumento: constitutionDocument.tipoDocumento,
                        });
                    }
                } else if (legalNature === "120240000" || legalNature === "120240001" || legalNature === "120240002") { // MEI, ME ou EPP
                    const constitutionDocument: any = additionalDocuments![0];
                    if (constitutionDocument) {
                        const constitutionDocumentProof = constitutionDocument.documentProof[0];
                        const result = !constitutionDocument.id
                            ? await toBase64(constitutionDocumentProof)
                            : null;
                        documentProofs.push({
                            fileOriginalName: constitutionDocumentProof.name,
                            fileInBase64: result,
                            fileSize: constitutionDocumentProof.size,
                            fileType: constitutionDocumentProof.type,
                            tipoDocumento: constitutionDocument.tipoDocumento,
                        });
                    }

                    const constitutionDocument2: any = additionalDocuments![1];
                    if (constitutionDocument2) {
                        const constitutionDocument2Proof = constitutionDocument2.documentProof[0];
                        const result = !constitutionDocument2.id
                            ? await toBase64(constitutionDocument2Proof)
                            : null;
                        documentProofs.push({
                            fileOriginalName: constitutionDocument2Proof.name,
                            fileInBase64: result,
                            fileSize: constitutionDocument2Proof.size,
                            fileType: constitutionDocument2Proof.type,
                            tipoDocumento: constitutionDocument2.tipoDocumento,
                        });
                    }
                } else if (legalNature === "120240005") { // DEMAIS
                    const constitutionDocument: any = additionalDocuments![0];
                    if (constitutionDocument) {
                        const constitutionDocumentProof = constitutionDocument.documentProof[0];
                        const result = !constitutionDocument.id
                            ? await toBase64(constitutionDocumentProof)
                            : null;
                        documentProofs.push({
                            fileOriginalName: constitutionDocumentProof.name,
                            fileInBase64: result,
                            fileSize: constitutionDocumentProof.size,
                            fileType: constitutionDocumentProof.type,
                            tipoDocumento: constitutionDocument.tipoDocumento,
                        });
                    }
                }
            }

            data = {
                ...data,
                socialContractProof,
                lastContractualAmendmentProof,
                bylawsProof,
                lastElectionMinuteProof,
                individualMicroentrepreneurCertificateProof,
                entrepreneurApplicationProof,
                documents: documentProofs,
            }

            await saveOverallData(data);
            navigate('/onboarding/address-legal-entity-register');
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    const addAdditionalDocument = () => {
        if (uploading) return;
        if (legalNature === "120240003") { // LTDA
            append({
                id: "",
                fileOriginalName: "",
                fileSize: "",
                fileType: "",
                documentProof: "",
                tipoDocumento: "120240032",
            })
        } else if (legalNature === "120240004") { // SA
            append({
                id: "",
                fileOriginalName: "",
                fileSize: "",
                fileType: "",
                documentProof: "",
                tipoDocumento: "120240032",
            })
        } else if (legalNature === "120240000" || legalNature === "120240001" || legalNature === "120240002") { // MEI, ME ou EPP
            if (fields.length === 0) {
                append({
                    id: "",
                    fileOriginalName: "",
                    fileSize: "",
                    fileType: "",
                    documentProof: "",
                    tipoDocumento: "120240032",
                })
            } else {
                append({
                    id: "",
                    fileOriginalName: "",
                    fileSize: "",
                    fileType: "",
                    documentProof: "",
                    tipoDocumento: "120240033",
                })
            }
        } else if (legalNature === "120240005") { // DEMAIS
            append({
                id: "",
                fileOriginalName: "",
                fileSize: "",
                fileType: "",
                documentProof: "",
                tipoDocumento: "120240032",
            })
        }
    }

    const buildAdditionalButton = () => {
        if (legalNature === "") return;

        let isFieldsLessThan2 = fields.length < 1;

        if (legalNature === "120240000" || legalNature === "120240001" || legalNature === "120240002") // MEI, ME ou EPP
            isFieldsLessThan2 = fields.length < 2;

        if (isFieldsLessThan2) {
            return (
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                        textAlign: "center",
                        marginTop: (theme) => theme.spacing(2),
                        marginBottom: (theme) => theme.spacing(2),
                    }}
                >
                    <Chip
                        label={t("text.optional").toUpperCase()}
                        size="small"
                        sx={{ marginRight: (theme) => theme.spacing(1) }}
                    />
                    <LinkMUI
                        component="button"
                        type="button"
                        variant="body2"
                        onClick={addAdditionalDocument}
                    >
                        {t("text.uploadAnAdditionalDocument")}
                    </LinkMUI>
                </Grid>
            );
        }

        return null;
    };

    const getHeaderTitle = (tipoDocumento: string) => {
        if (tipoDocumento === "120240012") return t("text.lastContractualAmendmentMustBeWithin");
        if (tipoDocumento === "120240018") return t("text.lastElectionMinuteProof");
        if (tipoDocumento === "120240034") return t("text.individualMicroentrepreneurCertificate");
        if (tipoDocumento === "120240035") return t("text.entrepreneurApplication");
        return t("text.constitutionDocument");
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={submitting} style={{ border: 0 }}>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '2rem',
                    }}
                >
                    {loading
                        ? <LoadingBox />
                        : <React.Fragment>
                            <LegalEntityStepper
                                activeStep={1}
                                legalNature={legalNature}
                            />
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={10}
                                lg={10}
                                xl={10}
                                sx={{
                                    background: 'var(--white)',
                                    borderRadius: '4px',
                                    padding: '2rem',
                                    // '@media(max-width: 899px)': {
                                    //     padding: '0rem',
                                    // }
                                }}
                            >
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <PageName
                                            title={t("text.overallData")}
                                            info={t("text.toProceedWithTheRegistrationPleaseFillInTheInformationBelow")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        {loading
                                            ?
                                            (<Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    <CircularProgress />
                                                </Grid>
                                            </Grid>)
                                            :
                                            (<Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                    lg={3}
                                                    xl={3}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="cnpj"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={formatCNPJ(field.value)}
                                                                onChange={(e) => {
                                                                    e.persist();
                                                                    masks.cnpjMask.onChange(e);
                                                                    field.onChange(e);
                                                                }}
                                                                inputRef={field.ref}
                                                                label={t("text.cnpj")}
                                                                placeholder={t("text.cnpj") as string}
                                                                fullWidth
                                                                error={!!errors.cnpj}
                                                                helperText={displayErrorMessage(errors.cnpj?.message) as string}
                                                                disabled
                                                                variant='outlined'
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="corporateName"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                                inputRef={field.ref}
                                                                label={t("text.corporateName")}
                                                                placeholder={t("text.corporateName") as string}
                                                                inputProps={{ maxLength: 100 }}
                                                                fullWidth
                                                                error={!!errors.corporateName}
                                                                helperText={displayErrorMessage(errors.corporateName?.message) as string}
                                                                variant='outlined'
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                    lg={3}
                                                    xl={3}
                                                >
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                        adapterLocale={
                                                            languages[i18n.language as keyof typeof languages]
                                                        }
                                                    >
                                                        <Controller
                                                            control={control}
                                                            name="constitutionDate"
                                                            render={({ field }) => (
                                                                <DatePicker
                                                                    value={field.value}
                                                                    onChange={field.onChange}
                                                                    inputRef={field.ref}
                                                                    inputFormat="dd/MM/yyyy"
                                                                    renderInput={(params) =>
                                                                        <TextField
                                                                            {...params}
                                                                            inputProps={{
                                                                                ...params.inputProps,
                                                                                placeholder: "Ex: 01/01/1990"
                                                                            }}
                                                                            label={t("text.constituitionDate")}
                                                                            error={!!errors.constitutionDate}
                                                                            helperText={displayErrorMessage(errors.constitutionDate?.message) as string}
                                                                            fullWidth
                                                                            variant="outlined"
                                                                        />
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="fantasyName"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                                inputRef={field.ref}
                                                                label={t("text.fantasyName")}
                                                                placeholder={t("text.fantasyName") as string}
                                                                inputProps={{ maxLength: 100 }}
                                                                fullWidth
                                                                error={!!errors.fantasyName}
                                                                helperText={displayErrorMessage(errors.fantasyName?.message) as string}
                                                                variant='outlined'
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="fieldOfActivity"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                                inputRef={field.ref}
                                                                label={t("text.fieldOfActivity")}
                                                                hint={t("text.fieldOfActivityHint") as string}
                                                                placeholder={t("text.fieldOfActivity") as string}
                                                                inputProps={{ maxLength: 1000 }}
                                                                fullWidth
                                                                error={!!errors.fieldOfActivity}
                                                                helperText={displayErrorMessage(errors.fieldOfActivity?.message) as string}
                                                                variant='outlined'
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={2}
                                                    lg={2}
                                                    xl={2}
                                                >
                                                    <CRMDdiOptions
                                                        control={control}
                                                        label={t("text.landlineDDI")}
                                                        name="phoneDdi"
                                                        ddis={ddis}
                                                        error={!!errors.phoneDdi}
                                                        ddiChanged={ddiPhoneChanged}
                                                    />
                                                    <FormHelperTextStyled>
                                                        {displayErrorMessage(errors.phoneDdi?.message) as string}
                                                    </FormHelperTextStyled>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={2}
                                                    lg={2}
                                                    xl={2}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="phoneDdd"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                                inputRef={field.ref}
                                                                label={t("text.landlineDDD")}
                                                                inputProps={{ maxLength: 3 }}
                                                                fullWidth
                                                                error={!!errors.phoneDdd}
                                                                helperText={displayErrorMessage(errors.phoneDdd?.message) as string}
                                                                variant='outlined'
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={2}
                                                    lg={2}
                                                    xl={2}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="phone"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                                inputRef={field.ref}
                                                                label={t("text.landline")}
                                                                inputProps={{ maxLength: 20 }}
                                                                fullWidth
                                                                error={!!errors.phone}
                                                                helperText={displayErrorMessage(errors.phone?.message) as string}
                                                                variant='outlined'
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={2}
                                                    lg={2}
                                                    xl={2}
                                                >
                                                    <CRMDdiOptions
                                                        control={control}
                                                        label={t("text.ddi")}
                                                        name="cellPhoneDdi"
                                                        ddis={ddis}
                                                        error={!!errors.cellPhoneDdi}
                                                        ddiChanged={ddiCellPhoneChanged}
                                                    />
                                                    <FormHelperTextStyled>
                                                        {displayErrorMessage(errors.cellPhoneDdi?.message) as string}
                                                    </FormHelperTextStyled>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={2}
                                                    lg={2}
                                                    xl={2}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="cellPhoneDdd"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                                inputRef={field.ref}
                                                                label={t("text.ddd")}
                                                                inputProps={{ maxLength: 3 }}
                                                                fullWidth
                                                                error={!!errors.cellPhoneDdd}
                                                                helperText={displayErrorMessage(errors.cellPhoneDdd?.message) as string}
                                                                variant='outlined'
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={2}
                                                    lg={2}
                                                    xl={2}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="cellPhone"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                                inputRef={field.ref}
                                                                label={t("text.cellphone")}
                                                                inputProps={{ maxLength: 20 }}
                                                                fullWidth
                                                                error={!!errors.cellPhone}
                                                                helperText={displayErrorMessage(errors.cellPhone?.message) as string}
                                                                variant='outlined'
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="email"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                                inputRef={field.ref}
                                                                type="email"
                                                                label={t("text.email")}
                                                                placeholder={t("text.email") as string}
                                                                inputProps={{ maxLength: 100 }}
                                                                fullWidth
                                                                error={!!errors.email}
                                                                helperText={displayErrorMessage(errors.email?.message) as string}
                                                                variant='outlined'
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={hasSite === "2" ? 6 : 2}
                                                    lg={hasSite === "2" ? 6 : 2}
                                                    xl={hasSite === "2" ? 6 : 2}
                                                >
                                                    <FormControl>
                                                        <FormLabelStyled>
                                                            {t("text.hasSite")}
                                                        </FormLabelStyled>
                                                        <Controller
                                                            control={control}
                                                            name="hasSite"
                                                            render={({ field }) => {
                                                                return (
                                                                    <RadioGroup row {...field}>
                                                                        <FormControlLabelStyled
                                                                            value="1"
                                                                            control={<Radio />}
                                                                            label={t("text.yes")}
                                                                        />
                                                                        <FormControlLabelStyled
                                                                            value="2"
                                                                            control={<Radio />}
                                                                            label={t("text.no")}
                                                                        />
                                                                    </RadioGroup>
                                                                );
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {errors.hasSite
                                                        ? <FormHelperTextStyled>{displayErrorMessage(errors.hasSite?.message) as string}</FormHelperTextStyled>
                                                        : null
                                                    }
                                                </Grid>
                                                {hasSite === "1" ?
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                    >
                                                        <Controller
                                                            control={control}
                                                            name="site"
                                                            render={({ field }) => (
                                                                <TextField
                                                                    value={hasSite === "1" ? field.value : ""}
                                                                    onChange={field.onChange}
                                                                    inputRef={field.ref}
                                                                    label={t("text.site")}
                                                                    placeholder="www.bancomoneycorp.com.br"
                                                                    inputProps={{ maxLength: 200 }}
                                                                    fullWidth
                                                                    error={!!errors.site}
                                                                    helperText={displayErrorMessage(errors.site?.message) as string}
                                                                    disabled={hasSite !== "1"}
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                                                                    }}
                                                                    variant='outlined'
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    : null}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        sx={{
                                                            display: 'flex'
                                                        }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <CRMLegalNatureOptions
                                                                name="legalNature"
                                                                label={t("text.legalNature")}
                                                                control={control}
                                                                legalNatures={legalNaturesOptions}
                                                                error={!!errors.legalNature}
                                                                resetField={resetField}
                                                                disabled={uploading}
                                                            />
                                                            <FormHelperTextStyled>
                                                                {displayErrorMessage(errors.legalNature?.message) as string}
                                                            </FormHelperTextStyled>
                                                        </Grid>

                                                        {legalNature === "120240005" // DEMAIS
                                                            ? <React.Fragment>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={12}
                                                                    lg={12}
                                                                    xl={12}
                                                                >
                                                                    <DocumentTypeUpload
                                                                        name="documentTypeUpload"
                                                                        label={t("text.documentType")}
                                                                        control={control}
                                                                        documentTypeUploads={documentTypeUploadOptions}
                                                                        error={!!errors.documentTypeUpload}
                                                                        resetField={resetField}
                                                                        disabled={uploading}
                                                                    />
                                                                    <FormHelperTextStyled>
                                                                        {displayErrorMessage(errors.documentTypeUpload?.message) as string}
                                                                    </FormHelperTextStyled>
                                                                </Grid>
                                                            </React.Fragment>
                                                            : null
                                                        }

                                                        {legalNature === "120240003" || (legalNature === "120240005" && documentTypeUpload === "1")
                                                            ? <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                            >
                                                                <FormControl>
                                                                    <FormLabelStyled>
                                                                        {t("text.hasRecentContractualAmendment")}
                                                                    </FormLabelStyled>

                                                                    <Controller
                                                                        control={control}
                                                                        name="hasContractualAlteration"
                                                                        render={({ field }) => {
                                                                            return (
                                                                                <RadioGroup row {...field}
                                                                                    onChange={(e) => {
                                                                                        field.onChange(e);
                                                                                        setValue('lastContractualAmendmentProof', '');
                                                                                    }}>
                                                                                    <FormControlLabelStyled
                                                                                        value="1"
                                                                                        control={<Radio />}
                                                                                        label={t("text.yes")}
                                                                                        disabled={uploading}
                                                                                    />
                                                                                    <FormControlLabelStyled
                                                                                        value="2"
                                                                                        control={<Radio />}
                                                                                        label={t("text.no")}
                                                                                        disabled={uploading}
                                                                                    />
                                                                                </RadioGroup>
                                                                            );
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                                {errors.hasContractualAlteration
                                                                    ? <FormHelperTextStyled>{displayErrorMessage(errors.hasContractualAlteration?.message) as string}</FormHelperTextStyled>
                                                                    : null
                                                                }
                                                            </Grid>
                                                            : null
                                                        }
                                                        {legalNature === "120240004" // SA
                                                            ? <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                            >
                                                                <FormControl>
                                                                    <FormLabelStyled>
                                                                        {t("text.isAnOpenedCapitalCompany")}
                                                                    </FormLabelStyled>
                                                                    <Controller
                                                                        control={control}
                                                                        name="openedCapital"
                                                                        render={({ field }) => {
                                                                            return (
                                                                                <RadioGroup row {...field}>
                                                                                    <FormControlLabelStyled
                                                                                        value="1"
                                                                                        control={<Radio />}
                                                                                        label={t("text.yes")}
                                                                                    />
                                                                                    <FormControlLabelStyled
                                                                                        value="2"
                                                                                        control={<Radio />}
                                                                                        label={t("text.no")}
                                                                                    />
                                                                                </RadioGroup>
                                                                            );
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                                {errors.openedCapital
                                                                    ? <FormHelperTextStyled>{displayErrorMessage(errors.openedCapital?.message) as string}</FormHelperTextStyled>
                                                                    : null
                                                                }
                                                            </Grid>
                                                            : null
                                                        }
                                                    </Grid>
                                                </Grid>
                                                {legalNature === "120240003" || (legalNature === "120240005" && documentTypeUpload === "1") // LTDA
                                                    ? <React.Fragment>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <Card>
                                                                <CardHeaderTitleStyled
                                                                    title={t("text.socialContract")}
                                                                    subheader={t("text.attachDocument")}
                                                                />
                                                                <Document
                                                                    title={t("text.add")}
                                                                    register={register}
                                                                    getValues={getValues}
                                                                    name="socialContractProof"
                                                                    uploading={uploading}
                                                                    setUploading={setUploading}
                                                                />
                                                                <CardActions>
                                                                    <Typography
                                                                        variant="caption"
                                                                        display="block"
                                                                    >
                                                                        {t("text.allowedFileTypesAndSize")}
                                                                    </Typography>
                                                                </CardActions>
                                                            </Card>
                                                            {errors.socialContractProof && <FormHelperTextStyled>{displayErrorMessage(errors.socialContractProof?.message) as string}</FormHelperTextStyled>}
                                                        </Grid>
                                                    </React.Fragment>
                                                    : null
                                                }

                                                {(legalNature === "120240003" || ((legalNature === "120240005" && documentTypeUpload === "1"))) && hasContractualAlteration === "1"// LTDA
                                                    ? <React.Fragment>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <Card>
                                                                <CardHeaderTitleStyled
                                                                    title={t("text.lastContractualAmendmentMustBeWithin")}
                                                                    subheader={t("text.attachDocument")}
                                                                />
                                                                <Document
                                                                    title={t("text.add")}
                                                                    register={register}
                                                                    getValues={getValues}
                                                                    name="lastContractualAmendmentProof"
                                                                    uploading={uploading}
                                                                    setUploading={setUploading}
                                                                />
                                                                <CardActions>
                                                                    <Typography
                                                                        variant="caption"
                                                                        display="block"
                                                                    >
                                                                        {t("text.allowedFileTypesAndSize")}
                                                                    </Typography>
                                                                </CardActions>
                                                            </Card>
                                                            {errors.lastContractualAmendmentProof && <FormHelperTextStyled>{displayErrorMessage(errors.lastContractualAmendmentProof?.message) as string}</FormHelperTextStyled>}
                                                        </Grid>
                                                    </React.Fragment>
                                                    : null
                                                }
                                                {legalNature === "120240004" || ((legalNature === "120240005" && documentTypeUpload === "2"))// SA
                                                    ? <React.Fragment>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <Card>
                                                                <CardHeaderTitleStyled
                                                                    title={t("text.bylaws")}
                                                                    subheader={t("text.attachDocument")}
                                                                />
                                                                <Document
                                                                    title={t("text.add")}
                                                                    register={register}
                                                                    getValues={getValues}
                                                                    name="bylawsProof"
                                                                    uploading={uploading}
                                                                    setUploading={setUploading}
                                                                />
                                                                <CardActions>
                                                                    <Typography
                                                                        variant="caption"
                                                                        display="block"
                                                                    >
                                                                        {t("text.allowedFileTypesAndSize")}
                                                                    </Typography>
                                                                </CardActions>
                                                            </Card>
                                                            {errors.bylawsProof && <FormHelperTextStyled>{displayErrorMessage(errors.bylawsProof?.message) as string}</FormHelperTextStyled>}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <Card>
                                                                <CardHeaderTitleStyled
                                                                    title={t("text.lastElectionMinuteProof")}
                                                                    subheader={t("text.attachDocument")}
                                                                />
                                                                <Document
                                                                    title={t("text.add")}
                                                                    register={register}
                                                                    getValues={getValues}
                                                                    name="lastElectionMinuteProof"
                                                                    uploading={uploading}
                                                                    setUploading={setUploading}
                                                                />
                                                                <CardActions>
                                                                    <Typography
                                                                        variant="caption"
                                                                        display="block"
                                                                    >
                                                                        {t("text.allowedFileTypesAndSize")}
                                                                    </Typography>
                                                                </CardActions>
                                                            </Card>
                                                            {errors.lastElectionMinuteProof && <FormHelperTextStyled>{displayErrorMessage(errors.lastElectionMinuteProof?.message) as string}</FormHelperTextStyled>}
                                                        </Grid>
                                                    </React.Fragment>
                                                    : null
                                                }
                                                {legalNature === "120240000" // MEI
                                                    ? <React.Fragment>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <Card>
                                                                <CardHeaderTitleStyled
                                                                    title={t("text.individualMicroentrepreneurCertificate")}
                                                                    subheader={t("text.attachDocument")}
                                                                />
                                                                <Document
                                                                    title={t("text.add")}
                                                                    register={register}
                                                                    getValues={getValues}
                                                                    name="individualMicroentrepreneurCertificateProof"
                                                                    uploading={uploading}
                                                                    setUploading={setUploading}
                                                                />
                                                                <CardActions>
                                                                    <Typography
                                                                        variant="caption"
                                                                        display="block"
                                                                    >
                                                                        {t("text.allowedFileTypesAndSize")}
                                                                    </Typography>
                                                                </CardActions>
                                                            </Card>
                                                            {errors.individualMicroentrepreneurCertificateProof && <FormHelperTextStyled>{displayErrorMessage(errors.individualMicroentrepreneurCertificateProof?.message) as string}</FormHelperTextStyled>}
                                                        </Grid>
                                                    </React.Fragment>
                                                    : null
                                                }
                                                {legalNature === "120240001" || legalNature === "120240002" // ME ou EPP
                                                    ? <React.Fragment>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <Card>
                                                                <CardHeaderTitleStyled
                                                                    title={t("text.entrepreneurApplication")}
                                                                    subheader={t("text.attachDocument")}
                                                                />
                                                                <Document
                                                                    title={t("text.add")}
                                                                    register={register}
                                                                    getValues={getValues}
                                                                    name="entrepreneurApplicationProof"
                                                                    uploading={uploading}
                                                                    setUploading={setUploading}
                                                                />
                                                                <CardActions>
                                                                    <Typography
                                                                        variant="caption"
                                                                        display="block"
                                                                    >
                                                                        {t("text.allowedFileTypesAndSize")}
                                                                    </Typography>
                                                                </CardActions>
                                                            </Card>
                                                            {errors.entrepreneurApplicationProof && <FormHelperTextStyled>{displayErrorMessage(errors.entrepreneurApplicationProof?.message) as string}</FormHelperTextStyled>}
                                                        </Grid>
                                                    </React.Fragment>
                                                    : null
                                                }
                                                {fields.map((item, index) => {
                                                    return (
                                                        <DocumentAdditional
                                                            key={index}
                                                            index={index}
                                                            title={t("text.add")}
                                                            name={`documents.${index}.documentProof`}
                                                            register={register}
                                                            errors={errors}
                                                            remove={remove}
                                                            getValues={getValues}
                                                            headertitle={getHeaderTitle(item.tipoDocumento)}
                                                            subheader={t("text.attachDocument") as string}
                                                            canRemove={fields.length === (index + 1)}
                                                            uploading={uploading}
                                                            setUploading={setUploading}
                                                        />
                                                    );
                                                })}
                                                {buildAdditionalButton()}
                                            </Grid>
                                            )}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    label={t("text.previousStep")}
                                                    component={Link}
                                                    to="/onboarding/contact-reason-legal-entity-register"
                                                    fullWidth
                                                    disabled={submitting || uploading}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                            >
                                                <LoadingButton
                                                    type="submit"
                                                    variant="contained"
                                                    label={t("text.nextStep")}
                                                    loading={submitting || uploading}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <Button
                                                    variant="text"
                                                    label={t("text.continueLater")}
                                                    onClick={handleSignOut}
                                                    disabled={submitting || uploading}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </fieldset>
        </form >
    );
}
