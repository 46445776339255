import React, {  useEffect, useState } from "react";
import {  SubmitHandler, useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { InstanceProps, create } from 'react-modal-promise';
import {
    Dialog, DialogContent, DialogActions, Grid, Card, CardActions, Typography
} from '@mui/material';

import { DialogTitleStyled } from '../DeleteModal/styles';
import { LoadingBox } from '../LoadingBox';
import { LoadingButton } from '../LoadingButton';
import {
    CardHeaderTitleStyled, FormHelperTextStyled, SubSubtitlePageForm
} from '../../styles/styles';
import Document from "../Document";
import { displayErrorMessage } from "../../utils/errorMessage";
import { toast } from 'react-toastify';
import { toBase64 } from "../../utils/fileUtils";
import { getDocumentByProspectAndType, saveDocument } from "../../services/documentService";
import {IMessage} from "../../interfaces/message"
import { IDocument } from "../../interfaces/document";

const validationSchema = yup.object({
    proof: yup.mixed()
        .test("required", "validations.fileRequired", (value) => value.length > 0)
        .test("fileSize", "validations.fileSize", (value) => {
            return value.length && value[0].size <= 20971520;
        })
        .test("fileType", "validations.fileType", (value) => {
            return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
        }),
});

interface AddDocumentModalProps extends InstanceProps<any, any>  {
    open: boolean ,
    onResolve: ()=> void ,
    onReject: ()=> void ,
    code?:string
}
const AddDocumentModal: React.FC<AddDocumentModalProps> = ({ open, onResolve, onReject, code }) => {
    const { t } = useTranslation();
    //const { user } = useContext(AuthContext);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [message, setMessage] = useState<IMessage>();
    const [uploading, setUploading] = useState<boolean>(false);

    const { handleSubmit, formState: { errors }, register, reset, getValues } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            proof: "",
        }
    });

    useEffect(() => {
        async function fetchDocument() {
            try {
                const response = await getDocumentByProspectAndType(code!);
                const data = response.data;

                const message = {
                    messageHeaderTitle: response.data.messageHeaderTitle,
                    messageHeaderSubheader: response.data.messageHeaderSubheader,
                    messageSubSubtitlePage: response.data.messageSubSubtitlePage
                }

                setMessage(message);


                var _proof : any;
                if (data.id) {
                    _proof = new File(["proof"], data.fileOriginalName, {
                        type: data.fileType,
                    });
                    _proof.id = data.id;
                }

                const responseData:any = {
                    proof: _proof ? [].concat(_proof) : ""
                };

                reset(responseData);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }

        fetchDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit: SubmitHandler<{ proof: any }> = async (data) => {
        try {
            setSubmitting(true);
            const proof = data.proof[0];
            if (proof && !proof.id) {
                const result = await toBase64(proof);
                const document : IDocument = {
                    code: code,
                    fileOriginalName: proof.name,
                    fileInBase64: result,
                    fileSize: proof.size,
                    fileType: proof.type,
                }

                await saveDocument(document);
                toast.success(t("text.toast.create"));
            }

            onResolve();

        } catch (error:any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }

    }

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            scroll="paper"
            fullWidth
        >
            {loading
                ? <LoadingBox />
                : <React.Fragment>
                    <DialogTitleStyled>
                        {t("text.addDocument")}
                    </DialogTitleStyled>
                    <DialogContent dividers>
                        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
                            <fieldset disabled={submitting} style={{ border: 0 }}>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <SubSubtitlePageForm>
                                                {t(`${message!.messageSubSubtitlePage}`) as string}
                                            </SubSubtitlePageForm>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Card>
                                                <CardHeaderTitleStyled
                                                    title={t(`${message!.messageHeaderTitle}`) as string}
                                                    subheader={t(`${message!.messageHeaderSubheader}`) as string}
                                                />
                                                <Document
                                                    title={t("text.add")}
                                                    register={register}
                                                    getValues={getValues}
                                                    name="proof"
                                                    uploading={uploading}
                                                    setUploading={setUploading}
                                                />
                                                <CardActions>
                                                    <Typography variant="caption" display="block">
                                                        {t("text.allowedFileTypesAndSize")}
                                                    </Typography>
                                                </CardActions>
                                            </Card>
                                            {errors.proof && <FormHelperTextStyled>{displayErrorMessage(errors.proof?.message) as string}</FormHelperTextStyled>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            disabled={submitting || uploading}
                            onClick={onReject}
                            label={t("text.cancel")}
                        />
                        <LoadingButton
                            type="submit"
                            label={t("text.add")}
                            form="submit-form"
                            loading={submitting || uploading}
                        />
                    </DialogActions>

                </React.Fragment>
            }
        </Dialog>
    )
}

export const addDocumentoModal = create(AddDocumentModal);