import CurrencyTextField from "@mgustavo/material-ui-currency-textfield";

import styled from "styled-components";

export const Label = styled.label`
    font-weight: 700;
    font-size: .75rem;
    color: var(--gray-600);
    /* margin-bottom: .25rem; */
    display: flex;
    align-items: flex-end;
    gap: .5rem;
`;

export const CurrencyTextFieldStyled = styled(CurrencyTextField)`
    && {
        margin-top: .25rem;
        margin-bottom: 0!important;
        
        .MuiOutlinedInput-root {
            background-color: var(--white);
            height: 2.75rem;
        }

        .MuiFormHelperText-root {
            margin-left: 0;
        }
    }
`; 