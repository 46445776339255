import {
    Autocomplete,
} from '@mui/material';
import {  Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { TextField } from "../TextField";
import React from 'react';

type Bank = {
    id:string ,
    name: string
}
interface CRMBankOptionsProps {
    control : any; 
    name: string ;
    banks: Array<Bank> ;
    error: any ;
}

export function CRMBankOptions({ control, name, banks, error }:CRMBankOptionsProps) {
    const { t } = useTranslation();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => {
                const { value, onChange } = field;
                return (
                    <Autocomplete
                        value={
                            value
                                ? banks.find((option: { id: string | number; }) => {
                                    return value === option.id;
                                }) ?? null
                                : null
                        }
                        onChange={(event, newValue) => {
                            onChange(newValue ? newValue.id : null);
                        }}
                        getOptionLabel={(bank) => bank.name}
                        options={banks}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputRef={field.ref}
                                error={error}
                                label={t("text.bankName")}
                                variant="outlined"
                            />
                        )}
                    />
                )
            }}
        />
    );
}