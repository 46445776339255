import {
    FormControl,
    Radio,
    RadioGroup
} from '@mui/material';
import {  Controller  } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { FormControlLabelStyled, FormLabelStyled } from '../../styles/styles';
import React from 'react';

type Product = {
    id:string  ;
    name:string ;
}

interface CRMProductSelectProps {
    control: any ;
    name: string;
    products: Array<Product>
}

export function CRMProductSelect({ control, name, products }: CRMProductSelectProps) {
    const { t } = useTranslation();
    return (
        <FormControl>
            <FormLabelStyled>
                {t("text.whichSoluctionYouAreInterestedIn")}
            </FormLabelStyled>

            <Controller
                control={control}
                name={name}
                render={({ field }) => (
                    <RadioGroup {...field}>
                        {products.map((product,index:number) => (
                            <FormControlLabelStyled
                                key={index}
                                value={product.id}
                                control={<Radio />}
                                label={t(product.name)}
                            />
                        ))}
                    </RadioGroup>
                )}
            />
        </FormControl>
    );
}