export interface IUpdateDocument {
    id: string;
    documentTypeDescription: string;
    fileOriginalName: string;
    fileInBase64: string;
    fileSize: string;
    fileType: string;
    
}

export const defaultValues: IUpdateDocument = {
    id: '',
    documentTypeDescription: '',
    fileOriginalName: '',
    fileInBase64: '',
    fileSize: '',
    fileType: '',
};