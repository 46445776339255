import { yupResolver } from '@hookform/resolvers/yup';
import {
    Card,
    CardActions,
    FormControl,
    Grid,
    Radio,
    RadioGroup,
    Typography
} from '@mui/material';
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { Button } from '../../../components/Button';
import { CurrencyTextField } from '../../../components/CurrencyTextField';
import { LoadingBox } from '../../../components/LoadingBox';
import { LoadingButton } from '../../../components/LoadingButton';
import { PageName } from '../../../components/PageName';
import { AuthContext } from '../../../contexts/auth';
import { getFinancialData, saveFinancialData } from '../../../services/onboardingService';
import {
    CardHeaderTitleStyled,
    FormControlLabelStyled,
    FormHelperTextStyled,
    FormLabelStyled,
    SubSubtitlePageForm,
} from '../../../styles/styles';
import { displayErrorMessage } from "../../../utils/errorMessage";
import Document from '../../../components/Document';
import { toBase64 } from "../../../utils/fileUtils";
import { LegalEntityStepper } from '../../../components/LegalEntityStepper';

const validationSchema = yup.object({
    partnerTypeId: yup.string(),
    foreignExchangeTransaction: yup.string(),
    shareCapital: yup.string()
        .test("valid", (value) => {
            const shareCapital = parseFloat(value!);
            return shareCapital > 0;
        })
        .required(),
    netWorth: yup.string()
        .test("valid", (value) => {
            const netWorth = parseFloat(value!);
            return netWorth > 0;
        })
        .required(),
    billingAnnual: yup.string()
        .test("valid", (value) => {
            const billingAnnual = parseFloat(value!);
            return billingAnnual > 0;
        })
        .required(),
    businessInfo: yup.string().required(),
    patrimonyProof: yup.mixed()
        .when(["businessInfo", "partnerTypeId", "foreignExchangeTransaction"], {
            is: (businessInfo:string, partnerTypeId:string, foreignExchangeTransaction:string) => businessInfo === "2" && (partnerTypeId === "1" || foreignExchangeTransaction === "1"),
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                })
        }),
    dreProof: yup.mixed()
        .when(["businessInfo", "partnerTypeId", "foreignExchangeTransaction"], {
            is: (businessInfo:string, partnerTypeId:string, foreignExchangeTransaction:string) => businessInfo === "2" && (partnerTypeId === "1" || foreignExchangeTransaction === "1"),
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                })
        }),
    spedProof: yup.mixed()
        .test("fileSize", "validations.fileSize", (value) => {
            if (value === "" || value.length === 0) {
                return true;
            }
            return value.length && value[0].size <= 20971520;
        })
        .test("fileType", "validations.fileType", (value) => {
            if (value === "" || value.length === 0) {
                return true;
            }

            return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
        }),
    receiptProof: yup.mixed()
        .when(["businessInfo", "partnerTypeId", "foreignExchangeTransaction"], {
            is: (businessInfo:string, partnerTypeId:string, foreignExchangeTransaction:string) => businessInfo === "1" && (partnerTypeId === "1" || foreignExchangeTransaction === "1"),
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                }),
        }),
    defisProof: yup.mixed()
        .when(["businessInfo", "partnerTypeId", "foreignExchangeTransaction"], {
            is: (businessInfo:string, partnerTypeId:string, foreignExchangeTransaction:string) => businessInfo === "1" && (partnerTypeId === "1" || foreignExchangeTransaction === "1"),
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                }),
        }),
    billingProof: yup.mixed()
        .when(["businessInfo", "partnerTypeId", "foreignExchangeTransaction"], {
            is: (businessInfo:string, partnerTypeId:string, foreignExchangeTransaction:string) => businessInfo === "1" && (partnerTypeId === "1" || foreignExchangeTransaction === "1"),
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                }),
            otherwise: yup.mixed()
                .test("fileSize", "validations.fileSize", (value) => {
                    if (value === "" || value.length === 0) {
                        return true;
                    }
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    if (value === "" || value.length === 0) {
                        return true;
                    }

                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                }),
        }),
    legalNature: yup.string(),
});

export function FinancialDataRegister() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { handleSignOut } = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);

    const { control, handleSubmit, formState: { errors }, reset, register, getValues } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            partnerTypeId: "",
            foreignExchangeTransaction: "",
            shareCapital: "",
            netWorth: "",
            billingAnnual: "",
            businessInfo: "",
            patrimonyProof: "",
            dreProof: "",
            spedProof: "",
            defisProof: "",
            receiptProof: "",
            billingProof: "",
            legalNature: "",
        }
    });

    const businessInfo = useWatch({
        control,
        name: "businessInfo",
    });

    const partnerTypeId = useWatch({
        control,
        name: "partnerTypeId",
    });

    const foreignExchangeTransaction = useWatch({
        control,
        name: "foreignExchangeTransaction",
    });

    const legalNature = useWatch({
        control,
        name: "legalNature",
    });

    useEffect(() => {
        const fetchFinancialData = async () => {
            try {
                const response = await getFinancialData();
                const responseData = response.data;

                var patrimonyProof = responseData.patrimonyProof;
                const patrimonyProofId = patrimonyProof?.id;
                if (patrimonyProofId) {
                    patrimonyProof = new File(["patrimonyProof"], patrimonyProof.fileOriginalName, {
                        type: patrimonyProof.fileType,
                    });
                    patrimonyProof.id = patrimonyProofId;
                }

                var dreProof = responseData.dreProof;
                const dreProofId = dreProof?.id;
                if (dreProofId) {
                    dreProof = new File(["dreProof"], dreProof.fileOriginalName, {
                        type: dreProof.fileType,
                    });
                    dreProof.id = dreProofId;
                }

                var spedProof = responseData.spedProof;
                const spedProofId = spedProof?.id;
                if (spedProofId) {
                    spedProof = new File(["spedProof"], spedProof.fileOriginalName, {
                        type: spedProof.fileType,
                    });
                    spedProof.id = spedProofId;
                }

                var defisProof = responseData.defisProof;
                const defisProofId = defisProof?.id;
                if (defisProofId) {
                    defisProof = new File(["defisProof"], defisProof.fileOriginalName, {
                        type: defisProof.fileType,
                    });
                    defisProof.id = defisProofId;
                }

                var receiptProof = responseData.receiptProof;
                const receiptProofId = receiptProof?.id;
                if (receiptProofId) {
                    receiptProof = new File(["receiptProof"], receiptProof.fileOriginalName, {
                        type: receiptProof.fileType,
                    });
                    receiptProof.id = receiptProofId;
                }

                var billingProof = responseData.billingProof;
                const billingProofId = billingProof?.id;
                if (billingProofId) {
                    billingProof = new File(["billingProof"], billingProof.fileOriginalName, {
                        type: billingProof.fileType,
                    });
                    billingProof.id = billingProofId;
                }

                const values = {
                    ...responseData,
                    patrimonyProof: patrimonyProof ? [].concat(patrimonyProof) : "",
                    defisProof: defisProof ? [].concat(defisProof) : "",
                    receiptProof: receiptProof ? [].concat(receiptProof) : "",
                    dreProof: dreProof ? [].concat(dreProof) : "",
                    spedProof: spedProof ? [].concat(spedProof) : "",
                    billingProof: billingProof ? [].concat(billingProof) : "",
                }

                reset(values);
                setLoading(false);
            } catch (error:any) {
                const errorCode = error.response?.data?.errorCode;
                if (errorCode === "12000") {
                    navigate('/dashboard');
                }
            }
        }
        fetchFinancialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = async (data:any) => {
        setSubmitting(true);

        try {

            let patrimonyProof = data.patrimonyProof[0];
            if (patrimonyProof && !patrimonyProof.id) {
                const result = await toBase64(patrimonyProof);
                patrimonyProof = {
                    fileOriginalName: patrimonyProof.name,
                    fileInBase64: result,
                    fileSize: patrimonyProof.size,
                    fileType: patrimonyProof.type,
                }
            }

            let dreProof = data.dreProof[0];
            if (dreProof && !dreProof.id) {
                const result = await toBase64(dreProof);
                dreProof = {
                    fileOriginalName: dreProof.name,
                    fileInBase64: result,
                    fileSize: dreProof.size,
                    fileType: dreProof.type,
                }
            }

            let spedProof = data.spedProof[0];
            if (spedProof && !spedProof.id) {
                const result = await toBase64(spedProof);
                spedProof = {
                    fileOriginalName: spedProof.name,
                    fileInBase64: result,
                    fileSize: spedProof.size,
                    fileType: spedProof.type,
                }
            }

            let receiptProof = data.receiptProof[0];
            if (receiptProof && !receiptProof.id) {
                const result = await toBase64(receiptProof);
                receiptProof = {
                    fileOriginalName: receiptProof.name,
                    fileInBase64: result,
                    fileSize: receiptProof.size,
                    fileType: receiptProof.type,
                }
            }

            let defisProof = data.defisProof[0];
            if (defisProof && !defisProof.id) {
                const result = await toBase64(defisProof);
                defisProof = {
                    fileOriginalName: defisProof.name,
                    fileInBase64: result,
                    fileSize: defisProof.size,
                    fileType: defisProof.type,
                }
            }

            let billingProof = data.billingProof[0];
            if (billingProof && !billingProof.id) {
                const result = await toBase64(billingProof);
                billingProof = {
                    fileOriginalName: billingProof.name,
                    fileInBase64: result,
                    fileSize: billingProof.size,
                    fileType: billingProof.type,
                }
            }

            data = {
                ...data,
                patrimonyProof: patrimonyProof,
                dreProof: dreProof,
                spedProof: spedProof,
                receiptProof: receiptProof,
                defisProof: defisProof,
                billingProof: billingProof,
            }

            await saveFinancialData(data);
            navigate('/onboarding/resume-legal-entity-register');
        } catch (error:any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={submitting} style={{ border: 0 }}>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '2rem',
                    }}
                >
                    {loading
                        ? <LoadingBox />
                        : <React.Fragment>
                            <LegalEntityStepper
                                activeStep={legalNature === "120240004" ? 5 : 6}
                                legalNature={legalNature}
                            />
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={10}
                                lg={10}
                                xl={10}
                                sx={{
                                    background: 'var(--white)',
                                    borderRadius: '4px',
                                    padding: '2rem',
                                    // '@media(max-width: 899px)': {
                                    //     padding: '0rem',
                                    // }
                                }}
                            >
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <PageName
                                            title={t("text.currentFinancialSituation")}
                                            info={t("text.toProceedWithTheRegistrationPleaseFillInTheInformationBelow")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={4}
                                                lg={4}
                                                xl={4}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="shareCapital"
                                                    render={({ field }) => (
                                                        <CurrencyTextField
                                                            label={t("text.shareCapital")}
                                                            currencySymbol="R$"
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            variant="outlined"
                                                            value={field.value}
                                                            onChange={(e:any, v:any) => {
                                                                field.onChange(v);
                                                            }}
                                                            inputRef={(ref:any) => {
                                                                field.ref({
                                                                    focus: () => {
                                                                        ref.domElement.focus();
                                                                    }
                                                                });
                                                            }}
                                                            placeholder="0,00"
                                                            fullWidth
                                                            error={!!errors.shareCapital}
                                                            helperText={displayErrorMessage(errors.shareCapital?.message) as string}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={4}
                                                lg={4}
                                                xl={4}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="netWorth"
                                                    render={({ field }) => (
                                                        <CurrencyTextField
                                                            label={t("text.netWorth")}
                                                            currencySymbol="R$"
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            variant="outlined"
                                                            value={field.value}
                                                            onChange={(e:string, v:string) => {
                                                                field.onChange(v);
                                                            }}
                                                            inputRef={(ref:any) => {
                                                                field.ref({
                                                                    focus: () => {
                                                                        ref.domElement.focus();
                                                                    }
                                                                });
                                                            }}
                                                            placeholder="0,00"
                                                            fullWidth
                                                            error={!!errors.netWorth}
                                                            helperText={displayErrorMessage(errors.netWorth?.message) as string}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={4}
                                                lg={4}
                                                xl={4}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="billingAnnual"
                                                    render={({ field }) => (
                                                        <CurrencyTextField
                                                            label={t("text.billingAnnual")}
                                                            currencySymbol="R$"
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            variant="outlined"
                                                            value={field.value}
                                                            onChange={(e:any, v:any) => {
                                                                field.onChange(v);
                                                            }}
                                                            inputRef={(ref:any) => {
                                                                field.ref({
                                                                    focus: () => {
                                                                        ref.domElement.focus();
                                                                    }
                                                                });
                                                            }}
                                                            placeholder="0,00"
                                                            fullWidth
                                                            error={!!errors.billingAnnual}
                                                            helperText={displayErrorMessage(errors.billingAnnual?.message) as string}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={3}
                                                lg={3}
                                                xl={3}
                                            >
                                                <FormControl>
                                                    <FormLabelStyled>
                                                        {t("text.isSimple")}
                                                    </FormLabelStyled>
                                                    <Controller
                                                        control={control}
                                                        name="businessInfo"
                                                        render={({ field }) => {
                                                            return (
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabelStyled
                                                                        value="1"
                                                                        control={<Radio />}
                                                                        label={t("text.yes")}
                                                                        disabled={uploading}
                                                                    />
                                                                    <FormControlLabelStyled
                                                                        value="2"
                                                                        control={<Radio />}
                                                                        label={t("text.no")}
                                                                        disabled={uploading}
                                                                    />
                                                                </RadioGroup>
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormHelperTextStyled>{displayErrorMessage(errors.businessInfo?.message) as string}</FormHelperTextStyled>
                                            </Grid>
                                            {partnerTypeId === "1" || foreignExchangeTransaction === "1"
                                                ? <>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <SubSubtitlePageForm>
                                                            {t("text.attachDocument")}
                                                        </SubSubtitlePageForm>
                                                    </Grid>
                                                    {businessInfo === "1"
                                                        ? <>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                            >
                                                                <Card>
                                                                    <CardHeaderTitleStyled
                                                                        title={t("text.defisReceipt")}
                                                                        subheader={t("text.attachDocument")}
                                                                    />
                                                                    <Document
                                                                        title={t("text.add")}
                                                                        register={register}
                                                                        getValues={getValues}
                                                                        name="defisProof"
                                                                        uploading={uploading}
                                                                        setUploading={setUploading}
                                                                    />
                                                                    <CardActions>
                                                                        <Typography
                                                                            variant="caption"
                                                                            display="block"
                                                                        >
                                                                            {t("text.allowedFileTypesAndSize")}
                                                                        </Typography>
                                                                    </CardActions>
                                                                </Card>
                                                                {errors.defisProof && <FormHelperTextStyled>{displayErrorMessage(errors.defisProof?.message) as string}</FormHelperTextStyled>}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                            >
                                                                <Card>
                                                                    <CardHeaderTitleStyled
                                                                        title={t("text.deliveryReceipt")}
                                                                        subheader={t("text.attachDocument")}
                                                                    />
                                                                    <Document
                                                                        title={t("text.add")}
                                                                        register={register}
                                                                        getValues={getValues}
                                                                        name="receiptProof"
                                                                        uploading={uploading}
                                                                        setUploading={setUploading}
                                                                    />
                                                                    <CardActions>
                                                                        <Typography
                                                                            variant="caption"
                                                                            display="block"
                                                                        >
                                                                            {t("text.allowedFileTypesAndSize")}
                                                                        </Typography>
                                                                    </CardActions>
                                                                </Card>
                                                                {errors.receiptProof && <FormHelperTextStyled>{displayErrorMessage(errors.receiptProof?.message) as string}</FormHelperTextStyled>}
                                                            </Grid>
                                                        </>
                                                        : null
                                                    }
                                                    {businessInfo === "2"
                                                        ? <>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                            >
                                                                <Card>
                                                                    <CardHeaderTitleStyled
                                                                        title={t("text.consolidatedBalance")}
                                                                        subheader={t("text.documentAssignForLegalRepresentativeAndAccountantSped")}
                                                                    />
                                                                    <Document
                                                                        title={t("text.add")}
                                                                        register={register}
                                                                        getValues={getValues}
                                                                        name="patrimonyProof"
                                                                        uploading={uploading}
                                                                        setUploading={setUploading}
                                                                    />
                                                                    <CardActions>
                                                                        <Typography
                                                                            variant="caption"
                                                                            display="block"
                                                                        >
                                                                            {t("text.allowedFileTypesAndSize")}
                                                                        </Typography>
                                                                    </CardActions>
                                                                </Card>
                                                                {errors.patrimonyProof && <FormHelperTextStyled>{displayErrorMessage(errors.patrimonyProof?.message) as string}</FormHelperTextStyled>}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                            >
                                                                <Card>
                                                                    <CardHeaderTitleStyled
                                                                        title={t("text.dre")}
                                                                        subheader={t("text.documentAssignForLegalRepresentativeAndAccountantSped")}
                                                                    />
                                                                    <Document
                                                                        title={t("text.add")}
                                                                        register={register}
                                                                        getValues={getValues}
                                                                        name="dreProof"
                                                                        uploading={uploading}
                                                                        setUploading={setUploading}
                                                                    />
                                                                    <CardActions>
                                                                        <Typography
                                                                            variant="caption"
                                                                            display="block"
                                                                        >
                                                                            {t("text.allowedFileTypesAndSize")}
                                                                        </Typography>
                                                                    </CardActions>
                                                                </Card>
                                                                {errors.dreProof && <FormHelperTextStyled>{displayErrorMessage(errors.dreProof?.message) as string}</FormHelperTextStyled>}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                            >
                                                                <Card>
                                                                    <CardHeaderTitleStyled
                                                                        title={`${t("text.spedReceipt")} ${t("text.ifAvailable")}`}
                                                                        subheader={t("text.documentAssignForLegalRepresentativeAndAccountantSped")}
                                                                    />
                                                                    <Document
                                                                        title={t("text.add")}
                                                                        register={register}
                                                                        getValues={getValues}
                                                                        name="spedProof"
                                                                        uploading={uploading}
                                                                        setUploading={setUploading}
                                                                    />
                                                                    <CardActions>
                                                                        <Typography
                                                                            variant="caption"
                                                                            display="block"
                                                                        >
                                                                            {t("text.allowedFileTypesAndSize")}
                                                                        </Typography>
                                                                    </CardActions>
                                                                </Card>
                                                                {errors.spedProof && <FormHelperTextStyled>{displayErrorMessage(errors.spedProof?.message) as string}</FormHelperTextStyled>}
                                                            </Grid>
                                                        </>
                                                        : null
                                                    }
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <Card>
                                                            <CardHeaderTitleStyled
                                                                title={businessInfo === "1" ? t("text.billingProof12months") : `${t("text.billingProof12monthsToNormalRegimen")} ${t("text.ifAvailable")}`}
                                                                subheader={t("text.billingDeclarationAssignForLegalRepresentativeAndAccountant")}
                                                            />
                                                            <Document
                                                                title={t("text.add")}
                                                                register={register}
                                                                getValues={getValues}
                                                                name="billingProof"
                                                                uploading={uploading}
                                                                setUploading={setUploading}
                                                            />
                                                            <CardActions>
                                                                <Typography
                                                                    variant="caption"
                                                                    display="block"
                                                                >
                                                                    {t("text.allowedFileTypesAndSize")}
                                                                </Typography>
                                                            </CardActions>
                                                        </Card>
                                                        {errors.billingProof && <FormHelperTextStyled>{displayErrorMessage(errors.billingProof?.message) as string}</FormHelperTextStyled>}
                                                    </Grid>
                                                </>
                                                : null
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    label={t("text.previousStep")}
                                                    component={Link}
                                                    to= {legalNature === "120240004" 
                                                    ? "/onboarding/administration-legal-entity"
                                                    : "/onboarding/corporate-structure-legal-entity-register"
                                                }
                                                    fullWidth
                                                    disabled={submitting || uploading}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                            >
                                                <LoadingButton
                                                    type="submit"
                                                    variant="contained"
                                                    label={t("text.nextStep")}
                                                    loading={submitting || uploading}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <Button
                                                    variant="text"
                                                    label={t("text.continueLater")}
                                                    onClick={handleSignOut}
                                                    fullWidth
                                                    disabled={submitting || uploading}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </fieldset>
        </form>
    );
}
