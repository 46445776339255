import React, { ReactNode } from "react";
import { Label, SelectStyled } from "./styles";
import { RefCallBack } from "react-hook-form";

interface SelectProps {
  children?: ReactNode;
  value: string;
  onChange: (...event: any[]) => void;
  inputRef?: RefCallBack;
  error?: boolean;
  label: string | undefined;
  displayEmpty?: boolean;
  disabled?: boolean;
}
export function Select({ label, disabled, ...rest }: SelectProps) {
  return (
    <>
      <Label>{label}</Label>
      <SelectStyled fullWidth {...rest} disabled={disabled} />
    </>
  );
}
