import { MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SelectStyled } from "./style";
import React from "react";

const languageOptions = [
    {
        name: "Português (Brasil)",
        value: "ptBR",
        countryCode: 'br',
    },
    {
        name: "English (United Kingdom)",
        value: "enGB",
        countryCode: 'gb',
    },
];

export function LanguageSelect() {
    const { i18n } = useTranslation();

    const handleChange = (e:any) => {
        i18n.changeLanguage(e.target.value)
    }

    return (
        <SelectStyled
            displayEmpty
            onChange={handleChange}
            value={i18n.language}
            variant="standard"
            fullWidth
            sx={{
                '& .MuiSvgIcon-root': {
                    color: "var(--white)"
                },
                '&:before': {
                    borderColor: "var(--white)",
                  }
            }}
        >
            {languageOptions.map((languageOption) => (
                <MenuItem
                    key={languageOption.countryCode}
                    value={languageOption.value}
                >
                    <span className={`fi fi-${languageOption.countryCode}`} style={{ marginRight: 8 }} />
                    {languageOption.name}
                </MenuItem>
            ))}
        </SelectStyled>
    );
}
