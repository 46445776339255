import { Add, DeleteOutlined, EditOutlined } from '@mui/icons-material';
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Zoom
} from '@mui/material';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { addBankDetailModal } from "../../components/AddBankDetailModal";
import { Button } from '../../components/Button';
import { deleteModal } from "../../components/DeleteModal";
import { editBankDetailModal } from "../../components/EditBankDetailModal";
import { LoadingBox } from '../../components/LoadingBox';
import { PageName } from '../../components/PageName';
import { deleteBankDetail, getBankDetails } from "../../services/bankDetailService";
import {
    TableCellBodyStyled,
    TableCellTitleStyled
} from '../../styles/styles';
import { IBankDetails } from '../../interfaces/bankDetails';

export function BankDetails() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [bankDetails, setBankDetails] = useState<IBankDetails[]>([]);

    useEffect(() => {
        fetchBankDetails();
    }, [])

    const fetchBankDetails = async () => {
        setLoading(true);
        try {
            const response = await getBankDetails();
            setBankDetails(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const _addBankDetailModal = async () => {
        try {
            await addBankDetailModal();
            fetchBankDetails();
        } catch (ignored) {
        }
    }

    const _editBankDetail = async (bankDetail:IBankDetails) => {
        try {
            await editBankDetailModal({ id: bankDetail.id });
            fetchBankDetails();
        } catch (ignored) {
        }
    }

    const _deleteBankDetail = async (bankDetail: IBankDetails) => {
        try {
            const title = t("text.remove");
            const message = `${t("text.areYouSureRemoveBankDetail")} ${bankDetail.accountNumber}?`;
            await deleteModal({
                title,
                message,
                onBeforeResolve: async () => await deleteBankDetail(bankDetail.id),
                closeOnConflict: true,
            });
            toast.success(t("text.toast.remove"));
            fetchBankDetails();
        } catch (ignored) {
        }
    }

    return (
        <Grid
            container
        >
            {loading
                ? <LoadingBox />
                : <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                        background: 'var(--white)',
                        borderRadius: '4px',
                        padding: '2rem',
                        '@media(max-width: 899px)': {
                            padding: '0rem',
                        }
                    }}
                >
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <PageName
                                title={t("text.bankInformation")}
                                info={t("text.changeOrAddMoreBankInformation")}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={4}
                                            xl={4}
                                        >
                                            <Button
                                                variant="outlined"
                                                label={t("text.addBankDetail")}
                                                startIcon={<Add />}
                                                onClick={_addBankDetailModal}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <TableContainer
                                        component={Paper}
                                        sx={{
                                            '@media (max-width: 1366px)': {
                                                maxHeight: 440,
                                            },
                                            maxHeight: 640,
                                        }}
                                    >
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCellTitleStyled align="left">{t("text.bankName")}</TableCellTitleStyled>
                                                    <TableCellTitleStyled align="left">{t("text.branch")}</TableCellTitleStyled>
                                                    <TableCellTitleStyled align="left">{t("text.account")}</TableCellTitleStyled>
                                                    <TableCellTitleStyled align="right">{t("text.actions")}</TableCellTitleStyled>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {bankDetails.map((bankDetail) => {
                                                    return <TableRow hover key={bankDetail.id}>
                                                        <TableCellBodyStyled align="left">{bankDetail.name}</TableCellBodyStyled>
                                                        <TableCellBodyStyled align="left">{bankDetail.agency}</TableCellBodyStyled>
                                                        <TableCellBodyStyled align="left">{bankDetail.accountNumber}</TableCellBodyStyled>
                                                        <TableCellBodyStyled
                                                            align="right"
                                                            sx={{
                                                                display: 'flex',
                                                                gap: '1rem'
                                                            }}
                                                        >
                                                            <Tooltip
                                                                title={t("text.remove")}
                                                                TransitionComponent={Zoom}
                                                                arrow
                                                            >
                                                                <IconButton
                                                                    sx={{
                                                                        color: 'var(--red)'
                                                                    }}
                                                                    onClick={() => _deleteBankDetail(bankDetail)}
                                                                >
                                                                    <DeleteOutlined />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip
                                                                title={t("text.edit")}
                                                                TransitionComponent={Zoom}
                                                                arrow
                                                            >
                                                                <IconButton
                                                                    sx={{
                                                                        color: 'var(--blue)'
                                                                    }}
                                                                    onClick={() => _editBankDetail(bankDetail)}
                                                                >
                                                                    <EditOutlined />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCellBodyStyled>
                                                    </TableRow>
                                                })}
                                                {bankDetails.length === 0 && (
                                                    <TableRow>
                                                        <TableCellBodyStyled colSpan={4}>
                                                            <Typography
                                                                sx={{
                                                                    color: 'var(--gray-900)',
                                                                    fontSize: '1rem',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center'
                                                                }}
                                                            >
                                                                {t("text.noRecordFound")}
                                                            </Typography>
                                                        </TableCellBodyStyled>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
}