import { Add, DeleteOutlined, EditOutlined } from '@mui/icons-material';
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Zoom
} from '@mui/material';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { addAttorneyModal } from '../../components/AddAttorneyModal';
import { Button } from '../../components/Button';
import { deleteModal } from '../../components/DeleteModal';
import { editAttorneyModal } from '../../components/EditAttorneyModal';
import { LoadingBox } from '../../components/LoadingBox';
import { PageName } from '../../components/PageName';
import { deleteAttorney, getAttorneys } from "../../services/attorneyService";
import {
    TableCellBodyStyled,
    TableCellTitleStyled
} from '../../styles/styles';
import * as masks from "../../utils/masks";
import { IAttorney } from '../../interfaces/attorney';

export function PowerOfAttorney() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [attorneys, setAttorneys] = useState<IAttorney[]>([]);

    useEffect(() => {
        fetchAttorneys();
    }, []);

    const fetchAttorneys = async () => {
        setLoading(true);
        try {
            const response = await getAttorneys();
            setAttorneys(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const _addAttorney = async () => {
        try {
            await addAttorneyModal();
            fetchAttorneys();
        } catch (ignored) {
        }
    }

    const _editAttorney = async (attorney:IAttorney) => {
        try {
            await editAttorneyModal({ id: attorney.id });
            fetchAttorneys();
        } catch (ignored) {
        }
    }

    const _deleteAttorney = async (attorney:IAttorney) => {
        try {
            const title = t("text.removePowerOfAttorney");
            const message = `${t("text.removePowerOfAttorneyAreYouSure")} ${attorney.name}?`;
            await deleteModal({ 
                title, 
                message, 
                onBeforeResolve: async () => await deleteAttorney(attorney.id), 
                closeOnConflict: true,
            });    
            toast.success(`${t("text.toast.remove")}`);
            fetchAttorneys();
        } catch (ignored) {
        }
    }

    return (
        <Grid
            container
        >
            {loading
                ? <LoadingBox />
                : (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{
                            background: 'var(--white)',
                            borderRadius: '4px',
                            padding: '2rem',
                            '@media(max-width: 899px)': {
                                padding: '0rem',
                            }
                        }}
                    >
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <PageName
                                    title={t("text.powerOfAttorney")}
                                    info={t("text.changeOrAddPowerOfAttorneysToYourProfile")}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={4}
                                                xl={4}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    label={t("text.addPowerOfAttorney")}
                                                    onClick={_addAttorney}
                                                    startIcon={<Add />}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <TableContainer
                                            component={Paper}
                                            sx={{
                                                '@media (max-width: 1366px)': {
                                                    maxHeight: 440,
                                                },
                                                maxHeight: 640,
                                            }}
                                        >
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCellTitleStyled align="left">{t("text.name")}</TableCellTitleStyled>
                                                        <TableCellTitleStyled align="right">{t("text.cpf")}</TableCellTitleStyled>
                                                        <TableCellTitleStyled align="right">{t("text.actions")}</TableCellTitleStyled>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {attorneys.map((attorney) => {
                                                        return <TableRow hover key={attorney.id}>
                                                            <TableCellBodyStyled align="left">{attorney.name}</TableCellBodyStyled>
                                                            <TableCellBodyStyled align="right">{masks.cpfMask.maskDefault(attorney.taxId)}</TableCellBodyStyled>
                                                            <TableCellBodyStyled
                                                                align="right"
                                                                sx={{
                                                                    display: 'flex',
                                                                    gap: '1rem'
                                                                }}
                                                            >
                                                                <Tooltip
                                                                    title={t("text.remove")}
                                                                    TransitionComponent={Zoom}
                                                                    arrow
                                                                >
                                                                    <IconButton
                                                                        sx={{
                                                                            color: 'var(--red)'
                                                                        }}
                                                                        onClick={() => _deleteAttorney(attorney)}
                                                                    >
                                                                        <DeleteOutlined />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip
                                                                    title={t("text.edit")}
                                                                    TransitionComponent={Zoom}
                                                                    arrow
                                                                >
                                                                    <IconButton
                                                                        sx={{
                                                                            color: 'var(--blue)'
                                                                        }}
                                                                        onClick={() => _editAttorney(attorney)}
                                                                    >
                                                                        <EditOutlined />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCellBodyStyled>
                                                        </TableRow>
                                                    })}
                                                    {attorneys.length === 0 && (
                                                        <TableRow>
                                                            <TableCellBodyStyled colSpan={3}>
                                                                <Typography
                                                                    sx={{
                                                                        color: 'var(--gray-900)',
                                                                        fontSize: '1rem',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center'
                                                                    }}
                                                                >
                                                                    {t("text.noItemsToDisplay")}
                                                                </Typography>
                                                            </TableCellBodyStyled>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    );
}
