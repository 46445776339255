import { yupResolver } from '@hookform/resolvers/yup';
import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from "yup";
import { Button } from '../../../components/Button';
import { LoadingBox } from '../../../components/LoadingBox';
import { LoadingButton } from '../../../components/LoadingButton';
import { PageName } from '../../../components/PageName';

const validationSchema = yup.object({

});

export function RegisterSubsidiary() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const { handleSubmit } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {

        }
    });

    const onSubmit = async (data: any) => {
        setSubmitting(true);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
            >
                {loading
                    ? <LoadingBox />
                    : <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{
                            background: 'var(--white)',
                            borderRadius: '4px',
                            padding: '2rem',
                            '@media(max-width: 899px)': {
                                padding: '0rem',
                            }
                        }}
                    >
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <PageName
                                    title={t("text.registerSubsidiary")} info={undefined} />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={4}
                                                lg={3}
                                                xl={3}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    label={t("text.add")}
                                                    startIcon={<Add />}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <Button
                                            variant="text"
                                            label={t("text.cancel")}
                                            component={Link}
                                            to="/dashboard"
                                            disabled={submitting}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            label={t("text.save")}
                                            loading={submitting}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </form >
    );
}
