import { isValidCNPJ, isValidCPF } from "@brazilian-utils/brazilian-utils";
import { create } from 'react-modal-promise';
import * as yup from "yup";
import * as masks from "../../utils/masks";
import { AddPartnerModal } from "./AddPartnerModal";

export const validationSchema = yup.object().shape({
    id: yup.string().nullable(),
    typeId: yup.string().required(),
    name: yup.string()
        .min(2)
        .required(),
    taxId: yup.string()
        .transform(masks.cpfCnpjMask.transform)
        .when('typeId', {
            is: "1",
            then: yup.string()
                .required()
                .test({
                    name: "validateCPF",
                    test: function (value) {
                        if (isValidCPF(value!)) {
                            return true;
                        }
                        return this.createError({
                            path: this.path,
                        })
                    }
                }),
            otherwise: yup.string()
                .required()
                .test({
                    name: "validateCNPJ",
                    test: function (value) {
                        if (isValidCNPJ(value!)) {
                            return true;
                        }
                        return this.createError({
                            path: this.path,
                        })
                    }
                })
        }),
    shareCapital: yup.string()
        .nullable()
        .test({
            name: "shareCapital",
            message: "validations.percentageBetween0And100",
            test: function (value) {
                const percentage = parseFloat(value!);
                return percentage > 0 && percentage <= 100;
            }
        }),
    legalRepresentative: yup.string()
        .when("typeId", {
            is: "1",
            then: yup.string().required()
        }),
    usPerson: yup.string()
        .when("typeId", {
            is: "1",
            then: yup.string().required()
        }),
    nif: yup.string()
        .when(["usPerson", "typeId"], {
            is: (usPerson: string, typeId: string) => usPerson === "1" && typeId === "1",
            then: yup.string().required()
        }),
    isPep: yup.string()
        .when("typeId", {
            is: "1",
            then: yup.string().required()
        }),
    isPepExplain: yup.string()
        .when(["isPep", "typeId"], {
            is: (isPep: string, typeId: string) => isPep === "1" && typeId === "1",
            then: yup.string().required()
        }),
    identificationProof: yup.mixed()
        .when(["typeId", "shareCapital", "partnerTypeId"], {
            is: (typeId: string, shareCapital: string, partnerTypeId: string) => typeId === "1" && (parseFloat(shareCapital) >= 25 || partnerTypeId === "2"),
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                })
        }),
    isNational: yup.string()
        .when("typeId", {
            is: "2",
            then: yup.string().required()
        }),
    country: yup.string()
        .when("isNational", {
            is: "2",
            then: yup.string().required()
        }),
    contractProof: yup.mixed()
        .when(["typeId", "shareCapital", "partnerTypeId"], {
            is: (typeId: string, shareCapital: string, partnerTypeId: string) => typeId === "2" && (parseFloat(shareCapital) >= 10),
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                })
        }),
});

export interface AddPartnerModalProps {
    open:boolean ,
    onResolve: ()=> void ,
    onReject: ()=> void ,
}

export const addPartnerModal = create(AddPartnerModal);
