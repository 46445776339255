export const toBase64 = (file:any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    //  reader.readAsDataURL(file, "UTF-8");
    reader.onload = () => {
        let encoded = reader.result!.toString().replace(/^data:(.*,)?/, '');
        if ((encoded.length % 4) > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
    };
    reader.onerror = error => reject(error);
})
