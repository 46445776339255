import { yupResolver } from '@hookform/resolvers/yup';
import {
    FormControl,
    FormGroup, Grid, Radio, RadioGroup
} from '@mui/material';
import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { Button } from '../../components/Button';
import { CurrencyCheckBoxOptions } from '../../components/CurrencyCheckBoxOptions';
import { LoadingBox } from '../../components/LoadingBox';
import { LoadingButton } from '../../components/LoadingButton';
import { PageName } from '../../components/PageName';
import { TextField } from '../../components/TextField';
import { getCRMCoinPromise } from '../../services/crmCoinService';
import { getPrePaidCardPromise, updatePrePaidCard } from '../../services/prePaidCardService';
import {
    FormControlLabelStyled, FormHelperTextStyled,
    FormLabelStyled
} from '../../styles/styles';
import { displayErrorMessage } from "../../utils/errorMessage";
import { removeSpecialCharactersAndAccents } from "../../utils/stringUtils";
import { ICoin } from '../../interfaces/coin';
import {IPrePaidCard} from "../../interfaces/prepaidcard"

const validationSchema = yup.object({
    coins: yup.array().min(1, "validations.selectOneOption"),
    traveler: yup.string().required(),
    travelerName: yup.string()
        .when("traveler", {
            is: "2",
            then: yup.string().required(),
        }),
    cardNumber: yup.string().matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", /*message: "validations.quantityBetween1And100",*/ excludeEmptyString: true }),
    cardId: yup.string().matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
});

export function PrePaidCard() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [coinOptions, setCoinOptions] = useState<ICoin[]>([]);

    const { control, handleSubmit, formState: { errors }, reset, setValue } = useForm<IPrePaidCard>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            coins: [] as ICoin[],
            traveler: "1",
            travelerName: "",
            cardNumber: "",
            cardId: "",
        }
    });

    const traveler = useWatch({
        control,
        name: "traveler",
    });

    useEffect(() => {
        async function fetchPrePaidCard() {
            try {
                const [coinOptions, prePaidCard] = await Promise.all([
                    getCRMCoinPromise(),
                    getPrePaidCardPromise()
                ]);

                setCoinOptions(coinOptions.data);
                reset(prePaidCard.data);
                setLoading(false);
            } catch (error:any) {
                const errorCode = error.response?.data?.errorCode;
                if (errorCode === "12000") {
                    navigate('/onboarding/order');
                }
            }
        }

        fetchPrePaidCard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const onSubmit = async (data:IPrePaidCard) => {
        setSubmitting(true);
        try {
            await updatePrePaidCard(data);
            toast.success(`${t("text.toast.success")}`);
            navigate('/dashboard');
        } catch (error:any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    };

  const selectAllCoins = () => {
    setValue("coins", coinOptions.map((c) => c.id as unknown as ICoin ));
  };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={submitting} style={{ border: 0 }}>
                <Grid
                    container
                >
                    {loading
                        ? <LoadingBox />
                        : <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{
                                background: 'var(--white)',
                                borderRadius: '4px',
                                padding: '2rem',
                                '@media(max-width: 899px)': {
                                    padding: '0rem',
                                }
                            }}
                        >
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <PageName
                                        title={t("text.prePaidCardInformation")}
                                        info={t("text.changeOrAddCardInformation")}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={4}
                                            lg={3}
                                            xl={3}
                                        >
                                            <FormControl>
                                                <FormLabelStyled>
                                                    {t("text.travelerOwnsRegistration")}
                                                </FormLabelStyled>
                                                <Controller
                                                    control={control}
                                                    name="traveler"
                                                    render={({ field }) => {
                                                        return (
                                                            <RadioGroup row {...field}>
                                                                <FormControlLabelStyled
                                                                    value="1"
                                                                    control={<Radio />}
                                                                    label={t("text.yes")}
                                                                />
                                                                <FormControlLabelStyled
                                                                    value="2"
                                                                    control={<Radio />}
                                                                    label={t("text.no")}
                                                                />
                                                            </RadioGroup>
                                                        );
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {traveler === "2"
                                            ? <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={8}
                                                lg={9}
                                                xl={9}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="travelerName"
                                                    render={({ field }) => (
                                                        <TextField
                                                            value={field.value}
                                                            onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                            inputRef={field.ref}
                                                            label={t("text.travellerName")}
                                                            placeholder={t("text.travellerName") as string}
                                                            inputProps={{ maxLength: 100 }}
                                                            fullWidth
                                                            error={!!errors.travelerName}
                                                            helperText={displayErrorMessage(errors.travelerName?.message) as string}
                                                            variant='outlined'
                                                            />
                                                    )}
                                                />
                                            </Grid>
                                            : null
                                        }
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Controller
                                                control={control}
                                                name="cardNumber"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                        inputRef={field.ref}
                                                        label={t("text.cardNumber")}
                                                        placeholder={t("text.cardNumber") as string}
                                                        inputProps={{ maxLength: 16 }}
                                                        fullWidth
                                                        error={!!errors.cardNumber}
                                                        helperText={displayErrorMessage(errors.cardNumber?.message) as string}
                                                        variant='outlined'
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Controller
                                                control={control}
                                                name="cardId"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                        inputRef={field.ref}
                                                        label={t("text.idCard")}
                                                        placeholder={t("text.idCard") as string}
                                                        hint={t("text.idCardHint") as string}
                                                        inputProps={{ maxLength: 10 }}
                                                        fullWidth
                                                        error={!!errors.cardId}
                                                        helperText={displayErrorMessage(errors.cardId?.message) as string}
                                                        variant='outlined'
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <FormControl>
                                                        <FormLabelStyled>
                                                            {t("text.prePaidCardCurrencies")}
                                                        </FormLabelStyled>
                                                        <FormGroup>
                                                            <Button
                                                                variant="outlined"
                                                                label={t("text.selectAll")}
                                                                sx={{
                                                                    marginTop: (theme) => theme.spacing(2),
                                                                    marginBottom: (theme) => theme.spacing(2),
                                                                }}
                                                                onClick={selectAllCoins}
                                                            />
                                                            <CurrencyCheckBoxOptions
                                                                name="coins"
                                                                currencies={coinOptions}
                                                                control={control}
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                    {errors.coins && <FormHelperTextStyled>{displayErrorMessage(errors.coins?.message) as string}</FormHelperTextStyled>}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <Button
                                                variant="text"
                                                label={t("text.cancel")}
                                                component={Link}
                                                to="/dashboard"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                label={t("text.save")}
                                                loading={submitting}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </fieldset>
        </form>
    );
}