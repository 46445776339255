import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import './i18n';
import "/node_modules/flag-icons/css/flag-icons.min.css";

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  
  <App />
);
