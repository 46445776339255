import api from "./api";
import {IOverallData} from "../interfaces/overallData"
import { IAddress } from "../interfaces/address";
import { IFinancialData } from "../interfaces/financialData";

export const getOverallDataPromise = () => api.get(`/api/onboarding/overall-data`, { withCredentials: true });

export const getOverallData = async () => {
    return await getOverallDataPromise();
}

export const saveOverallData = async (data: IOverallData , updateStepIndex = true) => {
    return await api.patch(`/api/onboarding/overall-data`, data, { withCredentials: true, headers: { 'X-UpdateStepIndex': updateStepIndex } });
}

export const getAddressPromise = () => api.get(`/api/onboarding/address`, { withCredentials: true });

export const getAddress = async () => {
    return await getAddressPromise();
}

export const saveAddress = async (data:IAddress, updateStepIndex = true) => {
    return await api.patch(`/api/onboarding/address`, data, { withCredentials: true, headers: { 'X-UpdateStepIndex': updateStepIndex } });
}

export const getFinancialData = async () => {
    return await api.get(`/api/onboarding/financial-data`, { withCredentials: true });
}

export const saveFinancialData = async (data:IFinancialData, updateStepIndex = true) => {
    return await api.patch(`/api/onboarding/financial-data`, data, { withCredentials: true, headers: { 'X-UpdateStepIndex': updateStepIndex } });
}

export const getLegalEntityResume = async () => {
    return await api.get(`/api/onboarding/legal-entity-resume`, { withCredentials: true });
}

export const saveLegalEntityResume = async (updateStepIndex = true) => {
    return await api.post(`/api/onboarding/legal-entity-resume`, {}, { withCredentials: true, headers: { 'X-UpdateStepIndex': updateStepIndex } });
}
