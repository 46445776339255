import { useTranslation } from "react-i18next";
import { CardHeaderTitleStyled } from "../../styles/styles";
import { documentInfo } from "../../utils/documentInfo";
import React from "react";


interface DocumentHeadProps {
    hasDriverLicense: string, 
    typeOfDocument: string
}

export const DocumentHead  : React.FC<DocumentHeadProps> = ({ hasDriverLicense, typeOfDocument }) => {
    const selectedDocumentInfo = documentInfo.find(d => d.id === typeOfDocument);
    const { t } = useTranslation();

    return (
        <CardHeaderTitleStyled
            title={
                hasDriverLicense === "1"
                    ? t("text.nationalDriversLicense")
                    : t(selectedDocumentInfo?.cardTitle!)
            }
            subheader={
                hasDriverLicense === "1"
                    ? t("text.CNHMustBeWithin")
                    : t(selectedDocumentInfo?.cardSubtitle!)
            }
        />
    )
}
