import {
    MenuItem
} from '@mui/material';
import {  Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Select } from "../Select";
import React from 'react';

type DocumentType = { 
    id:string ;
    description: string ;
    
}

interface CRMDocumentTypeOptionsProps {
    control : any ;
    name: string ;
    documentTypes: Array<DocumentType>
    error:any;
    disabled?:boolean;
}

export function CRMDocumentTypeOptions({ control, name, documentTypes, error, ...rest }: CRMDocumentTypeOptionsProps) {
    const { t } = useTranslation();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <Select
                    value={field.value}
                    onChange={field.onChange}
                    inputRef={field.ref}
                    error={error}
                    label={t("text.documentType") as string}
                    displayEmpty
                    {...rest}
                >
                    <MenuItem value="">{t("text.select")}</MenuItem>
                    {documentTypes.map((documentType) => (
                        <MenuItem
                            key={documentType.id}
                            value={documentType.id}
                        >
                            {documentType.description}
                        </MenuItem>
                    ))}
                </Select>
            )}
        />
    );
}