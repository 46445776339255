import { IContacts } from "../interfaces/contacts";
import api from "./api";


export const getContacts = async () => {
    return await api.get(`/api/contact`, { withCredentials: true });
}

export const saveContact = async (data:IContacts) => {
    return await api.post(`/api/contact`, data, { withCredentials: true });
}

export const updateContact = async (id:string, data:IContacts) => {
    return await api.patch(`/api/contact/${id}`, data, { withCredentials: true });
}

export const deleteContact = async (id:string) => {
    return await api.delete(`/api/contact/${id}`, { withCredentials: true });
}

export const getContactByIdPromise = async (id:string) => api.get(`/api/contact/${id}`, { withCredentials: true });
export const getContactById = async (id:string) => {
    return await getContactByIdPromise(id);
}