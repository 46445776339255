import React from "react";
import { EditOutlined, KeyboardArrowDown, KeyboardArrowUp, DeleteOutlined, Add, InfoOutlined, CheckOutlined } from '@mui/icons-material';
import {
    IconButton,
    Table,
    TableBody,
    TableRow,
    Tooltip,
    Zoom,
    Collapse
} from '@mui/material';
import {
    TableCellBodyStyled,
    TableCellTitleStyled,
} from '../../styles/styles';
import { useTranslation } from 'react-i18next';
import * as masks from "../../utils/masks";
import { IAttorney } from "../../interfaces/attorney";

interface UpdateAttorneyProps {
    personalData: any,
    attorneyRowClick: () => void,
    addAttorney: () => void,
    editAttorney: (attorney: any) => void,
    deleteAttorney: (attorney: any) => void,
    openAttorney: boolean
}

export function UpdateAttorney({ personalData, attorneyRowClick, addAttorney, editAttorney, deleteAttorney, openAttorney }: UpdateAttorneyProps) {

    const { t } = useTranslation();
    return (
        <>
            <TableRow>
                <TableCellBodyStyled>
                    {t("text.attorneys")}
                    {personalData.attorneys?.some((attorney: any) => {
                        return attorney.attorneyExistDocumentToUpdate === "1" && (
                            attorney.attorneyIdentificationDocumentStateCode === "448560001" ||
                            attorney.attorneyIdentificationDocumentStateCode === "448560002" ||
                            attorney.attorneyLetterDocumentStateCode === "448560001" ||
                            attorney.attorneyLetterDocumentStateCode === "448560002");
                    }) ? (
                        <Tooltip
                            title={t("text.letterOfAttorney")}
                            arrow
                        >
                            <IconButton sx={{ marginLeft: '8px', color: 'red' }}>
                                <InfoOutlined />
                            </IconButton>
                        </Tooltip>
                    ) : personalData.attorneys?.some((attorney: any) => {
                        return attorney.attorneyExistDocumentToUpdate === "1" && attorney.attorneyIdentificationDocumentStateCode === "999999999" && attorney.attorneyLetterDocumentStateCode === "999999999";
                    }) ? (
                        <Tooltip
                            title={t("text.letterOfAttorney")}
                            arrow
                        >
                            <IconButton sx={{ marginLeft: '8px', color: 'green' }}>
                                <CheckOutlined />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                </TableCellBodyStyled>
                <TableCellBodyStyled
                    align="right"
                    sx={{
                        display: 'flex',
                        gap: '1rem'
                    }}
                >
                    <Tooltip
                        title={t("text.attorneys")}
                        TransitionComponent={Zoom}
                        arrow
                    >
                        <IconButton
                            sx={{
                                color: 'var(--blue)'
                            }}
                            onClick={attorneyRowClick}
                        >
                            {openAttorney ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={t("text.add")}
                        TransitionComponent={Zoom}
                        arrow
                    >
                        <IconButton
                            sx={{
                                color: 'var(--blue)'
                            }}
                            onClick={addAttorney}
                        >
                            <Add />
                        </IconButton>
                    </Tooltip>
                </TableCellBodyStyled>
            </TableRow>
            <TableRow>
                <TableCellBodyStyled style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
                    <Collapse in={openAttorney} timeout="auto" unmountOnExit>
                        <div>
                            <Table stickyHeader>
                                <TableRow>
                                    <TableCellTitleStyled align="left">{t("text.name")}</TableCellTitleStyled>
                                    <TableCellTitleStyled align="right">{t("text.cpf")}</TableCellTitleStyled>
                                    <TableCellTitleStyled align="right">{t("text.actions")}</TableCellTitleStyled>
                                </TableRow>
                                <TableBody>
                                    {personalData.attorneys != null && personalData.attorneys.map((attorney: IAttorney) => {
                                        return <TableRow hover key={attorney.id}>
                                            <TableCellBodyStyled align="left">
                                                {attorney.name}
                                                {attorney.attorneyExistDocumentToUpdate === "1" && (
                                                    attorney.attorneyIdentificationDocumentStateCode === "448560001" ||
                                                    attorney.attorneyIdentificationDocumentStateCode === "448560002" ||
                                                    attorney.attorneyLetterDocumentStateCode === "448560001" ||
                                                    attorney.attorneyLetterDocumentStateCode === "448560002"
                                                )
                                                    && (
                                                        <Tooltip
                                                            title={t("text.partnerDocumentUpdate")}
                                                            arrow
                                                        >
                                                            <IconButton sx={{ marginLeft: '8px', color: 'red' }}>
                                                                <InfoOutlined />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                {attorney.attorneyExistDocumentToUpdate === "1" && attorney.attorneyIdentificationDocumentStateCode === "999999999" && attorney.attorneyLetterDocumentStateCode === "999999999"

                                                    && (
                                                        <Tooltip
                                                            title={t("text.partnerDocumentUpdate")}
                                                            arrow
                                                        >
                                                            <IconButton sx={{ marginLeft: '8px', color: 'green' }}>
                                                                <CheckOutlined />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                            </TableCellBodyStyled>
                                            <TableCellBodyStyled align="right">{masks.cpfMask.maskDefault(attorney.taxId)}</TableCellBodyStyled>
                                            <TableCellBodyStyled
                                                align="right"
                                                sx={{
                                                    display: 'flex',
                                                    gap: '1rem'
                                                }}
                                            >
                                                <Tooltip
                                                    title={t("text.remove")}
                                                    TransitionComponent={Zoom}
                                                    arrow
                                                >
                                                    <IconButton
                                                        sx={{
                                                            color: 'var(--red)'
                                                        }}
                                                        onClick={() => deleteAttorney(attorney)}
                                                    >
                                                        <DeleteOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    title={t("text.edit")}
                                                    TransitionComponent={Zoom}
                                                    arrow
                                                >
                                                    <IconButton
                                                        sx={{
                                                            color: 'var(--blue)'
                                                        }}
                                                        onClick={() => editAttorney(attorney)}
                                                    >
                                                        <EditOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCellBodyStyled>
                                        </TableRow>

                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </Collapse>
                </TableCellBodyStyled>
            </TableRow>
        </>
    );
}