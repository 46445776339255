import { Box, CircularProgress } from "@mui/material";
import React, { useContext } from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import { RequireAuth } from '../components/RequireAuth';
import { AuthContext } from '../contexts/auth';
import { BankDetails } from '../pages/BankDetails';
import { ChangeProfile } from "../pages/ChangeProfile";
import { Contacts } from '../pages/Contacts';
import { CreateAccount } from '../pages/CreateAccount';
import { ForgotPassword } from "../pages/ForgotPassword";
import { Home } from '../pages/Home';
import { AddressLegalEntity } from "../pages/LegalEntity/AddressLegalEntity";
import { AddressLegalEntityRegister } from "../pages/LegalEntity/AddressLegalEntityRegister";
import { Administration } from "../pages/LegalEntity/Administration";
import { CorporateStructure } from "../pages/LegalEntity/CorporateStructure";
import { FinancialData } from "../pages/LegalEntity/FinancialData";
import { FinancialDataRegister } from "../pages/LegalEntity/FinancialDataRegister";
import { LegalEntityResume } from "../pages/LegalEntity/LegalEntityResume";
import { LegalEntityResumeRegister } from "../pages/LegalEntity/LegalEntityResumeRegister";
import { MoneycorpServicesPJ } from "../pages/LegalEntity/MoneycorpServicesPJ";
import { OverallData } from "../pages/LegalEntity/OverallData";
import { OverallDataRegister } from "../pages/LegalEntity/OverallDataRegister";
import { RegisterSubsidiary } from "../pages/LegalEntity/RegisterSubsidiary";
import { Login } from '../pages/Login';
import { LoginCnpj } from '../pages/LoginCnpj';
import { MoneycorpServices } from '../pages/Tourism/MoneycorpServices';
import { Order } from '../pages/Order';
import { PowerOfAttorney } from '../pages/PowerOfAttorney';
import { PrePaidCard } from '../pages/PrePaidCard';
import { AddressRegister } from "../pages/Register/AddressRegister";
import { BankingReferencesRegister } from "../pages/Register/BankingReferencesRegister";
import { PersonalDataRegister } from "../pages/Register/PersonalDataRegister";
import { ResumeRegister } from "../pages/Register/ResumeRegister";
import { ResetPassword } from "../pages/ResetPassword";
import { AddressTourism } from "../pages/Tourism/AddressTourism";
import { IncomeAndPowerOfAttorney } from "../pages/Tourism/IncomeAndPowerOfAttorney";
import { PersonalDataTourism } from "../pages/Tourism/PersonalDataTourism";
import { ResumeTourism } from "../pages/Tourism/ResumeTourism";
import { DefaultLayout } from '../pages/_layouts/default';
import { DefaultDrawer } from '../pages/_layouts/drawer';
import { SmallForm } from "../pages/_layouts/smallForm";
import { NotFound } from "../pages/NotFound";
import { MoneycorpServicesRegister } from "../pages/Register/MoneycorpServicesRegister";
import { MoneycorpServicesPJRegister } from "../pages/LegalEntity/MoneycorpServicesPJRegister";
import { IncomeAndPowerOfAttorneyRegister } from "../pages/Register/IncomeAndPowerOfAttorneyRegister";
import { UploadDocument } from "../pages/UploadDocument";
import { RegistrationDocuments } from "../pages/RegistrationDocuments";
import { AdministrationLegalEntity } from "../pages/LegalEntity/AdministrationLegalEntity";
import { CorporateStructureLegalEntity } from "../pages/LegalEntity/CorporateStructureLegalEntity";
import { BankingReferencesLegalEntity } from "../pages/LegalEntity/BankingReferencesLegalEntity";
import { Version } from "../pages/Version";
import { PersonalDataUpdate } from "../pages/RegistrationUpdatePerson/PersonalDataUpdate";
import { PersonalDocumentUpdate } from "../pages/RegistrationUpdatePerson/PersonalDocumentUpdate";
import { PersonalDataResumeUpdate } from "../pages/RegistrationUpdatePerson/PersonalDataResumeUpdate";
import { CorporateResumeUpdate } from "../pages/RegistrationUpdateCorporate/CorporateResumeUpdate";
import { CorporateUpdate } from "../pages/RegistrationUpdateCorporate/CorporateUpdate";

export default function Routes() {
    const { user } = useContext(AuthContext);

    if (user.loading) {
        return (
            <Box
                height="100vh"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <CircularProgress />
            </Box>
        )
    }

    return (
        <ReactRoutes>
            <Route path="/" element={<SmallForm />}>
                <Route index element={<Login />} />
                <Route path="loginPj" element={<LoginCnpj />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="create" element={<CreateAccount />} />
                <Route path="version" element={<Version />} />
            </Route>

            <Route path="/dashboard" element={<RequireAuth><DefaultDrawer /></RequireAuth>}>
                <Route index element={<Home />} />
                <Route path="contacts" element={<Contacts />} />
                <Route path="bank-details" element={<BankDetails />} />
                <Route path="pre-paid-card" element={<PrePaidCard />} />
                <Route path="power-of-attorney" element={<PowerOfAttorney />} />
                <Route path="registration-documents" element={<RegistrationDocuments />} />
                <Route path="upload-document" element={<UploadDocument />} />

                <Route path="administration" element={<Administration />} />
                <Route path="corporate-structure" element={<CorporateStructure />} />
                <Route path="bank-details-legal-entity" element={<BankDetails />} />
                <Route path="contacts-legal-entity" element={<Contacts  />} />
                {/* <Route path="contacts-legal-entity" element={<Contacts legalEntity />} /> */}
                <Route path="power-of-attorney-legal-entity" element={<PowerOfAttorney />} />
                <Route path="register-subsidiary-legal-entity" element={<RegisterSubsidiary />} />

                {/* Alteração de perfil PF */}
                <Route path="contact-reason" element={<MoneycorpServices />} />
                <Route path="personal-data" element={<PersonalDataTourism />} />
                <Route path="address" element={<AddressTourism />} />
                <Route path="income-powerOfAttorney" element={<IncomeAndPowerOfAttorney />} />
                <Route path="resume" element={<ResumeTourism />} />

                {/* Alteração de perfil PJ */}
                <Route path="contact-reason-legal-entity" element={<MoneycorpServicesPJ />} />
                <Route path="overall-data-legal-entity" element={<OverallData />} />
                <Route path="address-legal-entity" element={<AddressLegalEntity />} />
                <Route path="banking-references-legal-entity" element={<BankingReferencesLegalEntity onboarding={false} />} />
                <Route path="administration-legal-entity" element={<AdministrationLegalEntity onboarding={false} />} />
                <Route path="corporate-structure-legal-entity" element={<CorporateStructureLegalEntity onboarding={false} />} />
                <Route path="financial-data-legal-entity" element={<FinancialData />} />
                <Route path="resume-legal-entity" element={<LegalEntityResume />} />

                <Route path="order" element={<Order />} />
                <Route path="change-profile" element={<ChangeProfile />} />
            </Route>

            <Route path="/onboarding" element={<RequireAuth><DefaultLayout /></RequireAuth>} >
                <Route path="contact-reason-register" element={<MoneycorpServicesRegister />} />
                <Route path="personal-data-register" element={<PersonalDataRegister />} />
                <Route path="address-register" element={<AddressRegister />} />
                <Route path="banking-references-register" element={<BankingReferencesRegister />} />
                <Route path="income-powerOfAttorney-register" element={<IncomeAndPowerOfAttorneyRegister />} />
                <Route path="resume-register" element={<ResumeRegister />} />

                <Route path="contact-reason-legal-entity-register" element={<MoneycorpServicesPJRegister />} />
                <Route path="overall-data-legal-entity-register" element={<OverallDataRegister />} />
                <Route path="address-legal-entity-register" element={<AddressLegalEntityRegister />} />
                <Route path="banking-references-legal-entity-register" element={<BankingReferencesLegalEntity />} />
                <Route path="administration-legal-entity-register" element={<AdministrationLegalEntity />} />
                <Route path="corporate-structure-legal-entity-register" element={<CorporateStructureLegalEntity />} />
                <Route path="financial-data-legal-entity-register" element={<FinancialDataRegister />} />
                <Route path="resume-legal-entity-register" element={<LegalEntityResumeRegister />} />

                <Route path="order" element={<Order />} />
            </Route>

            <Route path="/update" element={<RequireAuth><DefaultLayout /></RequireAuth>} >
                <Route path="personal-document-update" element={<PersonalDocumentUpdate />} />
                <Route path="personal-data-update" element={<PersonalDataUpdate />} />
                <Route path="personal-resume-update" element={<PersonalDataResumeUpdate />} />

                <Route path="corporate-data-update" element={<CorporateUpdate />} />
                <Route path="corporate-resume-update" element={<CorporateResumeUpdate />} />
            </Route>

            <Route path='*' element={<NotFound />} />
        </ReactRoutes >
    )
}
