export const documentInfo = [
    {
        id: "120240000",
        subtitle: "text.attachYourRG",
        cardTitle: "text.IdCard",
        cardSubtitle: "text.RGMustBeWithin",
        message: "text.enterYourRGDataBelow",
        updateMessage: "text.updateYourRGDataBelow",
    },
    {
        id: "120240001",
        subtitle: "text.attachYourClassIdentityCard",
        cardTitle: "text.classIdentityCard",
        cardSubtitle: "text.classIdentityCardMustBeWithin",
        message: "text.enterYourClassIdentityCardDataBelow",
        updateMessage: "text.updateYourClassIdentityCardDataBelow",
    },
    {
        id: "120240002",
        subtitle: "text.attachYourRNE",
        cardTitle: "text.foreignerNationalRegistration",
        cardSubtitle: "text.RNEMustBeWithin",
        message: "text.enterYourRNEDataBelow",
        updateMessage: "text.updateYourRNEDataBelow",
    },
    {
        id: "120240003",
        subtitle: "text.attachYourCNH",
        cardTitle: "text.nationalDriversLicense",
        cardSubtitle: "text.CNHMustBeWithin",
        message: "text.enterYourCNHDataBelow",
        updateMessage: "text.updateYourCNHDataBelow",
    },
    {
        id: "120240004",
        subtitle: "text.attachYourPassport",
        cardTitle: "text.passport",
        cardSubtitle: "text.passportMustBeWithin",
        message: "text.enterYourPassportDataBelow",
        updateMessage: "text.updateYourPassportDataBelow",
    },
];
