import { IBankDetails } from "../interfaces/bankDetails";
import api from "./api";

export const getBankDetails = async () => {
    return await api.get(`/api/client-bank-detail/client`, { withCredentials: true });
}

export const getBankDetailByIdPromise = async (id:string) => api.get(`/api/client-bank-detail/${id}`, { withCredentials: true });
export const getBankDetailById = async (id:string) => {
    return await getBankDetailByIdPromise(id);
}

export const getFirstBankDetailPromise = async () => api.get(`/api/client-bank-detail/first`, { withCredentials: true });
export const getFirstBankDetail = async () => {
    return await getFirstBankDetailPromise();
}

export const saveBankDetail = async (data:IBankDetails, updateStepIndex = true) => {
    return await api.post(`/api/client-bank-detail`, data, { withCredentials: true, headers: { 'X-UpdateStepIndex': updateStepIndex } });
}

export const updateBankDetail = async (id:string, data:IBankDetails) => {
    return await api.patch(`/api/client-bank-detail/${id}`, data, { withCredentials: true });
}

export const deleteBankDetail = async (id:string) => {
    return await api.delete(`/api/client-bank-detail/${id}`, { withCredentials: true });
}