import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider
} from "@mui/material";
import React, { ElementType } from 'react';
import { useTranslation } from "react-i18next";
import {
    Button
} from "../Button";
import {
    CardActionsStyled,
    CardContentStyled,
    CardIcon,
    CardInfo,
    CardStyled, CardTitle
} from "./style";

interface CardHomeProps {
    showBorder?: boolean;
    title: string;
    info: string;
    icon: any;
    buttonLabel: string;
    component: ElementType<any> | undefined;
    to: string;
    showChangeProfile?: boolean;
    canEdit?: boolean;
    border?: boolean;
}

export const CardHome: React.FC<CardHomeProps> = ({ showBorder = false, title, info, icon, buttonLabel, component, to, canEdit = true }) => {
    const { t } = useTranslation();
    
    return (
        <CardStyled showBorder={showBorder}>
            <CardContentStyled>
                <div>
                    <CardTitle>
                        {title}
                    </CardTitle>
                    <CardInfo>
                        {info}
                    </CardInfo>
                </div>
                <CardIcon>
                    {icon}
                </CardIcon>
            </CardContentStyled>
            <Divider />
            <CardActionsStyled>
                <Button
                    disabled={!canEdit}
                    component={component}
                    to={to}
                    label={buttonLabel}
                />
            </CardActionsStyled>
        </CardStyled>
    );
}