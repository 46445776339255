import { ReactNode, useContext } from "react"
import { AuthContext } from '../../contexts/auth'
import { Navigate, useLocation } from "react-router-dom"
import React from "react";

export const RequireAuth = ({ children } : {children: ReactNode}) => {
    const location = useLocation();
    const { user } = useContext<any>(AuthContext);

    if (!user.authenticated) {
        return <Navigate to="/" replace state={{ from: location }} />
    }

    return <>
    {children}
    </>
}
