import { InputLabel, NativeSelect, OutlinedInput } from "@mui/material";
import React from "react";
import {  Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AddressState } from "../EditAddressModal/AddressState";



 interface CRMAddressStateOptionsProps {
   // control:Control<FieldValues>, 
   control: any
    name:string, 
    label:string, 
    placeholder?:string, 
    states:Array<AddressState>, 
    error:any, 
    disabled?:boolean, 
 }
export function CRMAddressStateOptions({ control, name, label, placeholder, states, error, disabled }:CRMAddressStateOptionsProps ) {
    const { t } = useTranslation();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <>
                    <InputLabel
                        variant="standard"
                        htmlFor="uncontrolled-native"
                        sx={{
                            fontWeight: "700",
                            fontSize: ".75rem",
                            color: "var(--gray-600)",
                            display: "flex",
                            alignItems: "flex-end",
                            gap: ".5rem",
                        }}
                    >
                        {label}
                    </InputLabel>
                    <NativeSelect
                        disabled={disabled}
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        error={error}
                        input={<OutlinedInput />}
                        sx={{
                            marginTop: ".25rem",
                            marginBottom: "0!important",
                            height: "2.75rem",
                            backgroundColor: "#fff",
                        }}
                        fullWidth
                    >
                        <option value="">{t("text.select")}</option>
                        {states.map((state) => (
                        
                            <option
                                key={state.id}
                                value={state.id}
                            >
                                {state.state}
                            </option>
                        ))}
                    </NativeSelect>
                </>
            )}
        />
    );
}