import { Select } from "@mui/material";
import styled from "styled-components";

export const SelectStyled = styled(Select)`
    && {
        /* @media (min-width: 1000px) { */
            height: 2.75rem;
            color: var(--white);
            border: 0!important;
            
            .MuiSvgIcon-root {
                color: 'white'
            }
        /* } */

    }
`;