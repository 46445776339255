import React, { useLayoutEffect } from "react";
import { Outlet } from 'react-router-dom';
import { Header } from "../../../components/Header";

export function DefaultLayout() {
    useLayoutEffect(() => {
        document.body.style.background = "var(--background)"
    });

    return (
        <>
            <Header />
            <Outlet />
        </>
    );
}