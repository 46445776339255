import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import { Add, DeleteOutlined, EditOutlined, ErrorOutlineOutlined } from '@mui/icons-material';
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Zoom
} from '@mui/material';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { addPartnerModal } from "../../../components/AddPartnerModal";
import { Button } from '../../../components/Button';
import { deleteModal } from "../../../components/DeleteModal";
import { editPartnerModal } from "../../../components/EditPartnerModal";
import { LoadingBox } from '../../../components/LoadingBox';
import { PageName } from '../../../components/PageName';
import { deleteCorporateStructure, getCorporateStructure } from "../../../services/corporateStructureService";
import {
    TableCellBodyStyled,
    TableCellTitleStyled
} from '../../../styles/styles';
import * as masks from "../../../utils/masks";
import bigDecimal from 'js-big-decimal';
import { IPartners } from '../../../interfaces/partners';

export function CorporateStructure() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [partners, setPartners] = useState<IPartners[]>([]);

    const totalPercentage = () => {
        var total = new bigDecimal('0');
        for (var partner in partners) {
            total = total.add(new bigDecimal(partners[partner].shareCapital.toString()));
        }
        return total;
    }

    useEffect(() => {
        fetchPartners();
    }, [])

    const fetchPartners = async () => {
        setLoading(true);
        try {
            const response = await getCorporateStructure();
            setPartners(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const _addPartner = async () => {
        try {
            await addPartnerModal();
            fetchPartners();
        } catch (ignored) {
        }
    }

    const _editPartner = async (partner:IPartners) => {
        try {
            await editPartnerModal({ id: partner.id });
            fetchPartners();
        } catch (ignored) {
        }
    }

    const _deletePartner = async (partner:IPartners) => {
        try {
            const title = t("text.remove");
            const message = `${t("text.areYouSureRemovePartner")} ${partner.name}?`;
            await deleteModal({
                title,
                message,
                onBeforeResolve: async () => await deleteCorporateStructure(partner.id),
                closeOnConflict: true,
            });
            toast.success('Sócio removido com sucesso.');
            fetchPartners();
        } catch (ignored) {
        }
    }

    return (
        <Grid
            container
        >
            {loading
                ? <LoadingBox />
                : <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                        background: 'var(--white)',
                        borderRadius: '4px',
                        padding: '2rem',
                        '@media(max-width: 899px)': {
                            padding: '0rem',
                        }
                    }}
                >
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <PageName
                                title={t("text.corporateStructure")}
                                info={t("text.registerBusinessPartners")}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={4}
                                            xl={4}
                                        >
                                            <Button
                                                variant="outlined"
                                                label={t("text.addBusinessPartner")}
                                                startIcon={<Add />}
                                                onClick={_addPartner}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <TableContainer
                                        component={Paper}
                                        sx={{
                                            '@media (max-width: 1366px)': {
                                                maxHeight: 440,
                                            },
                                            maxHeight: 640,
                                        }}
                                    >
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCellTitleStyled align="left">{t("text.document")}</TableCellTitleStyled>
                                                    <TableCellTitleStyled align="left">{t("text.name")}</TableCellTitleStyled>
                                                    <TableCellTitleStyled align="left">{t("text.percentageOnEquity")}</TableCellTitleStyled>
                                                    <TableCellTitleStyled align="right">{t("text.actions")}</TableCellTitleStyled>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {partners.map((partner) => {
                                                    return <TableRow hover key={partner.id}>
                                                        <TableCellBodyStyled align="left">{partner.typeId === "1" ? masks.cpfMask.maskDefault(partner.taxId) : masks.cnpjMask.maskDefault(partner.taxId)}</TableCellBodyStyled>
                                                        <TableCellBodyStyled align="left">{partner.name}</TableCellBodyStyled>

                                                        {partner.shareCapital === 0 ?
                                                            <TableCellBodyStyled align="left">
                                                                {formatCurrency(partner.shareCapital, { precision: 3 })}
                                                                <Tooltip title={t("validations.corporateStructurePercentageNotZero")} arrow>
                                                                    <IconButton color='error'>
                                                                        <ErrorOutlineOutlined />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCellBodyStyled> :
                                                            <TableCellBodyStyled align="left">{formatCurrency(partner.shareCapital as number, { precision: 3 })}</TableCellBodyStyled>
                                                        }
                                                        <TableCellBodyStyled
                                                            align="right"
                                                            sx={{
                                                                display: 'flex',
                                                                gap: '1rem'
                                                            }}
                                                        >
                                                            <Tooltip
                                                                title={t("text.remove")}
                                                                TransitionComponent={Zoom}
                                                                arrow
                                                            >
                                                                <IconButton
                                                                    sx={{
                                                                        color: 'var(--red)'
                                                                    }}
                                                                    onClick={() => _deletePartner(partner)}
                                                                >
                                                                    <DeleteOutlined />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip
                                                                title={t("text.edit")}
                                                                TransitionComponent={Zoom}
                                                                arrow
                                                            >
                                                                <IconButton
                                                                    sx={{
                                                                        color: 'var(--blue)'
                                                                    }}
                                                                    onClick={() => _editPartner(partner)}
                                                                >
                                                                    <EditOutlined />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCellBodyStyled>
                                                    </TableRow>
                                                })}
                                                {partners.length === 0 ? (
                                                    <TableRow>
                                                        <TableCellBodyStyled colSpan={3}>
                                                            <Typography
                                                                sx={{
                                                                    color: 'var(--gray-900)',
                                                                    fontSize: '1rem',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center'
                                                                }}
                                                            >
                                                                {t("text.noItemsToDisplay")}
                                                            </Typography>
                                                        </TableCellBodyStyled>
                                                    </TableRow>
                                                ) : <TableRow>
                                                    <TableCellBodyStyled colSpan={2} />
                                                    <TableCellBodyStyled>
                                                        {formatCurrency(Number(totalPercentage().getValue()), { precision: 3 })} %
                                                        {totalPercentage().compareTo(new bigDecimal('100')) !== 0 && (
                                                            <Tooltip title={t("validations.corporateStructurePercentageNotOneHundred")} arrow>
                                                                <IconButton color='error'>
                                                                    <ErrorOutlineOutlined />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </TableCellBodyStyled>
                                                </TableRow>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
}