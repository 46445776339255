import {
    Avatar,
    DialogContent, DialogTitle,
    List,
    ListItemIcon,
    Toolbar,
    Typography
} from "@mui/material";
import styled from "styled-components";

export const StyledList = styled(List)`
    && {
        padding: 0;
        box-sizing: border-box;
        color: var(--white);

        .MuiListItemIcon-root {
            color: var(--white);
        }
        
        .MuiButtonBase-root.active {
            background-color: rgba(88, 118, 144, 0.2);
            border-radius: 0;
        }
        
        .MuiButtonBase-root {
            padding: 1em 2em;
        }

        .MuiButtonBase-root:hover {
            border-radius: 0;
            background-color: rgba(88, 118, 144, 0.06);
        }
    }
`;

export const ToolbarStyled = styled(Toolbar)`
    && {
        padding: 2rem;
        border-bottom: 1px solid var(--gray-300-withOpacity);
        
        img {
            height: 3.5rem;
        }

        @media(max-width:900px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
`;

export const ToolbarUserInfoStyled = styled(Toolbar)`
    && {
        display: flex;
        align-items: flex-start;
        gap: .5em;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem;
        border-top: 1px solid var(--gray-300-withOpacity);
    }
`;

export const UserNameStyled = styled(Typography)`
    && {
        font-size: .875rem;
        font-weight: 400;
        color: var(--white);
    }
`;

export const Div = styled.div`
    display: flex;
    flex-direction: column;
    gap: .25rem;
`;

export const AvatarStyled = styled(Avatar)`
    && {
        background: var(--brandColor-dark-gray);
        width: 2rem;
        height: 2rem;
        font-size: .75rem;
    }
`;

export const ListItemIconStyled = styled(ListItemIcon)`
    && {
        min-width: 0;
        margin-right: .5rem;
        justify-content: center;

        svg {
            font-size: 1.5rem;
        }
    }
`;

export const DialogTitleStyled = styled(DialogTitle)`
    && {
        font-weight: 700;
        color: var(--gray-900);
    }
`;

export const DialogContentStyled = styled(DialogContent)`
    && {
        font-weight: 400;
        color: var(--gray-600);
    }
`;

export const UserInfo = styled.div`
    display: flex;
    align-items: center;
    text-align: left;
    gap: .5rem;
`;