import React from 'react';
import { ButtonStyled } from "./styles";

interface LoadingButtonProps {
    label: string;
    onClick? : ()=> void  ,
    loading?: boolean,
    type?: "button" | "submit" | "reset";
     form?: string;
    disabled?: boolean ;
    variant?: "text" | "outlined" | "contained";
    fullWidth?:true
}
// Type '{ type: string; label: string; form: string; loading: boolean; }' is not assignable to type 'IntrinsicAttributes & LoadingButtonProps'.
export const LoadingButton: React.FC<LoadingButtonProps> = ({ label, ...rest }) => {
    return (
        <ButtonStyled {...rest}>
            {label}
        </ButtonStyled>
    );
}
