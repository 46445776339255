import { yupResolver } from '@hookform/resolvers/yup';
import {
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Radio,
    RadioGroup,
    Checkbox
} from '@mui/material';
import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { create } from 'react-modal-promise';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { Button } from '../Button';
import { LoadingBox } from '../LoadingBox';
import { LoadingButton } from '../LoadingButton';
import { CurrencyTextField } from '../CurrencyTextField';
import { TextField } from '../TextField';
import {
    FormControlLabelStyled,
    FormHelperTextStyled,
    FormLabelStyled
} from '../../styles/styles';
import { displayErrorMessage } from '../../utils/errorMessage';
import { DialogTitleStyled } from '../DeleteModal/styles';
import { getCorporateDataUpdatePromise, updateCorporatePurposeData } from '../../services/updateService';
import { getCRMIndicateTypePromise } from '../../services/crmIndicateTypeService';
import { getCRMProductPromise } from '../../services/crmProduct';
import { CRMProductSelect } from '../CRMProductSelect';
import { removeSpecialCharactersAndAccents } from '../../utils/stringUtils';
import { IPurposeData } from '../../interfaces/purposeData';

const validationSchema = yup.object({
    partnerTypeId: yup.string().required(),
    foreignExchangeTransaction: yup.string().required(),
    productId: yup.string().required(),
    howMuchCommercial: yup.string()
        .nullable()
        .test({
            name: "validatehowMuchCommercial",
            test: function (value) {
                const partnerTypeId = this.parent.partnerTypeId;
                const productId = this.parent.productId;
                const foreignExchangeTransaction = this.parent.foreignExchangeTransaction;

                if (partnerTypeId === "1" && (productId === "fe49f319-bd70-ed11-81ac-0022481b5e08" || productId === "f2e77675-bd70-ed11-81ac-0022481b5e08")) {
                    if (value === null || value!.trim() === "") return false;
                    const howMuchCommercial = parseInt(value!);
                    return howMuchCommercial > 0

                } else if (partnerTypeId === "2" && foreignExchangeTransaction === "1" && (productId === "fe49f319-bd70-ed11-81ac-0022481b5e08" || productId === "f2e77675-bd70-ed11-81ac-0022481b5e08")) {
                    if (value === null || value!.trim() === "") return false;
                    const howMuchCommercial = parseInt(value!);
                    return howMuchCommercial > 0
                }

                return true;
            }
        }),
    howMuchTourism: yup.string()
        .nullable()
        .test({
            name: "howMuchTourism",
            test: function (value) {
                const partnerTypeId = this.parent.partnerTypeId;
                const productId = this.parent.productId;
                const foreignExchangeTransaction = this.parent.foreignExchangeTransaction;

                if (partnerTypeId === "1" && (productId === "01a5563e-bd70-ed11-81ac-0022481b5e08" || productId === "f2e77675-bd70-ed11-81ac-0022481b5e08")) {
                    if (value === null || value!.trim() === "") return false;
                    const howMuchTourism = parseInt(value!);
                    return howMuchTourism > 0
                } else if (partnerTypeId === "2" && foreignExchangeTransaction === "1" && (productId === "01a5563e-bd70-ed11-81ac-0022481b5e08" || productId === "f2e77675-bd70-ed11-81ac-0022481b5e08")) {
                    if (value === null || value!.trim() === "") return false;
                    const howMuchTourism = parseInt(value!);
                    return howMuchTourism > 0
                }

                return true;
            }
        }),
    cardQuantity: yup.string()
        .nullable()
        .test({
            name: "cardQuantity",
            message: "validations.quantityBetween1And100",
            test: function (value) {
                const partnerTypeId = this.parent.partnerTypeId;
                const productId = this.parent.productId;
                const foreignExchangeTransaction = this.parent.foreignExchangeTransaction;

                if (partnerTypeId === "1" && (productId === "01a5563e-bd70-ed11-81ac-0022481b5e08" || productId === "f2e77675-bd70-ed11-81ac-0022481b5e08")) {
                    if (value === null || value!.trim() === "") return false;
                    const quantity = parseInt(value!);
                    return quantity >= 1 && quantity <= 100;
                } else if (partnerTypeId === "2" && foreignExchangeTransaction === "1" && (productId === "01a5563e-bd70-ed11-81ac-0022481b5e08" || productId === "f2e77675-bd70-ed11-81ac-0022481b5e08")) {
                    if (value === null || value!.trim() === "") return false;
                    const quantity = parseInt(value!);
                    return quantity >= 1 && quantity <= 100;
                }

                return true;
            }
        }),
    indications: yup.array()
        .when("partnerTypeId", {
            is: "2",
            then: yup.array().min(1, "validations.selectOneOption"),
        }),
    others: yup.string()
        .when('indications', {
            is: (val: string | string[]) => val.includes('6'),
            then: yup.string().required(),
        }),
    legalNature: yup.string(),
});


interface EditCorporateContactReasonModalProps {
    open: boolean;
    onResolve: () => void;
    onReject: () => void;
}

const EditCorporateContactReasonModal = ({ open, onResolve, onReject } : EditCorporateContactReasonModalProps ) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [indicationOptions, setIndicationOptions] = useState<any[]>([]);
    const [productOptions, setProductOptions] = useState([]);

    const { control, handleSubmit, formState: { errors }, reset } = useForm<IPurposeData>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            partnerTypeId: "1",
            foreignExchangeTransaction: "1",
            productId: "fe49f319-bd70-ed11-81ac-0022481b5e08",
            howMuchCommercial: "",
            howMuchTourism: "",
            cardQuantity: "",
            indications: [],
            legalNature: "",
        }
    });

    const productId = useWatch({
        control,
        name: "productId"
    });

    const partnerTypeId = useWatch({
        control,
        name: "partnerTypeId"
    });

    const foreignExchangeTransaction = useWatch({
        control,
        name: "foreignExchangeTransaction"
    });

    const indications = useWatch({
        control,
        name: "indications"
    });

    useEffect(() => {
        async function fetchContactReason() {
            try {
                const [productOptions, indicationOptions, contactReason] = await Promise.all([
                    getCRMProductPromise(),
                    getCRMIndicateTypePromise(),
                    getCorporateDataUpdatePromise()
                ]);

                setProductOptions(productOptions.data);
                setIndicationOptions(indicationOptions.data);
                reset(contactReason.data);

                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }

        fetchContactReason();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (data: IPurposeData) => {

        setSubmitting(true);
        try {
            await updateCorporatePurposeData(data);
            toast.success(`${t("text.toast.successEdit")}`);
            onResolve();
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            scroll="paper"
            fullWidth
        >
            {loading
                ? <LoadingBox />
                : <React.Fragment>
                    <DialogTitleStyled>
                        {t("text.contactReason")}
                    </DialogTitleStyled>
                    <DialogContent dividers>
                        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
                            <fieldset disabled={submitting} style={{ border: 0 }}>
                                <Grid
                                    container
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >

                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        sx={{
                                            background: 'var(--white)',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        <Grid
                                            container
                                            spacing={3}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <Grid
                                                    container
                                                    spacing={2}
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <FormControl>
                                                            <FormLabelStyled>
                                                                {t("text.whatDoYouWant")}
                                                            </FormLabelStyled>
                                                            <Controller
                                                                control={control}
                                                                name="partnerTypeId"
                                                                render={({ field }) => {
                                                                    return (
                                                                        <RadioGroup row {...field}>
                                                                            <FormControlLabelStyled
                                                                                value="1"
                                                                                control={<Radio />}
                                                                                label={t("text.closeOperations")}
                                                                            />
                                                                            <FormControlLabelStyled
                                                                                value="2"
                                                                                control={<Radio />}
                                                                                label={t("text.beAMoneycorpPartner")}
                                                                            />
                                                                        </RadioGroup>
                                                                    );
                                                                }}
                                                            />
                                                        </FormControl>
                                                        {errors.partnerTypeId && (
                                                            <FormHelperTextStyled>
                                                                {displayErrorMessage(errors.partnerTypeId?.message) as string}
                                                            </FormHelperTextStyled>
                                                        )}
                                                    </Grid>

                                                    {partnerTypeId === "2" && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <FormControl>
                                                                <FormLabelStyled>
                                                                    {t("text.yourCompanyNeedsToCloseOperationsInYourName")}
                                                                </FormLabelStyled>
                                                                <Controller
                                                                    control={control}
                                                                    name="foreignExchangeTransaction"
                                                                    render={({ field }) => {
                                                                        return (
                                                                            <RadioGroup row {...field}>
                                                                                <FormControlLabelStyled
                                                                                    value="1"
                                                                                    control={<Radio />}
                                                                                    label={t("text.yes")}
                                                                                />
                                                                                <FormControlLabelStyled
                                                                                    value="0"
                                                                                    control={<Radio />}
                                                                                    label={t("text.no")}
                                                                                />
                                                                            </RadioGroup>
                                                                        );
                                                                    }}
                                                                />
                                                            </FormControl>
                                                            {errors.foreignExchangeTransaction && (
                                                                <FormHelperTextStyled>
                                                                    {displayErrorMessage(errors.foreignExchangeTransaction?.message) as string}
                                                                </FormHelperTextStyled>
                                                            )}
                                                        </Grid>
                                                    )}

                                                    {(partnerTypeId === "1" || foreignExchangeTransaction === "1") && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <Grid
                                                                container
                                                                spacing={1}
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={12}
                                                                    lg={12}
                                                                    xl={12}
                                                                >
                                                                    <CRMProductSelect
                                                                        control={control}
                                                                        name="productId"
                                                                        products={productOptions}
                                                                    />
                                                                    {errors.productId && (
                                                                        <FormHelperTextStyled>
                                                                            {displayErrorMessage(errors.productId?.message) as string}
                                                                        </FormHelperTextStyled>
                                                                    )}
                                                                </Grid>
                                                                {(productId === "fe49f319-bd70-ed11-81ac-0022481b5e08" || productId === "f2e77675-bd70-ed11-81ac-0022481b5e08") && (
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={6}
                                                                        lg={6}
                                                                        xl={6}
                                                                    >
                                                                        <Controller
                                                                            control={control}
                                                                            name="howMuchCommercial"
                                                                            render={({ field }) => (
                                                                                <CurrencyTextField
                                                                                    label={t("text.howMuchYourCompanyIntendToOperateCommercialExchange")}
                                                                                    currencySymbol="R$"
                                                                                    decimalCharacter=","
                                                                                    digitGroupSeparator="."
                                                                                    variant="outlined"
                                                                                    value={field.value}
                                                                                    onChange={(e:any, v:any) => {
                                                                                        return field.onChange(v);
                                                                                    }}
                                                                                    inputRef={(ref: any) => {
                                                                                        return field.ref({
                                                                                            focus: () => {
                                                                                                ref!.domElement.focus();
                                                                                            }
                                                                                        });
                                                                                    }}
                                                                                    placeholder="0,00"
                                                                                    fullWidth
                                                                                    error={!!errors.howMuchCommercial}
                                                                                    helperText={displayErrorMessage(errors.howMuchCommercial?.message) as string}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                )}
                                                                {(productId === "01a5563e-bd70-ed11-81ac-0022481b5e08" || productId === "f2e77675-bd70-ed11-81ac-0022481b5e08") && (
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={6}
                                                                        lg={6}
                                                                        xl={6}
                                                                    >
                                                                        <Controller
                                                                            control={control}
                                                                            name="howMuchTourism"
                                                                            render={({ field }) => (
                                                                                <CurrencyTextField
                                                                                    label={t("text.howMuchYourCompanyIntendToOperateTourismExchange")}
                                                                                    currencySymbol="R$"
                                                                                    decimalCharacter=","
                                                                                    digitGroupSeparator="."
                                                                                    variant="outlined"
                                                                                    value={field.value}
                                                                                    onChange={(e:any, v:any) => {
                                                                                        field.onChange(v);
                                                                                    }}
                                                                                    inputRef={(ref: { domElement: { focus: () => void; }; }) => {
                                                                                        field.ref({
                                                                                            focus: () => {
                                                                                                ref.domElement.focus();
                                                                                            }
                                                                                        });
                                                                                    }}
                                                                                    placeholder="0,00"
                                                                                    fullWidth
                                                                                    error={!!errors.howMuchTourism}
                                                                                    helperText={displayErrorMessage(errors.howMuchTourism?.message) as string}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                )}
                                                                {(productId === "01a5563e-bd70-ed11-81ac-0022481b5e08" || productId === "f2e77675-bd70-ed11-81ac-0022481b5e08") && (
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={6}
                                                                        lg={6}
                                                                        xl={6}
                                                                    >
                                                                        <Controller
                                                                            control={control}
                                                                            name="cardQuantity"
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    value={field.value}
                                                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                                                    inputRef={field.ref}
                                                                                    label={t("text.howManyCardsIn12Months")}
                                                                                    placeholder="Ex. 10"
                                                                                    fullWidth
                                                                                    error={!!errors.cardQuantity}
                                                                                    helperText={displayErrorMessage(errors.cardQuantity?.message) as string}
                                                                                    type="number"
                                                                                    variant="outlined"
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                    {partnerTypeId === "2" && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <Grid
                                                                container
                                                                spacing={1}
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={12}
                                                                    lg={12}
                                                                    xl={12}
                                                                >
                                                                    <FormGroup>
                                                                        <FormLabelStyled>
                                                                            {t("text.whatKindOfCustomer")}
                                                                        </FormLabelStyled>
                                                                        <Controller
                                                                            name="indications"
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <React.Fragment>
                                                                                    {indicationOptions.map((indicationOption) => (
                                                                                        <FormControlLabel
                                                                                            key={indicationOption.id}
                                                                                            label={indicationOption.description}
                                                                                            control={
                                                                                                <Checkbox
                                                                                                    value={indicationOption.id}
                                                                                                    checked={field.value.some(
                                                                                                        (existingValue: any) => existingValue === indicationOption.id
                                                                                                    )}
                                                                                                    onChange={(event, checked) => {
                                                                                                        if (checked) {
                                                                                                            field.onChange([
                                                                                                                ...field.value,
                                                                                                                event.target.value
                                                                                                            ]);
                                                                                                        } else {
                                                                                                            field.onChange(
                                                                                                                field.value.filter(
                                                                                                                    (value: any) => value !== event.target.value
                                                                                                                )
                                                                                                            );
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                        />
                                                                                    ))}
                                                                                </React.Fragment>
                                                                            )}
                                                                        />
                                                                    </FormGroup>
                                                                    {errors.indications && <FormHelperTextStyled>{displayErrorMessage(errors.indications?.message) as string}</FormHelperTextStyled>}
                                                                </Grid>
                                                                {indications.includes('120240005')
                                                                    ? <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={12}
                                                                        lg={12}
                                                                        xl={12}
                                                                    >
                                                                        <Controller
                                                                            control={control}
                                                                            name="others"
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    value={field.value}
                                                                                    onChange={field.onChange}
                                                                                    inputRef={field.ref}
                                                                                    label={t("text.others")}
                                                                                    placeholder={t("text.others") as string}
                                                                                    inputProps={{ maxLength: 100 }}
                                                                                    fullWidth
                                                                                    error={!!errors.others}
                                                                                    helperText={displayErrorMessage(errors.others?.message) as string }
                                                                                    variant="outlined"
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                    : null
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                </Grid>
                            </fieldset>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={submitting}
                            onClick={onReject}
                            label={t("text.cancel")}
                        />
                        <LoadingButton
                            type="submit"
                            label={t("text.saveChanges")}
                            form="submit-form"
                            loading={submitting}
                        />
                    </DialogActions>
                </React.Fragment>
            }
        </Dialog>
    );
}

export const editCorporateContactReasonModal = create(EditCorporateContactReasonModal);
