import React, { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Box } from '@mui/material';
import LogoMoneycorp from '../../assets/image/moneycorp.svg';

export function NotFound() {
    const navigate = useNavigate();

    useLayoutEffect(() => {
        document.body.style.background = "var(--background)"
    });

    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
        }}>
            <Box sx={{
                width: "960px",
                height: "480px",
                p: 4,
            }}>
                <img
                    style={{
                        maxWidth: "340px",
                        height: "auto"
                    }}
                    src={LogoMoneycorp}
                    alt="Moneycorp banco de câmbio"
                />
                <Box sx={{ m: 6 }} />
                <h1>Página não encontrada.</h1>
                <Box sx={{ m: 2 }} />
                <Button
                    label="Retornar à página inicial"
                    variant="contained"
                    onClick={() => navigate('/', { replace: true })}
                />
            </Box>
        </Box>
    );
}
