import {
    Grid,
    Step,
    StepButton,
    Stepper
} from '@mui/material';
import React from 'react';
import i18n from 'i18next';
import { useNavigate } from 'react-router-dom';


const getSteps = (register: boolean, legalNature: any) => {
    return legalNature === "120240004"
        ? [
            {
                label: i18n.t("text.contactReason"),
                link: register ? '/onboarding/contact-reason-legal-entity-register' : '/dashboard/contact-reason-legal-entity',
            },
            {
                label: i18n.t("text.overallData"),
                link: register ? '/onboarding/overall-data-legal-entity-register' : '/dashboard/overall-data-legal-entity',
            },
            {
                label: i18n.t("text.address"),
                link: register ? '/onboarding/address-legal-entity-register' : '/dashboard/address-legal-entity',
            },
            {
                label: i18n.t("text.bankInformation"),
                link: register ? '/onboarding/banking-references-legal-entity-register' : '/dashboard/banking-references-legal-entity',
            },
            {
                label: i18n.t("text.administration"),
                link: register ? '/onboarding/administration-legal-entity-register' : '/dashboard/administration-legal-entity',
            },
            {
                label: i18n.t("text.financialSituation"),
                link: register ? '/onboarding/financial-data-legal-entity-register' : '/dashboard/financial-data-legal-entity',
            },
            {
                label: i18n.t("text.summary"),
                link: register ? '/onboarding/resume-legal-entity-register' : '/dashboard/resume-legal-entity',
            },
        ]
        : [
            {
                label: i18n.t("text.contactReason"),
                link: register ? '/onboarding/contact-reason-legal-entity-register' : '/dashboard/contact-reason-legal-entity',
            },
            {
                label: i18n.t("text.overallData"),
                link: register ? '/onboarding/overall-data-legal-entity-register' : '/dashboard/overall-data-legal-entity',
            },
            {
                label: i18n.t("text.address"),
                link: register ? '/onboarding/address-legal-entity-register' : '/dashboard/address-legal-entity',
            },
            {
                label: i18n.t("text.bankInformation"),
                link: register ? '/onboarding/banking-references-legal-entity-register' : '/dashboard/banking-references-legal-entity',
            },
            {
                label: i18n.t("text.administration"),
                link: register ? '/onboarding/administration-legal-entity-register' : '/dashboard/administration-legal-entity',
            },
            {
                label: i18n.t("text.corporateStructure"),
                link: register ? '/onboarding/corporate-structure-legal-entity-register' : '/dashboard/corporate-structure-legal-entity',
            },
            {
                label: i18n.t("text.financialSituation"),
                link: register ? '/onboarding/financial-data-legal-entity-register' : '/dashboard/financial-data-legal-entity',
            },
            {
                label: i18n.t("text.summary"),
                link: register ? '/onboarding/resume-legal-entity-register' : '/dashboard/resume-legal-entity',
            },
        ];
}

interface LegalEntityStepperProps {
    activeStep: number;
    register?: boolean;
    legalNature?: any;
}

export const LegalEntityStepper = ({ activeStep, register = true, legalNature }: LegalEntityStepperProps) => {

    const steps = getSteps(register, legalNature);
    const navigate = useNavigate()

    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
                padding: '2rem 0rem 1.5rem 0rem',
                position: 'sticky',
                top: '0',
                backgroundColor: '#f7f7f7',
                zIndex: '999',
            }}
        >
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step) => (
                    <Step key={step.label}>
                        <StepButton color="inherit" onClick={() => navigate(step.link)}>
                            {step.label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        </Grid>
    )
}
