import { IPartners } from "../interfaces/partners";
import api from "./api";

export const getCorporateStructurePromise = async () => api.get(`/api/client-corporate-structure/client`, { withCredentials: true });

export const getCorporateStructure = async () => {
    return await getCorporateStructurePromise();
}

export const getCorporateStructureByIdPromise = async (id:string) => api.get(`/api/client-corporate-structure/${id}`, { withCredentials: true });

export const getCorporateStructureById = async (id:string) => {
    return await getCorporateStructureByIdPromise(id);
}

export const saveCorporateStructure = async (data:IPartners) => {
    return await api.post(`/api/client-corporate-structure`, data, { withCredentials: true });
}

export const updateCorporateStructure = async (id:string, data:IPartners) => {
    return await api.patch(`/api/client-corporate-structure/${id}`, data, { withCredentials: true });
}

export const deleteCorporateStructure = async (id:string) => {
    return await api.delete(`/api/client-corporate-structure/${id}`, { withCredentials: true });
}

export const updateCorporateSructureStep = async () => {
    return await api.post(`/api/client-corporate-structure/step`, {}, { withCredentials: true });
}
