import {
    Box,
    CircularProgress
} from '@mui/material';
import React from "react";

export const LoadingBox = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100vh',
                // padding: (theme) => theme.spacing(2)
            }}
        >
            <CircularProgress size={30} />
        </Box>
    )
}
