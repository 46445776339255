import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';

export const ButtonStyled = styled(LoadingButton)`
    && {
        height: 2.75rem;
        text-transform: uppercase;
        letter-spacing: .02rem;
        font-weight: 700;
        font-size: .875rem;
        text-align: center;
    }
`;
