import { IAddress } from "../interfaces/address";
import { IAttorney } from "../interfaces/attorney";
import { ICorporateFinancial } from "../interfaces/corporateFinancial";
import { IIncomeAndPowerOfAttorney } from "../interfaces/income";
import { IOverallData } from "../interfaces/overallData";
import { IPurposeData } from "../interfaces/purposeData";
import api from "./api";

export const getPersonalDataUpdatePromise = (language?: string) => api.get(`/api/update`, { withCredentials: true, headers: { 'x-language': language! } });

export const getCorporateDataUpdatePromise = () => api.get(`/api/update/corporate`, { withCredentials: true });

export const getPersonalDataUpdate = async (language?: string) => {
    return await getPersonalDataUpdatePromise(language);
}

export const getUpdatePrivateResume = async (language?: string) => {
    return await api.get(`/api/update/resume`, { withCredentials: true, headers: { 'x-language': language! } });
}

export const getUpdateCorporateResume = async (language: string) => {
    return await api.get(`/api/update/corporate/resume`, { withCredentials: true, headers: { 'x-language': language } });
}

export const getCorporateDataUpdate = async () => {
    return await getCorporateDataUpdatePromise();
}

export const updatePurposeData = async (data: IPurposeData) => {
    return await api.put(`/api/update/purpose-data`, data, { withCredentials: true });
}

export const updateCorporatePurposeData = async (data: IPurposeData) => {
    return await api.put(`/api/update/corporate/purpose-data`, data, { withCredentials: true });
}

export const updatePersonalData = async (data: any) => {
    return await api.put(`/api/update/personal-data`, data, { withCredentials: true });
}

export const updateOverallData = async (data: IOverallData) => {
    return await api.put(`/api/update/corporate/overall-data`, data, { withCredentials: true });
}

export const updateAddress = async (data: IAddress) => {
    return await api.put(`/api/update/address-data`, data, { withCredentials: true });
}

export const updateCorporateAddress = async (data: IAddress) => {
    return await api.put(`/api/update/corporate/address-data`, data, { withCredentials: true });
}

export const updateIncome = async (data: IIncomeAndPowerOfAttorney) => {
    return await api.put(`/api/update/income-data`, data, { withCredentials: true });
}

export const updateCorporateFinancial = async (data: ICorporateFinancial) => {
    return await api.put(`/api/update/corporate/financial-data`, data, { withCredentials: true });
}

export const updateResume = async (data: any) => {
    return await api.put(`/api/update/resume-data`, data, { withCredentials: true });
}

export const getRegistrationUpdateBankDataByIdPromise = async (id: string) => api.get(`/api/update/bank/${id}`, { withCredentials: true });
export const getRegistrationUpdateBankDataById = async (id: string) => {
    return await getRegistrationUpdateBankDataByIdPromise(id);
}

export const registrationUpdateBankDataAdd = async (data: any) => {
    return await api.post(`/api/update/bank`, data, { withCredentials: true });
}

export const registrationUpdateBankDataUpdate = async (id: string, data: any) => {
    return await api.patch(`/api/update/bank/${id}`, data, { withCredentials: true });
}

export const registrationUpdateBankDataDelete = async (id: string) => {
    return await api.delete(`/api/update/bank/${id}`, { withCredentials: true });
}

export const getRegistrationUpdateAttorneyByIdPromise = async (id: string) => api.get(`/api/update/attorney/${id}`, { withCredentials: true });
export const getRegistrationUpdateAttorneyById = async (id: string) => {
    return await getRegistrationUpdateAttorneyByIdPromise(id);
}

export const registrationUpdateAttorneyAdd = async (data: any) => {
    return await api.post(`/api/update/attorney`, data, { withCredentials: true });
}

export const registrationUpdateAttorneyUpdate = async (id: string, data: IAttorney) => {
    return await api.patch(`/api/update/attorney/${id}`, data, { withCredentials: true });
}

export const registrationUpdateAttorneyDelete = async (id: string) => {
    return await api.delete(`/api/update/attorney/${id}`, { withCredentials: true });
}

export const getRegistrationUpdateCompanyAssociateByIdPromise = async (id: string) => api.get(`/api/update/company-associate/${id}`, { withCredentials: true });
export const getRegistrationUpdateCompanyAssociateById = async (id: string) => {
    return await getRegistrationUpdateCompanyAssociateByIdPromise(id);
}

export const registrationUpdateCompanyAssociateAdd = async (data: any) => {
    return await api.post(`/api/update/company-associate`, data, { withCredentials: true });
}

export const registrationUpdateCompanyAssociateUpdate = async (id: string, data: any) => {
    return await api.patch(`/api/update/company-associate/${id}`, data, { withCredentials: true });
}

export const registrationUpdateCompanyAssociateDelete = async (id: string) => {
    return await api.delete(`/api/update/company-associate/${id}`, { withCredentials: true });
}

export const getDocumentsToUpdatePromise = (language: string) => api.get(`/api/update/documents-to-update`, { withCredentials: true, headers: { 'x-language': language } });

export const getDocumentsToUpdate = async (language: string) => {
    return await getDocumentsToUpdatePromise(language);
}

export const getDocumentsToUpdateById = async (id: string) => {
    return await api.get(`/api/update/documents-to-update/${id}`, { withCredentials: true });
}

export const documentToUpdateSubmission = async (data: any) => {
    return await api.post(`/api/update/documents-to-update`, data, { withCredentials: true });
}
