import { isValidCPF } from '@brazilian-utils/brazilian-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Card,
    CardActions,
    Checkbox,
    Grid,
    Typography
} from '@mui/material';
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import * as yup from "yup";
import { Button } from '../../../components/Button';
import { CRMBankAccountTypeOptions } from '../../../components/CRMBankAccountTypeOptions';
import { CRMBankOptions } from '../../../components/CRMBankOptions';
import Document from '../../../components/Document';
import { LoadingBox } from '../../../components/LoadingBox';
import { LoadingButton } from '../../../components/LoadingButton';
import { OnboardingStepperRegister } from '../../../components/OnboardingStepperRegister';
import { PageName } from '../../../components/PageName';
import { TextField } from '../../../components/TextField';
import { AuthContext } from "../../../contexts/auth";
import { getBankAccountTypesPromise } from '../../../services/bankAccountTypeService';
import { getFirstBankDetailPromise, saveBankDetail } from '../../../services/bankDetailService';
import { getBanksPromise } from '../../../services/bankService';
import {
    CardHeaderTitleStyled,
    FormControlLabelStyled,
    FormHelperTextStyled,
} from '../../../styles/styles';
import { displayErrorMessage } from "../../../utils/errorMessage";
import { toBase64 } from '../../../utils/fileUtils';
import * as masks from "../../../utils/masks";
import { removeSpecialCharactersAndAccents } from "../../../utils/stringUtils";

const validationSchema = yup.object({
    estimatedValueId: yup.string().required(),
    id: yup.string(),
    accountTypeId: yup.string().required(),
    bankId: yup.string().required(),
    agency: yup.string()
        .required()
        .matches(/^[0-9-]*$/, "validations.matchesNumbersAndDashes"),
    accountNumber: yup.string()
        .required()
        .matches(/^[0-9-]*$/, "validations.matchesNumbersAndDashes"),
    jointAccount: yup.string(),
    accountHolderName: yup.string()
        .when("jointAccount", {
            is: "true",
            then: yup.string().required(),
        }),
    accountHolderDocument: yup.string()
        .when("jointAccount", {
            is: "true",
            then: yup.string()
                .required()
                .transform(masks.cpfMask.transform)
                .test("validateCPF", (value) => {
                    return isValidCPF(value!)
                }),
        }),
    jointAccountProof: yup.mixed()
        .when("jointAccount", {
            is: "true",
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                })
        }),
});

export function BankingReferencesRegister() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { handleSignOut } = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [bankOptions, setBankOptions] = useState([]);
    const [bankAccountTypeOptions, setBankAccountTypeOptions] = useState([]);
    const [uploading, setUploading] = useState<boolean>(false);

    const { control, handleSubmit, formState: { errors }, reset, register, getValues } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            estimatedValueId: "",
            id: "",
            accountTypeId: "",
            code: "",
            agency: "",
            name: "",
            accountNumber: "",
            jointAccount: false,
            accountHolderName: "",
            accountHolderDocument: "",
            jointAccountProof: "",
            bankId:"",
        }
    });

    useEffect(() => {
        async function fetchBankReferences() {
            try {
                const [bankAccountTypeOptions, bankOptions, firstBankDetail] = await Promise.all([
                    getBankAccountTypesPromise(),
                    getBanksPromise(),
                    getFirstBankDetailPromise()
                ]);


                setBankAccountTypeOptions(bankAccountTypeOptions.data);
                setBankOptions(bankOptions.data)

                var jointAccountProof:any;
                if (firstBankDetail.data.jointAccount) {
                    jointAccountProof = new File(["jointAccountProof"], firstBankDetail.data.fileOriginalName, {
                        type: firstBankDetail.data.fileType,
                    });
                    jointAccountProof.id = firstBankDetail.data.id;
                }

                const values = {
                    ...firstBankDetail.data,
                    accountHolderName: firstBankDetail.data.accountHolderName,
                    accountHolderDocument: masks.cpfMask.maskDefault(firstBankDetail.data.accountHolderDocument),
                    jointAccountProof: jointAccountProof ? [].concat(jointAccountProof) : "",
                }

                reset(values);
                setLoading(false);
            } catch (error:any) {
                const errorCode = error.response?.data?.errorCode;
                if (errorCode === "12000") {
                    navigate('/dashboard');
                }
            }
        }

        fetchBankReferences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = async (data:any) => {
        setSubmitting(true);
        try {
            const jointAccountProof = data.jointAccountProof[0];
            const jointAccount = data.jointAccount === "true";
            const hasJointAccountProofId = !!jointAccountProof?.id;
            const shouldSendFile = jointAccount && !hasJointAccountProofId;
            if (shouldSendFile) {
                const result = await toBase64(jointAccountProof);
                data.fileOriginalName = jointAccountProof.name;
                data.fileInBase64 = result;
                data.fileSize = jointAccountProof.size;
                data.fileType = jointAccountProof.type;
            }
            delete data.jointAccountProof;

            await saveBankDetail(data);

            navigate(
                estimatedValueId === "1"
                    ? `/onboarding/resume-register`
                    : `/onboarding/income-powerOfAttorney-register`
            )

        } catch (error:any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    const jointAccount = useWatch({
        control,
        name: "jointAccount",
    });

    const estimatedValueId = useWatch({
        control,
        name: "estimatedValueId"
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '2rem',
                }}
            >
                {loading
                    ? <LoadingBox />
                    : <React.Fragment>
                        <OnboardingStepperRegister
                            estimatedValueId={estimatedValueId}
                            activeStep={3}
                        />
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={10}
                            xl={10}
                            sx={{
                                background: 'var(--white)',
                                borderRadius: '4px',
                                padding: '2rem',
                                '@media(max-width: 899px)': {
                                    padding: '0rem',
                                }
                            }}
                        >
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <PageName
                                        title={t("text.bankInformation")}
                                        info={t("text.toProceedWithTheRegistrationPleaseFillInTheInformationBelow")}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '1rem'
                                                    }}
                                                >
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={3}
                                                    xl={3}
                                                >
                                                    <CRMBankAccountTypeOptions
                                                        name="accountTypeId"
                                                        control={control}
                                                        bankAccountTypes={bankAccountTypeOptions}
                                                        error={!!errors.accountTypeId}
                                                    />
                                                    <FormHelperTextStyled>
                                                        {displayErrorMessage(errors.accountTypeId?.message) as string}
                                                    </FormHelperTextStyled>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={3}
                                                    xl={3}
                                                >
                                                    <CRMBankOptions
                                                        name="bankId"
                                                        control={control}
                                                        banks={bankOptions}
                                                        error={!!errors.bankId}
                                                    />
                                                    <FormHelperTextStyled>
                                                        {displayErrorMessage(errors.bankId?.message) as string}
                                                    </FormHelperTextStyled>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={2}
                                                    xl={2}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="agency"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => {
                                                                    const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                                    field.onChange(cleanedValue);
                                                                }}
                                                                inputRef={field.ref}
                                                                label={t("text.branch")}
                                                                placeholder={t("text.branch") as string}
                                                                fullWidth
                                                                error={!!errors?.agency}
                                                                helperText={displayErrorMessage(errors.agency?.message) as string}
                                                                variant='outlined'
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={2}
                                                    xl={2}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="accountNumber"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => {
                                                                    const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                                    field.onChange(cleanedValue);
                                                                }}
                                                                inputRef={field.ref}
                                                                label={t("text.account")}
                                                                placeholder={t("text.account") as string}
                                                                fullWidth
                                                                error={!!errors?.accountNumber}
                                                                helperText={displayErrorMessage(errors.accountNumber?.message) as string}
                                                                variant='outlined'
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={2}
                                                    xl={2}
                                                    sx={{
                                                        marginTop: '1.70rem',
                                                        '@media(max-width: 1200px)': {
                                                            marginTop: '0',
                                                        }
                                                    }}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="jointAccount"
                                                        render={({ field: { value, onChange, ref } }) => (
                                                            <FormControlLabelStyled
                                                                control={
                                                                    <Checkbox
                                                                        checked={value}
                                                                        onChange={onChange}
                                                                        inputRef={ref}
                                                                        disabled={uploading}
                                                                    />
                                                                }
                                                                label={t("text.isJointAccount")}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                {jointAccount && (
                                                    <>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={9}
                                                            xl={9}
                                                        >
                                                            <Controller
                                                                control={control}
                                                                name="accountHolderName"
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        value={field.value}
                                                                        onChange={(e) => {
                                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                                            field.onChange(cleanedValue);
                                                                        }}
                                                                        inputRef={field.ref}
                                                                        label={t("text.fullAccountHolderName")}
                                                                        placeholder={t("text.fullAccountHolderName") as string}
                                                                        fullWidth
                                                                        error={!!errors?.accountHolderName}
                                                                        helperText={displayErrorMessage(errors?.accountHolderName?.message) as string}
                                                                        variant='outlined'
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={3}
                                                            xl={3}
                                                        >
                                                            <Controller
                                                                control={control}
                                                                name="accountHolderDocument"
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        value={field.value}
                                                                        onChange={(e) => {
                                                                            e.persist();
                                                                            masks.cpfMask.onChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        inputRef={field.ref}
                                                                        label={t("text.accountHolderCPF")}
                                                                        placeholder={t("text.accountHolderCPF") as string}
                                                                        fullWidth
                                                                        error={!!errors?.accountHolderDocument}
                                                                        helperText={displayErrorMessage(errors?.accountHolderDocument?.message) as string}
                                                                        variant='outlined'
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <Card>
                                                                <CardHeaderTitleStyled
                                                                    title={t("text.jointAccountConfirmation")}
                                                                    subheader={t("text.copyOfCheckStatementCardEtc")}
                                                                />
                                                                <Document
                                                                    title={t("text.add")}
                                                                    register={register}
                                                                    getValues={getValues}
                                                                    name="jointAccountProof"
                                                                    uploading={uploading}
                                                                    setUploading={setUploading}
                                                                />
                                                                <CardActions>
                                                                    <Typography variant="caption" display="block">{t("text.allowedFileTypesAndSize")}</Typography>
                                                                </CardActions>
                                                            </Card>
                                                            <FormHelperTextStyled>
                                                                {displayErrorMessage(errors?.jointAccountProof?.message) as string}
                                                            </FormHelperTextStyled>
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <Button
                                                variant="outlined"
                                                label={t("text.previousStep")}
                                                component={Link}
                                                to="/onboarding/address-register"
                                                disabled={submitting || uploading}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                label={t("text.nextStep")}
                                                fullWidth
                                                loading={submitting || uploading}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Button
                                                variant="text"
                                                label={t("text.continueLater")}
                                                onClick={handleSignOut}
                                                disabled={submitting || uploading}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        </form>
    );
}
