import { formatCEP, formatCPF } from '@brazilian-utils/brazilian-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Alert,
    AlertTitle,
    Checkbox,
    Divider,
    Grid
} from '@mui/material';
import { format, parse } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import { toast } from "react-toastify";
import * as yup from "yup";
import { Button } from '../../../components/Button';
import { LoadingBox } from '../../../components/LoadingBox';
import { LoadingButton } from '../../../components/LoadingButton';
import { OnboardingStepperRegister } from '../../../components/OnboardingStepperRegister';
import { PageName } from '../../../components/PageName';
import { AuthContext } from "../../../contexts/auth";
import { getDetail, updateDetail } from '../../../services/detailService';
import {
    FormControlLabelStyled,
    FormHelperTextStyled,
    InfoDescription,
    InfoTitle,
    LinkCheckbox,
    SubtitlePageForm
} from '../../../styles/styles';
import { displayErrorMessage } from "../../../utils/errorMessage";
import * as masks from "../../../utils/masks";
import { termsAndAgreementsModal } from "../../../components/TermsAndAgreementsModal";
import { IClient } from '../../../interfaces/client';

const validationSchema = yup.object({
    lgpd: yup.string().oneOf(['true'], "validations.requiredAgree"),
    trueInformation: yup.string().oneOf(['true'], "validations.requiredAgree"),
    authorizedProvide: yup.string().oneOf(['true'], "validations.requiredAgree"),
    jointResolution: yup.string().oneOf(['true'], "validations.requiredAgree"),
});

export function ResumeRegister() {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState<boolean>( false);
    const [loading, setLoading] = useState<boolean>(true);
    const { handleSignOut } = useContext(AuthContext);
    const [client, setClient] = useState<IClient | null >();

    useEffect(() => {
        async function fetchDetail() {
            try {
                const response = await getDetail(i18n.language);
                setClient(response.data);
                setLoading(false);
            } catch (error:any) {
                const errorCode = error.response?.data?.errorCode;
                if (errorCode === "12000") {
                    navigate('/dashboard');
                }
            }
        }

        fetchDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            lgpd: false,
            trueInformation: true,
            authorizedProvide: true,
            jointResolution: false,
        }
    });

    const onSubmit = async (data : any) => {
        setSubmitting(true);
        try {
            await updateDetail(data);
            navigate('/onboarding/order', { state: { canVisit: true } });
        } catch (error:any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    };

    const getMessage = () => {
        const productName = client!.productName;
        const operationEstimatedValueName = client!.estimatedValueId === "1"
            ? t("text.upToValue")
            : t("text.aboveValue");
        return t("text.summaryProfileClaim", {
            product_name: t(productName),
            operation_estimated_value_name: operationEstimatedValueName,
        });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '2rem',
                }}
            >
                {loading
                    ? <LoadingBox />
                    : <React.Fragment>
                        <OnboardingStepperRegister
                            estimatedValueId={client!.estimatedValueId}
                            activeStep={client!.estimatedValueId === "1" ? 4 : 5}
                        />
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={10}
                            xl={10}
                            sx={{
                                background: 'var(--white)',
                                borderRadius: '4px',
                                padding: '2rem',
                                '@media(max-width: 899px)': {
                                    padding: '0rem',
                                }
                            }}
                        >
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <PageName
                                        title={t("text.summary")}
                                        info={t("text.summaryInfo")}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={3}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <SubtitlePageForm>
                                                                {t("text.profile")}
                                                            </SubtitlePageForm>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <Divider />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >

                                                    <Alert severity="info">
                                                        <AlertTitle>
                                                            {getMessage()}
                                                        </AlertTitle>
                                                        {t("text.summaryProfileInfo")}
                                                    </Alert>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <SubtitlePageForm>
                                                                {t("text.personalData")}
                                                            </SubtitlePageForm>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <Divider />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.cpf")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoDescription>{formatCPF(client!.document)}</InfoDescription>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.fullName")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoDescription>{client!.name}</InfoDescription>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.dob")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    {<InfoDescription>{masks.dateMask.maskDefault(client!.dateOfBirth)}</InfoDescription>}
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.fullMotherName")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoDescription>{client!.motherName}</InfoDescription>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.email")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    {<InfoDescription>{client!.email}</InfoDescription>}
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.documentType")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    {<InfoDescription>{client?.documents?.[0].documentTypeName}</InfoDescription>}
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.documentNumber")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    {<InfoDescription>{client?.documents?.[0].documentNumber}</InfoDescription>}
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.issuingBody")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    {<InfoDescription>{client?.documents?.[0].documentIssuer}</InfoDescription>}
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.issuingState")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    {<InfoDescription>{client?.documents?.[0].documentStateIssuer.toUpperCase()}</InfoDescription>}
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.issueDate")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoDescription>
                                                        {format(parse(client!.documents?.[0].documentDateOfIssue, 'yyyy/MM/dd', new Date()), 'dd/MM/yyyy')}
                                                    </InfoDescription>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.cellphone")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoDescription>{client!.contact.cellPhoneDdd}{client!.contact.cellPhone}</InfoDescription>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.howDidYouHearAboutMoneycorp")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoDescription>{client!.howDidYouMeet}{client!.howDidYouMeetExplain ? ' (' + client!.howDidYouMeetExplain + ')' : ''}</InfoDescription>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <SubtitlePageForm>
                                                                {t("text.address")}
                                                            </SubtitlePageForm>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <Divider />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.country")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoDescription>
                                                        {client?.address?.country.toUpperCase()}
                                                    </InfoDescription>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.zipCode")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoDescription>
                                                        {client?.address?.country === "BR" ? formatCEP(client?.address?.zipCode) : client?.address?.zipCode}
                                                    </InfoDescription>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.streetName")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoDescription>
                                                        {client?.address?.street}
                                                    </InfoDescription>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.number")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoDescription>
                                                        {client?.address?.number}
                                                    </InfoDescription>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.complement")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoDescription>
                                                        {client?.address?.complement || "-"}
                                                    </InfoDescription>
                                                </Grid>
                                                {client?.address?.country === "BR" ?
                                                    <>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            sm={6}
                                                            md={6}
                                                            lg={6}
                                                            xl={6}
                                                        >
                                                            <InfoTitle>{t("text.neighborhood")}</InfoTitle>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            sm={6}
                                                            md={6}
                                                            lg={6}
                                                            xl={6}
                                                        >
                                                            <InfoDescription>
                                                                {client?.address?.neighborhood}
                                                            </InfoDescription>
                                                        </Grid></> : null}
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.city")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoDescription>
                                                        {client?.address?.city}
                                                    </InfoDescription>
                                                </Grid>
                                                {client?.address?.country === "BR" ?
                                                    <>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            sm={6}
                                                            md={6}
                                                            lg={6}
                                                            xl={6}
                                                        >
                                                            <InfoTitle>{t("text.uf")}</InfoTitle>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            sm={6}
                                                            md={6}
                                                            lg={6}
                                                            xl={6}
                                                        >
                                                            <InfoDescription>
                                                                {client?.address?.state.toUpperCase()}
                                                            </InfoDescription>
                                                        </Grid>
                                                    </> : null
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <SubtitlePageForm>
                                                                {t("text.bankInformation")}
                                                            </SubtitlePageForm>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <Divider />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.accountType")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoDescription>
                                                        {client?.bankingReference?.accountTypeName}
                                                    </InfoDescription>
                                                </Grid>
                                                {/*<Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.code")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoDescription>{/*bankingReference.code</InfoDescription>
                                                </Grid>*/}
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.branch")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoDescription>{client?.bankingReference?.agency}</InfoDescription>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.bankName")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoDescription>{client?.bankingReference?.name}</InfoDescription>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoTitle>{t("text.account")}</InfoTitle>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <InfoDescription>{client?.bankingReference?.accountNumber}</InfoDescription>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <SubtitlePageForm>
                                                                {t("text.termsAndConditions")}
                                                            </SubtitlePageForm>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <Divider />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <Controller
                                                                control={control}
                                                                name="lgpd"
                                                                render={({ field: props }) => (
                                                                    <FormControlLabelStyled
                                                                        control={
                                                                            <Checkbox
                                                                                {...props}
                                                                                checked={props.value}
                                                                                onChange={(e) => props.onChange(e.target.checked)}
                                                                            />
                                                                        }
                                                                        label={<>{
                                                                            reactStringReplace(t("text.termsAndConditionsPrivacyPolicy") as string, "{{privacy_policy_link}}", (_, index) => (
                                                                                <LinkCheckbox key={index} href={t("text.privacyPolicyLink") as string} target="_blank">{t("text.privacyPolicy")}</LinkCheckbox>
                                                                            ))
                                                                        }
                                                                        </>}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.lgpd && <FormHelperTextStyled>{displayErrorMessage(errors.lgpd?.message) as string}</FormHelperTextStyled>}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <Controller
                                                                control={control}
                                                                name="jointResolution"
                                                                render={({ field: props }) => (
                                                                    <FormControlLabelStyled
                                                                        control={
                                                                            <Checkbox
                                                                                {...props}
                                                                                checked={props.value}
                                                                                onChange={(e) => props.onChange(e.target.checked)}
                                                                            />
                                                                        }
                                                                        label={<>{
                                                                            reactStringReplace(t("text.jointResolution") as string, "{{terms_and_conditions_link}}", (_, index) => (
                                                                                <LinkCheckbox
                                                                                    key={index}
                                                                                    component="button"
                                                                                    type="button"
                                                                                    variant="body2"
                                                                                    onClick={async () => {
                                                                                        try {
                                                                                            await termsAndAgreementsModal();
                                                                                        } catch (ignored) {
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {t("text.termsAndConditions")}
                                                                                </LinkCheckbox>
                                                                            ))
                                                                        }
                                                                        </>}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.jointResolution && <FormHelperTextStyled>{displayErrorMessage(errors.jointResolution?.message) as string}</FormHelperTextStyled>}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <Button
                                                variant="outlined"
                                                label={t("text.previousStep")}
                                                component={Link}
                                                to={client!.estimatedValueId === "1"
                                                    ? "/onboarding/banking-references-register"
                                                    : "/onboarding/income-powerOfAttorney-register"}
                                                fullWidth
                                                disabled={submitting}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                label={t("text.finish")}
                                                loading={submitting}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Button
                                                variant="text"
                                                label={t("text.continueLater")}
                                                onClick={handleSignOut}
                                                disabled={submitting}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        </form>
    );
}
