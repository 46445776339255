import { isValidEmail } from "@brazilian-utils/brazilian-utils";
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Switch,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Controller,useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InstanceProps, create } from 'react-modal-promise';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { AuthContext } from "../../contexts/auth";
import { getContactByIdPromise, updateContact } from '../../services/contactService';
import {
    FormControlLabelStyled,
    FormHelperTextStyled,
} from '../../styles/styles';
import { displayErrorMessage } from '../../utils/errorMessage';
import { Button } from '../Button';
import { DialogTitleStyled } from '../DeleteModal/styles';
import { LoadingBox } from '../LoadingBox';
import { LoadingButton } from '../LoadingButton';
import { TextField } from '../TextField';
import { getCRMDdiPromise } from "../../services/crmDdi";
import { CRMDdiOptions } from "../CRMDdiOptions";
import { stripToEmpty, removeSpecialCharactersAndAccents } from "../../utils/stringUtils";
import { hasCellPhone, hasNumber, hasPhone } from "../../utils/phoneUtils";
import { IContacts } from "../../interfaces/contacts";

const validationSchema = yup.object().shape({
    id: yup.string().nullable(),
    name: yup.string()
        .min(2)
        .required(),
    occupation: yup.string().required(),
    email: yup.string()
        .required()
        .min(10)
        .transform((curr, orig) => stripToEmpty(curr))
        .test("validateEmail", (value) => isValidEmail(stripToEmpty(value))),
    cellPhoneDdi: yup.string().nullable()
        .test('hasNumber', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasCellPhone', 'validations.required', (value, context) => {
            return hasCellPhone(context);
        }),
    cellPhoneDdd: yup.string().nullable()
        .test('hasNumber', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasCellPhone', 'validations.required', (value, context) => {
            return hasCellPhone(context);
        })
        .matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    cellPhone: yup.string().nullable()
        .test('hasCellPhone', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasPhone', 'validations.required', (value, context) => {
            return hasCellPhone(context);
        })
        .matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    phoneDdi: yup.string().nullable()
        .test('hasNumber', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasPhone', 'validations.required', (value, context) => {
            return hasPhone(context);
        }),
    phoneDdd: yup.string().nullable()
        .test('hasNumber', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasPhone', 'validations.required', (value, context) => {
            return hasPhone(context);
        })
        .matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    phone: yup.string().nullable()
        .test('custom', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasPhone', 'validations.required', (value, context) => {
            return hasPhone(context);
        })
        .matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    whatsApp: yup.boolean().required(),
}, [['cellPhone', 'phone']]);
// antes o retorno era  ['cellPhone', 'phone']

interface EditContactModalProps extends InstanceProps<any, any> {
    id: string,
}
const EditContactModal:React.FC<EditContactModalProps> = ({ open, onResolve, onReject, id }) => {
    const { t } = useTranslation();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const { user } = useContext(AuthContext);
    const { type } = user.value;
    const [ddis, setDdis] = useState([]);

    const { control, handleSubmit, formState: { errors }, reset, setValue } = useForm<IContacts>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            id: "",
            name: "",
            occupation: "",
            email: "",
            cellPhoneDdd: "",
            cellPhoneDdi: "",
            cellPhone: "",
            phoneDdi: "",
            phoneDdd: "",
            phone: "",
            whatsApp: false,
        }
    });

    useEffect(() => {
        async function fetchContact() {
            try {
                const [ddis, contact] = await Promise.all([
                    getCRMDdiPromise(),
                    getContactByIdPromise(id)
                ]);

                setDdis(ddis.data);
                const data = contact.data;
                reset(data);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }

        fetchContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ddiPhoneChanged = () => {
        setValue('phoneDdd', '');
        setValue('phone', '');
    }

    const ddiCellPhoneChanged = () => {
        setValue('cellPhoneDdd', '');
        setValue('cellPhone', '');
    }

    const onSubmit = async (data: IContacts) => {
        setSubmitting(true);

        try {
            await updateContact(id, data);
            toast.success(t("text.toast.successEdit"));
            onResolve();
        } catch (error:any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            scroll="paper"
            fullWidth
        >
            {loading
                ? <LoadingBox />
                : <React.Fragment>
                    <DialogTitleStyled>
                        {t("text.editContact")}
                    </DialogTitleStyled>
                    <DialogContent dividers>
                        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
                            <fieldset disabled={submitting} style={{ border: 0 }}>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={8}
                                        lg={8}
                                        xl={8}
                                    >
                                        <Controller
                                            control={control}
                                            name="name"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={t("text.fullName")}
                                                    placeholder={t("text.fullName") as string}
                                                    inputProps={{ maxLength: 100 }}
                                                    fullWidth
                                                    error={!!errors.name}
                                                    helperText={displayErrorMessage(errors.name?.message) as string }
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                    >
                                        <Controller
                                            control={control}
                                            name="occupation"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={type === 1 ? t("text.relationship") : t("text.positionDepartment")}
                                                    placeholder={type === 1 ? t("text.relationship") as string: t("text.positionDepartment") as string}
                                                    fullWidth
                                                    error={!!errors.occupation}
                                                    helperText={displayErrorMessage(errors.occupation?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Controller
                                            control={control}
                                            name="email"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    inputRef={field.ref}
                                                    label={t("text.email")}
                                                    placeholder={t("text.email") as string }
                                                    inputProps={{ maxLength: 100 }}
                                                    fullWidth
                                                    error={!!errors.email}
                                                    helperText={displayErrorMessage(errors.email?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        spacing={3}
                                        xs={10}
                                        sm={10}
                                        md={10}
                                        lg={10}
                                        xl={10}
                                    >
                                        <Grid
                                            item
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                        >
                                            <CRMDdiOptions
                                                control={control}
                                                label={t("text.ddi")}
                                                name="phoneDdi"
                                                ddis={ddis}
                                                error={!!errors.phoneDdi}
                                                ddiChanged={ddiPhoneChanged}
                                            />
                                            <FormHelperTextStyled>
                                                {displayErrorMessage(errors.phoneDdi?.message) as string }
                                            </FormHelperTextStyled>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                        >
                                            <Controller
                                                control={control}
                                                name="phoneDdd"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                        inputRef={field.ref}
                                                        label={t("text.ddd")}
                                                        inputProps={{ maxLength: 3 }}
                                                        fullWidth
                                                        error={!!errors.phoneDdd}
                                                        helperText={displayErrorMessage(errors.phoneDdd?.message) as string }
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                        >
                                            <Controller
                                                control={control}
                                                name="phone"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                        inputRef={field.ref}
                                                        label={t("text.landline")}
                                                        inputProps={{ maxLength: 15 }}
                                                        fullWidth
                                                        error={!!errors.phone}
                                                        helperText={displayErrorMessage(errors.phone?.message) as string }
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        spacing={3}
                                        xs={10}
                                        sm={10}
                                        md={10}
                                        lg={10}
                                        xl={10}
                                    >
                                        <Grid
                                            item
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                        >
                                            <CRMDdiOptions
                                                name="cellPhoneDdi"
                                                label={t("text.ddi")}
                                                control={control}
                                                ddis={ddis}
                                                error={!!errors.cellPhoneDdi}
                                                ddiChanged={ddiCellPhoneChanged}
                                            />
                                            <FormHelperTextStyled>
                                                {displayErrorMessage(errors.cellPhoneDdi?.message) as string }
                                            </FormHelperTextStyled>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                        >
                                            <Controller
                                                control={control}
                                                name="cellPhoneDdd"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                        inputRef={field.ref}
                                                        label={t("text.ddd")}
                                                        inputProps={{ maxLength: 3 }}
                                                        fullWidth
                                                        error={!!errors.cellPhoneDdd}
                                                        helperText={displayErrorMessage(errors.cellPhoneDdd?.message) as string}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                        >
                                            <Controller
                                                control={control}
                                                name="cellPhone"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                        inputRef={field.ref}
                                                        label={t("text.cellphone")}
                                                        inputProps={{ maxLength: 15 }}
                                                        fullWidth
                                                        error={!!errors.cellPhone}
                                                        helperText={displayErrorMessage(errors.cellPhone?.message) as string }
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                            sx={{
                                                marginTop: '1.70rem',
                                                '@media(max-width: 1200px)': {
                                                    marginTop: '0',
                                                }
                                            }}
                                        >
                                            <Controller
                                                control={control}
                                                name={"whatsApp"}
                                                render={({ field: { value, onChange, ref } }) => (
                                                    <FormControlLabelStyled
                                                        control={
                                                            <Switch checked={value}
                                                                onChange={onChange}
                                                                inputRef={ref} />
                                                        }
                                                        label={t("text.isWhatsApp")}
                                                    />
                                                )}
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </fieldset>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={submitting} onClick={onReject} label={t("text.cancel")} />
                        <LoadingButton
                            type="submit"
                            label={t("text.saveChanges")}
                            form="submit-form"
                            loading={submitting}
                        />
                    </DialogActions>
                </React.Fragment>
            }
        </Dialog>
    );
}

export const editContactModal = create(EditContactModal);
