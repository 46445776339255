import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { forwardRef } from "react";
import { Label, TextFieldStyled } from "./styles";
import React from "react";
import { OutlinedTextFieldProps } from "@mui/material/TextField";

interface TextFieldProps extends OutlinedTextFieldProps {
  label?: string | null;
  hint?: string;
  bordercolor?: string;
  min?: string;
}

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(({ label, hint, variant = "outlined", ...rest }, ref) => {
  return (
    <>
      <Label>
        {label}
        {hint ? (
          <Tooltip title={hint} arrow>
            <IconButton
              sx={{
                padding: 0,
                svg: {
                  fontSize: "1.125rem",
                },
              }}
            >
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </Label>
      <TextFieldStyled fullWidth {...rest} ref={ref} variant={variant} />
    </>
  );
});
