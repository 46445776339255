import { Add, DeleteOutlined, EditOutlined } from '@mui/icons-material';
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Zoom
} from '@mui/material';
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { addContactModal } from "../../components/AddContactModal";
import { Button } from '../../components/Button';
import { deleteModal } from "../../components/DeleteModal";
import { editContactModal } from "../../components/EditContactModal";
import { LoadingBox } from '../../components/LoadingBox';
import { PageName } from '../../components/PageName';
import { AuthContext } from "../../contexts/auth";
import { deleteContact, getContacts } from "../../services/contactService";
import {
    TableCellBodyStyled,
    TableCellTitleStyled
} from '../../styles/styles';
import { errorModal } from '../../components/ErrorModal';
import { IContacts } from '../../interfaces/contacts';


export function Contacts() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [contacts, setContacts] = useState<IContacts[]>([]);
    const { user } = useContext(AuthContext);
    const { type } = user.value;

    useEffect(() => {
        fetchContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchContacts = async () => {
        setLoading(true);
        try {
            const response = await getContacts();
            setContacts(response.data);
            setLoading(false);
        } catch (error:any) {
            setLoading(false);
            try {
                const errorMessage = error.response?.data?.message || error.message;
                await errorModal({
                    title: 'Não foi possível carregar os contatos',
                    message: errorMessage,
                });
                fetchContacts();
            } catch (ignored) {
            }
        }
    }

    const _addContact = async () => {
        try {
            await addContactModal();
            fetchContacts();
        } catch (ignored) {
        }
    }

    const _editContact = async (contact:IContacts) => {
        try {
            await editContactModal({ id: contact.id });
            fetchContacts();
        } catch (ignored) {
        }
    }

    const _deletePartner = async (contact:IContacts) => {
        try {
            const title = t("text.remove");
            const message = `${t("text.areYouSureRemoveContact")} ${contact.name}?`;
            await deleteModal({
                title,
                message,
                onBeforeResolve: async () => await deleteContact(contact.id),
                closeOnConflict: true,
            });

            toast.success(t("text.toast.remove"));
            fetchContacts();
        } catch (ignored) {
        }
    }

    return (
        <Grid
            container
        >
            {loading
                ? <LoadingBox />
                : <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                        background: 'var(--white)',
                        borderRadius: '4px',
                        padding: '2rem',
                        '@media(max-width: 899px)': {
                            padding: '0rem',
                        }
                    }}
                >
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <PageName
                                title={t("text.contacts")}
                                info={t("text.changeOrAddMoreContactsForABetterExperience")}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={4}
                                            xl={4}
                                        >
                                            <Button
                                                variant="outlined"
                                                label={t("text.addContact")}
                                                startIcon={<Add />}
                                                onClick={_addContact}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <TableContainer
                                        component={Paper}
                                        sx={{
                                            '@media (max-width: 1366px)': {
                                                maxHeight: 440,
                                            },
                                            maxHeight: 640,
                                        }}
                                    >
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCellTitleStyled align="left">{t("text.name")}</TableCellTitleStyled>
                                                    <TableCellTitleStyled align="left">{t("text.landline")}</TableCellTitleStyled>
                                                    <TableCellTitleStyled align="left">{t("text.cellphone")}</TableCellTitleStyled>
                                                    <TableCellTitleStyled align="left">{t("text.email")}</TableCellTitleStyled>
                                                    <TableCellTitleStyled align="left">{type === 1 ? t("text.relationship") : t("text.positionDepartment")}</TableCellTitleStyled>
                                                    <TableCellTitleStyled align="center">{t("text.actions")}</TableCellTitleStyled>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {contacts.map((contact) => {
                                                    return <TableRow hover key={contact.id}>
                                                        <TableCellBodyStyled align="left">{contact.name}</TableCellBodyStyled>
                                                        <TableCellBodyStyled align="left">{contact.phoneDdd}{contact.phone}</TableCellBodyStyled>
                                                        <TableCellBodyStyled align="left">{contact.cellPhoneDdd}{contact.cellPhone}</TableCellBodyStyled>
                                                        <TableCellBodyStyled align="left">{contact.email}</TableCellBodyStyled>
                                                        <TableCellBodyStyled align="left">{contact.occupation}</TableCellBodyStyled>
                                                        <TableCellBodyStyled
                                                            align="right"
                                                            sx={{
                                                                display: 'flex',
                                                                gap: '1rem'
                                                            }}
                                                        >
                                                            <Tooltip
                                                                title={t("text.remove")}
                                                                TransitionComponent={Zoom}
                                                                arrow
                                                            >
                                                                <IconButton
                                                                    sx={{
                                                                        color: 'var(--red)'
                                                                    }}
                                                                    onClick={() => _deletePartner(contact)}
                                                                >
                                                                    <DeleteOutlined />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip
                                                                title={t("text.edit")}
                                                                TransitionComponent={Zoom}
                                                                arrow
                                                            >
                                                                <IconButton
                                                                    sx={{
                                                                        color: 'var(--blue)'
                                                                    }}
                                                                    onClick={() => _editContact(contact)}
                                                                >
                                                                    <EditOutlined />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCellBodyStyled>
                                                    </TableRow>
                                                })}
                                                {contacts.length === 0 && (
                                                    <TableRow>
                                                        <TableCellBodyStyled colSpan={6}>
                                                            <Typography
                                                                sx={{
                                                                    color: 'var(--gray-900)',
                                                                    fontSize: '1rem',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center'
                                                                }}
                                                            >
                                                                {t("text.noRecordFound")}
                                                            </Typography>
                                                        </TableCellBodyStyled>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
}