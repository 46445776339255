import { forwardRef } from "react";
import { Label, CurrencyTextFieldStyled } from "./styles";
import React from "react";
import { OutlinedTextFieldProps, } from '@mui/material/TextField';

interface CurrencyTextFieldProps extends OutlinedTextFieldProps {
    label: string,
    currencySymbol: string,
    decimalCharacter: string,
    digitGroupSeparator: string,
    variant: any,
    value: string,
    onChange: any,
    inputRef?: any;
    placeholder: string
    decimalPlaces?: number
}

export const CurrencyTextField = forwardRef<HTMLInputElement, CurrencyTextFieldProps>(({ label, ...rest }, ref) => {
    return (
        <>
            <Label>{label}</Label>
            <CurrencyTextFieldStyled
                fullWidth
                ref={ref}
                {...rest}
            />
        </>
    );
});
