import { Add } from '@mui/icons-material';
import { Grid, IconButton, TableContainer, Table, TableHead, Paper, TableRow, TableBody, Tooltip, Zoom, Alert, AlertTitle } from '@mui/material';
import { LoadingBox } from '../../../components/LoadingBox';
import { LoadingButton } from "../../../components/LoadingButton";
import { Button } from '../../../components/Button';
import React, { useContext, useEffect, useState } from "react";
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import {
    TableCellBodyStyled,
    TableCellTitleStyled
} from '../../../styles/styles';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { PageName } from '../../../components/PageName';
import { AuthContext } from "../../../contexts/auth";
import { RegistrationUpdatePersonStepper } from '../../../components/RegistrationUpdatePersonStepper';
import { getDocumentsToUpdate } from '../../../services/updateService';
import { updateDocumentModal } from '../../../components/UpdateDocumentModal';
import { IDocumentToUpdate } from '../../../interfaces/documentToUpdate';

const validationSchema = yup.object({
    stepIndex: yup.string(),
    productId: yup.string(),
    estimatedValueId: yup.string(),

});

export function PersonalDocumentUpdate() {
    const { handleSignOut } = useContext(AuthContext);
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [documents, setDocuments] = useState<IDocumentToUpdate[]>([]);

    const { control, handleSubmit, formState: { errors }, reset, resetField, register, getValues, setValue } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            stepIndex: "",
            productId: "",
            estimatedValueId: "",
        }
    });

    const onSubmit = async data => {
        setSubmitting(true);

        try {
            navigate(`/update/personal-resume-update`);
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    const fetchDocumentsToUpdate = async () => {
        setLoading(true);
        try {
            const response = await getDocumentsToUpdate(i18n.language);
            setDocuments(response.data);
            setLoading(false);
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        }
    }

    const _updateDocumentModal = async (document) => {
        try {
            await updateDocumentModal({ id: document.id });
            fetchDocumentsToUpdate();
        } catch (ignored) {
        }
    }


    const previous = async () => {
        navigate(`/update/personal-data-update`);
    }

    useEffect(() => {
        fetchDocumentsToUpdate();
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '2rem',
                }}
            >
                {loading
                    ? <LoadingBox />
                    : <React.Fragment>
                        <RegistrationUpdatePersonStepper
                            customerType={"PF"}
                            activeStep={1} />
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{
                                background: 'var(--white)',
                                borderRadius: '4px',
                                padding: '2rem',
                                '@media(max-width: 899px)': {
                                    padding: '0rem',
                                }
                            }}
                        >
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Alert severity="info">
                                        <AlertTitle><strong>{t("text.updateAlertTitle")}</strong></AlertTitle>
                                    </Alert>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    sx={{
                                        background: 'var(--white)',
                                        borderRadius: '4px',
                                        padding: '2rem',
                                        '@media(max-width: 899px)': {
                                            padding: '0rem',
                                        }
                                    }}
                                >
                                    <Grid
                                        container
                                        spacing={3}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <PageName
                                                title={t("text.registrationDocuments")}
                                                info={t("text.toProceedWithTheRegistrationPleaseFillInTheInformationBelow")}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <TableContainer
                                                        component={Paper}
                                                        sx={{
                                                            '@media (max-width: 1366px)': {
                                                                maxHeight: 440,
                                                            },
                                                            maxHeight: 640,
                                                        }}
                                                    >
                                                        <Table
                                                            stickyHeader
                                                            aria-label={t("text.registrationDocumentsTable")}
                                                        >
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCellTitleStyled align="left">{t("text.document")}</TableCellTitleStyled>
                                                                    <TableCellTitleStyled align="left">{t("text.status")}</TableCellTitleStyled>
                                                                    <TableCellTitleStyled align="right">{t("text.actions")}</TableCellTitleStyled>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {documents.map((document, index) => {
                                                                    return (
                                                                        <TableRow hover key={index}>
                                                                            <TableCellBodyStyled align="left">{t(`${document.documentTypeDescription}`)}</TableCellBodyStyled>
                                                                            <TableCellBodyStyled align="left">{t(`${document.stateCodeDescription}`)}</TableCellBodyStyled>
                                                                            <TableCellBodyStyled
                                                                                align="right"
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    gap: '1rem'
                                                                                }}
                                                                            >
                                                                                <Tooltip
                                                                                    title={t("text.add")}
                                                                                    TransitionComponent={Zoom}
                                                                                    arrow
                                                                                >
                                                                                    <IconButton
                                                                                        disabled={!document.enableToUpdate}
                                                                                        sx={{
                                                                                            color: 'var(--green)'
                                                                                        }}
                                                                                        onClick={() => _updateDocumentModal(document)}
                                                                                    >
                                                                                        <Add />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </TableCellBodyStyled>
                                                                        </TableRow>
                                                                    )
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <Button
                                                onClick={previous}
                                                variant="outlined"
                                                label={t("text.previousStep")}
                                                disabled={submitting}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                label={t("text.nextStep")}
                                                fullWidth
                                                loading={submitting}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Button
                                                variant="text"
                                                label={t("text.continueLater")}
                                                onClick={handleSignOut}
                                                disabled={submitting}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        </form>
    );

}
