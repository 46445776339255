import React, { useLayoutEffect } from 'react';
import {
    Box
} from "@mui/material";
import { Outlet } from 'react-router-dom';
import LogoMoneycorp from '../../../assets/image/moneycorp.svg';
import { LanguageSelect } from '../../../components/LanguageSelect';
import PatternMoneycorp from '../../../assets/image/pattern.svg';

export function SmallForm() {
    useLayoutEffect(() => {
        document.body.style.background = "var(--brandColor-dark-blue)"
    });

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",


            '@media (min-width: 899px)': {
                height: "100vh",
            },
            '@media (max-width: 899px)': {
            },


        }}>
            <Box sx={{
                width: "1366px",
                '@media (min-width: 899px)': {
                    height: "100%",
                    backgroundImage: `url(${PatternMoneycorp})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top right",
                    backgroundSize: "640px",
                },
                '@media (max-width: 899px)': {
                },
            }}>
                <Box sx={{
                    '@media (min-width: 899px)': {
                        position: "fixed",
                    },
                    '@media (max-width: 899px)': {
                    },
                    p: 4,
                    zIndex: 1300,
                }}>
                    <a href="/">
                        <img
                            style={{
                                height: "6rem"
                            }}
                            src={LogoMoneycorp}
                            alt="Moneycorp banco de câmbio"
                        />
                    </a>
                </Box>
                <Box sx={{
                    '@media (min-width: 899px)': {
                        position: "fixed",
                        inset: "0px",
                    },
                    '@media (max-width: 899px)': {
                    },
                }}>
                    <Box className="scrollBody">
                        <Box sx={{
                            backgroundColor: "#fdfdfd",
                            borderRadius: "4px",
                            margin: "32px",
                            position: "relative",
                            overflowY: "auto",
                            display: "inline-block",
                            verticalAlign: "middle",
                            textAlign: "left",
                            maxWidth: "424px",
                        }}>
                            <Outlet />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    '@media (min-width: 899px)': {
                        position: "fixed",
                    },
                    '@media (max-width: 899px)': {
                    },
                    bottom: 0,
                    p: 4,
                }}>
                    <LanguageSelect />
                </Box>
            </Box>
        </Box>
    );
}
