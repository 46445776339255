import TextField from "@mui/material/TextField";
import styled from "styled-components";

interface CustomTextFieldProps {
    borderColor?: string;
}

const options = {
    shouldForwardProp: (prop: string) => prop !== 'borderColor',
};

const outlinedSelectors = [
    '& .MuiOutlinedInput-notchedOutline',
    '&:hover .MuiOutlinedInput-notchedOutline',
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline',
];

export const Label = styled.label`
    font-weight: 700;
    font-size: .75rem;
    color: var(--gray-600);
    /* margin-bottom: .25rem; */
    display: flex;
    align-items: flex-end;
    gap: .5rem;
`;

export const TextFieldStyled = styled(TextField).attrs(options)<CustomTextFieldProps>(({ borderColor }) => ({
    '& label.Mui-focused': {
        color: borderColor,
    },
    [outlinedSelectors.join(',')]: {
        borderColor,
    },
    '&&': {
        marginTop: '.25rem',
        marginBottom: '0! important',
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'var(--white)',
            height: '2.75rem',
        },
        '& .MuiAutocomplete-input': {
            padding: '0 0 0 3px',
        },
    }
}));


// import { TextField } from "@mui/material";
// import styled from "styled-components";


// const options = {
//     shouldForwardProp: (prop:string) => prop !== 'borderColor',
// };

// const outlinedSelectors = [
//     '& .MuiOutlinedInput-notchedOutline',
//     '&:hover .MuiOutlinedInput-notchedOutline',
//     '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline',
// ];

// export const Label = styled.label`
//     font-weight: 700;
//     font-size: .75rem;
//     color: var(--gray-600);
//     /* margin-bottom: .25rem; */
//     display: flex;
//     align-items: flex-end;
//     gap: .5rem;
// `;

// export const TextFieldStyled = styled(
//     TextField).attrs(options)(({ bordercolor }) => ({
//     '& label.Mui-focused': {
//         color: bordercolor,
//     },
//     [outlinedSelectors.join(',')]: {
//         bordercolor,
//     },
//     '&&': {
//         marginTop: '.25rem',
//         marginBottom: '0! important',
//         '& .MuiOutlinedInput-root': {
//             backgroundColor: 'var(--white)',
//             height: '2.75rem',
//         },
//         '& .MuiAutocomplete-input': {
//             padding: '0 0 0 3px',
//         },
//     }
// }));
