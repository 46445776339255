import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import enGB from './translations/enGB.json';
import ptBR from './translations/ptBR.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "ptBR",
        interpolation: {
            escapeValue: false,
        },
        supportedLngs: ["enGB", "ptBR"],
        resources: {
            ptBR: ptBR,
            enGB: enGB,
        }
    });
