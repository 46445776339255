import {
    MenuItem
} from '@mui/material';
import { Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Select } from "../Select";
import React from 'react';

type LegalNature = {
    id: string;
    description:string ;
}

interface CRMLegalNatureOptionsProps {
    control :  any ;
    name: string ;
   label:string ;
   legalNatures:Array<LegalNature> ;
    error:any;
    resetField:  any;
    disabled?: boolean
}

export function CRMLegalNatureOptions({ control, name, label, legalNatures, error, resetField, ...rest }:CRMLegalNatureOptionsProps) {
    const { t } = useTranslation();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <Select
                    value={field.value}
                    onChange={(e: any) => {
                        field.onChange(e);
                        resetField("documents", { defaultValue: [] });
                    }}
                    inputRef={field.ref}
                    error={error}
                    label={label}
                    displayEmpty
                    {...rest}
                >
                    <MenuItem value="">{t("text.select")}</MenuItem>
                    {legalNatures.map((legalNature) => (
                        <MenuItem
                            key={legalNature.id}
                            value={legalNature.id}
                        >
                            {legalNature.description}
                        </MenuItem>
                    ))}
                </Select>
            )}
        />
    );
}