import { yupResolver } from '@hookform/resolvers/yup';
import {
    FormControl,
    Grid,
    Radio,
    RadioGroup
} from '@mui/material';
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import * as yup from "yup";
import { Button } from '../../../components/Button';
import { LoadingButton } from '../../../components/LoadingButton';
import { PageName } from "../../../components/PageName";
import { AuthContext } from "../../../contexts/auth";
import { getPurposeDataPromise, savePurposeData } from "../../../services/clientService";
import {
    FormControlLabelStyled,
    FormHelperTextStyled,
    FormLabelStyled
} from '../../../styles/styles';
import { displayErrorMessage } from "../../../utils/errorMessage";
import { OnboardingStepper } from '../../../components/OnboardingStepper';
import { LoadingBox } from '../../../components/LoadingBox';
import { CRMOriginOptions } from '../../../components/CRMOriginOptions';
import { TextField } from '../../../components/TextField';
import { removeSpecialCharactersAndAccents } from '../../../utils/stringUtils';
import { getCRMOriginPromise } from '../../../services/crmOrigin';

const validationSchema = yup.object({
    productId: yup.string().required(),
    howDidYouMeet: yup.string().required(),
    howDidYouMeetExplain: yup.string().required(),
    estimatedValueId: yup.string()
        .when("productId", {
            is: (val:string) => val === "fe49f319-bd70-ed11-81ac-0022481b5e08" || val === "0141d350-bd70-ed11-81ac-0022481b5e08",
            then: yup.string()
                .test("estimatedValueId", (value) => {
                    return value === "1" || value === "2";
                })
        })
        .when("productId", {
            is: "6c7e3226-bd70-ed11-81ac-0022481b5e08",
            then: yup.string()
                .test("estimatedValueId", (value) => {
                    return value === "1" || value === "2" || value === "3";
                })
        }),
});

export function MoneycorpServices() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user, fetchUser } = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [originOptions, setOriginOptions] = useState([]);

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            productId: "",
            howDidYouMeet: "",
            howDidYouMeetExplain: "",
            estimatedValueId: "",
        }
    });

    const productId = useWatch({
        control,
        name: "productId"
    });

    const howDidYouMeet = useWatch({
        control,
        name: "howDidYouMeet",
    });

    const estimatedValueId = useWatch({
        control,
        name: "estimatedValueId"
    });

    useEffect(() => {
        async function fetchPurposeData() {
            try {
                const [originOptions, purposeData] = await Promise.all([
                    getCRMOriginPromise(),
                    getPurposeDataPromise(user.value.userId)
                ]);

                setOriginOptions(originOptions.data);

                let responseData = purposeData.data;
                if (responseData.productId) {
                    reset(responseData);
                }

                setLoading(false);
            } catch (error:any) {
                const errorCode = error.response?.data?.errorCode;
                if (errorCode === "12000") {
                    navigate('/onboarding/order');
                }
            }
        }

        fetchPurposeData();
    }, [navigate, reset, user.value.userId])

    const onSubmit = async (data:any) => {
        setSubmitting(true);
        try {
            await savePurposeData(data, user.value.userId, false);
            await fetchUser();
            navigate('/dashboard/personal-data');
        } catch (error:any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    };

    return (

        <form onSubmit={handleSubmit(onSubmit)}>

            <Grid
                container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '2rem',
                }}
            >
                {loading
                    ? <LoadingBox />
                    : <React.Fragment>
                        <OnboardingStepper
                            estimatedValueId={estimatedValueId}
                            activeStep={0} />
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={10}
                            xl={10}
                            sx={{
                                background: 'var(--white)',
                                borderRadius: '4px',
                                padding: '2rem',
                                '@media(max-width: 899px)': {
                                    padding: '0rem',
                                }
                            }}
                        >
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <PageName
                                        title={t("text.contactReason")}
                                        info={t("text.forABetterExperience")}
                                    />
                                </Grid>
                                {productId && (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <FormControl>
                                            <FormLabelStyled>
                                                {t("text.howMuchYouIntendToOperateDuring12Months")}
                                            </FormLabelStyled>
                                            <Controller
                                                control={control}
                                                name="estimatedValueId"
                                                render={({ field }) => {
                                                    return (
                                                        <RadioGroup {...field}>
                                                            <FormControlLabelStyled
                                                                value="1"
                                                                control={<Radio />}
                                                                label={t("text.until70Thousand")}
                                                            />
                                                            <FormControlLabelStyled
                                                                value="2"
                                                                control={<Radio />}
                                                                label={t("text.over70Thousand")}
                                                            />
                                                        </RadioGroup>
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                        {errors.estimatedValueId && (
                                            <FormHelperTextStyled>
                                                {displayErrorMessage(errors.estimatedValueId?.message) as string}
                                            </FormHelperTextStyled>
                                        )}
                                    </Grid>
                                )}

<Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                >
                                    <CRMOriginOptions
                                        name="howDidYouMeet"
                                        control={control}
                                        origins={originOptions}
                                        error={!!errors.howDidYouMeet?.message}
                                    />
                                    <FormHelperTextStyled>
                                        {displayErrorMessage(errors.howDidYouMeet?.message) as string}
                                    </FormHelperTextStyled>
                                </Grid>

                                {howDidYouMeet !== ""
                                    ? <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={8}
                                        lg={8}
                                        xl={8}
                                    >
                                        <Controller
                                            control={control}
                                            name="howDidYouMeetExplain"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                        field.onChange(cleanedValue);
                                                    }}
                                                    inputRef={field.ref}
                                                    label={t("text.explain")}
                                                    placeholder={t("text.explain") as string}
                                                    inputProps={{ maxLength: 100 }}
                                                    fullWidth
                                                    error={!!errors.howDidYouMeetExplain}
                                                    helperText={displayErrorMessage(errors.howDidYouMeetExplain?.message) as string}
                                                    variant='outlined'
                                                />
                                            )}
                                        />
                                    </Grid>
                                    : null
                                }

                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <Button
                                                variant="text"
                                                label={t("text.cancel")}
                                                component={Link}
                                                to="/dashboard"
                                                disabled={submitting}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                label={t("text.nextStep")}
                                                fullWidth
                                                loading={submitting}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </React.Fragment>
                }
            </Grid>

            {/*<Grid
                container
            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                        background: 'var(--white)',
                        borderRadius: '4px',
                        padding: '2rem',
                        '@media(max-width: 899px)': {
                            padding: '0rem',
                        }
                    }}
                >
                    {loading
                        ? <Box sx={{ display: 'flex', justifyContent: 'center', padding: (theme) => theme.spacing(2) }}>
                            <CircularProgress size={30} />
                        </Box>
                        : <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <PageName
                                    title={t("text.contactReason")}
                                    info={t("text.forABetterExperience")}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <CRMProductSelect
                                    control={control}
                                    name="productId"
                                    products={productOptions}
                                />

                                {errors.productId && (
                                    <FormHelperTextStyled>
                                        {displayErrorMessage(errors.productId?.message)}
                                    </FormHelperTextStyled>
                                )}
                            </Grid>

                            {productId && (
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <FormControl>
                                        <FormLabelStyled>
                                            {t("text.howMuchYouIntendToOperateDuring12Months")}
                                        </FormLabelStyled>
                                        <Controller
                                            control={control}
                                            name="estimatedValueId"
                                            render={({ field }) => {
                                                return (
                                                    <RadioGroup {...field}>
                                                        <FormControlLabelStyled
                                                            value="1"
                                                            control={<Radio />}
                                                            label={t("text.until70Thousand")}
                                                        />
                                                        <FormControlLabelStyled
                                                            value="2"
                                                            control={<Radio />}
                                                            label={t("text.over70Thousand")}
                                                        />
                                                    </RadioGroup>
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    {errors.estimatedValueId && (
                                        <FormHelperTextStyled>
                                            {displayErrorMessage(errors.estimatedValueId?.message)}
                                        </FormHelperTextStyled>
                                    )}
                                </Grid>
                            )}

                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <Button
                                            variant="text"
                                            label={t("text.cancel")}
                                            component={Link}
                                            to="/dashboard"
                                            disabled={submitting}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            label={t("text.nextStep")}
                                            fullWidth
                                            loading={submitting}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                </Grid>*/}
        </form>
    );
}