import React, { useState } from 'react';
import {
    Dialog,
    DialogActions
} from '@mui/material';
import { InstanceProps, create } from 'react-modal-promise';
import { Button } from '../Button';
import { DialogContentStyled, DialogTitleStyled } from './styles';
import { toast } from 'react-toastify';
import { LoadingButton } from '../LoadingButton';
import { useTranslation } from 'react-i18next';

interface DeleteModalProps {
    open: boolean ,
    title: string , 
    message:string,
    onResolve: ()=> void , 
    onReject: ()=> void , 
    onBeforeResolve:()=> void ,
    closeOnConflict: boolean,
}

interface ModalProps extends InstanceProps<any, any> {
    open: boolean;
    title: string;
    message: string;
    onResolve: () => void;
    onReject: () => void;
    onBeforeResolve: () => void;
    closeOnConflict: boolean;
}


const DeleteModal : React.FC<DeleteModalProps> = ({ open, onResolve, onReject, title, message, onBeforeResolve, closeOnConflict = false }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);

    const _onResolve = async () => {
        if (onBeforeResolve) {
            setLoading(true);
            try {
                await onBeforeResolve();
                onResolve();
            } catch (error:any) {
                const errorStatus = error.response?.data?.status;
                const errorMessage = error.response?.data?.message || error.message;
                toast.error(errorMessage);
                if (closeOnConflict && errorStatus === 'CONFLICT')
                    onReject();
            } finally {
                setLoading(false);
            }
        } else {
            onResolve();
        }
    }

    return (
        <Dialog open={open} scroll="paper">
            <DialogTitleStyled>{title}</DialogTitleStyled>
            <DialogContentStyled>{message}</DialogContentStyled>
            <DialogActions>
                <Button disabled={loading} onClick={onReject} label={t("text.no")} />
                <LoadingButton
                    onClick={_onResolve}
                    label={t("text.yes")}
                    loading={loading}
                />
            </DialogActions>
        </Dialog>
    );
}

export const deleteModal = create<ModalProps>(DeleteModal);
