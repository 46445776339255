import { Add, DeleteOutlined, Image } from '@mui/icons-material';
import {
    Avatar,
    Box,
    CardActionArea,
    CardActions,
    LinearProgress,
    Typography
} from '@mui/material';
import React, { useState, useImperativeHandle, forwardRef } from "react";
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import TextButton from '../TextButton';
import { deleteModal } from '../DeleteModal';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import api from '../../services/api';

interface DocumentProps {
    title: string,
    name: string,
    register: any,
    onDownload?: (file: any) => void,
    getValues: (name: string) => void,
    uploading: boolean,
    setUploading: (isUploading: boolean) => void
}

export type DocumentHandle = {
    removeFile: () => void;
}

export enum Variant {
    determinate = "determinate",
    indeterminate = "indeterminate",
    buffer = "buffer",
    query = "query",
}

const Document = forwardRef<DocumentHandle, DocumentProps>(({ title, onDownload, name, register, getValues, uploading, setUploading }, ref) => {
    const { t } = useTranslation();
    const { onChange, ...params }: any = register(name);
    const values = getValues(name);
    const firstValue = Array.isArray(values) ? values[0] : null;
    const [file, setFile] = useState(firstValue);
    const [progress, setProgress] = useState(0);
    const [variant, setVariant] = useState<Variant>(Variant.determinate);

    useImperativeHandle(ref, () => ({
        removeFile() {
            setFile(null);
            onChange({ target: { name: name, value: "" } });
        }
    }), [onChange, name]);

    const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files![0];
        if (!selectedFile) return;

        if (selectedFile && selectedFile.size > 20971520) { // 20mb
            removeUnwantedFile(event);
            toast.error(t("O arquivo deve ter no máximo 20MB."));
            return;
        }

        setUploading(true);
        try {
            var formData = new FormData();
            formData.append("file", selectedFile);
            const response = await api.post('/api/file/scan', formData, {
                onUploadProgress: (progressEvent) => {
                    setProgress(parseInt(((progressEvent.loaded / progressEvent.total) * 100).toString(), 10));
                    if (progressEvent.loaded === progressEvent.total) {
                        setVariant(Variant.indeterminate);
                    }
                },
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            const malwareScanResult = response.data.result;
            if (malwareScanResult === 'No threats found') {
                setVariant(Variant.determinate);
                setProgress(0);
                setFile(selectedFile);
                onChange(event);
            } else if (malwareScanResult === "Malicious") {
                removeUnwantedFile(event);
                toast.error(t("text.maliciousFile"));
            } else {
                removeUnwantedFile(event);
                toast.error(t("text.maliciousFileVerificationFailure"));
            }
        } catch (error) {
            removeUnwantedFile(event);
            toast.error(t("text.toast.errorResetPassword"));
        } finally {
            setUploading(false);
        }
    }

    const removeUnwantedFile = (e: { target: { value: string; }; }) => {
        e.target.value = "";
        setFile(null);
        onChange({ target: { name: name, value: "" } });
    }

    if (file) {
        return (
            <React.Fragment>
                <Box
                    sx={{
                        height: 150,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingLeft: (theme) => theme.spacing(2),
                        paddingRight: (theme) => theme.spacing(2),
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <Avatar sx={{ marginRight: (theme) => theme.spacing(2) }}>
                            <Image />
                        </Avatar>
                        <TextButton disabled={!file.id} label={file.name} onClick={() => onDownload!(file)} />
                    </div>
                </Box>
                <CardActions>
                    <Button
                        variant="text"
                        startIcon={<DeleteOutlined />}
                        label={t("text.remove")}
                        size="small"
                        onClick={async () => {
                            try {
                                const title = t("text.remove");
                                const message = t("text.areYouSureRemoveDocument");
                                await deleteModal({
                                    title,
                                    message,
                                    open: true, // valor padrão ou opcional
                                    onResolve: () => { }, // valor padrão ou opcional
                                    onReject: () => { }, // valor padrão ou opcional
                                    onBeforeResolve: () => { }, // valor padrão ou opcional
                                    closeOnConflict: false // valor padrão ou opcional
                                });
                                setFile(null);
                                onChange({ target: { name: name, value: "" } });
                            } catch (ignored) {
                            }
                        }}
                    />
                </CardActions>
            </React.Fragment>
        )
    }

    return (
        <CardActionArea component="label" disabled={uploading}>
            <Box
                sx={{
                    height: 150,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column"
                }}
            >
                <div style={{ width: 0, overflow: "hidden" }}>
                    <input
                        id="single"
                        style={{ opacity: 0, filter: "alpha(opacity=0)" }}
                        accept=".jpeg, .jpg, image/bmp, image/png, application/pdf"
                        type="file"
                        onChange={(event) => {
                            uploadFile(event);
                        }}
                        {...params}
                    />
                </div>
                {uploading
                    ? <Box
                        sx={{ width: '100%' }}
                        paddingLeft={3}
                        paddingRight={3}
                    >
                        <LinearProgress variant={variant} value={progress} />
                    </Box>
                    : <>
                        <Add fontSize="large" />
                        <Typography
                            variant="button"
                            display="block"
                            gutterBottom
                            sx={{ marginTop: (theme) => theme.spacing(2) }}
                        >
                            {title}
                        </Typography>

                    </>
                }
            </Box>
        </CardActionArea>
    );
});

Document.propTypes = {
    uploading: PropTypes.bool.isRequired,
    setUploading: PropTypes.func.isRequired,
};

export default Document;
