import { Add } from '@mui/icons-material';
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip, Zoom
} from '@mui/material';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { LoadingBox } from '../../components/LoadingBox';
import { PageName } from '../../components/PageName';
import {
    TableCellBodyStyled,
    TableCellTitleStyled
} from '../../styles/styles';
import { getDocumentsByProspect } from '../../services/documentService';
import { addDocumentoModal } from '../../components/AddDocument';
import { IRegistrationDocument } from '../../interfaces/registrationDocuments';

export function RegistrationDocuments() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [documents, setDocuments] = useState<IRegistrationDocument[]>([]);

    useEffect(() => {
        fetchDocuments();
    }, []);

    const fetchDocuments = async () => {
        setLoading(true);
        try {
            const response = await getDocumentsByProspect();
            setDocuments(response.data);
            setLoading(false);
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        }
    }

    const _addDocumentoModal = async (document: any) => {
        try {
            await addDocumentoModal({ code: document.code });
            fetchDocuments();
        } catch (ignored) {
        }
    }

    return (
        <Grid container>
            {loading
                ? <LoadingBox />
                : <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                        background: 'var(--white)',
                        borderRadius: '4px',
                        padding: '2rem',
                        '@media(max-width: 899px)': {
                            padding: '0rem',
                        }
                    }}
                >
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <PageName
                                title={t("text.registrationDocuments")}
                                info={t("text.manageYourDocumentOrAddOthers")}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <TableContainer
                                        component={Paper}
                                        sx={{
                                            '@media (max-width: 1366px)': {
                                                maxHeight: 440,
                                            },
                                            maxHeight: 640,
                                        }}
                                    >
                                        <Table
                                            stickyHeader
                                            aria-label={t("text.registrationDocumentsTable")}
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCellTitleStyled align="left">{t("text.document")}</TableCellTitleStyled>
                                                    <TableCellTitleStyled align="right">{t("text.actions")}</TableCellTitleStyled>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {documents.map((document, index) => {
                                                    return (
                                                        <TableRow hover key={index}>
                                                            <TableCellBodyStyled align="left">{t(`${document.name}`)}</TableCellBodyStyled>
                                                            <TableCellBodyStyled
                                                                align="right"
                                                                sx={{
                                                                    display: 'flex',
                                                                    gap: '1rem'
                                                                }}
                                                            >
                                                                <Tooltip
                                                                    title={t("text.add")}
                                                                    TransitionComponent={Zoom}
                                                                    arrow
                                                                >
                                                                    <IconButton
                                                                        sx={{
                                                                            color: 'var(--green)'
                                                                        }}
                                                                        onClick={() => _addDocumentoModal(document)}
                                                                    >
                                                                        <Add />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCellBodyStyled>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}
        </Grid>
    );
}
