import {
    Grid,
    Step,
    StepButton,
    Stepper,
} from '@mui/material';
import i18n from 'i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const getSteps = (customerType : string) => {

    if (customerType === "PJ") {
        let steps = [
            {
                label: i18n.t("text.personalData"),
                link: "/update/corporate-data-update"
            },
            {
                label: i18n.t("text.summary"),
                link: "/update/corporate-resume-update"
            },
        ];

        return steps;
    } else {
        let steps = [
            {
                label: i18n.t("text.personalData"),
                link: "/update/personal-data-update"
            },
            {
                label: i18n.t("text.summary"),
                link: "/update/personal-resume-update"
            },
        ];

        return steps;
    }
}

interface RegistrationUpdatePersonStepperProps {
    customerType:string
    activeStep:number
}

export const RegistrationUpdatePersonStepper = ({ customerType, activeStep } : RegistrationUpdatePersonStepperProps) => {
    const steps = getSteps(customerType);
    const navigate = useNavigate()

    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
                padding: '2rem 0rem 1.5rem 0rem',
                position: 'sticky',
                top: '0',
                backgroundColor: '#f7f7f7',
                zIndex: '999',
            }}
        >
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step) => (
                    <Step key={step.label}>
                        <StepButton color="inherit" onClick={() => navigate(step.link)}>
                            {step.label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        </Grid>
    )
}
