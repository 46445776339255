import React, { createContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import useAuth from './hooks/useAuth'
import * as yup from "yup";
import { yupLocale } from '../utils/yupLocale';
import eventBus from '../utils/eventBus';

yup.setLocale(yupLocale);

const AuthContext = createContext<any>(null)

function AuthProvider({ children }: { children: any }) {
    const { user, handleSignUp, handleSignIn, handleSignOut, fetchUser } = useAuth();

    useEffect(() => {
        eventBus.on("logout", () => {
            toast.error('Sua sessão expirou. Para continuar usando, é só logar de novo.');
            handleSignOut();
        });

        return () => {
            eventBus.remove("logout", () => { });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AuthContext.Provider value={{ user, handleSignUp, handleSignIn, handleSignOut, fetchUser }}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider }
