import React from "react";
import { PageInfo, PageTitle } from "./style";

export function PageName({ title, info } : {title:string , info?:any}) {
    return (
        <>
            <PageTitle>
                {title}
            </PageTitle>
          {info &&   <PageInfo>
                {info}
            </PageInfo>}
        </>
    );
}