import React from "react";
import { ButtonStyled } from "./styles";
import { ButtonProps } from "@mui/material";

interface StyledButtonProps {
    label?: string | null;
}

export const Button = <C extends React.ElementType>(props: ButtonProps<C, { component?: C }> & StyledButtonProps) => {
    return <ButtonStyled {...props}>
        {props.label}
        {props.children}
    </ButtonStyled>
}
