import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import React from "react";
import { EditOutlined, KeyboardArrowDown, KeyboardArrowUp, DeleteOutlined, Add, ErrorOutlineOutlined, InfoOutlined, CheckOutlined } from '@mui/icons-material';
import {
    IconButton,
    Table,
    TableBody,
    TableRow,
    Tooltip,
    Zoom,
    Collapse,
    Typography
} from '@mui/material';
import {
    TableCellBodyStyled,
    TableCellTitleStyled,
} from '../../styles/styles';
import { useTranslation } from 'react-i18next';
import * as masks from "../../utils/masks";
import bigDecimal from 'js-big-decimal';
import { IPartners } from '../../interfaces/partners';

interface UpdatePartnerProps {
    personalData: any;
    partnerRowClick: () => void;
    addPartner: () => void;
    editPartner: (partner: any) => void;
    deletePartner: (partner: any) => void;
    openPartner: boolean;
}

export function UpdatePartner({ personalData, partnerRowClick, addPartner, editPartner, deletePartner, openPartner }: UpdatePartnerProps) {

    const totalPercentage = () => {
        var total = new bigDecimal('0');
        for (var partner in personalData.partners) {
            total = total.add(new bigDecimal(personalData.partners[partner].shareCapital.toString()));
        }
        return total;
    }

    const { t } = useTranslation();
    return (
        <>
            <TableRow>
                <TableCellBodyStyled>
                    {t("text.businessPartners")}
                    {personalData.partners?.some((partner: any) =>
                        partner.partnerExistDocumentToUpdate === "1" && (partner.partnerIdentificationDocumentStateCode === "448560001" ||
                            partner.partnerIdentificationDocumentStateCode === "448560002")
                    ) ? (
                        <Tooltip
                            title={t("text.partnerDocumentUpdate")}
                            arrow
                        >
                            <IconButton sx={{ marginLeft: '8px', color: 'red' }}>
                                <InfoOutlined />
                            </IconButton>
                        </Tooltip>
                    ) : personalData.partners?.some((partner: any) =>
                        partner.partnerExistDocumentToUpdate === "1" && partner.partnerIdentificationDocumentStateCode === "999999999"
                    ) ? (
                        <Tooltip
                            title={t("text.partnerDocumentUpdate")}
                            arrow
                        >
                            <IconButton sx={{ marginLeft: '8px', color: 'green' }}>
                                <CheckOutlined />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                </TableCellBodyStyled>
                <TableCellBodyStyled
                    align="right"
                    sx={{
                        display: 'flex',
                        gap: '1rem'
                    }}
                >
                    <Tooltip
                        title={t("text.businessPartners")}
                        TransitionComponent={Zoom}
                        arrow
                    >
                        <IconButton
                            sx={{
                                color: 'var(--blue)'
                            }}
                            onClick={partnerRowClick}
                        >
                            {openPartner ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={t("text.add")}
                        TransitionComponent={Zoom}
                        arrow
                    >
                        <IconButton
                            sx={{
                                color: 'var(--blue)'
                            }}
                            onClick={addPartner}
                        >
                            <Add />
                        </IconButton>
                    </Tooltip>
                </TableCellBodyStyled>
            </TableRow>
            <TableRow>
                <TableCellBodyStyled style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
                    <Collapse in={openPartner} timeout="auto" unmountOnExit>
                        <div>
                            <Table stickyHeader>
                                <TableRow>
                                    <TableCellTitleStyled align="left">{t("text.document")}</TableCellTitleStyled>
                                    <TableCellTitleStyled align="left">{t("text.name")}</TableCellTitleStyled>
                                    <TableCellTitleStyled align="left">{t("text.percentageOnEquity")}</TableCellTitleStyled>
                                    <TableCellTitleStyled align="right">{t("text.actions")}</TableCellTitleStyled>
                                </TableRow>
                                <TableBody>
                                    {personalData.partners != null && personalData.partners.map((partner: IPartners) => {
                                        return <TableRow hover key={partner.id}>
                                            <TableCellBodyStyled align="left">{partner.typeId === "1" ? masks.cpfMask.maskDefault(partner.taxId) : masks.cnpjMask.maskDefault(partner.taxId)}</TableCellBodyStyled>
                                            <TableCellBodyStyled align="left">
                                                {partner.name}
                                                {partner.partnerExistDocumentToUpdate === "1" && (partner.partnerIdentificationDocumentStateCode === "448560001" ||
                                                    partner.partnerIdentificationDocumentStateCode === "448560002")
                                                    && (
                                                        <Tooltip
                                                            title={t("text.partnerDocumentUpdate")}
                                                            arrow
                                                        >
                                                            <IconButton sx={{ marginLeft: '8px', color: 'red' }}>
                                                                <InfoOutlined />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                {(partner.partnerExistDocumentToUpdate === "1" && partner.partnerIdentificationDocumentStateCode === "999999999")
                                                    && (
                                                        <Tooltip
                                                            title={t("text.partnerDocumentUpdate")}
                                                            arrow
                                                        >
                                                            <IconButton sx={{ marginLeft: '8px', color: 'green' }}>
                                                                <CheckOutlined />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                            </TableCellBodyStyled>
                                            {partner.shareCapital === 0 ?
                                                <TableCellBodyStyled align="left">
                                                    {formatCurrency(partner.shareCapital, { precision: 3 })}
                                                    <Tooltip title={t("validations.corporateStructurePercentageNotZero")} arrow>
                                                        <IconButton color='error'>
                                                            <ErrorOutlineOutlined />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCellBodyStyled> :
                                                <TableCellBodyStyled align="left">{formatCurrency(partner.shareCapital as number, { precision: 3 })}</TableCellBodyStyled>
                                            }
                                            <TableCellBodyStyled
                                                align="right"
                                                sx={{
                                                    display: 'flex',
                                                    gap: '1rem'
                                                }}
                                            >
                                                <Tooltip
                                                    title={t("text.remove")}
                                                    TransitionComponent={Zoom}
                                                    arrow
                                                >
                                                    <IconButton
                                                        sx={{
                                                            color: 'var(--red)'
                                                        }}
                                                        onClick={() => deletePartner(partner)}
                                                    >
                                                        <DeleteOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    title={t("text.edit")}
                                                    TransitionComponent={Zoom}
                                                    arrow
                                                >
                                                    <IconButton
                                                        sx={{
                                                            color: 'var(--blue)'
                                                        }}
                                                        onClick={() => editPartner(partner)}
                                                    >
                                                        <EditOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCellBodyStyled>
                                        </TableRow>
                                    })}
                                    {personalData.partners != null && personalData.partners.length === 0 ? (
                                        <TableRow>
                                            <TableCellBodyStyled colSpan={3}>
                                                <Typography
                                                    sx={{
                                                        color: 'var(--gray-900)',
                                                        fontSize: '1rem',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    {t("text.noItemsToDisplay")}
                                                </Typography>
                                            </TableCellBodyStyled>
                                        </TableRow>
                                    ) : <TableRow>
                                        <TableCellBodyStyled colSpan={2} />
                                        <TableCellBodyStyled>
                                            {formatCurrency(Number(totalPercentage().getValue()), { precision: 3 })} %
                                            {totalPercentage().compareTo(new bigDecimal('100')) !== 0 && (
                                                <Tooltip title={t("validations.corporateStructurePercentageNotOneHundred")} arrow>
                                                    <IconButton color='error'>
                                                        <ErrorOutlineOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </TableCellBodyStyled>
                                    </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </Collapse>
                </TableCellBodyStyled>
            </TableRow>
        </>
    );
}