import {
    MenuItem
} from '@mui/material';
import {Controller} from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Select } from "../Select";
import React from 'react';

type DocumentTypeUploadProp = {
    id:string ,
    name:string
}
interface DocumentTypeUploadProps {
    control:any, 
    name:string, 
    label:string, 
    documentTypeUploads:Array<DocumentTypeUploadProp>, 
    error:any, 
    resetField:any,
    disabled?:boolean
}

export function DocumentTypeUpload({ control, name, label, documentTypeUploads, error, resetField, ...rest }: DocumentTypeUploadProps) {
    const { t } = useTranslation();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <Select
                    value={field.value}
                    onChange={(e: string) => {
                        field.onChange(e);
                        resetField("documents", { defaultValue: [] });
                    }}
                    inputRef={field.ref}
                    error={error}
                    label={label}
                    displayEmpty
                    {...rest}
                >
                    <MenuItem value="">{t("text.select")}</MenuItem>
                    {documentTypeUploads.map((documentTypeUpload) => (
                        <MenuItem
                            key={documentTypeUpload.id}
                            value={documentTypeUpload.id}
                        >
                            {documentTypeUpload.name}
                        </MenuItem>
                    ))}
                </Select>
            )}
        />
    );
}