import { AppBar } from "@mui/material";
import styled from 'styled-components';

export const AppBarStyled = styled(AppBar)`
    && {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem 2rem;

        /* height: 5.75rem; */
        background-color: var(--brandColor-dark-blue);

        img {
            height: 3rem;
        }
    }
`;