import React from "react";
import {
  EditOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  DeleteOutlined,
  Add,
  InfoOutlined,
} from "@mui/icons-material";
import {
  IconButton,
  Table,
  TableBody,
  TableRow,
  Tooltip,
  Zoom,
  Collapse,
} from "@mui/material";
import { TableCellBodyStyled, TableCellTitleStyled } from "../../styles/styles";
import { useTranslation } from "react-i18next";
import { IBankDetails } from "../../interfaces/bankDetails";

interface UpdateBankDetailProps {
  personalData: any;
  bankRowClick: () => void;
  addBankDetailModal: () => void;
  editBankDetail: (bankDetail: any) => void;
  deleteBankDetail: (bankDetail: any) => void;
  openBankDetail: boolean;
}

export function UpdateBankDetail({
  personalData,
  bankRowClick,
  addBankDetailModal,
  editBankDetail,
  deleteBankDetail,
  openBankDetail,
}: UpdateBankDetailProps) {
  const { t } = useTranslation();
  return (
    <>
      <TableRow>
        <TableCellBodyStyled>
          {t("text.bankInformation")}
          {personalData.documentsToUpdate?.some((documentToUpdate: any) =>
            documentToUpdate.stateCode === 448560001 && [6].includes(documentToUpdate.documentType)
          ) && (
              <Tooltip
                title={t("text.jointAccountConfirmation")}
                arrow
              >
                <IconButton sx={{ marginLeft: '8px', color: 'red' }}>
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            )}
        </TableCellBodyStyled>
        <TableCellBodyStyled
          align="right"
          sx={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <Tooltip
            title={t("text.bankInformation")}
            TransitionComponent={Zoom}
            arrow
          >
            <IconButton
              sx={{
                color: "var(--blue)",
              }}
              onClick={bankRowClick}
            >
              {openBankDetail ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Tooltip>
          <Tooltip title={t("text.add")} TransitionComponent={Zoom} arrow>
            <IconButton
              sx={{
                color: "var(--blue)",
              }}
              onClick={addBankDetailModal}
            >
              <Add />
            </IconButton>
          </Tooltip>
        </TableCellBodyStyled>
      </TableRow>
      <TableRow>
        <TableCellBodyStyled
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={2}
        >
          <Collapse in={openBankDetail} timeout="auto" unmountOnExit>
            <div>
              <Table stickyHeader>
                <TableRow>
                  <TableCellTitleStyled align="left">
                    {t("text.bankName")}
                  </TableCellTitleStyled>
                  <TableCellTitleStyled align="left">
                    {t("text.branch")}
                  </TableCellTitleStyled>
                  <TableCellTitleStyled align="left">
                    {t("text.account")}
                  </TableCellTitleStyled>
                  <TableCellTitleStyled align="right">
                    {t("text.actions")}
                  </TableCellTitleStyled>
                </TableRow>
                <TableBody>
                  {personalData.bankingReferenceResponses.map((bankDetail: IBankDetails) => {
                    return (
                      <TableRow hover key={bankDetail.id}>
                        <TableCellBodyStyled align="left">
                          {bankDetail.name}
                        </TableCellBodyStyled>
                        <TableCellBodyStyled align="left">
                          {bankDetail.agency}
                        </TableCellBodyStyled>
                        <TableCellBodyStyled align="left">
                          {bankDetail.accountNumber}
                        </TableCellBodyStyled>
                        <TableCellBodyStyled
                          align="right"
                          sx={{
                            display: "flex",
                            gap: "1rem",
                          }}
                        >
                          <Tooltip
                            title={t("text.remove")}
                            TransitionComponent={Zoom}
                            arrow
                          >
                            <IconButton
                              sx={{
                                color: "var(--red)",
                              }}
                              onClick={() => deleteBankDetail(bankDetail)}
                            >
                              <DeleteOutlined />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={t("text.edit")}
                            TransitionComponent={Zoom}
                            arrow
                          >
                            <IconButton
                              sx={{
                                color: "var(--blue)",
                              }}
                              onClick={() => editBankDetail(bankDetail)}
                            >
                              <EditOutlined />
                            </IconButton>
                          </Tooltip>
                        </TableCellBodyStyled>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </Collapse>
        </TableCellBodyStyled>
      </TableRow>
    </>
  );
}
