import { isValidCEP, isValidCPF, isValidEmail } from '@brazilian-utils/brazilian-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add, Search } from '@mui/icons-material';
import {
    Alert,
    Card, CardActions, Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    Grid, MenuItem, Radio,
    RadioGroup,
    Typography
} from '@mui/material';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { sub } from "date-fns";
import React, { useEffect, useState, useRef } from "react";
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { InstanceProps, create } from 'react-modal-promise';
import { toast } from 'react-toastify';
import { useDidUpdate } from "rooks";
import * as yup from "yup";
import Document from '../Document';
import { findAddressByCep } from '../../services/cepService';
import { getAdministrator, updateAdministrator } from "../../services/clientService";
import { getAddressStatePromise } from '../../services/crmAddressState';
import { getCRMDocumentTypePromise } from '../../services/crmDocumentTypeService';
import {
    FormControlLabelStyled,
    FormHelperTextStyled,
    FormLabelInfoStyled,
    FormLabelStyled,
    SubtitlePageForm
} from '../../styles/styles';
import { languages } from '../../utils/adapterLocaleUtils';
import { displayErrorMessage } from '../../utils/errorMessage';
import { toBase64 } from "../../utils/fileUtils";
import * as masks from "../../utils/masks";
import { Button } from '../Button';
import { CRMAddressStateOptions } from '../CRMAddressStateOptions';
import { CRMDocumentTypeOptions } from '../CRMDocumentTypeOptions';
import { DialogTitleStyled } from '../DeleteModal/styles';
import DocumentAdditional from '../DocumentAdditional';
import { DocumentAlert } from '../DocumentAlert';
import { DocumentHead } from '../DocumentHead';
import { LoadingBox } from "../LoadingBox";
import { LoadingButton } from '../LoadingButton';
import { Select } from '../Select';
import { TextField } from '../TextField';
import { CurrencyTextField } from '../CurrencyTextField';
import { getSubscriptionTypesPromise } from '../../services/crmSignatureTypes';
import { SignatureTypesOptions } from '../SignatureTypesOptions';
import { stripToEmpty, removeSpecialCharactersAndAccents } from "../../utils/stringUtils";
import { getCRMDdiPromise } from '../../services/crmDdi';
import { CRMDdiOptions } from '../CRMDdiOptions';
import { AddressState } from '../EditAddressModal/AddressState';
import { IAdminstrator } from '../../interfaces/administrator';

const validationSchema = yup.object({
    typeOfRepresentative: yup.string().required(),
    taxId: yup.string()
        .required()
        .transform(masks.cpfMask.transform)
        .test("validateCPF", (value) => {
            return isValidCPF(value!)
        }),
    name: yup.string()
        .min(2)
        .required(),
    dateOfBirth: yup.date()
        .nullable()
        .transform((curr, orig) => orig === '' ? null : curr)
        .typeError('Error')
        .required()
        .max(sub(new Date(), { years: 18 }), "validations.under18"),
    email: yup.string()
        .required()
        .min(10)
        .transform((curr, orig) => stripToEmpty(curr))
        .test("validateEmail", (value) => isValidEmail(stripToEmpty(value))),
    phoneDdi: yup.string().nullable().required(),
    phoneDdd: yup.string().nullable().required().matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    phone: yup.string().nullable().required().matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    motherName: yup.string()
        .min(2)
        .required(),
    usPerson: yup.string().required(),
    nif: yup.string()
        .when("usPerson", {
            is: "1",
            then: yup.string().required()
        }),
    isPep: yup.string().required(),
    isPepExplain: yup.string()
        .when("isPep", {
            is: "1",
            then: yup.string().required()
        }),
    birthCity: yup.string().required(),
    birthState: yup.string().required(),
    hasDriverLicense: yup.string().required(),
    typeOfDocument: yup.string()
        .when("hasDriverLicense", {
            is: "2",
            then: yup.string().required()
        }),
    documentNumber: yup.string()
        .required()
        .matches(/^[a-zA-Z0-9]+$/, "validations.notSpecialCharacter"),
    documentIssuer: yup.string().required(),
    documentStateIssuer: yup.string().required(),
    documentDateOfIssue: yup.date()
        .nullable()
        .transform((curr, orig) => orig === '' ? null : curr)
        .typeError('Error')
        .required()
        .min(new Date(1500, 0, 1))
        .max(new Date()),
    addressZipCode: yup.string()
        .required()
        .transform(masks.cepMask.transform)
        .test("validateCep", (value) => {
            return isValidCEP(value!)
        }),
    addressStreet: yup.string().required(),
    addressNumber: yup.string().required(),
    addressNeighborhood: yup.string().required(),
    addressCity: yup.string().required(),
    addressState: yup.string().required(),
    addressComplement: yup.string(),
    isAssociate: yup.string()
        .when("typeOfRepresentative", {
            is: "120240000",
            then: yup.string().required(),
        }),
    shareCapital: yup.string()
        .when(["typeOfRepresentative", "isAssociate"], {
            is: (typeOfRepresentative: string, isAssociate: string) => isAssociate === "1" && typeOfRepresentative === "120240000",
            then: yup.string().required()
                .test({
                    name: "shareCapital",
                    message: "validations.percentageBetween0And100",
                    test: function (value) {
                        const percentage = parseFloat(value!);
                        return percentage > 0 && percentage <= 100;
                    }
                }),
        }),
    identificationProof: yup.mixed()
        .test("required", "validations.fileRequired", (value) => value.length > 0)
        .test("fileSize", "validations.fileSize", (value) => {
            return value.length && value[0].size <= 20971520;
        })
        .test("fileType", "validations.fileType", (value) => {
            return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
        }),
    documents: yup.array()
        .of(
            yup.object().shape({
                id: yup.string(),
                documentProof: yup.mixed()
                    .test("required", "validations.fileRequired", (value) => value.length > 0)
                    .test("fileSize", "validations.fileSize", (value) => {
                        return value.length && value[0].size <= 20971520;
                    })
                    .test("fileType", "validations.fileType", (value) => {
                        return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                    }),
            })
        ),
    subscriptionType: yup.string().required(),
});

interface EditAdministratorModalProps extends InstanceProps<any, any> {

    id?: string
}

const EditAdministratorModal: React.FC<EditAdministratorModalProps> = ({ open, onResolve, onReject, id }) => {
    const { i18n, t } = useTranslation();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [cep, setCep] = useState({ loading: false, error: false });
    const [documentTypes, setDocumentTypes] = useState([]);
    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [states, setStates] = useState<AddressState[]>([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const proofRef = useRef<any>(null);
    const [ddis, setDdis] = useState([]);

    const { control, handleSubmit, formState: { errors }, reset, resetField, register, getValues, setFocus, setValue } = useForm<IAdminstrator>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            typeOfRepresentative: "",
            taxId: "",
            name: "",
            dateOfBirth: "",
            birthCity: "",
            birthState: "",
            email: "",
            phoneDdi: "",
            phoneDdd: "",
            phone: "",
            motherName: "",
            hasDriverLicense: "",
            documentTypeId: "",
            documentNumber: "",
            documentIssuer: "",
            documentStateIssuer: "",
            documentDateOfIssue: "",
            identificationProof: "",
            addressZipCode: "",
            addressStreet: "",
            addressNumber: "",
            addressNeighborhood: "",
            addressCity: "",
            addressState: "",
            addressComplement: "",
            isAssociate: "",
            shareCapital: "",
            usPerson: "",
            nif: "",
            isPep: "",
            isPepExplain: "",
            subscriptionType: "",
            typeOfDocument: "",
        }
    });

    const { fields, append, remove } = useFieldArray<any, string, "keyName">({
        control,
        name: "documents",
        keyName: "keyName",
    });

    const isPep = useWatch({
        control,
        name: "isPep",
    });

    const usPerson = useWatch({
        control,
        name: "usPerson",
    });

    const hasDriverLicense = useWatch({
        control,
        name: "hasDriverLicense",
    });

    const typeOfRepresentative = useWatch({
        control,
        name: "typeOfRepresentative",
    });

    const typeOfDocument = useWatch({
        control,
        name: "typeOfDocument",
    });

    const isAssociate = useWatch({
        control,
        name: "isAssociate",
    });

    const ddiPhoneChanged = () => {
        setValue('phoneDdd', '');
        setValue('phone', '');
    }

    useEffect(() => {
        async function fetchAdministrator() {
            try {
                const [subscriptionTypes, documentTypes, states, ddisOptions, administrator] = await Promise.all([
                    getSubscriptionTypesPromise(),
                    getCRMDocumentTypePromise(),
                    getAddressStatePromise(),
                    getCRMDdiPromise(),
                    getAdministrator(id!),
                ]);

                setDocumentTypes(documentTypes.data);
                setSubscriptionTypes(subscriptionTypes.data)
                setStates(states.data);
                setDdis(ddisOptions.data);
                const administratorData = administrator.data;

                var identificationProof = administratorData.identificationProof;
                const typeOfDocument = identificationProof.typeId;
                const documentNumber = identificationProof.number;
                const documentIssuer = identificationProof.issuer;
                const documentStateIssuer = identificationProof.stateIssuer;
                const documentDateOfIssue = identificationProof.dateOfIssue;
                const identificationProofId = identificationProof.id;
                identificationProof = new File(["identificationProof"], identificationProof.fileOriginalName, {
                    type: identificationProof.fileType,
                });
                identificationProof.id = identificationProofId;

                let values = {
                    ...administratorData,
                    taxId: masks.cpfMask.maskDefault(administratorData.taxId),
                    typeOfDocument,
                    documentNumber,
                    documentIssuer,
                    documentStateIssuer,
                    documentDateOfIssue,
                    identificationProof: identificationProof ? [].concat(identificationProof) : "",
                    addressZipCode: masks.cepMask.maskDefault(administratorData.addressZipCode),
                }

                if (administratorData?.documents) {
                    let documents = [];

                    for (let i = 0; i < administratorData.documents.length; i = i + 1) {

                        var _documentProof: any = new File(["documentProof"], administratorData.documents[i].fileOriginalName, {
                            type: administratorData.documents[i].fileType,
                        });
                        _documentProof.id = administratorData.documents[i].id;


                        documents.push({
                            id: administratorData.documents[i].id,
                            documentTypeId: administratorData.documents[i].id,
                            documentProof: _documentProof ? [].concat(_documentProof) : "",
                        });
                    }

                    values = {
                        ...values,
                        documents: documents
                    };
                }

                reset(values);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }

        fetchAdministrator();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const findByCep = async () => {
        const zipCode = masks.cepMask.unmask(getValues("addressZipCode"));
        if (zipCode === "" || zipCode.length !== 8) return;
        setCep({ loading: true, error: false });
        try {
            const response = await findAddressByCep(zipCode);
            if (response.data.erro) {
                toast.error(`${t("validations.findAddress")}`);
                setCep({ loading: false, error: false });
                return;
            }
            const state = states.find(s => s.state === response.data.uf);
            const values = {
                ...getValues(),
                addressStreet: removeSpecialCharactersAndAccents(response.data.logradouro),
                addressNeighborhood: removeSpecialCharactersAndAccents(response.data.bairro),
                addressCity: removeSpecialCharactersAndAccents(response.data.localidade),
                addressState: state?.id,
                addressComplement: removeSpecialCharactersAndAccents(response.data.complemento),
            }

            setCep({ loading: false, error: false });
            reset(values);
        } catch (error) {
            setCep({ loading: false, error: true });
            console.log('error', error);
            toast.error(t("text.toast.zipCodeServiceUnavailable"));
        }
    }

    const onSubmit = async (data: IAdminstrator) => {

        setSubmitting(true);
        try {
            let identificationProof: any = data.identificationProof[0];
            const identificationProofId = identificationProof.id;
            if (!identificationProofId) {
                const result = await toBase64(identificationProof);
                identificationProof = {
                    typeId: data.typeOfDocument,
                    fileOriginalName: identificationProof.name,
                    fileInBase64: result,
                    fileSize: identificationProof.size,
                    fileType: identificationProof.type,
                    number: data.documentNumber,
                    issuer: data.documentIssuer,
                    stateIssuer: data.documentStateIssuer,
                    dateOfIssue: data.documentDateOfIssue,
                }
            } else {
                identificationProof = {
                    id: identificationProofId,
                    typeId: data.typeOfDocument,
                    number: data.documentNumber,
                    issuer: data.documentIssuer,
                    stateIssuer: data.documentStateIssuer,
                    dateOfIssue: data.documentDateOfIssue,
                }
            }

            data = {
                ...data,
                identificationProof,
            }

            if (data?.documents) {

                let documentProofs = [];
                for (let i = 0; i < data.documents.length; i = i + 1) {
                    let documentProof = data.documents[i].documentProof[0];
                    const result = !documentProof.id ? await toBase64(documentProof) : null;
                    documentProofs.push({
                        id: documentProof.id,
                        fileOriginalName: documentProof.name,
                        fileInBase64: result,
                        fileSize: documentProof.size,
                        fileType: documentProof.type
                    });
                }

                data = {
                    ...data,
                    documents: documentProofs
                }
            }

            await updateAdministrator(id!, data);
            toast.success(`${t("text.toast.successEdit")}`);
            onResolve();
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    const cannotAddDocument = () => {
        return fields.length !== 0 || uploading || (hasDriverLicense === "" || (hasDriverLicense === "2" && (typeOfDocument === "")));
    }

    useDidUpdate(() => {
        if (!cep.loading && !cep.error) setFocus("addressNumber", { shouldSelect: true });
    }, [cep]);

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            scroll="paper"
            fullWidth
        >
            {loading
                ? <LoadingBox />
                : <React.Fragment>
                    <DialogTitleStyled>
                        {t("text.editAdministrator")}
                    </DialogTitleStyled>
                    <DialogContent dividers>
                        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
                            <fieldset disabled={submitting || cep.loading} style={{ border: 0 }}>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Grid
                                            container
                                            spacing={1}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <SubtitlePageForm>
                                                    {t("text.identificationData")}
                                                </SubtitlePageForm>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <Divider />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                    >
                                        <Controller
                                            control={control}
                                            name="typeOfRepresentative"
                                            render={({ field }) => (
                                                <Select
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    inputRef={field.ref}
                                                    error={!!errors.typeOfRepresentative}
                                                    label={t("text.representativeType") as string}
                                                    displayEmpty
                                                >
                                                    <MenuItem value="">{t("text.select")}</MenuItem>
                                                    <MenuItem value="120240000">{t("text.administrator")}</MenuItem>
                                                    <MenuItem value="120240001">{t("text.director")}</MenuItem>
                                                </Select>
                                            )}
                                        />
                                        {errors.typeOfRepresentative && <FormHelperTextStyled>{displayErrorMessage(errors.typeOfRepresentative.message) as String}</FormHelperTextStyled>}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                    >
                                        <Controller
                                            control={control}
                                            name="taxId"
                                            render={({ field }) => (
                                                <TextField
                                                    disabled
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        e.persist();
                                                        masks.cpfMask.onChange(e);
                                                        field.onChange(e);
                                                    }}
                                                    inputRef={field.ref}
                                                    label={t("text.cpf")}
                                                    placeholder={t("text.cpf") as string}
                                                    fullWidth
                                                    error={!!errors.taxId}
                                                    helperText={displayErrorMessage(errors?.taxId?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <Controller
                                            control={control}
                                            name="name"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={t("text.fullName")}
                                                    placeholder={t("text.fullName") as string}
                                                    inputProps={{ maxLength: 100 }}
                                                    fullWidth
                                                    error={!!errors.name}
                                                    helperText={displayErrorMessage(errors?.name?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                    >
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            adapterLocale={
                                                languages[i18n.language as keyof typeof languages]
                                            }
                                        >
                                            <Controller
                                                control={control}
                                                name="dateOfBirth"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                        inputRef={field.ref}
                                                        inputFormat="dd/MM/yyyy"
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    placeholder: t("text.dob")
                                                                }}
                                                                label={t("text.dob")}
                                                                error={!!errors.dateOfBirth}
                                                                helperText={displayErrorMessage(errors?.dateOfBirth?.message) as string}
                                                                fullWidth
                                                                variant="outlined"
                                                            />
                                                        }
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <Controller
                                            control={control}
                                            name="birthCity"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={t("text.placeOfBirth")}
                                                    placeholder={t("text.placeOfBirth") as string}
                                                    inputProps={{ maxLength: 100 }}
                                                    fullWidth
                                                    error={!!errors.birthCity}
                                                    helperText={displayErrorMessage(errors?.birthCity?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                    >
                                        <CRMAddressStateOptions
                                            name="birthState"
                                            label={t("text.stateOfBirth")}
                                            control={control}
                                            states={states}
                                            error={!!errors.birthState}
                                        />
                                        <FormHelperTextStyled>
                                            {displayErrorMessage(errors.birthState?.message) as string}
                                        </FormHelperTextStyled>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Controller
                                            control={control}
                                            name="email"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    inputRef={field.ref}
                                                    label={t("text.email")}
                                                    placeholder={t("text.email") as string}
                                                    inputProps={{ maxLength: 100 }}
                                                    fullWidth
                                                    error={!!errors.email}
                                                    helperText={displayErrorMessage(errors?.email?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <Controller
                                            control={control}
                                            name="motherName"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={t("text.fullMotherName")}
                                                    placeholder={t("text.fullMotherName") as string}
                                                    inputProps={{ maxLength: 100 }}
                                                    fullWidth
                                                    error={!!errors.motherName}
                                                    helperText={displayErrorMessage(errors?.motherName?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={2}
                                        lg={2}
                                        xl={2}
                                    >
                                        <CRMDdiOptions
                                            control={control}
                                            label={t("text.ddi")}
                                            name="phoneDdi"
                                            ddis={ddis}
                                            error={!!errors.phoneDdi}
                                            ddiChanged={ddiPhoneChanged}
                                        />
                                        <FormHelperTextStyled>
                                            {displayErrorMessage(errors.phoneDdi?.message) as String}
                                        </FormHelperTextStyled>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={2}
                                        lg={2}
                                        xl={2}
                                    >
                                        <Controller
                                            control={control}
                                            name="phoneDdd"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={t("text.ddd")}
                                                    inputProps={{ maxLength: 3 }}
                                                    fullWidth
                                                    error={!!errors.phoneDdd}
                                                    helperText={displayErrorMessage(errors.phoneDdd?.message) as String}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={2}
                                        lg={2}
                                        xl={2}
                                    >
                                        <Controller
                                            control={control}
                                            name="phone"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={t("text.landline")}
                                                    inputProps={{ maxLength: 20 }}
                                                    fullWidth
                                                    error={!!errors.phone}
                                                    helperText={displayErrorMessage(errors.phone?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <DocumentAlert
                                            hasDriverLicense={hasDriverLicense}
                                            typeOfDocument={typeOfDocument}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <FormControl>
                                            <FormLabelStyled>
                                                {t("text.hasCNH")}
                                            </FormLabelStyled>
                                            <Controller
                                                control={control}
                                                name="hasDriverLicense"
                                                render={({ field }) => {
                                                    return (
                                                        <RadioGroup
                                                            row
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                resetField("documentTypeId");
                                                                if (proofRef.current !== null)
                                                                    proofRef.current.removeFile();
                                                                remove(0);
                                                            }}
                                                            value={field.value}
                                                        //  {...field}
                                                        >
                                                            <FormControlLabelStyled
                                                                value="1"
                                                                control={<Radio />}
                                                                label={t("text.yes")}
                                                                disabled={uploading}
                                                            />
                                                            <FormControlLabelStyled
                                                                value="2"
                                                                control={<Radio />}
                                                                label={t("text.no")}
                                                                disabled={uploading}
                                                            />
                                                        </RadioGroup>
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                        {errors.hasDriverLicense
                                            ? <FormHelperTextStyled>{displayErrorMessage(errors.hasDriverLicense.message) as string}</FormHelperTextStyled>
                                            : null
                                        }
                                    </Grid>
                                    {hasDriverLicense === "2" && (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                        >
                                            <CRMDocumentTypeOptions
                                                control={control}
                                                name="typeOfDocument"
                                                documentTypes={documentTypes}
                                                error={!!errors.typeOfDocument}
                                            />
                                            <FormHelperTextStyled>
                                                {displayErrorMessage(errors.typeOfDocument?.message) as string}
                                            </FormHelperTextStyled>
                                        </Grid>
                                    )}
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                    >
                                        <Controller
                                            control={control}
                                            name="documentNumber"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={(hasDriverLicense === "2") ? t("text.documentNumber") : t("text.cnhNumber")}
                                                    placeholder={(hasDriverLicense === "2") ? t("text.documentNumber") as string : t("text.cnhNumber") as string}
                                                    inputProps={{ maxLength: 100 }}
                                                    fullWidth
                                                    error={!!errors.documentNumber}
                                                    helperText={displayErrorMessage(errors?.documentNumber?.message) as String}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={hasDriverLicense === "2" ? 2 : 3}
                                        lg={hasDriverLicense === "2" ? 2 : 3}
                                        xl={hasDriverLicense === "2" ? 2 : 3}
                                    >
                                        <Controller
                                            control={control}
                                            name="documentIssuer"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={t("text.issuingBody")}
                                                    placeholder={t("text.issuingBody") as string}
                                                    inputProps={{ maxLength: 100 }}
                                                    fullWidth
                                                    error={!!errors.documentIssuer}
                                                    helperText={displayErrorMessage(errors?.documentIssuer?.message) as String}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={hasDriverLicense === "2" ? 2 : 3}
                                        lg={hasDriverLicense === "2" ? 2 : 3}
                                        xl={hasDriverLicense === "2" ? 2 : 3}
                                    >
                                        <CRMAddressStateOptions
                                            name="documentStateIssuer"
                                            label={t("text.issuingState")}
                                            control={control}
                                            states={states}
                                            error={!!errors.documentStateIssuer}
                                        />
                                        <FormHelperTextStyled>
                                            {displayErrorMessage(errors.documentStateIssuer?.message) as String}
                                        </FormHelperTextStyled>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                    >
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            adapterLocale={languages[i18n.language as keyof typeof languages]}
                                        >
                                            <Controller
                                                control={control}
                                                name="documentDateOfIssue"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                        inputRef={field.ref}
                                                        inputFormat="dd/MM/yyyy"
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    placeholder: t("text.issueDate")
                                                                }}
                                                                label={t("text.issueDate")}
                                                                error={!!errors.documentDateOfIssue}
                                                                helperText={displayErrorMessage(errors?.documentDateOfIssue?.message) as string}
                                                                fullWidth
                                                                variant="outlined"
                                                            />
                                                        }
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    {(hasDriverLicense === "1" || typeOfDocument !== "")
                                        ? <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Card>
                                                <DocumentHead
                                                    hasDriverLicense={hasDriverLicense}
                                                    typeOfDocument={typeOfDocument}
                                                />
                                                <Document
                                                    ref={proofRef}
                                                    register={register}
                                                    getValues={getValues}
                                                    title={t("text.add")}
                                                    name="identificationProof"
                                                    uploading={uploading}
                                                    setUploading={setUploading}
                                                />
                                                <CardActions>
                                                    <Typography
                                                        variant="caption"
                                                        display="block"
                                                    >
                                                        {t("text.allowedFileTypesAndSize")}
                                                    </Typography>
                                                </CardActions>
                                            </Card>
                                            {errors.identificationProof && <FormHelperTextStyled>{displayErrorMessage(errors.identificationProof?.message) as string}</FormHelperTextStyled>}
                                        </Grid>
                                        : null
                                    }
                                    {fields.map((item, index) => {
                                        return (
                                            <>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                    key={index}
                                                    paddingTop={"1rem"}
                                                >
                                                    <DocumentAdditional
                                                        index={index}
                                                        title={t("text.add")}
                                                        name={`documents.${index}.documentProof`}
                                                        register={register}
                                                        errors={errors}
                                                        remove={remove}
                                                        getValues={getValues}
                                                        hasDriverLicense={hasDriverLicense}
                                                        typeOfDocument={typeOfDocument}
                                                        uploading={uploading}
                                                        setUploading={setUploading}
                                                    />
                                                </Grid>
                                            </>
                                        );
                                    })}

                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        paddingTop={"1rem"}

                                    >
                                        <Button
                                            variant="outlined"
                                            label={t("text.addDocument")}
                                            startIcon={<Add />}
                                            onClick={() => append({
                                                id: "",
                                                fileOriginalName: "",
                                                fileSize: "",
                                                fileType: "",
                                                documentProof: "",
                                            })}
                                            disabled={cannotAddDocument()}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Grid
                                            container
                                            spacing={1}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <Alert severity="info">
                                                    {t("text.foreignAddressesCantBeAddedShort")}
                                                </Alert>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <SubtitlePageForm>
                                                    {t("text.address")}
                                                </SubtitlePageForm>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <Divider />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={9}
                                        lg={2}
                                        xl={2}
                                    >
                                        <Controller
                                            control={control}
                                            name="addressZipCode"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        e.persist();
                                                        masks.cepMask.onChange(e);
                                                        field.onChange(e);
                                                    }}
                                                    inputRef={field.ref}
                                                    label={t("text.zipCode")}
                                                    placeholder={t("text.zipCode") as string}
                                                    inputProps={{ maxLength: 20 }}
                                                    fullWidth
                                                    error={!!errors.addressZipCode}
                                                    helperText={displayErrorMessage(errors?.addressZipCode?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={3}
                                        lg={2}
                                        xl={2}
                                        sx={{
                                            marginTop: '1.3rem',
                                        }}
                                    >
                                        <Button
                                            type="button"
                                            label={t("text.search")}
                                            variant="outlined"
                                            startIcon={<Search />}
                                            onClick={findByCep}
                                            disabled={submitting || cep.loading}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={9}
                                        lg={5}
                                        xl={5}
                                    >
                                        <Controller
                                            control={control}
                                            name="addressStreet"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={t("text.streetName")}
                                                    placeholder={t("text.streetName") as string}
                                                    inputProps={{ maxLength: 250 }}
                                                    fullWidth
                                                    error={!!errors.addressStreet}
                                                    helperText={displayErrorMessage(errors?.addressStreet?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                    >
                                        <Controller
                                            control={control}
                                            name="addressNumber"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={t("text.number")}
                                                    placeholder={t("text.number") as string}
                                                    inputProps={{ maxLength: 10 }}
                                                    fullWidth
                                                    error={!!errors.addressNumber}
                                                    helperText={displayErrorMessage(errors?.addressNumber?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <Controller
                                            control={control}
                                            name="addressComplement"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={t("text.complement")}
                                                    placeholder={t("text.complement") as string}
                                                    inputProps={{ maxLength: 100 }}
                                                    fullWidth
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <Controller
                                            control={control}
                                            name="addressNeighborhood"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={t("text.neighborhood")}
                                                    placeholder={t("text.neighborhood") as string}
                                                    inputProps={{ maxLength: 100 }}
                                                    fullWidth
                                                    error={!!errors.addressNeighborhood}
                                                    helperText={displayErrorMessage(errors?.addressNeighborhood?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={9}
                                        lg={9}
                                        xl={9}
                                    >
                                        <Controller
                                            control={control}
                                            name="addressCity"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={t("text.city")}
                                                    placeholder={t("text.city") as string}
                                                    inputProps={{ maxLength: 80 }}
                                                    fullWidth
                                                    error={!!errors.addressCity}
                                                    helperText={displayErrorMessage(errors?.addressCity?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                    >
                                        <CRMAddressStateOptions
                                            name="addressState"
                                            label={t("text.uf")}
                                            control={control}
                                            states={states}
                                            error={!!errors.addressState}
                                        />
                                        <FormHelperTextStyled>
                                            {displayErrorMessage(errors.addressState?.message) as string}
                                        </FormHelperTextStyled>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Divider />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Grid
                                            container
                                            spacing={1}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <SubtitlePageForm>
                                                    {t("text.moreInformation")}
                                                </SubtitlePageForm>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <Divider />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <SignatureTypesOptions
                                            name="subscriptionType"
                                            label={t("text.subscriptionType")}
                                            control={control}
                                            subscriptionTypes={subscriptionTypes}
                                            error={!!errors.subscriptionType}
                                        />
                                        <FormHelperTextStyled>
                                            {displayErrorMessage(errors.subscriptionType?.message) as string}
                                        </FormHelperTextStyled>
                                    </Grid>
                                    {typeOfRepresentative === "120240000"
                                        ? <React.Fragment>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={3}
                                                lg={2}
                                                xl={2}
                                            >
                                                <FormControl>
                                                    <FormLabelStyled>
                                                        {t("text.isPartner")}
                                                    </FormLabelStyled>
                                                    <Controller
                                                        control={control}
                                                        name="isAssociate"
                                                        render={({ field }) => {
                                                            return (
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabelStyled
                                                                        value="1"
                                                                        control={<Radio />}
                                                                        label={t("text.yes")}
                                                                    />
                                                                    <FormControlLabelStyled
                                                                        value="2"
                                                                        control={<Radio />}
                                                                        label={t("text.no")}
                                                                    />
                                                                </RadioGroup>
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                                {errors.isAssociate
                                                    ? <FormHelperTextStyled>{displayErrorMessage(errors.isAssociate.message) as string}</FormHelperTextStyled>
                                                    : null
                                                }
                                            </Grid>
                                            {isAssociate === "1" ? <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={2}
                                                lg={2}
                                                xl={2}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="shareCapital"
                                                    render={({ field }) => (
                                                        <CurrencyTextField
                                                            label={t("text.percentageOnEquity")}
                                                            currencySymbol="%"
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            variant="outlined"
                                                            decimalPlaces={3}
                                                            value={field.value}
                                                            onChange={(e: string, v: string) => {
                                                                field.onChange(v);
                                                            }}
                                                            inputRef={(ref: any) => {
                                                                field.ref({
                                                                    focus: () => {
                                                                        ref.domElement.focus();
                                                                    }
                                                                });
                                                            }}
                                                            placeholder="0,00"
                                                            fullWidth
                                                            error={!!errors.shareCapital}
                                                            helperText={displayErrorMessage(errors.shareCapital?.message) as string}
                                                            disabled={uploading}
                                                        />
                                                    )}
                                                />
                                            </Grid> : null}
                                        </React.Fragment>
                                        : null
                                    }
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Grid
                                            container
                                            spacing={1}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <FormLabelStyled>
                                                    {t("text.areYouUsPerson")}
                                                </FormLabelStyled>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <FormLabelInfoStyled>
                                                    {t("text.usPersonExplanation")}
                                                </FormLabelInfoStyled>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <FormControl>
                                            <Controller
                                                control={control}
                                                name="usPerson"
                                                render={({ field }) => {
                                                    return (
                                                        <RadioGroup row {...field}>
                                                            <FormControlLabelStyled
                                                                value="1"
                                                                control={<Radio />}
                                                                label={t("text.yes")}
                                                            />
                                                            <FormControlLabelStyled
                                                                value="2"
                                                                control={<Radio />}
                                                                label={t("text.no")}
                                                            />
                                                        </RadioGroup>
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                        {errors.usPerson
                                            ? <FormHelperTextStyled>{displayErrorMessage(errors.usPerson.message) as String}</FormHelperTextStyled>
                                            : null
                                        }
                                    </Grid>
                                    {usPerson === "1"
                                        ?
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Controller
                                                control={control}
                                                name="nif"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                        inputRef={field.ref}
                                                        label={t("text.nif")}
                                                        placeholder={t("text.nif") as string}
                                                        inputProps={{ maxLength: 100 }}
                                                        fullWidth
                                                        error={!!errors.nif}
                                                        helperText={displayErrorMessage(errors.nif?.message) as String}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        : null
                                    }
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Grid
                                            container
                                            spacing={1}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <FormLabelStyled>
                                                    {t("text.isPepOrHasRelationshipWithPep")}
                                                </FormLabelStyled>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <FormLabelInfoStyled>
                                                    {t("text.pepExplanation")}
                                                </FormLabelInfoStyled>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <FormControl>
                                            <Controller
                                                control={control}
                                                name="isPep"
                                                render={({ field }) => {
                                                    return (
                                                        <RadioGroup row {...field}>
                                                            <FormControlLabelStyled
                                                                value="1"
                                                                control={<Radio />}
                                                                label={t("text.pepYes")}
                                                            />
                                                            <FormControlLabelStyled
                                                                value="2"
                                                                control={<Radio />}
                                                                label={t("text.pepNo")}
                                                            />
                                                        </RadioGroup>
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                        {errors.isPep
                                            ? <FormHelperTextStyled>{displayErrorMessage(errors.isPep.message) as String}</FormHelperTextStyled>
                                            : null
                                        }
                                    </Grid>
                                    {isPep === "1" && (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Controller
                                                control={control}
                                                name="isPepExplain"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                        inputRef={field.ref}
                                                        label={t("text.explain")}
                                                        placeholder={t("text.explain") as string}
                                                        fullWidth
                                                        error={!!errors.isPepExplain}
                                                        helperText={displayErrorMessage(errors?.isPepExplain?.message) as string}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </fieldset>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={submitting || cep.loading || uploading}
                            onClick={onReject}
                            label={t("text.cancel")}
                        />
                        <LoadingButton
                            type="submit"
                            label={t("text.saveChanges")}
                            form="submit-form"
                            loading={submitting || cep.loading || uploading}
                        />
                    </DialogActions>
                </React.Fragment>
            }
        </Dialog>
    );
}

export const editAdministratorModal = create(EditAdministratorModal);
