
import { SubSubtitlePageForm } from "../../styles/styles";

import React from "react";

export const DocumentSubtitleAdditional = ({ label} : { label: string }  )  => {
 
    return (
        <SubSubtitlePageForm>
            {label}
        </SubSubtitlePageForm>
    )
}
