import { Add, DeleteOutlined, EditOutlined } from '@mui/icons-material';
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Zoom
} from '@mui/material';
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addAdministratorModal } from '../../../components/AddAdministratorModal';
import { Button } from '../../../components/Button';
import { deleteModal } from '../../../components/DeleteModal';
import { editAdministratorModal } from '../../../components/EditAdministratorModal';
import { LoadingBox } from '../../../components/LoadingBox';
import { PageName } from '../../../components/PageName';
import { AuthContext } from "../../../contexts/auth";
import { deleteAdministrator, getAdministrators, updateAdministratorStep } from "../../../services/clientService";
import {
    TableCellBodyStyled,
    TableCellTitleStyled
} from '../../../styles/styles';
import * as masks from "../../../utils/masks";
import { LegalEntityStepper } from '../../../components/LegalEntityStepper';
import { LoadingButton } from '../../../components/LoadingButton';
import { IAdminstrator } from '../../../interfaces/administrator';

export function AdministrationLegalEntity({ onboarding = true }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { handleSignOut } = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [administrators, setAdministrators] = useState<IAdminstrator[]>([]);
    const [legalNature, setLegalNature] = useState<string>();
  //  const [legalNature, setLegalNature] = useState([]);

    useEffect(() => {
        fetchAdministrators();
    }, []);

    const fetchAdministrators = async () => {
        setLoading(true);
        try {
            const response = await getAdministrators();
            setAdministrators(response.data.administrators);
            setLegalNature(response.data.legalNature);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const _addAdministrator = async () => {
        try {
            await addAdministratorModal();
            fetchAdministrators();
        } catch (ignored) {
        }
    }

    const _editAdministrator = async (administrator:IAdminstrator) => {
        try {
            await editAdministratorModal({ id: administrator.id });
            fetchAdministrators();
        } catch (ignored) {
        }
    }

    const _deleteAdministrator = async (administrator:IAdminstrator) => {
        try {
            const title = t("text.remove");
            const message = `${t("text.areYouSureRemoveAdministrator")} ${administrator.name}?`;
            await deleteModal({
                title,
                message,
                onBeforeResolve: async () => await deleteAdministrator(administrator.id),
                closeOnConflict: true,
            });
            toast.success(`${t("text.toast.remove")}`);
            fetchAdministrators();
        } catch (ignored) {
        }
    }

    const nextStep = async () => {
        if (administrators.length === 0) {
            toast.error(t("text.addAtLeastOneAdministrator"));
            return;
        }

        setSubmitting(true);
        try {
            if (onboarding) await updateAdministratorStep();

            let to;
            legalNature === "120240004" 
            ? to = onboarding  
            ? `/onboarding/financial-data-legal-entity-register` 
            : `/dashboard/financial-data-legal-entity` 
            : to = onboarding  
            ? `/onboarding/corporate-structure-legal-entity-register` 
            : `/dashboard/corporate-structure-legal-entity`;

            navigate(to);
        } catch (error:any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <Grid
            container
            sx={onboarding
                ? {
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '2rem',
                }
                : null}
        >
            {loading
                ? <LoadingBox />
                : <React.Fragment>
                    <LegalEntityStepper
                        activeStep={4}
                        register={onboarding}
                        legalNature={legalNature}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={onboarding ? 10 : 12}
                        lg={onboarding ? 10 : 12}
                        xl={onboarding ? 10 : 12}
                        sx={{
                            background: 'var(--white)',
                            borderRadius: '4px',
                            padding: '2rem',
                        }}
                    >
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <PageName
                                    title={t("text.administrator")}
                                    info={t("text.youNeedToRegisterAdministratorsOrDirectors")}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={6}
                                                xl={4}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    label={t("text.addAdministrator")}
                                                    startIcon={<Add />}
                                                    onClick={_addAdministrator}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <TableContainer
                                            component={Paper}
                                            sx={{
                                                '@media (max-width: 1366px)': {
                                                    maxHeight: 440,
                                                },
                                                maxHeight: 640,
                                            }}
                                        >
                                            <Table
                                                stickyHeader
                                                aria-label={t("text.administratorsTable")}
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCellTitleStyled align="left">{t("text.administrator")}</TableCellTitleStyled>
                                                        <TableCellTitleStyled align="right">{t("text.cpf")}</TableCellTitleStyled>
                                                        <TableCellTitleStyled align="right">{t("text.actions")}</TableCellTitleStyled>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {administrators.map((administrator) => {
                                                        return <TableRow hover key={administrator.id}>
                                                            <TableCellBodyStyled align="left">{administrator.name}</TableCellBodyStyled>
                                                            <TableCellBodyStyled align="right">{masks.cpfMask.maskDefault(administrator.taxId)}</TableCellBodyStyled>
                                                            <TableCellBodyStyled
                                                                align="right"
                                                                sx={{
                                                                    display: 'flex',
                                                                    gap: '1rem'
                                                                }}
                                                            >
                                                                <Tooltip
                                                                    title={t("text.remove")}
                                                                    TransitionComponent={Zoom}
                                                                    arrow
                                                                >
                                                                    <IconButton
                                                                        sx={{
                                                                            color: 'var(--red)'
                                                                        }}
                                                                        onClick={() => _deleteAdministrator(administrator)}
                                                                    >
                                                                        <DeleteOutlined />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip
                                                                    title={t("text.edit")}
                                                                    TransitionComponent={Zoom}
                                                                    arrow
                                                                >
                                                                    <IconButton
                                                                        sx={{
                                                                            color: 'var(--blue)'
                                                                        }}
                                                                        onClick={() => _editAdministrator(administrator)}
                                                                    >
                                                                        <EditOutlined />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCellBodyStyled>
                                                        </TableRow>
                                                    })}
                                                    {administrators.length === 0 && (
                                                        <TableRow>
                                                            <TableCellBodyStyled colSpan={3}>
                                                                <Typography
                                                                    sx={{
                                                                        color: 'var(--gray-900)',
                                                                        fontSize: '1rem',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center'
                                                                    }}
                                                                >
                                                                    {t("text.noItemsToDisplay")}
                                                                </Typography>
                                                            </TableCellBodyStyled>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <Button
                                            variant="outlined"
                                            label={t("text.previousStep")}
                                            component={Link}
                                            to={onboarding ? "/onboarding/banking-references-legal-entity-register" : "/dashboard/banking-references-legal-entity"}
                                            disabled={submitting}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <LoadingButton
                                            onClick={nextStep}
                                            variant="contained"
                                            label={t("text.nextStep")}
                                            fullWidth
                                            loading={submitting}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Button
                                            variant="text"
                                            label={t("text.continueLater")}
                                            onClick={handleSignOut}
                                            disabled={submitting}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
        </Grid>
    );
}
