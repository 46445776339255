import { useMemo, useContext } from "react";
import axios from "axios";
import { AuthContext } from '../contexts/auth';
import eventBus from "../utils/eventBus";
import { useTranslation } from "react-i18next";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json'
    },
});

const AxiosInterceptor = ({ children } : {children:any}) => {
    const { user } = useContext(AuthContext)
    const { i18n } = useTranslation();

    useMemo(() => {
        const interceptor = api.interceptors.request.use(function (config) {
            const { withCredentials, ...interceptedConfig } = config;
            interceptedConfig.headers = {
                ...config.headers,
                'Accept-Language': i18n.language
            };
            if (withCredentials) {
                const { accessToken } = user.value;

                interceptedConfig.headers = {
                    ...interceptedConfig.headers,
                    Authorization: `Bearer ${accessToken}`
                };
            }

            return interceptedConfig;
        });

        return () => api.interceptors.response.eject(interceptor);
    }, [i18n.language, user.value]);

    return children;
};

api.interceptors.response.use((response) => {
    return Promise.resolve(response);
}, async function (error) {
    if (error.response.status === 401) {
        eventBus.dispatch("logout",()=>{});
        return Promise.reject();
    }
    return Promise.reject(error);
})

export default api;
export { AxiosInterceptor };
