import { Card, CardActions, CardContent, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

interface CardStyledProps {
    showBorder: boolean;
}

export const CardStyled = styled(Card, {
    shouldForwardProp: (prop: string) => prop !== "showBorder"
}) <CardStyledProps>`
    && {
        background: var(--white);
        border-radius: 4px;
        border: ${(props: { showBorder: any; }) => props.showBorder ? '2px solid rgba(242, 165, 22, 0.3)' : 'none'};
        width: 100%!important;
    }
`;

export const CardContentStyled = styled(CardContent)`
    && {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        padding: 1.5rem;
        height: 8rem;

        @media(max-width: 500px) {
            height: 12rem;
        }
    }
`;

export const CardTitle = styled(Typography)`
    && {
        font-size: 1.25rem;
        color: var(--gray-900);
        font-weight: 700;
        margin-bottom: .5rem;
    }
`;

export const CardInfo = styled(Typography)`
    && {
        font-size: 1rem;
        color: var(--gray-600);
        font-weight: 400;
    }
`;

export const CardActionsStyled = styled(CardActions)`
    && {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 1rem;
    }
`;

export const CardIcon = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg {
        color: var(--brandColor-dark-blue);
        font-size: 3rem;
    }
`;
