import {
    AccountBalanceOutlined,
    DescriptionOutlined,
    HistoryEduOutlined,
    PaidOutlined,
    PeopleOutlined,
    PersonAddAlt,
    PersonOutline,
    PhoneOutlined,
    StoreOutlined,
    UpdateOutlined
} from "@mui/icons-material";
import {
    Alert,
    AlertTitle,
    Badge,
    Grid,
    Typography
} from "@mui/material";
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CardHome } from "../../components/Card";
import { PageName } from "../../components/PageName";
import { AuthContext } from "../../contexts/auth";
import { decapitalizeFirstLetter } from "../../utils/stringUtils";
import { toast } from 'react-toastify';
import { LoadingBox } from "../../components/LoadingBox";
import { useNavigate } from "react-router-dom";

const TypographyBody1 = ({ children }: { children: any }) => (
    <Typography variant="body1">
        {children}
    </Typography>
);

const TypographyBody2 = ({ children }: { children: any }) => (
    <Typography variant="body2">
        {children}
    </Typography>
);

const TypographyBody2Strong = ({ children }: { children: any }) => (
    <Typography variant="body2">
        <strong>{children}</strong>
    </Typography>
);

export function Home() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user, fetchUser } = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(true);
    const { value: { name, type, productId, productName, estimatedValueName, sa, canEdit, partnerOrClient, warning, stateCode, update, updateStateCode } } = user;
    const warningMessages = warning;
    const warningsSocialStructure = warningMessages?.messages.filter((warningSocialStructure: any) => warningSocialStructure.type === "QuadroSocietario").length;
    const warningsBankDetail = warningMessages?.messages.filter((warningsBankDetail: any) => warningsBankDetail.type === "DadoBancario").length;
    const warningsContact = warningMessages?.messages.filter((warningsContact: any) => warningsContact.type === "Contato").length;
    const warningsLegalRepresentative = warningMessages?.messages.filter((warningLegalRepresentative: any) => warningLegalRepresentative.type === "Administrador").length;

    useEffect(() => {
        const refreshUser = async () => {
            try {
                await fetchUser();
                setLoading(false);
            } catch (error: any) {
                const errorMessage = error.response?.data?.message || error.message;
                toast.error(errorMessage);
            }
        }
        refreshUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*useDidUpdate(async () => {
        if (update) {
            try {
                await registrationUpdateNotificationModal();
                navigate(
                    type === 1
                        ? `/update/personal-data-update`
                        : `/update/corporate-data-update`
                );
            } catch (ignored) {
            }
        }
    }, [user]);*/

    const getRegistrationActiveAndReadyMessage = () => {
        const textToReplace = t("text.registrationActiveAndReadyMessage");

        const replacedContent = textToReplace
            .replace(/{{typographyBody1}}(.*?){{\/typographyBody1}}/g, (_, innerContent) => `<TypographyBody1>${innerContent}</TypographyBody1>`)
            .replace(/{{typographyBody2}}(.*?){{\/typographyBody2}}/g, (_, innerContent) => `<TypographyBody2>${innerContent}</TypographyBody2>`)
            .replace(/{{typographyBody2Strong}}(.*?){{\/typographyBody2Strong}}/g, (_, innerContent) => `<TypographyBody2Strong>${innerContent}</TypographyBody2Strong>`)

        const div = document.createElement('div');
        div.innerHTML = replacedContent;
        const replacedNodes = Array.from(div.childNodes);

        return replacedNodes.map((node, index) => {
            if (node.nodeName.toLowerCase() === 'typographybody1') {
                return <TypographyBody1 key={index}>{node.textContent}</TypographyBody1>;
            } else if (node.nodeName.toLowerCase() === 'typographybody2') {
                return <TypographyBody2 key={index}>{node.textContent}</TypographyBody2>;
            } else if (node.nodeName.toLowerCase() === 'typographybody2strong') {
                return <TypographyBody2Strong key={index}>{node.textContent}</TypographyBody2Strong>;
            } else {
                return node.textContent;
            }
        });
    }

    return (
        <Grid
            container
            spacing={3}>
            {loading
                ? <LoadingBox />
                : <React.Fragment>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        <PageName
                            title={`${t("text.greeting")}, ${name}!`}
                            info={t("text.manageYourDataAndMakeChanges")}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        {stateCode === "1" && !update // QUALIFIED
                            ? <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                paddingBottom={2}
                            >
                                <Alert severity="success">
                                    <AlertTitle><strong>{t("text.registrationActiveAndReadyTitle")}</strong></AlertTitle>
                                    {getRegistrationActiveAndReadyMessage()}
                                </Alert>
                            </Grid>
                            : null
                        }
                        {stateCode === "2" && !update// REVIEW
                            ? <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                paddingBottom={2}
                            >
                                <Alert severity="error">
                                    <AlertTitle><strong>{t("text.alertSubtitle1")}</strong></AlertTitle>
                                    <Typography variant="body1">{t("text.alertBody1")}</Typography>
                                    <Typography variant="body2">{t("text.alertBody2")}</Typography>
                                    <Typography variant="body2">{t("text.alertBody3")}</Typography>
                                </Alert>
                            </Grid>
                            : null
                        }
                        {stateCode === "3" && !update// PENDING
                            ? <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                paddingBottom={2}
                            >
                                <Alert severity="warning">
                                    <p>{warningMessages.warningCount} {warningMessages.warningCount > 1
                                        ? t("validations.pendingIssuesFound")
                                        : t("validations.pendingFound")
                                    }</p>
                                    {warningMessages.messages.map((message: any) => (
                                        <p key={message.message}>• {t(`${message.message}`)}{ }</p>
                                    ))}
                                </Alert>
                            </Grid>
                            : null
                        }
                        {stateCode === "4" && !update// WAITING
                            ? <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                paddingBottom={2}
                            >
                                <Alert severity="info">
                                    <AlertTitle><strong>{t("text.registrationWaitingTitle")}</strong></AlertTitle>
                                    <Typography variant="body1">{t("text.registrationWaitingMessage")}</Typography>
                                </Alert>
                            </Grid>
                            : null
                        }
                        {update && updateStateCode === "0" // UPDATE PENDING
                            ? <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                paddingBottom={2}
                            >
                                <Alert severity="info">
                                    <AlertTitle><strong>{t("text.updateNotificationTitle")}</strong></AlertTitle>
                                    <Typography variant="body1">{t("text.updateNotificationMessage")}</Typography>
                                </Alert>
                            </Grid>
                            : null
                        }
                        <Grid
                            container
                            spacing={2}
                        >
                            {type === 2
                                ? <>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                    >
                                        {update
                                            ? <CardHome
                                                title={t("text.registrationProfile")}
                                                info={
                                                    partnerOrClient === "120240000"
                                                        ? t("text.onlyClientInformations")
                                                        : partnerOrClient === "120240001"
                                                            ? t("text.onlyIndicatorInformations")
                                                            : t("text.changeOrAddMoreInformations")
                                                }
                                                icon={<PersonOutline />}
                                                buttonLabel={t("text.updateInformation")}
                                                component={Link}
                                                to="/update/corporate-data-update"
                                                canEdit={updateStateCode === "0"}
                                            />
                                            : <CardHome
                                                title={t("text.registrationProfile")}
                                                info={
                                                    partnerOrClient === "120240000"
                                                        ? t("text.onlyClientInformations")
                                                        : partnerOrClient === "120240001"
                                                            ? t("text.onlyIndicatorInformations")
                                                            : t("text.changeOrAddMoreInformations")
                                                }
                                                icon={<PersonOutline />}
                                                buttonLabel={t("text.changeProfile")}
                                                component={Link}
                                                to="/dashboard/contact-reason-legal-entity"
                                                canEdit={canEdit}
                                            />}
                                    </Grid>
                                    {/* <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                    >
                                        <CardHome
                                            title={t("text.updateMenuTitle")}
                                            info={t("text.updateNotificationMessage")}
                                            icon={<UpdateOutlined />}
                                            buttonLabel={t("text.updateMenuTitle")}
                                            component={Link}
                                            to={`/update/corporate-data-update`}
                                            canEdit={updateStateCode === "0"}
                                        />
                                    </Grid> */}
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                    >
                                        <Badge
                                            sx={{ width: '100%' }}
                                            badgeContent={warningsLegalRepresentative}
                                            color="warning"
                                            max={999}
                                        >
                                            <CardHome
                                                showBorder={warningsLegalRepresentative > 0}
                                                title={t("text.administrator")}
                                                info={t("text.addOrChangeRepresentatives")}
                                                icon={<PeopleOutlined />}
                                                buttonLabel={t("text.checkAdministrator")}
                                                component={Link}
                                                to="/dashboard/administration"
                                                canEdit={canEdit}
                                            />
                                        </Badge>
                                    </Grid>
                                    {
                                        sa
                                            ? null
                                            : <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={6}
                                                xl={6}
                                            >
                                                <Badge
                                                    sx={{ width: '100%' }}
                                                    badgeContent={warningsSocialStructure}
                                                    color="warning"
                                                >
                                                    <CardHome
                                                        showBorder={warningsSocialStructure > 0}
                                                        title={t("text.corporateStructure")}
                                                        info={t("text.registerBusinessPartners")}
                                                        icon={<PersonAddAlt />}
                                                        buttonLabel={t("text.checkBusinessPartner")}
                                                        component={Link}
                                                        to="/dashboard/corporate-structure"
                                                        canEdit={canEdit}
                                                    />
                                                </Badge>
                                            </Grid>
                                    }
                                    {/* <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                    >
                                        <CardHome
                                            title="Cadastrar filial"
                                            info="Preencha as informações da filial caso precise cadastrá-la."
                                            icon={<AddBusinessOutlined />}
                                            buttonLabel="Cadastrar filial"
                                            component={Link}
                                            to="/dashboard/register-subsidiary-legal-entity"
                                        />
                                    </Grid> */}
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                    >
                                        <Badge
                                            sx={{ width: '100%' }}
                                            badgeContent={warningsBankDetail}
                                            color="warning"
                                        >
                                            <CardHome
                                                showBorder={warningsBankDetail > 0}
                                                border={false}
                                                title={t("text.bankInformation")}
                                                info={t("text.addOrConsultBankInformation")}
                                                icon={<AccountBalanceOutlined />}
                                                buttonLabel={t("text.checkBankInformation")}
                                                component={Link}
                                                to="/dashboard/bank-details-legal-entity"
                                                canEdit={canEdit}
                                            />
                                        </Badge>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                    >
                                        <Badge
                                            sx={{ width: '100%' }}
                                            badgeContent={warningsContact}
                                            color="warning"
                                        >
                                            <CardHome
                                                showBorder={warningsContact > 0}
                                                title={t("text.contacts")}
                                                info={t("text.changeOrAddMoreContacts")}
                                                icon={<PhoneOutlined />}
                                                buttonLabel={t("text.changeOrAddContacts")}
                                                component={Link}
                                                to="/dashboard/contacts-legal-entity"
                                                canEdit={canEdit}
                                            />
                                        </Badge>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                    >
                                        <CardHome
                                            title={t("text.powerOfAttorney")}
                                            info={t("text.addPowerOfAttorneyInformationAndDocuments")}
                                            icon={<HistoryEduOutlined />}
                                            buttonLabel={t("text.checkPowerOfAttorney")}
                                            component={Link}
                                            to="/dashboard/power-of-attorney-legal-entity"
                                            canEdit={canEdit}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                    >
                                        <CardHome
                                            title={t("text.registrationDocuments")}
                                            info={t("text.manageYourDocumentOrAddOthers")}
                                            icon={<DescriptionOutlined />}
                                            buttonLabel={t("text.addOrUpdateRegistrationDocuments")}
                                            component={Link}
                                            to="/dashboard/registration-documents"
                                            canEdit={canEdit}
                                        />
                                    </Grid>
                                </>
                                : <>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                    >
                                        {update
                                            ? <CardHome
                                                title={t("text.registrationProfile")}
                                                info={`${t(productName)} ${t("text.pretensionToOperate")} ${decapitalizeFirstLetter(estimatedValueName)} ${t("text.during12Months")}`}
                                                icon={<PersonOutline />}
                                                buttonLabel={t("text.updateInformation")}
                                                component={Link}
                                                to="/update/personal-data-update"
                                                canEdit={updateStateCode === "0"}
                                            />
                                            : <CardHome
                                                title={t("text.registrationProfile")}
                                                info={`${t(productName)} ${t("text.pretensionToOperate")} ${decapitalizeFirstLetter(estimatedValueName)} ${t("text.during12Months")}`}
                                                icon={<PersonOutline />}
                                                buttonLabel={t("text.changeProfile")}
                                                component={Link}
                                                to="/dashboard/contact-reason"
                                                canEdit={canEdit}
                                            />}


                                    </Grid>

                                    {/* <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                    >
                                        <CardHome
                                            title={t("text.updateMenuTitle")}
                                            info={t("text.updateNotificationMessage")}
                                            icon={<UpdateOutlined />}
                                            buttonLabel={t("text.updateMenuTitle")}
                                            component={Link}
                                            to={`/update/personal-data-update`}
                                            canEdit={updateStateCode === "0"}
                                        />
                                    </Grid> */}

                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                    >
                                        <Badge
                                            sx={{ width: '100%' }}
                                            badgeContent={warningsContact}
                                            color="warning"
                                        >
                                            <CardHome
                                                showBorder={warningsContact > 0}
                                                title={t("text.contacts")}
                                                info={t("text.changeOrAddMoreContacts")}
                                                icon={<PhoneOutlined />}
                                                buttonLabel={t("text.changeOrAddContacts")}
                                                component={Link}
                                                to="/dashboard/contacts"
                                                canEdit={canEdit}
                                            />
                                        </Badge>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                    >
                                        <Badge
                                            sx={{ width: '100%' }}
                                            badgeContent={warningsBankDetail}
                                            color="warning"
                                        >
                                            <CardHome
                                                showBorder={warningsBankDetail > 0}
                                                title={t("text.bankInformation")}
                                                info={t("text.addOrConsultBankInformation")}
                                                icon={<AccountBalanceOutlined />}
                                                buttonLabel={t("text.checkBankInformation")}
                                                component={Link}
                                                to="/dashboard/bank-details"
                                                canEdit={canEdit}
                                            />
                                        </Badge>
                                    </Grid>
                                    {productId === '6c7e3226-bd70-ed11-81ac-0022481b5e08' || productId === '0141d350-bd70-ed11-81ac-0022481b5e08'
                                        ? <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={6}
                                            xl={6}
                                        >
                                            <CardHome
                                                title={t("text.prePaidCardInformation")}
                                                info={t("text.changeOrAddCardInformation")}
                                                icon={<PaidOutlined />}
                                                buttonLabel={t("text.addOrChangeData")}
                                                component={Link}
                                                to="/dashboard/pre-paid-card"
                                                canEdit={canEdit}
                                            />
                                        </Grid>
                                        : null
                                    }
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                    >
                                        <CardHome
                                            title={t("text.powerOfAttorney")}
                                            info={t("text.addPowerOfAttorneyInformationAndDocuments")}
                                            icon={<HistoryEduOutlined />}
                                            buttonLabel={t("text.checkPowerOfAttorney")}
                                            component={Link}
                                            to="/dashboard/power-of-attorney"
                                            canEdit={canEdit}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                    >
                                        <CardHome
                                            title={t("text.registrationDocuments")}
                                            info={t("text.manageYourDocumentOrAddOthers")}
                                            icon={<DescriptionOutlined />}
                                            buttonLabel={t("text.addOrUpdateRegistrationDocuments")}
                                            component={Link}
                                            to="/dashboard/registration-documents"
                                            canEdit={canEdit}
                                        />
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
        </Grid >
    );
}