import { yupResolver } from '@hookform/resolvers/yup';
import {
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    Radio,
    RadioGroup
} from '@mui/material';
import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { create } from 'react-modal-promise';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { Button } from '../Button';
import { LoadingBox } from '../LoadingBox';
import { LoadingButton } from '../LoadingButton';
import {
    FormControlLabelStyled,
    FormHelperTextStyled,
    FormLabelStyled
} from '../../styles/styles';
import { displayErrorMessage } from '../../utils/errorMessage';
import { DialogTitleStyled } from '../DeleteModal/styles';
import { getPersonalDataUpdatePromise, updatePurposeData } from '../../services/updateService';
import { getCRMProductPromise } from '../../services/crmProduct';
import { CRMProductSelect } from '../CRMProductSelect';
import {IPurposeData} from '../../interfaces/purposeData'

const validationSchema = yup.object({
    productId: yup.string().required(),
    estimatedValueId: yup.string()
        .when("productId", {
            is: (val: string) => val === "fe49f319-bd70-ed11-81ac-0022481b5e08" || val === "0141d350-bd70-ed11-81ac-0022481b5e08",
            then: yup.string()
                .test("estimatedValueId", (value) => {
                    return value === "1" || value === "2";
                })
        })
        .when("productId", {
            is: "6c7e3226-bd70-ed11-81ac-0022481b5e08",
            then: yup.string()
                .test("estimatedValueId", (value) => {
                    return value === "1" || value === "2" || value === "3";
                })
        }),
});

interface EditContactReasonModalProps {
    open: boolean;
    onResolve : ()=>void;
    onReject : ()=>void;
}

const EditContactReasonModal:React.FC<EditContactReasonModalProps> = ({ open, onResolve, onReject }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [productOptions, setProductOptions] = useState([]);

    const { control, handleSubmit, formState: { errors }, reset } = useForm<IPurposeData>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            productId: "",
            estimatedValueId: "",
        }
    });

    const productId = useWatch({
        control,
        name: "productId"
    });

    

    useEffect(() => {
        async function fetchPurposeData() {
            try {
                const [productOptions, purposeData] = await Promise.all([
                    getCRMProductPromise(),
                    getPersonalDataUpdatePromise()
                ]);

                setProductOptions(productOptions.data);

                let responseData = purposeData.data;

                let values = {
                    productId: responseData.productId,
                    estimatedValueId: responseData.estimatedValueId,
                }

                if (values.productId) {
                    reset(values);
                }

                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }

        fetchPurposeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (data:IPurposeData) => {
        setSubmitting(true);
        try {
            await updatePurposeData(data);
            toast.success(`${t("text.toast.successEdit")}`);
            onResolve();
        } catch (error:any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            scroll="paper"
            fullWidth
        >
            {loading
                ? <LoadingBox />
                : <React.Fragment>
                    <DialogTitleStyled>
                        {t("text.contactReason")}
                    </DialogTitleStyled>
                    <DialogContent dividers>
                        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
                            <fieldset disabled={submitting} style={{ border: 0 }}>
                                <Grid
                                    container
                                    spacing={2}
                                >

                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <CRMProductSelect
                                            control={control}
                                            name="productId"
                                            products={productOptions}
                                        />

                                        {errors.productId && (
                                            <FormHelperTextStyled>
                                                {displayErrorMessage(errors.productId?.message) as string}
                                            </FormHelperTextStyled>
                                        )}
                                    </Grid>
                                    {productId && (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <FormControl>
                                                <FormLabelStyled>
                                                    {t("text.howMuchYouIntendToOperateDuring12Months")}
                                                </FormLabelStyled>
                                                <Controller
                                                    control={control}
                                                    name="estimatedValueId"
                                                    render={({ field }) => {
                                                        return (
                                                            <RadioGroup {...field}>
                                                                <FormControlLabelStyled
                                                                    value="1"
                                                                    control={<Radio />}
                                                                    label={t("text.until70Thousand")}
                                                                />
                                                                <FormControlLabelStyled
                                                                    value="2"
                                                                    control={<Radio />}
                                                                    label={t("text.over70Thousand")}
                                                                />
                                                            </RadioGroup>
                                                        );
                                                    }}
                                                />
                                            </FormControl>
                                            {errors.estimatedValueId && (
                                                <FormHelperTextStyled>
                                                    {displayErrorMessage(errors.estimatedValueId?.message) as string}
                                                </FormHelperTextStyled>
                                            )}
                                        </Grid>
                                    )}
                                </Grid>
                            </fieldset>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={submitting}
                            onClick={onReject}
                            label={t("text.cancel")}
                        />
                        <LoadingButton
                            type="submit"
                            label={t("text.saveChanges")}
                            form="submit-form"
                            loading={submitting}
                        />
                    </DialogActions>
                </React.Fragment>
            }
        </Dialog>
    );
}

export const editContactReasonModal = create(EditContactReasonModal);
