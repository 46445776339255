import { formatCEP, formatCNPJ, formatCurrency } from "@brazilian-utils/brazilian-utils";
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Checkbox,
    Divider,
    Grid
} from '@mui/material';
import { format, parse } from "date-fns";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import { toast } from "react-toastify";
import * as yup from "yup";
import { Button } from '../../../components/Button';
import { LegalEntityStepper } from "../../../components/LegalEntityStepper";
import { LoadingBox } from "../../../components/LoadingBox";
import { LoadingButton } from '../../../components/LoadingButton';
import { PageName } from '../../../components/PageName';
import { getLegalEntityResume, saveLegalEntityResume } from "../../../services/onboardingService";
import {
    FormControlLabelStyled,
    FormHelperTextStyled,
    InfoDescription,
    InfoTitle, LinkCheckbox, SubtitlePageForm
} from '../../../styles/styles';
import { displayErrorMessage } from "../../../utils/errorMessage";
import { termsAndAgreementsModal } from "../../../components/TermsAndAgreementsModal";
import { ILegalEntityResume } from "../../../interfaces/legalEntityResume";

const validationSchema = yup.object({
    lgpd: yup.string().oneOf(['true'], "validations.requiredAgree"),
    trueInformation: yup.string().oneOf(['true'], "validations.requiredAgree"),
    authorizedProvide: yup.string().oneOf(['true'], "validations.requiredAgree"),
    jointResolution: yup.string().oneOf(['true'], "validations.requiredAgree"),
});

export function LegalEntityResume() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [resume, setResume] = useState<ILegalEntityResume | null>(null);

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            lgpd: false,
            trueInformation: true,
            authorizedProvide: true,
            jointResolution: false,
        }
    });

    useEffect(() => {
        const fetchLegalEntityResume = async () => {
            try {
                const response = await getLegalEntityResume();

                setResume(response.data);
                setLoading(false);
            } catch (error:any) {
                const errorCode = error.response?.data?.errorCode;
                if (errorCode === "12000") {
                    navigate('/dashboard');
                }
            }
        }
        fetchLegalEntityResume();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = async (data:any) => {
        setSubmitting(true);
        try {
            await saveLegalEntityResume(false);
            navigate('/dashboard/change-profile');
        } catch (error:any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={submitting} style={{ border: 0 }}>
                <Grid
                    container
                >
                    {loading
                        ? <LoadingBox />
                        : <React.Fragment>
                            <LegalEntityStepper
                                activeStep={resume!.legalNature === "120240004" ? 6 : 7}
                                register={false}
                                legalNature={resume!.legalNature}
                            />
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                sx={{
                                    background: 'var(--white)',
                                    borderRadius: '4px',
                                    padding: '2rem',
                                    // '@media(max-width: 899px)': {
                                    //     padding: '0rem',
                                    // }
                                }}
                            >
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <PageName
                                            title={t("text.summary")}
                                            info={t("text.summaryInfoCompany")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <Grid
                                                    container
                                                    spacing={3}
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                            >
                                                                <Grid
                                                                    container
                                                                    spacing={1}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={12}
                                                                        lg={12}
                                                                        xl={12}
                                                                    >
                                                                        <SubtitlePageForm>
                                                                            {t("text.overallData")}
                                                                        </SubtitlePageForm>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={12}
                                                                        lg={12}
                                                                        xl={12}
                                                                    >
                                                                        <Divider />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoTitle>{t("text.cnpj")}</InfoTitle>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoDescription>{formatCNPJ(resume!.cnpj)}</InfoDescription>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoTitle>{t("text.corporateName")}</InfoTitle>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoDescription>{resume!.corporateName}</InfoDescription>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoTitle>{t("text.constituitionDate")}</InfoTitle>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoDescription>{format(parse(resume!.constitutionDate, 'yyyy/MM/dd', new Date()), 'dd/MM/yyyy')}</InfoDescription>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoTitle>{t("text.fantasyName")}</InfoTitle>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoDescription>{resume!.fantasyName}</InfoDescription>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoTitle>{t("text.fieldOfActivity")}</InfoTitle>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoDescription>{resume!.fieldOfActivity}</InfoDescription>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoTitle>{t("text.email")}</InfoTitle>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoDescription>{resume!.email}</InfoDescription>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoTitle>{t("text.site")}</InfoTitle>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoDescription>{resume!.site}</InfoDescription>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoTitle>{t("text.howDidYouHearAboutMoneycorp")}</InfoTitle>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoDescription>{resume!.howDidYouMeet}{resume!.howDidYouMeetExplain ? ' (' + resume!.howDidYouMeetExplain + ')' : ''}</InfoDescription>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                            >
                                                                <Grid
                                                                    container
                                                                    spacing={1}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={12}
                                                                        lg={12}
                                                                        xl={12}
                                                                    >
                                                                        <SubtitlePageForm>
                                                                            {t("text.address")}
                                                                        </SubtitlePageForm>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={12}
                                                                        lg={12}
                                                                        xl={12}
                                                                    >
                                                                        <Divider />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoTitle>{t("text.country")}</InfoTitle>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoDescription>
                                                                    {resume?.country?.toUpperCase()}
                                                                </InfoDescription>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoTitle>{t("text.zipCode")}</InfoTitle>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoDescription>
                                                                    {formatCEP(resume!.addressZipCode)}
                                                                </InfoDescription>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoTitle>{t("text.streetName")}</InfoTitle>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoDescription>
                                                                    {resume!.addressStreet}
                                                                </InfoDescription>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoTitle>{t("text.number")}</InfoTitle>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoDescription>
                                                                    {resume!.addressNumber}
                                                                </InfoDescription>
                                                            </Grid>
                                                            {resume?.country === "BR" ?
                                                                <>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        sm={6}
                                                                        md={6}
                                                                        lg={6}
                                                                        xl={6}
                                                                    >
                                                                        <InfoTitle>{t("text.neighborhood")}</InfoTitle>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        sm={6}
                                                                        md={6}
                                                                        lg={6}
                                                                        xl={6}
                                                                    >
                                                                        <InfoDescription>
                                                                            {resume.addressNeighborhood}
                                                                        </InfoDescription>
                                                                    </Grid> </> : null}
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoTitle>{t("text.city")}</InfoTitle>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoDescription>
                                                                    {resume!.addressCity}
                                                                </InfoDescription>
                                                            </Grid>
                                                            {resume?.addressState === "BR" ?
                                                                <>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        sm={6}
                                                                        md={6}
                                                                        lg={6}
                                                                        xl={6}
                                                                    >
                                                                        <InfoTitle>{t("text.uf")}</InfoTitle>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        sm={6}
                                                                        md={6}
                                                                        lg={6}
                                                                        xl={6}
                                                                    >
                                                                        <InfoDescription>
                                                                            {resume?.addressState?.toUpperCase()}
                                                                        </InfoDescription>
                                                                    </Grid>
                                                                </> : null}
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoTitle>{t("text.complement")}</InfoTitle>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoDescription>
                                                                    {resume!.addressComplement ?? '-'}
                                                                </InfoDescription>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                            >
                                                                <Grid
                                                                    container
                                                                    spacing={1}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={12}
                                                                        lg={12}
                                                                        xl={12}
                                                                    >
                                                                        <SubtitlePageForm>
                                                                            {t("text.financialSituation")}
                                                                        </SubtitlePageForm>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={12}
                                                                        lg={12}
                                                                        xl={12}
                                                                    >
                                                                        <Divider />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoTitle>{t("text.shareCapital")}</InfoTitle>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoDescription>
                                                                    R$ {formatCurrency(resume!.shareCapital)}
                                                                </InfoDescription>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoTitle>{t("text.netWorth")}</InfoTitle>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoDescription>
                                                                    R$ {formatCurrency(resume!.netWorth)}
                                                                </InfoDescription>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoTitle>{t("text.billingAnnual")}</InfoTitle>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoDescription>
                                                                    R$  {formatCurrency(resume!.billingAnnual)}
                                                                </InfoDescription>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoTitle>{t("text.legalNature")}</InfoTitle>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoDescription>
                                                                    {resume!.info}
                                                                </InfoDescription>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoTitle>{t("text.isSimple")}</InfoTitle>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                            >
                                                                <InfoDescription>
                                                                    {resume!.businessInfo === "1" ? t("text.yes") : t("text.no")}
                                                                </InfoDescription>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                            >
                                                                <Grid
                                                                    container
                                                                    spacing={1}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={12}
                                                                        lg={12}
                                                                        xl={12}
                                                                    >
                                                                        <SubtitlePageForm>
                                                                            {t("text.termsAndConditions")}
                                                                        </SubtitlePageForm>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={12}
                                                                        lg={12}
                                                                        xl={12}
                                                                    >
                                                                        <Divider />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                spacing={1}
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={12}
                                                                    lg={12}
                                                                    xl={12}
                                                                >
                                                                    <Controller
                                                                        control={control}
                                                                        name="lgpd"
                                                                        render={({ field: props }) => (
                                                                            <FormControlLabelStyled
                                                                                control={
                                                                                    <Checkbox
                                                                                        {...props}
                                                                                        checked={props.value}
                                                                                        onChange={(e) => props.onChange(e.target.checked)}
                                                                                    />
                                                                                }
                                                                                label={<>{
                                                                                    reactStringReplace(t("text.termsAndConditionsPrivacyPolicy") as string, "{{privacy_policy_link}}", (_, index) => (
                                                                                        <LinkCheckbox key={index} href={t("text.privacyPolicyLink") as string} target="_blank">{t("text.privacyPolicy")}</LinkCheckbox>
                                                                                    ))
                                                                                }
                                                                                </>}
                                                                            />
                                                                        )}
                                                                    />
                                                                    {errors.lgpd && <FormHelperTextStyled>{displayErrorMessage(errors.lgpd?.message) as string}</FormHelperTextStyled>}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={12}
                                                                    lg={12}
                                                                    xl={12}
                                                                >
                                                                    <Controller
                                                                        control={control}
                                                                        name="jointResolution"
                                                                        render={({ field: props }) => (
                                                                            <FormControlLabelStyled
                                                                                control={
                                                                                    <Checkbox
                                                                                        {...props}
                                                                                        checked={props.value}
                                                                                        onChange={(e) => props.onChange(e.target.checked)}
                                                                                    />
                                                                                }
                                                                                label={<>{
                                                                                    reactStringReplace(t("text.jointResolution") as string, "{{terms_and_conditions_link}}", (_, index) => (
                                                                                        <LinkCheckbox
                                                                                            key={index}
                                                                                            component="button"
                                                                                            type="button"
                                                                                            variant="body2"
                                                                                            onClick={async () => {
                                                                                                try {
                                                                                                    await termsAndAgreementsModal();
                                                                                                } catch (ignored) {
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            {t("text.termsAndConditions")}
                                                                                        </LinkCheckbox>
                                                                                    ))
                                                                                }
                                                                                </>}
                                                                            />
                                                                        )}
                                                                    />
                                                                    {errors.jointResolution && <FormHelperTextStyled>{displayErrorMessage(errors.jointResolution?.message) as string}</FormHelperTextStyled>}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                            >
                                                <Button
                                                    component={Link}
                                                    to="/dashboard/financial-data-legal-entity"
                                                    variant="outlined"
                                                    label={t("text.previousStep")}
                                                    fullWidth
                                                    disabled={submitting}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                            >
                                                <LoadingButton
                                                    type="submit"
                                                    variant="contained"
                                                    label={t("text.nextStep")}
                                                    fullWidth
                                                    loading={submitting}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </fieldset>
        </form>
    );
}
