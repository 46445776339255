import { InputLabel, NativeSelect, OutlinedInput } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ISignatureType } from "../../interfaces/signatureTypes";
import React from "react";

interface SignatureTypesOptionsProps {
    control: any ;
    name: string;
    label: string;
    subscriptionTypes: Array<ISignatureType>,
    error: any;
}

export function SignatureTypesOptions({ control, name, label, subscriptionTypes, error } : SignatureTypesOptionsProps) {
    const { t } = useTranslation();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <>
                    <InputLabel
                        variant="standard"
                        htmlFor="uncontrolled-native"
                        sx={{
                            fontWeight: "700",
                            fontSize: ".75rem",
                            color: "var(--gray-600)",
                            display: "flex",
                            alignItems: "flex-end",
                            gap: ".5rem",
                        }}
                    >
                        {label}
                    </InputLabel>
                    <NativeSelect
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        error={error}
                        input={<OutlinedInput />}
                        sx={{
                            marginTop: ".25rem",
                            marginBottom: "0!important",
                            height: "2.75rem",
                            backgroundColor: "#fff",
                        }}
                        fullWidth
                    >
                        <option value="">{t("text.select")}</option>
                        {subscriptionTypes.map((subscriptionType) => (
                            <option
                                key={subscriptionType.id}
                                value={subscriptionType.id}
                            >
                                {subscriptionType.description}
                            </option>
                        ))}
                    </NativeSelect>
                </>
            )}
        />
    );
}