import { Select } from "@mui/material";
import styled from "styled-components";

export const Label = styled.label`
    font-weight: 700;
    font-size: .75rem;
    color: var(--gray-600);
    /* margin-bottom: .25rem; */
    display: flex;
    align-items: flex-end;
    gap: .5rem;
`;

export const SelectStyled = styled(Select)`
    && {
        margin-top: .25rem;
        margin-bottom: 0!important;
        height: 2.75rem;
        background-color: #fff;
    }
`;