import React, { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { InstanceProps, create } from 'react-modal-promise';
import {
    Dialog, DialogContent, DialogActions, Grid, Card, CardActions, Typography
} from '@mui/material';
import { AuthContext } from '../../contexts/auth';
import { DialogTitleStyled } from '../DeleteModal/styles';
import { LoadingBox } from '../LoadingBox';
import { LoadingButton } from '../LoadingButton';
import {
    CardHeaderTitleStyled, FormHelperTextStyled
} from '../../styles/styles';
import Document from "../Document";
import { displayErrorMessage } from "../../utils/errorMessage";
import { toast } from 'react-toastify';
import { toBase64 } from "../../utils/fileUtils";
import { getDocumentsToUpdateById, documentToUpdateSubmission } from "../../services/updateService";
import { IUpdateDocument, defaultValues } from "../../interfaces/updateDocument";


const validationSchema = yup.object({
    proof: yup.mixed()
        .test("required", "validations.fileRequired", (value) => value.length > 0)
        .test("fileSize", "validations.fileSize", (value) => {
            return value.length && value[0].size <= 20971520;
        })
        .test("fileType", "validations.fileType", (value) => {
            return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
        }),
});

interface UpdateDocumentModalProps extends InstanceProps<any, any> {
    open: boolean,
    onResolve: () => void,
    onReject: () => void,
    id?: string
}

const UpdateDocumentModal: React.FC<UpdateDocumentModalProps> = ({ open, onResolve, onReject, id }) => {

    const { t } = useTranslation();
    const { user } = useContext(AuthContext);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [document, setDocument] = useState<IUpdateDocument>(defaultValues);
    const [uploading, setUploading] = useState<boolean>(false);

    const { control, handleSubmit, formState: { errors }, register, reset, getValues, setValue } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            proof: "",
        }
    });

    useEffect(() => {
        async function fetchDocument() {
            try {
                const response = await getDocumentsToUpdateById(id!);
                const data = response.data;

                setDocument(response.data);


                var _proof: any;
                if (data.id) {
                    _proof = new File(["proof"], data.fileOriginalName, {
                        type: data.fileType,
                    });
                    _proof.id = data.id;
                }

                const responseData = {
                    proof: ""
                };

                reset(responseData);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }

        fetchDocument();
    }, []);

    const onSubmit: SubmitHandler<{ proof: any }> = async (data) => {
        try {
            setSubmitting(true);
            const proof = data.proof[0];
            if (proof && !proof.id) {
                const result = await toBase64(proof);
                const data = {
                    ...document,
                    fileOriginalName: proof.name,
                    fileInBase64: result,
                    fileSize: proof.size,
                    fileType: proof.type,
                }

                console.log("data");
                console.log(data);

                await documentToUpdateSubmission(data);
                toast.success(t("text.toast.create"));
            }

            onResolve();

        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }

    }

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            scroll="paper"
            fullWidth
        >
            {loading
                ? <LoadingBox />
                : <React.Fragment>
                    <DialogTitleStyled>
                        {t("text.addDocument")}
                    </DialogTitleStyled>
                    <DialogContent dividers>
                        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
                            <fieldset disabled={submitting} style={{ border: 0 }}>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Card>
                                                <CardHeaderTitleStyled
                                                    title={t(`${document?.documentTypeDescription}`) as string}
                                                    subheader={t(`text.attachDocument`)}
                                                />
                                                <Document
                                                    title={t("text.add")}
                                                    register={register}
                                                    getValues={getValues}
                                                    name="proof"
                                                    uploading={uploading}
                                                    setUploading={setUploading}
                                                />
                                                <CardActions>
                                                    <Typography variant="caption" display="block">
                                                        {t("text.allowedFileTypesAndSize")}
                                                    </Typography>
                                                </CardActions>
                                            </Card>
                                            {errors.proof && <FormHelperTextStyled>{displayErrorMessage(errors.proof?.message) as string}</FormHelperTextStyled>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            disabled={submitting || uploading}
                            onClick={onReject}
                            label={t("text.cancel")}
                        />
                        <LoadingButton
                            type="submit"
                            label={t("text.add")}
                            form="submit-form"
                            loading={submitting || uploading}
                        />
                    </DialogActions>

                </React.Fragment>
            }
        </Dialog>
    )
}

export const updateDocumentModal = create(UpdateDocumentModal);