import {
    Box, CircularProgress, Dialog, DialogContent, DialogTitle, Typography
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';



export const DownloadingDialog = ({ open } : { open: boolean }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open} >
            <DialogTitle>{t("text.downloading")}</DialogTitle>
            <DialogContent>
                <Box sx={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <div>
                        <CircularProgress />
                    </div>
                    <Box>
                        <Typography ml={2} variant="body2" gutterBottom>
                            {t("text.loading")}
                        </Typography>
                        <Typography ml={2} variant="subtitle2">
                            {t("text.dontCloseThisTab")}
                        </Typography>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
