import React, { useState } from "react";
import { create } from 'react-modal-promise';
import {
    Dialog,
    Typography,
    IconButton,
} from '@mui/material';
import { LoadingBox } from '../LoadingBox';
import api from "../../services/api";
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './style.css';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from "react-i18next";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TermsAndAgreementsModal = ({ open, onResolve }) => {
    const { t } = useTranslation();
    const [loadingDocument, setLoadingDocument] = useState(true);
    const [scale, setScale] = React.useState(1.0);
    const [numPages, setNumPages] = React.useState(1);

    const zoomNegative = () => {
        if (scale === 0.6) return;
        setScale((prevCount) => parseFloat((prevCount - 0.4).toFixed(2)));
    };

    const zoomPositive = () => {
        if (scale === 4.2) return;
        setScale((prevCount) => parseFloat((prevCount + 0.4).toFixed(2)));
    };

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
        setLoadingDocument(false);
    }

    return (
        <Dialog
            open={open}
            fullScreen
        >
            <div className="container">
                <div className="section1">
                    <Typography>
                        {t("text.termsAndConditions")}
                    </Typography>
                    <div>
                        <IconButton sx={{ color: "white" }} onClick={zoomNegative}>
                            <RemoveIcon />
                        </IconButton>

                        <IconButton sx={{ color: "white" }} onClick={zoomPositive}>
                            <AddIcon />
                        </IconButton>
                    </div>
                    <IconButton sx={{ color: "white" }} onClick={onResolve}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div class="section2-container">
                    <Document
                        renderMode="canvas"
                        file={`${api.defaults.baseURL}/api/terms-and-agreements`}
                        className="section2"
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={<LoadingBox />}
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                scale={scale}
                                className={loadingDocument ? null : "PDFPage"}
                            />
                        ))}
                    </Document>
                </div>
            </div>
        </Dialog>
    )
}

export const termsAndAgreementsModal = create(TermsAndAgreementsModal);
