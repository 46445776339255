import {
    MenuItem
} from '@mui/material';
import { Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Select } from "../Select";
import React from 'react';

type Origin = {
    id:string ,
    name:string;
}

interface CRMOriginOptionsProps {
    control:any ;
    name: string; 
    origins: Array<Origin> ; 
    error: any; 
    disabled?: boolean
}

export function CRMOriginOptions({ control, name, origins, error, disabled }: CRMOriginOptionsProps) {
    const { t } = useTranslation();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <Select
                    value={field.value}
                    onChange={field.onChange}
                    inputRef={field.ref}
                    error={error}
                    label={t("text.howDidYouHearAboutMoneycorp") as string}
                    displayEmpty
                    disabled={disabled}
                >
                    <MenuItem value="">{t("text.select")}</MenuItem>
                    {origins.map((origin) => (
                        <MenuItem 
                            key={origin.id} 
                            value={origin.id}
                        >
                            {origin.name}
                        </MenuItem>
                    ))}
                </Select>
            )}
        />
    );
}