import {
    MenuItem
} from '@mui/material';
import { Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Select } from "../Select";
import React from 'react';

     
type  BankAccountType = {
description: string;
id: string ; 
name: string
}

interface  CRMBankAccountTypeOptionsProps {
    control :  any;
    name: string ;
    bankAccountTypes : Array<BankAccountType>; 
    error:any ;

}

export function CRMBankAccountTypeOptions({ control, name, bankAccountTypes, error } : CRMBankAccountTypeOptionsProps ) {
    const { t } = useTranslation();
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <Select
                    value={field.value}
                    onChange={field.onChange}
                    inputRef={field.ref}
                    error={error}
                    label={t("text.accountType") as string}
                    displayEmpty
                >
                    <MenuItem value="">{t("text.select")}</MenuItem>
                    {bankAccountTypes.map((bankAccountType) => (
                        <MenuItem 
                            key={bankAccountType.id} 
                            value={bankAccountType.id}
                        >
                            {bankAccountType.description}
                        </MenuItem>
                    ))}
                </Select>
            )}
        />
    );
}