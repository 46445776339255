import { isValidCEP } from '@brazilian-utils/brazilian-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { Search } from '@mui/icons-material';
import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid
} from '@mui/material';
import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { create } from 'react-modal-promise';
import { toast } from 'react-toastify';
import { useDidUpdate } from "rooks";
import * as yup from "yup";
import { Button } from '../Button';
import { LoadingBox } from '../LoadingBox';
import { LoadingButton } from '../LoadingButton';
import { TextField } from '../TextField';
import { findAddressByCep } from '../../services/cepService';
import { getAddressStatePromise } from '../../services/crmAddressState';
import {

    FormHelperTextStyled,

} from '../../styles/styles';

import { displayErrorMessage } from '../../utils/errorMessage';

import * as masks from "../../utils/masks";
import { CRMAddressStateOptions } from '../CRMAddressStateOptions';
import { DialogTitleStyled } from '../DeleteModal/styles';
import { removeSpecialCharactersAndAccents } from '../../utils/stringUtils';
import { getCorporateDataUpdatePromise } from '../../services/updateService';
import { getCRMCountriesPromise } from '../../services/crmCountryService';
import { CRMCountryOptions } from '../CRMCountryOptions';
import { updateCorporateAddress } from '../../services/updateService';
import { AddressState } from '../EditAddressModal/AddressState';
import { IAddress } from '../../interfaces/address'
const validationSchema = yup.object({
    country: yup.string().required(),
    zipCode: yup.string()
        .when("country", {
            is: "BR",
            then: yup.string().required()
                .transform(masks.cepMask.transform)
                .test("validateCep", (value) => {
                    return isValidCEP(value!)
                }),
            otherwise: yup.string().required()
        }),
    street: yup.string().required(),
    number: yup.string().required(),
    neighborhood: yup.string()
        .when("country", {
            is: "BR",
            then: yup.string().required()
        }),
    city: yup.string().required(),
    state: yup.string()
        .when("country", {
            is: "BR",
            then: yup.string().required()
        }),
    complement: yup.string(),
});

interface EditCorporateAddressModalProps {
    open: boolean,
    onResolve: () => void,
    onReject: () => void,
}

const EditCorporateAddressModal: React.FC<EditCorporateAddressModalProps> = ({ open, onResolve, onReject }) => {
    const { t } = useTranslation();
    const [cep, setCep] = useState({ loading: false, error: false });
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [addressStateOptions, setAddressStateOptions] = useState<AddressState[]>([]);
    const [countryOptions, setCountryOptions] = useState([]);

    const { control, handleSubmit, formState: { errors }, reset, setFocus, getValues } = useForm<IAddress>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            country: "BR",
            zipCode: "",
            street: "",
            number: "",
            neighborhood: "",
            city: "",
            state: "",
            complement: "",
        }
    });

    const country = useWatch({
        control,
        name: "country"
    });

    const countryChanged = () => {

        const values = {
            ...getValues(),
            zipCode: "",
            street: "",
            neighborhood: "",
            city: "",
            state: "",
            complement: "",
            number: "",
        }

        setFocus("zipCode", { shouldSelect: true });
        reset(values);
    }

    useEffect(() => {
        async function fetchAddress() {
            try {

                const [addressStateOptions, countryOptions, customer] = await Promise.all([
                    getAddressStatePromise(),
                    getCRMCountriesPromise(),
                    getCorporateDataUpdatePromise()
                ]);

                setAddressStateOptions(addressStateOptions.data);
                setCountryOptions(countryOptions.data);

                const responseData = customer.data;

                let values = {
                    ...responseData,
                    country: responseData.country === "" ? "BR" : responseData.country,
                    zipCode: masks.cepMask.maskDefault(responseData.zipCode),
                }

                reset(values);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }

        fetchAddress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useDidUpdate(() => {
        if (!cep.loading && !cep.error) setFocus("number", { shouldSelect: true });
    }, [cep]);

    const findByCep = async () => {
        const zipCode = masks.cepMask.unmask(getValues("zipCode"));
        if (zipCode === "" || zipCode.length !== 8) return;
        setCep({ loading: true, error: false });
        try {
            const response = await findAddressByCep(zipCode);
            if (response.data.erro) {
                setCep({ loading: false, error: false });
                toast.error(`${t("validations.findAddress")}`);
                return;
            }

            const state = addressStateOptions.find(s => s.state === response.data.uf);
            const values = {
                ...getValues(),
                street: removeSpecialCharactersAndAccents(response.data.logradouro),
                neighborhood: removeSpecialCharactersAndAccents(response.data.bairro),
                city: removeSpecialCharactersAndAccents(response.data.localidade),
                state: state?.id,
                complement: removeSpecialCharactersAndAccents(response.data.complemento),
            }

            setCep({ loading: false, error: false });
            reset(values);
        } catch (error) {
            setCep({ loading: false, error: true });
            console.log('error', error);
            toast.error(t("text.toast.zipCodeServiceUnavailable"));
        }
    }

    const onSubmit = async (data: IAddress) => {

        setSubmitting(true);
        try {
            await updateCorporateAddress(data);
            toast.success(`${t("text.toast.successEdit")}`);
            onResolve();
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            scroll="paper"
            fullWidth
        >
            {loading
                ? <LoadingBox />
                : <React.Fragment>
                    <DialogTitleStyled>
                        {t("text.editAddress")}
                    </DialogTitleStyled>
                    <DialogContent dividers>
                        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
                            <fieldset disabled={submitting || cep.loading} style={{ border: 0 }}>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <CRMCountryOptions
                                            name="country"
                                            control={control}
                                            countries={countryOptions}
                                            error={!!errors.country}
                                            countryChanged={countryChanged}
                                        />
                                        <FormHelperTextStyled>
                                            {displayErrorMessage(errors.country?.message) as string}
                                        </FormHelperTextStyled>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={9}
                                        lg={2}
                                        xl={2}
                                    >
                                        <Controller
                                            control={control}
                                            name="zipCode"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        e.persist();
                                                        if (country === "BR") {
                                                            masks.cepMask.onChange(e);
                                                            field.onChange(e);
                                                        } else {
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        };
                                                    }}
                                                    inputRef={field.ref}
                                                    label={t("text.zipCode")}
                                                    placeholder={t("text.zipCode") as string}
                                                    inputProps={{ maxLength: 20 }}
                                                    fullWidth
                                                    error={!!errors.zipCode}
                                                    helperText={displayErrorMessage(errors?.zipCode?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {country === "BR" ?
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={3}
                                            lg={2}
                                            xl={2}
                                            sx={{
                                                marginTop: '1.3rem',
                                            }}
                                        >
                                            <Button
                                                type="button"
                                                label={t("text.search")}
                                                variant="outlined"
                                                startIcon={<Search />}
                                                onClick={() => findByCep()}
                                                disabled={submitting}
                                                fullWidth
                                            />
                                        </Grid> : null}
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={9}
                                        lg={country === "BR" ? 5 : 7}
                                        xl={country === "BR" ? 5 : 7}
                                    >
                                        <Controller
                                            control={control}
                                            name="street"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={t("text.streetName")}
                                                    placeholder={t("text.streetName") as string}
                                                    inputProps={{ maxLength: 250 }}
                                                    fullWidth
                                                    error={!!errors.street}
                                                    helperText={displayErrorMessage(errors?.street?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                    >
                                        <Controller
                                            control={control}
                                            name="number"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={t("text.number")}
                                                    placeholder={t("text.number") as string}
                                                    inputProps={{ maxLength: 10 }}
                                                    fullWidth
                                                    error={!!errors.number}
                                                    helperText={displayErrorMessage(errors?.number?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={country === "BR" ? 6 : 12}
                                        lg={country === "BR" ? 6 : 12}
                                        xl={country === "BR" ? 6 : 12}
                                    >
                                        <Controller
                                            control={control}
                                            name="complement"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={t("text.complement")}
                                                    placeholder={t("text.complement") as string}
                                                    inputProps={{ maxLength: 100 }}
                                                    fullWidth
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {country === "BR" ?
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <Controller
                                                control={control}
                                                name="neighborhood"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.neighborhood")}
                                                        placeholder={t("text.neighborhood") as string}
                                                        inputProps={{ maxLength: 100 }}
                                                        fullWidth
                                                        error={!!errors.neighborhood}
                                                        helperText={displayErrorMessage(errors?.neighborhood?.message) as string}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid> : null
                                    }
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={country === "BR" ? 9 : 12}
                                        lg={country === "BR" ? 9 : 12}
                                        xl={country === "BR" ? 9 : 12}
                                    >
                                        <Controller
                                            control={control}
                                            name="city"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={t("text.city")}
                                                    placeholder={t("text.city") as string}
                                                    inputProps={{ maxLength: 80 }}
                                                    fullWidth
                                                    error={!!errors.city}
                                                    helperText={displayErrorMessage(errors?.city?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {country === "BR" ?
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={3}
                                            lg={3}
                                            xl={3}
                                        >
                                            <CRMAddressStateOptions
                                                name="state"
                                                label={t("text.uf")}
                                                control={control}
                                                states={addressStateOptions}
                                                error={!!errors.state}
                                            />
                                            <FormHelperTextStyled>
                                                {displayErrorMessage(errors.state?.message) as string}
                                            </FormHelperTextStyled>
                                        </Grid> : null}
                                </Grid>
                            </fieldset>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={submitting || cep.loading}
                            onClick={onReject}
                            label={t("text.cancel")}
                        />
                        <LoadingButton
                            type="submit"
                            label={t("text.saveChanges")}
                            form="submit-form"
                            loading={submitting || cep.loading}
                        />
                    </DialogActions>
                </React.Fragment>
            }
        </Dialog>
    );
}

export const editCorporateAddressModal = create(EditCorporateAddressModal);
