import React from 'react';
import { ButtonStyled } from './styles';

interface TextButtonProps {
  label: string;
  disabled?:boolean, 
  startIcon?: any ,
  component?:any ,
  to?: string ,
  onClick?: ()=> void 
}

const TextButton: React.FC<TextButtonProps> = ({ label, ...rest }) => {
  return (
    <ButtonStyled variant="text" {...rest}>
      {label}
    </ButtonStyled>
  );
};

export default TextButton;
