import { Typography } from "@mui/material";
import styled from "styled-components";

export const PageTitle = styled(Typography)`
    && {
        font-size: 1.5rem;
        font-weight: 700;
        color: var(--gray-900);
        margin-bottom: .25rem;
    }
`;

export const PageInfo = styled(Typography)`
    && {
        font-size: 1rem;
        font-weight: 400;
        color: var(--gray-600);
    }
`;