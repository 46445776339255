import { isValidCPF, isValidCNPJ } from "@brazilian-utils/brazilian-utils";
import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowBack } from "@mui/icons-material";
import { Alert, Grid } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { LoadingButton } from '../../components/LoadingButton';
import TextButton from "../../components/TextButton";
import { TextField } from "../../components/TextField";
import api from '../../services/api';
import { displayErrorMessage } from "../../utils/errorMessage";
import * as masks from "../../utils/masks";

const validationSchema = yup.object({
    username: yup.string().required()
        .transform(masks.cpfCnpjMask.transform)
        .test({
            name: "validateCpfCnpj",
            test: function (value) {
                if (value!.length <= 11) {
                    if (isValidCPF(value!)) {
                        return true;
                    }
                    return this.createError({
                        path: 'username',
                    })
                } else {
                    if (isValidCNPJ(value!)) {
                        return true;
                    }
                    return this.createError({
                        path: 'username',
                    })
                }
            }
        }),
});

export function ForgotPassword() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            username: "",
        }
    })

    const onSubmit = async (data : any) => {
        setLoading(true)
        const { username } = data
        try {
            await api.post(`/api/password/forgot?taxId=${username}`, {},);
            setFeedbackMessage(`${t("text.forgotPasswordFeedbackMessage")}`);
        } catch (error:any) {
            toast.error(error.response.data.message ?? error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                item
                xs={12}
                sm={8}
                md={4}
                lg={4}
                xl={4}
                sx={{ p: 4 }}
            >
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{
                            textTransform: 'uppercase'
                        }}
                    >
                        <TextButton
                            label={t("text.back")}
                            startIcon={<ArrowBack />}
                            component={Link}
                            to="/"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        <Controller
                            control={control}
                            name="username"
                            render={({ field }) => (
                                <TextField
                                    autoFocus
                                    id="username"
                                    fullWidth
                                    value={field.value}
                                    onChange={(e) => {
                                        e.persist();
                                        masks.cpfCnpjMask.onChange(e);
                                        field.onChange(e);
                                    }}
                                    inputRef={field.ref}
                                    label={t("text.registrationHolderCpfCnpj")}
                                    placeholder={t("text.registrationHolderCpfCnpj") as string}
                                    error={!!errors.username}
                                    helperText={displayErrorMessage(errors?.username?.message) as string}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        <LoadingButton
                            type="submit"
                            label={t("text.recoverAccount")}
                            variant="contained"
                            fullWidth
                            loading={loading && feedbackMessage === ""}
                            disabled={feedbackMessage !== ""}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        {feedbackMessage ? (
                            <Alert severity="success">
                                {feedbackMessage}
                            </Alert>
                        ) : (
                            <Alert severity="info">
                                {t("text.recoverAccountEnterYourDocument")}
                            </Alert>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </form >
    );
}
